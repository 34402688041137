import { StopOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Input,
  Row,
  Space,
  Switch,
  Table,
  notification,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import type { RenderedCell } from 'rc-table/lib/interface';
import { useEffect, useMemo, useState } from 'react';
import UserFilter, { FormDataFilter } from 'src/components/UserFilter';
import formatDate from 'src/helper/formatDate';
import {
  FilterAdvancedUser,
  UseFetchUserParams,
  useFetchAdvancedFilterUsers,
} from 'src/hooks/api';
import { useFetchAllegiances } from 'src/hooks/api/allegiance';
import { useAddTagUser, useFetchTags } from 'src/hooks/api/tag';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { User } from 'src/types';

export enum TypeUserTable {
  PAGE = 'page',
  DRAWER = 'drawer',
}
export type UserTableProps = {
  // TODO: refer this by actionRenderer
  isSelect?: boolean;
  actionRenderer?: (
    value: any,
    record: User,
    index: number,
  ) => React.ReactNode | RenderedCell<User> | undefined;
  rowSelection?: TableRowSelection<User>;
  type?: TypeUserTable;
  onSelectedAll?: (value: boolean) => void;
  onFilter?: (value: any) => void;
  optionFilter?: FilterAdvancedUser | undefined;
  selectedAllUser?: boolean;
};

export default function UserTable(props: UserTableProps) {
  const {
    isSelect,
    actionRenderer,
    rowSelection,
    type,
    onSelectedAll,
    selectedAllUser,
    onFilter,
    optionFilter,
  } = props;

  // Hooks
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<User>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [filter, setFilter] = useState<FilterAdvancedUser | null>(null);
  const [openFilter, setOpenFilter] = useState(false);

  // Server states
  const params: UseFetchUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      ...filter,
      search,
    };
  }, [mapTableParams, pagination, sorters, search, filter]);

  const dataSource = useFetchAdvancedFilterUsers({ ...params });
  const dataAllegianceSource = useFetchAllegiances();
  const dataSourceTags = useFetchTags();

  const { mutateAsync: addTagUser } = useAddTagUser();

  useEffect(() => {
    setFilter({ ...optionFilter });
  }, [optionFilter]);

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
  };

  const handleSubmitFilter = (data: FormDataFilter) => {
    const dataFilter = {
      isAirdropped: data.options?.includes('isAirdropped'),
      isWhitelisted: data.options?.includes('isWhitelisted'),
      isBanned: data.options?.includes('isBanned'),
      startCreatedAt: data.createdAt ? data.createdAt[0] : null,
      endCreatedAt: data.createdAt ? data.createdAt[1] : null,
      allegiance: data.allegiance,
      tagIds: data.tagIds,
    };
    setFilter(dataFilter);

    if (onFilter) {
      onFilter(dataFilter);
    }
  };

  const handleResetFilter = () => {
    setFilter(null);
  };

  const handleSetOgStatus = async (user: User) => {
    const tagOg = dataSourceTags.data?.items.find((item) => item.name === 'OG');

    if (tagOg != null) {
      await addTagUser({
        id: tagOg.id,
        userIds: [user.id.toString()],
      });
      notification.success({ message: 'User updated success' });
    }
  };

  const handleSelectAllUser = (e: CheckboxChangeEvent) => {
    if (onSelectedAll) {
      onSelectedAll(e.target.checked);
    }
  };

  const renderTitle = () => (
    <Row>
      {isSelect ? (
        <Col flex={1}>
          <Checkbox onChange={handleSelectAllUser}>All</Checkbox>
        </Col>
      ) : null}
      <Col flex={0}>
        <Space size="small">
          <Input.Search
            allowClear
            placeholder="Search"
            onSearch={handleSearch}
          />
          <UserFilter
            onOpen={handleOpenFilter}
            open={openFilter}
            onCancel={handleCancelFilter}
            onSubmit={(data: FormDataFilter) => handleSubmitFilter(data)}
            allegiances={dataAllegianceSource?.data?.items || []}
            tags={dataSourceTags.data?.items || []}
            onReset={handleResetFilter}
          />
        </Space>
      </Col>
    </Row>
  );

  const renderUserColumn = (user: User) => (
    <>
      {user.isBanned ? (
        <>
          <StopOutlined />
          &nbsp;
        </>
      ) : null}
      {user.username}
    </>
  );

  const renderColumns = (): ColumnsType<User> => [
    {
      title: 'Username',
      render: (_, user) => renderUserColumn(user),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'XPs',
      dataIndex: 'xp',
      width: 100,
      sorter: true,
      responsive: ['xl'],
    },
    {
      title: 'Whitelisted',
      dataIndex: 'isWhitelistClaimed',
      width: '1%',
      render: (value) => <Switch disabled checked={value} />,
    },
    {
      title: 'Airdropped',
      dataIndex: 'isAirdropWhitelistClaimed',
      width: '1%',
      render: (value) => <Switch disabled checked={value} />,
    },
    {
      title: 'OG',
      dataIndex: 'isOg',
      width: '1%',
      render: (value, record) => (
        <Switch checked={value} onChange={() => handleSetOgStatus(record)} />
      ),
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 140,
      render: (value) => formatDate(value),
    },
    {
      key: 'actions',
      title: '',
      width: '1%',
      align: 'right',
      render: actionRenderer,
    },
  ];

  const renderColumnsDrawer = (): ColumnsType<User> => [
    {
      title: 'User',
      render: (_, user) => renderUserColumn(user),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'XPs',
      dataIndex: 'xp',
      width: 100,
      sorter: true,
    },

    {
      title: 'Created date',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => formatDate(value),
    },
  ];

  return (
    <Space style={{ display: 'flex' }} direction="vertical" size="middle">
      {/* TODO: refactor this to use getter instead */}
      {isSelect ? (
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
            selectedRowKeys: selectedAllUser
              ? dataSource.data?.items?.map((item) => item?.id)
              : rowSelection?.selectedRowKeys,
            preserveSelectedRowKeys: true,
          }}
          rowKey={(record) => record.id}
          title={renderTitle}
          columns={
            type === TypeUserTable.DRAWER
              ? renderColumnsDrawer()
              : renderColumns()
          }
          dataSource={dataSource.data?.items}
          loading={dataSource.isLoading}
          pagination={pagination}
          onChange={handleParamsChange}
        />
      ) : (
        <Table
          title={renderTitle}
          columns={
            type === TypeUserTable.DRAWER
              ? renderColumnsDrawer()
              : renderColumns()
          }
          dataSource={dataSource.data?.items}
          loading={dataSource.isLoading}
          pagination={pagination}
          onChange={handleParamsChange}
        />
      )}
    </Space>
  );
}
