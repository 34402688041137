import { Button, Card, Drawer, Form, Input, message, Space } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import CollectionDetail from 'src/components/Collection/CollectionDetail';
import CollectionForm from 'src/components/Collection/CollectionForm';
import CollectionList from 'src/components/Collection/CollectionList';
import BasicModal from 'src/components/Modal';
import {
  createCollection,
  deleteCollection,
  updateCollection,
  useFetchCollections,
} from 'src/hooks/api/collection';
import {
  Collection,
  CollectionDeleteArg,
  CollectionPutArg,
} from 'src/hooks/api/typing';
import { createRequester } from 'src/hooks/useApi';
import { useAppDispatch } from 'src/store/dispatch';
import { ErrorDataResponse, ErrorResponse } from 'src/typing';

type ConfirmCollectionFormData = {
  collectionName: string;
};

function CollectionPage() {
  const { data: collections, refetch } = useFetchCollections();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Action succeed',
      duration: 1,
    });
  };

  const failed = (content: string) => {
    messageApi.open({
      type: 'error',
      content,
      duration: 2,
    });
  };

  const [modal2Open, setModal2Open] = useState<boolean>(false);
  const [modalVerify2Open, setModalVerify2Open] = useState(false);
  const [modal2OpenEditCollection, setModal2OpenEditCollection] =
    useState(false);
  const [selectedCollection, setSelectedCollection] =
    useState<Collection | null>(null);

  const dispatch = useAppDispatch();

  const refetchCollections = () => {
    refetch();
  };

  const onSubmitCollection = async (collection: Collection): Promise<void> => {
    const requester = createRequester({ dispatch });

    try {
      await createCollection({
        requester,
        collection,
      });

      setModal2Open(false);
      refetchCollections();
      success();
    } catch (error) {
      const { response } = error as AxiosError;
      const { message: errMes } = (
        (response?.data as ErrorResponse).error as ErrorDataResponse[]
      )[0];
      failed(errMes || 'Action failed');
    }
  };

  const onSubmitCollectionNewInfo = async (
    collection: CollectionPutArg,
  ): Promise<void> => {
    if (!selectedCollection) {
      return;
    }

    try {
      await updateCollection(collection);

      setSelectedCollection(null);
      setModal2Open(false);
      setModal2OpenEditCollection(false);

      refetchCollections();
      success();
    } catch (error) {
      const { response } = error as AxiosError;
      const { message: errMes } = (
        (response?.data as ErrorResponse).error as ErrorDataResponse[]
      )[0];
      failed(errMes || 'Action failed');
    }
  };

  // const [
  //   collectionToReveal,
  //   setCollectionToReveal] = useState<Collection | null>(null);

  const onViewCollection = (collection: Collection) => {
    setSelectedCollection(collection);
  };

  const onEditCollection = (collection: Collection) => {
    setSelectedCollection(collection);
    setModal2OpenEditCollection(true);
  };

  const onDelete = async (): Promise<void> => {
    if (selectedCollection) {
      const { id } = selectedCollection;

      if (!id) {
        return;
      }

      const requester = createRequester({ dispatch });

      const collectionDeleteParams: CollectionDeleteArg = {
        requester,
        id,
      };

      await deleteCollection(collectionDeleteParams);
      setSelectedCollection(null);
      success();
      refetchCollections();
    }
  };

  const [form] = Form.useForm<ConfirmCollectionFormData>();
  const [isConfirmingCollection, setIsConfirmingCollection] = useState(false);

  const handleSubmit = async (data: ConfirmCollectionFormData) => {
    setIsConfirmingCollection(true);

    const { collectionName } = data;

    if (collectionName !== selectedCollection?.name) {
      failed('Collection name is wrong!');
      return false;
    }

    setIsConfirmingCollection(false);

    await onDelete();

    form.resetFields();
    setModalVerify2Open(false);
    setSelectedCollection(null);

    return true;
  };

  const onOpenVerifyModal = () => {
    setModalVerify2Open(true);
  };

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ display: 'flex' }}
      className="collectionContainer"
    >
      {contextHolder}
      <Button type="primary" onClick={() => setModal2Open(true)}>
        New Collection
      </Button>
      <Card title="Collections">
        <CollectionList
          collections={collections || []}
          onView={onViewCollection}
          onEdit={onEditCollection}
        />
      </Card>
      <BasicModal
        title="Add Collection"
        open={modal2Open}
        onClose={(): void => setModal2Open(false)}
      >
        <CollectionForm
          onFinish={onSubmitCollection}
          collectionData={null}
          isEdit={false}
        />
      </BasicModal>
      <BasicModal
        title="Edit Collection"
        open={modal2OpenEditCollection}
        onClose={(): void => {
          setSelectedCollection(null);
          setModal2OpenEditCollection(false);
        }}
      >
        <CollectionForm
          onFinish={onSubmitCollectionNewInfo}
          collectionData={selectedCollection}
          isEdit
        />
      </BasicModal>
      <BasicModal
        title="Verify this action!"
        open={modalVerify2Open}
        onClose={(): void => setModalVerify2Open(false)}
      >
        <Card>
          <Form name="Confirm collection" form={form} onFinish={handleSubmit}>
            <Form.Item
              name="collectionName"
              rules={[
                {
                  required: true,
                  message:
                    'Please input collection name which you want to delete',
                },
              ]}
            >
              <Input placeholder="Colletion name" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isConfirmingCollection}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </BasicModal>
      {selectedCollection !== null && !modal2OpenEditCollection && (
        <Drawer
          open
          size="large"
          onClose={() => setSelectedCollection(null)}
          extra={
            <Space>
              <Button type="primary" danger onClick={() => onOpenVerifyModal()}>
                Delete
              </Button>
            </Space>
          }
        >
          <CollectionDetail collection={selectedCollection!} />
        </Drawer>
      )}
    </Space>
  );
}

export default CollectionPage;
