import Icon, {
  BellOutlined,
  CheckCircleOutlined,
  CloudDownloadOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  FileImageOutlined,
  MailOutlined,
  QuestionCircleFilled,
  SafetyOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TagsOutlined,
  TeamOutlined,
  ToolOutlined,
  TransactionOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ActivityIcon } from 'src/assets/icons/menu-activity.svg';
import { SystemPermissions } from 'src/constants/roles-permissions';
import { useAppDispatch } from 'src/store/dispatch';
import {
  selectIsSiderCollapse,
  selectPermissionsState,
} from 'src/store/selectors';
import { setSiderCollapse } from 'src/store/slices/app-slice';
import styles from './Sider.module.scss';

type MenuItem = Required<MenuProps>['items'][number] & {
  permission?: string;
  children?: MenuItem[];
};

const items: MenuItem[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    permission: SystemPermissions.VIEW_PAGE_DASHBOARD.key,
    icon: <DashboardOutlined />,
  },
  {
    key: 'members',
    label: 'Members',
    icon: <UserOutlined />,
    children: [
      {
        key: 'users',
        label: 'All Users',
        permission: SystemPermissions.VIEW_PAGE_USER.key,
      },
      {
        key: 'xpLedgers',
        label: 'XP Ledgers',
        permission: SystemPermissions.VIEW_PAGE_LEDGER.key,
      },
      {
        key: 'grantXp',
        label: 'Grant XP',
        permission: SystemPermissions.VIEW_PAGE_GRANT_XP.key,
      },
      {
        key: 'referralLedger',
        label: 'Referral Ledger',
        permission: SystemPermissions.VIEW_PAGE_REFERRAL_LEDGER.key,
      },
      {
        key: 'referralBoard',
        label: 'Referral Leaderboard',
        permission: SystemPermissions.VIEW_PAGE_REFERRAL_BOARD.key,
      },
    ],
  },
  {
    key: 'activity',
    label: 'Activity',
    icon: <Icon component={ActivityIcon} />,
    children: [
      {
        key: 'activities',
        label: 'Activities',
        permission: SystemPermissions.VIEW_PAGE_ACTIVITY.key,
      },
      {
        key: 'activitySubmission',
        label: 'Activity Submissions',
        permission: SystemPermissions.VIEW_PAGE_ACTIVITY_SUBMISSION.key,
      },
    ],
  },
  {
    key: 'allegiance',
    label: 'Allegiance',
    icon: <CheckCircleOutlined />,
    children: [
      {
        key: 'allegiances',
        label: 'Allegiances',
        permission: SystemPermissions.VIEW_PAGE_ALLEGIANCE.key,
      },
      {
        key: 'allegianceSubmission',
        label: 'Allegiance Submissions',
        permission: SystemPermissions.VIEW_PAGE_ALLEGIANCE_SUBMISSION.key,
      },
    ],
  },
  {
    key: 'dropGroup',
    label: 'Drop',
    icon: <ShoppingOutlined />,
    children: [
      {
        key: 'collection',
        label: 'Collections',
        permission: SystemPermissions.VIEW_PAGE_AIRDROP.key,
        icon: <ShopOutlined />,
      },
      {
        key: 'drop',
        label: 'Drops',
        permission: SystemPermissions.VIEW_PAGE_DROP.key,
        icon: <ShoppingOutlined />,
      },
      {
        key: 'whitelistUsers',
        label: 'Whitelist Users',
        permission: SystemPermissions.VIEW_PAGE_WHITELIST_USER.key,
        icon: <UsergroupAddOutlined />,
      },
      {
        key: 'airdrop',
        label: 'Airdrop',
        permission: SystemPermissions.VIEW_PAGE_AIRDROP.key,
        icon: <CloudDownloadOutlined />,
      },
    ],
  },
  {
    key: 'nftMinters',
    label: 'NFT minters',
    permission: SystemPermissions.VIEW_PAGE_DROP.key,
    icon: <TransactionOutlined />,
  },
  {
    key: 'collaborator',
    label: 'Collaborator',
    permission: SystemPermissions.VIEW_PAGE_COLLABORATOR.key,
    icon: <ShopOutlined />,
  },
  {
    key: 'giveAway',
    label: 'Give away',
    permission: SystemPermissions.VIEW_PAGE_GIVE_AWAY.key,
    icon: <ShopOutlined />,
  },
  {
    key: 'discount',
    label: 'Discount',
    permission: SystemPermissions.VIEW_PAGE_DISCOUNT.key,
    icon: <DollarCircleOutlined />,
  },
  {
    key: 'accessManagement',
    label: 'Access Management',
    icon: <SettingOutlined />,
    children: [
      {
        key: 'admin',
        label: 'Admins',
        icon: <TeamOutlined />,
        permission: SystemPermissions.VIEW_PAGE_ADMIN.key,
      },
      {
        key: 'role',
        label: 'Manage Roles',
        permission: SystemPermissions.VIEW_PAGE_ROLE.key,
        icon: <SafetyOutlined />,
      },
    ],
  },
  {
    key: 'tool',
    label: 'Tools',
    icon: <ToolOutlined />,
    children: [
      {
        key: 'deploy-collection-nft',
        label: 'Deploy Collection NFT',
        permission: SystemPermissions.VIEW_PAGE_DEPLOY_COLLECTION_NFT.key,
      },
      {
        key: 'collection-manager',
        label: 'Collection Manager',
        permission: SystemPermissions.VIEW_PAGE_COLLECTION_MANAGER.key,
      },
      {
        key: 'private-sale-manager',
        label: 'Private Sale Manager',
        permission: SystemPermissions.VIEW_PAGE_PRIVATE_SALE_MANAGER.key,
      },
    ],
  },
  {
    key: 'faq',
    label: 'Faqs',
    icon: <QuestionCircleFilled />,
    permission: SystemPermissions.VIEW_PAGE_DISCOUNT.key,
  },
  {
    key: 'assetsRepository',
    label: 'Assets Repository',
    icon: <FileImageOutlined />,
    permission: SystemPermissions.VIEW_PAGE_ASSET.key,
  },
  {
    key: 'notifications',
    label: 'Notifications',
    icon: <BellOutlined />,
    permission: SystemPermissions.VIEW_PAGE_TAG.key,
    children: [
      {
        key: 'notification',
        label: 'Notifications',
        icon: <BellOutlined />,
        permission: SystemPermissions.VIEW_PAGE_NOTIFICATION.key,
      },
      {
        key: 'emailMessages',
        label: 'Email messages',
        icon: <MailOutlined />,
        permission: SystemPermissions.VIEW_PAGE_EMAIL_MESSAGE.key,
      },
    ],
  },
  {
    key: 'tag',
    label: 'Tags',
    icon: <TagsOutlined />,
    permission: SystemPermissions.VIEW_PAGE_TAG.key,
  },
  {
    key: 'totam',
    label: 'Totams',
    icon: <TagsOutlined />,
    children: [
      {
        key: 'totam',
        label: 'Totams',
        permission: SystemPermissions.VIEW_PAGE_TOTAM.key,
      },
      {
        key: 'transaction',
        label: 'Transactions',

        permission: SystemPermissions.VIEW_PAGE_TOTAM.key,
      },
    ],
  },
];

const itemRouteMap: Record<string, string> = {
  dashboard: '/dashboard',
  users: '/users',
  collection: '/collections',
  drop: '/drops',
  whitelistUsers: '/whitelist-users',
  admin: '/admins',
  role: '/roles',
  airdrop: '/airdrop',
  'deploy-collection-nft': '/deploy-collection-nft',
  'collection-manager': '/collection-manager',
  'private-sale-manager': '/private-sale-manager',
  activities: '/activities',
  activitySubmission: '/activity-submissions',
  allegiances: '/allegiances',
  collaborator: '/collaborators',
  allegianceSubmission: '/allegiance-submissions',
  discount: '/discount',
  xpLedgers: '/xp-ledgers',
  grantXp: '/xp-grants',
  faq: '/faq',
  referralLedger: '/referral-ledger',
  referralBoard: '/referral-board',
  assetsRepository: '/assets-repository',
  notification: '/notifications',
  tag: '/tags',
  emailMessages: '/email-messages',
  giveAway: '/give-aways',
  totam: '/totams',
  transaction: '/transactions',
  nftMinters: '/nfts-minters',
};

const reverseItemRouteMap: Record<string, string> = Object.entries(
  itemRouteMap,
).reduce(
  (result, [key, value]) => ({
    ...result,
    [value]: key,
  }),
  {},
);

const getMenusAccess = (
  menus: MenuItem[],
  permissions: string[] | undefined,
) => {
  const data: MenuItem[] = [];

  menus.forEach((item) => {
    const hasPermission = (permission: string | undefined) =>
      !permission || permissions?.includes(permission);

    const hasChildren = (menu: MenuItem) =>
      menu.children && menu.children.length > 0;

    const hasChildrenAccess = (children?: MenuItem[]) =>
      children?.filter((child) =>
        child.permission ? hasPermission(child.permission) : false,
      );

    if (hasChildren(item)) {
      const childrenAccess = hasChildrenAccess(item.children);
      Object.assign(item, { children: childrenAccess });

      if (hasChildren(item)) {
        data.push(item);
      }
    }

    if (hasPermission(item.permission) && !hasChildren(item)) {
      data.push(item);
    }
  });

  return data;
};

export default function Sider() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const permissions = useSelector(selectPermissionsState);

  const collapsed = useSelector(selectIsSiderCollapse);

  const selectedMenuKeys: string[] = useMemo(() => {
    const selectedItem = reverseItemRouteMap[location.pathname];
    return [selectedItem];
  }, [location]);

  const handleMenuClick: MenuProps['onClick'] = (menuInfo) => {
    navigate(itemRouteMap[menuInfo.key]);
  };

  const handleCollapse = (value: boolean) => {
    dispatch(setSiderCollapse(value));
  };

  const canAccessItems = getMenusAccess(items, permissions);

  return (
    <Layout.Sider
      className={styles.sideBar}
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
      width={256}
    >
      <div className={styles.sideBarLogo}>
        <img alt="logo" src="logo.png" />
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={['1']}
        mode="inline"
        items={canAccessItems}
        selectedKeys={selectedMenuKeys}
        onClick={handleMenuClick}
      />
    </Layout.Sider>
  );
}
