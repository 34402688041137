import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import CreateCollaboratorModal from 'src/components/CreateCollaboratorModal';
import formatDate from 'src/helper/formatDate';
import { addAirdropUsers, addEligibleUsers } from 'src/hooks/api';
import { useFetchActivities } from 'src/hooks/api/activity';
import { useFetchAllegiances } from 'src/hooks/api/allegiance';
import {
  useCreateCollaborator,
  useDeleteCollaborator,
  UseFetchCollaboratorParams,
  useFetchCollaborators,
  useUpdateCollaborator,
} from 'src/hooks/api/collaborator';
import { TypeAirdropUser, TypeEligibleUser } from 'src/hooks/api/typing';
import { createRequester } from 'src/hooks/useApi';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { useAppDispatch } from 'src/store/dispatch';
import { ActivityStatus, User } from 'src/types';
import { Collaborator } from 'src/types/Collaborator';
import { ErrorDataResponse } from 'src/typing';

export default function CollaboratorPage() {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<Collaborator>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [collaborator, setCollaborator] = useState<Collaborator | null>(null);

  const dispatch = useAppDispatch();
  const requester = createRequester({ dispatch });
  // Server states
  const params: UseFetchCollaboratorParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);
  const dataSource = useFetchCollaborators({ ...params });

  const { mutateAsync: mutationCreateCollaborator } = useCreateCollaborator();
  const { mutateAsync: mutationUpdateCollaborator } = useUpdateCollaborator();
  const { mutateAsync: mutationDeleteCollaborator } = useDeleteCollaborator();

  const dataAllegianceSource = useFetchAllegiances();
  const dataActivitySource = useFetchActivities();

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleAddCollaborator = () => {
    setOpenDrawer(true);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleClose = () => {
    setIsEdit(false);
    setOpenDrawer(false);
    setCollaborator(null);
  };

  const handleSubmit = async (formValue: any): Promise<void> => {
    try {
      if (!isEdit) {
        await mutationCreateCollaborator(formValue);
        notification.success({ message: 'Collaborator created successfully' });
      } else if (collaborator) {
        await mutationUpdateCollaborator({
          collaboratorID: collaborator.id,
          data: {
            ...formValue,
            userList: formValue.users,
            userToRemove: formValue.userToRemove,
          },
        });
        notification.success({ message: 'Collaborator updated successfully' });
      }

      setOpenDrawer(false);
      setIsEdit(false);
      setCollaborator(null);
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.error[0].message;

      if (errorMessage === 'error.linked_activities_another') {
        notification.error({ message: 'Activity already linked with another Collab' });
      } else {
        notification.error({ message: `Collaborator failed: ${errorMessage}` });
      }
    }
  };

  const handleClickView = (record: Collaborator) => {
    setOpenDrawer(true);
    setCollaborator(record);
  };

  const handleClickEdit = () => {
    setIsEdit(true);
  };

  const handleDeleteCollaborator = async (deleteCollaborator: Collaborator) => {
    try {
      await mutationDeleteCollaborator(deleteCollaborator);
      notification.success({ message: 'Collaborator deleted successfully' });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.error[0].message;
      notification.error({ message: `Collaborator failed: ${errorMessage}` });
    }
  };

  const handleWhiteUser = async (user: User) => {
    try {
      if (!user) {
        return;
      }

      await addEligibleUsers({
        userIds: [user?.id],
        requester,
        type: TypeEligibleUser.collaborator,
      });
      notification.success({ message: 'Whitelist user successfully' });
    } catch (error) {
      notification.error({ message: 'Whitelist user failed' });
    }
  };

  const handleAirdropUser = async (user: User) => {
    try {
      if (!user) {
        return;
      }

      await addAirdropUsers({
        userIds: [user?.id],
        requester,
        type: TypeAirdropUser.collaborator,
      });
      notification.success({ message: 'Airdrop user successfully' });
    } catch (error) {
      notification.error({ message: 'Airdrop user failed' });
    }
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<Collaborator> => [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Referral code',
      dataIndex: 'refCode',
    },
    {
      title: 'Referral XP reward',
      dataIndex: 'referralXP',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },

    {
      title: 'Created at',
      dataIndex: ['createdAt'],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => formatDate(value),
      responsive: ['xl'],
    },
    {
      title: '',
      dataIndex: '',
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleClickView(record)}>View</Button>
          <Popconfirm
            title="Delete the collaborator"
            description="Are you sure you want to delete?"
            onConfirm={async () => handleDeleteCollaborator(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={[0, 16]}>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          onClick={handleAddCollaborator}
          icon={<PlusOutlined />}
        >
          Add collaborator
        </Button>
        <CreateCollaboratorModal
          collaborator={collaborator}
          isEdit={isEdit}
          onEdit={handleClickEdit}
          open={openDrawer}
          onClose={handleClose}
          onSubmit={handleSubmit}
          allegiances={dataAllegianceSource?.data?.items || []}
          onAirdropUser={handleAirdropUser}
          onWhiteUser={handleWhiteUser}
          activities={dataActivitySource?.data?.items.filter(
            (item) => item.status === ActivityStatus.Publish,
          )}
        />
      </Col>
      <Col span={24}>
        <Card title="Collaborators" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
    </Row>
  );
}
