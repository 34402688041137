import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  App,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError, isAxiosError } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import CreateActivity from 'src/components/ActivityDetail/CreateActivity';
import {
  useAssignActivityWinner,
  useCreateActivity,
  useDeleteActivity,
  useFetchActivities,
  UseFetchActivityParams,
  useUpdateActivity,
} from 'src/hooks/api/activity';
import { useFetchAllegiances } from 'src/hooks/api/allegiance';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { Activity, ActivityStatus } from 'src/types/Activity';
import { ErrorDataResponse } from 'src/typing';
import WinnerAssignModal from './WinnerAssignModal';

const formatDate = (date: string) => dayjs(date).format('DD-MM-YYYY');
export const filterProofTypes = [
  {
    text: 'Photo',
    value: 'photo',
  },
  {
    text: 'Video',
    value: 'video',
  },
  {
    text: 'Discord',
    value: 'discord',
  },
  {
    text: 'Text',
    value: 'text',
  },
  {
    text: 'Audio',
    value: 'audio',
  },
];

export default function ActivityPage() {
  // Hooks
  const app = App.useApp();
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<Activity>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [activity, setActivity] = useState<Activity | undefined>(undefined);
  const [edit, setEdit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [openAssignWinnerModal, setOpenAssignWinnerModal] = useState(false);
  // Server states
  const params: UseFetchActivityParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);
  const dataSource = useFetchActivities({ ...params });
  const dataAllegianceSource = useFetchAllegiances();
  const { mutateAsync: mutationCreateActivity } = useCreateActivity();
  const { mutateAsync: mutationUpdateActivity } = useUpdateActivity();
  const { mutateAsync: mutationDeleteActivity } = useDeleteActivity();
  const { mutateAsync: assignActivityWinner } = useAssignActivityWinner();
  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleAddActivity = () => {
    setActivity(undefined);
    setEdit(false);
    setOpenDrawer(true);
  };

  const handleViewActivity = (newActivity: Activity) => {
    setActivity(newActivity);
    setEdit(true);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleDeleteActivity = async (deleteActivity: Activity) => {
    try {
      await mutationDeleteActivity(deleteActivity);
      notification.success({ message: 'Activity deleted successfully' });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Activity deleted failed: ${errorMessage}`,
      });
    }
  };

  const handleSubmit = async (formValues: Activity) => {
    if (!edit) {
      try {
        await mutationCreateActivity(formValues);
        setOpenDrawer(false);
        notification.success({ message: 'Activity created successfully' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notification.error({
          message: `Activity create failed: ${errorMessage}`,
        });
      }
    }

    if (edit) {
      try {
        await mutationUpdateActivity(formValues);
        notification.success({ message: 'Activity updated success' });
        setOpenDrawer(false);
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notification.error({
          message: `Activity update failed: ${errorMessage}`,
        });
      }
    }
  };

  const handleUpdateStatus = async (record: Activity, value: boolean) => {
    const updateActivity = { ...record };

    if (value) {
      updateActivity.status = ActivityStatus.Publish;
    } else {
      updateActivity.status = ActivityStatus.Draft;
    }

    try {
      await mutationUpdateActivity(updateActivity);
      notification.success({ message: 'Activity updated success' });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Activity update failed: ${errorMessage}`,
      });
    }
  };

  const handleAssignWinnerClick = (record: Activity) => {
    setActivity(record);
    setOpenAssignWinnerModal(true);
  };

  const handleAssignTag = async (value: any) => {
    try {
      await assignActivityWinner({
        id: value.activityId,
        userIds: value.userIds,
      });
      app.message.success('Success');
    } catch (err: any) {
      const errorMessage = isAxiosError(err)
        ? err.response?.data?.message
        : err.message;
      app.message.error(errorMessage);
    } finally {
      setOpenAssignWinnerModal(false);
    }
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<Activity> => [
    {
      title: 'Activity name',
      dataIndex: 'title',
      sorter: true,
      filters: filterProofTypes,
      onFilter: (value, record): boolean =>
        record.submissionType.proofType === value,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'XP tokens',
      dataIndex: 'xpAward',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 120,
      render: (value) => formatDate(value),
      responsive: ['lg'],
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 120,
      render: (value) => formatDate(value),
      responsive: ['lg'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 200,
      render: (_, record) =>
        `${
          record.description?.length >= 22
            ? `${record.description?.substr(0, 22)}...`
            : record.description
        }`,
      responsive: ['xl'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '1%',
      render: (_, record) => (
        <Switch
          checkedChildren="Published"
          unCheckedChildren="Draft"
          checked={record.status === ActivityStatus.Publish}
          onChange={(value) => handleUpdateStatus(record, value)}
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      width: '15%',
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleViewActivity(record)}>Edit</Button>
          <Popconfirm
            title="Delete the activity"
            description="Are you sure to delete this activity?"
            onConfirm={async () => handleDeleteActivity(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
          <Button onClick={() => handleAssignWinnerClick(record)}>
            Assign winner
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row justify="end" gutter={[0, 16]}>
        <Col flex="0 0 0">
          <Button
            type="primary"
            onClick={handleAddActivity}
            icon={<PlusOutlined />}
          >
            Add activity
          </Button>
        </Col>
        <Col span={24}>
          <Card title="Activities" extra={renderSearch()}>
            <Table
              columns={renderColumns()}
              dataSource={dataSource.data?.items}
              loading={dataSource.isLoading}
              pagination={pagination}
              onChange={handleParamsChange}
            />
          </Card>
        </Col>
      </Row>
      <CreateActivity
        activity={activity}
        onClose={handleClose}
        open={openDrawer}
        onSubmit={(activityData) => handleSubmit(activityData)}
        allegiances={dataAllegianceSource.data?.items}
      />
      {openAssignWinnerModal ? (
        <WinnerAssignModal
          activity={activity}
          open={openAssignWinnerModal}
          onOk={handleAssignTag}
          onCancel={() => setOpenAssignWinnerModal(false)}
        />
      ) : null}
    </>
  );
}
