import { AxiosInstance } from 'axios';
import { PrivateSale } from 'src/types/Drop';

export type PaginationParams = {
  page?: string | number;
  limit?: string | number;
};
export type SortDirection = 'ASC' | 'DESC';
export type SortParams = {
  sortBy?: string;
  sortDirection?: SortDirection;
};
export type SearchParams = {
  search?: string;
};

export type PageMeta = {
  totalItems: number;
  totalPages: number;
};
export type Page<T> = {
  items: T[];
  totalXpAwarded?: number;
  meta: PageMeta;
};

export enum ProfileStatus {
  None = 'none',
  Eligible = 'eligible',
  Claimed = 'claimed',
  Claimable = 'claimable',
}
export type ShippingAddress = {
  name: string;
  address1: string;
  address2: string;
  address3: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
};
export type User = {
  id: number;
  walletAddress: string;
  walletType: string;
  whitelistStatus: ProfileStatus;
  airdropStatus: ProfileStatus;
  whitelistDate?: Date;
  xp: number;
  avatar?: string;
  email: string;
  discordUsername?: string;
  twitterHandle?: string;
  telegramUsername?: string;
  username?: string;
  refCode: string;
  shippingAddress?: ShippingAddress;
  isOg: boolean;
  isBanned: boolean;
  createdAt: Date;
  isWhitelistClaimed: boolean;
  isWhitelistEligible: boolean;
  isAirdropWhitelistClaimed: boolean;
  isAirdropWhitelistEligible: boolean;
};

export type Collection = {
  id?: number;
  name: string;
  totalSupply: number;
  defaultNftImg: string;
  contractAddress?: string;
  isReveal?: boolean;
  imageFolderURL?: string;
  collectionTagImage?: string;
  collectionNotificationImage?: string;
  collectionDescription: string;
  nftDescription: string;
};

export type CollectionPostArg = {
  requester: AxiosInstance;
  collection: Collection;
};

export type CollectionDeleteArg = {
  requester: AxiosInstance;
  id: number;
};

export type CollectionPutArg = {
  requester: AxiosInstance;
  id: number;
  contractAddress?: string;
  name?: string;
  totalSupply?: number;
  imageFolderURL?: string;
  collectionTagImage?: string;
  collectionNotificationImage?: string;
  defaultNftImg?: string;
  collectionDescription?: string;
  nftDescription?: string;
};

export type GetDropDetailsArg = {
  requester: AxiosInstance;
  dropId: string;
};

export type Drop = {
  id?: number;
  name: string;
  maxWhitelistNFTPerPerson: number;
  maxAirdropNFTPerPerson: number;
  maxAirdropQty?: number;
  maxPreSaleQty: number;
  presaleStart: Date;
  presaleEnd: Date;
  publicSaleEnd: Date;
  publicSaleStart: Date;
  claimStartDate: Date;
  claimEndDate: Date;
  qty: number;
  nfts?: any[];
  collectionId?: number;
  isRevealed?: boolean;
  mActive: boolean;
  preSalePrice: number;
  publicSalePrice: number;
  whitelistXpFee: number;
  airdropXpFee: number;
  dropTagImage?: string;
  dropNotificationImage?: string;
} & { privateSale?: PrivateSale };

export type DropStatistics = {
  totalNFTMinted: number;
  totalNFTAirDropped: number;
  totalWhitelistClaimedUsers: number;
  totalWhitelistEligibleUsers: number;
  totalAirdropClaimedUser: number;
  totalAirdropEligibleUser: number;
  totalPreSaleNFT: number;
  totalPublicSaleNFT: number;
  totalAirdropNFTReceivedUser: number;
};

export type AirdropEligibleUser = {
  isClaimed: boolean;
  isClaimFree: boolean;
  id: number;
  dropId: number;
  userId: number;
};

export type EligibleUser = {
  id?: number;
  isWhitelisted: boolean;
  dropId: number;
  userId: number;
};

export type EligibleUserTransform = {
  id: number;
  dropName: string;
  userName: string;
  isWhitelisted: boolean;
  userId?: number;
};

export type DropPostArg = {
  requester: AxiosInstance;
  drop: Drop;
};

export type DropPutArg = {
  requester: AxiosInstance;
  drop: Partial<Drop>;
};

export enum TypeEligibleUser {
  normal = 'normal',
  collaborator = 'collaborator',
  claimFree = 'claimFree',
}

export enum TypeAirdropUser {
  normal = 'normal',
  collaborator = 'collaborator',
  claimFree = 'claimFree',
}

export type AddEligibleUsersArg = {
  requester: AxiosInstance;
  userIds: number[];
  type?: TypeEligibleUser;
};

export type GetCollectionDetailsArg = {
  requester: AxiosInstance;
  collectionId: number;
};

export type AddAirdropUsersArg = {
  requester: AxiosInstance;
  userIds: number[];
  type?: TypeAirdropUser;
};

export type RecipientInfoType = {
  userId: number;
  address: string;
};

export type AirdropNFTInfoType = {
  tokenId: number[];
  walletAddress: string[];
  txHash: string;
};

export type AirdropArg = {
  requester: AxiosInstance;
  airdropNFTInfo: AirdropNFTInfoType;
};

export type RevealDropArg = {
  id: number;
  requester: AxiosInstance;
};

export type ActivateDropArg = {
  id: number;
  requester: AxiosInstance;
};

export enum NFTType {
  Airdrop = 'airdrop',
  Mint = 'mint',
}

export type NFT = {
  id: number;
  tokenId: number;
  dropId: number;
  voucherId: number;
  paidAmount: number;
  recipientWalletAddress?: string;
  userId: number;
  generatedAt: number | string;
  walletAddress?: string;
  from?: string;
  txHash?: string;
  type: NFTType;
};

export type NFTVoucher = {
  expiry: number;
  id: number;
  nonce: number;
  price: number;
  quantity: string;
  recipientWalletAddress: string;
  signature: string;
  userId: number;
};
