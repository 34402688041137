import { Faq } from 'src/types/Faq';
import {
  Page, PaginationParams, SearchParams, SortParams,
} from './typing';
import useFetch from './useFetch';

const KEY_FAQ_QUERIES = ['faqs'];

export type UseFetchFaqParams = PaginationParams & SortParams & SearchParams;

export const useFetchFaqs = (params?: UseFetchFaqParams) => useFetch<Page<Faq>>({
  key: [...KEY_FAQ_QUERIES, params],
  url: '/faqs',
  searchParams: params,
});
