import { ProCard, ProDescriptions } from '@ant-design/pro-components';
import { Button, Image, Row, Space } from 'antd';
import { useActiveDrop, useRevealDrop } from 'src/hooks/api';
import { Collection, Drop } from 'src/hooks/api/typing';

type DropDetailProps = {
  drop: Drop | undefined;
  collection: Collection | undefined;
};

function renderDropStatus(drop: Drop | undefined) {
  return drop?.mActive ? 'Drop active' : 'Drop not active';
}

function renderRevealStatus(drop: Drop | undefined) {
  return drop?.isRevealed ? 'Drop revealed' : 'Drop not revealed';
}

function DropDetails(params: DropDetailProps) {
  const { drop } = params;

  const { mutateAsync: activeDrop, isLoading: isActivating } = useActiveDrop({
    dropId: JSON.stringify(drop?.id),
  });

  const onActivateDrop = async () => {
    if (drop && drop.id) {
      activeDrop({ dropId: drop.id.toString() });
    }
  };

  const { mutateAsync: revealDrop, isLoading: isRevealing } = useRevealDrop({
    dropId: JSON.stringify(drop?.id),
  });

  const onRevealDrop = async () => {
    if (drop && drop.id) {
      revealDrop({ dropId: drop.id.toString() });
    }
  };

  const renderTitleButtons = () => (
    <Row>
      <Space
        size="small"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          type="default"
          onClick={() => {
            onActivateDrop();
          }}
          loading={isActivating}
          disabled={drop?.mActive}
        >
          {drop?.mActive ? 'Make End' : 'Make Active'}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onRevealDrop();
          }}
          loading={isRevealing}
          disabled={drop?.isRevealed}
        >
          Reveal Drop
        </Button>
      </Space>
    </Row>
  );

  return (
    <ProCard title="Drop Details" extra={renderTitleButtons()} split="vertical">
      <ProCard colSpan={8} bordered={false}>
        <ProDescriptions
          column={1}
          emptyText="-"
          dataSource={params}
          columns={[
            {
              title: 'Drop Name',
              dataIndex: ['drop', 'name'],
            },
            {
              title: 'Collection Name',
              dataIndex: ['collection', 'name'],
            },
            {
              title: 'Max Whitelist NFT per person',
              dataIndex: ['drop', 'maxWhitelistNFTPerPerson'],
            },
            {
              title: 'Max Airdrop NFT per person',
              dataIndex: ['drop', 'maxAirdropNFTPerPerson'],
            },
            {
              title: 'Total NFTs reserved for Airdrop',
              dataIndex: ['drop', 'maxAirdropQty'],
            },
            {
              title: 'Max Presale quantity',
              dataIndex: ['drop', 'maxPreSaleQty'],
            },
            {
              title: 'Total NFT Quantity',
              dataIndex: ['drop', 'qty'],
            },
            {
              title: 'Whitelist XP Fee',
              dataIndex: ['drop', 'whitelistXpFee'],
            },
            {
              title: 'Airdrop XP Fee',
              dataIndex: ['drop', 'airdropXpFee'],
            },
            {
              title: 'NFT Collection URL',
              dataIndex: ['drop', 'qty'],
            },
          ]}
        />
      </ProCard>
      <ProCard colSpan={8} split="horizontal">
        <ProDescriptions
          column={1}
          emptyText="-"
          dataSource={params}
          columns={[
            {
              title: 'Presale start',
              dataIndex: ['drop', 'presaleStart'],
              valueType: 'dateTime',
            },
            {
              title: 'Presale end',
              dataIndex: ['drop', 'presaleEnd'],
              valueType: 'dateTime',
            },
            {
              title: 'Public sale start',
              dataIndex: ['drop', 'publicSaleStart'],
              valueType: 'dateTime',
            },
            {
              title: 'Public sale end',
              dataIndex: ['drop', 'publicSaleEnd'],
              valueType: 'dateTime',
            },
            {
              title: 'Private sale start',
              dataIndex: ['drop', 'privateSale', 'startDate'],
              valueType: 'dateTime',
            },
            {
              title: 'Private sale end',
              dataIndex: ['drop', 'privateSale', 'endDate'],
              valueType: 'dateTime',
            },

            {
              title: 'Private sale total quantity',
              dataIndex: ['drop', 'privateSale', 'totalQty'],
              valueType: 'string',
            },

            {
              title: 'Private sale min quantity per user',
              dataIndex: ['drop', 'privateSale', 'minQtyPerUser'],
              valueType: 'string',
            },
            {
              title: 'Private sale max quantity per user',
              dataIndex: ['drop', 'privateSale', 'maxQtyPerUser'],
              valueType: 'string',
            },
            {
              title: 'Private sale price',
              dataIndex: ['drop', 'privateSale', 'price'],
              valueType: 'string',
            },
            {
              title: 'Private sale is activate',
              dataIndex: ['drop', 'privateSale', 'isActivated'],
              valueType: 'string',
              renderText: (value) => (value ? 'true' : 'false'),
            },
            {
              title: 'Private sale smart contract id',
              dataIndex: ['drop', 'privateSale', 'smartContractId'],
              valueType: 'string',
            },
          ]}
        />
        <ProCard title="DROP STATUS" ghost>
          <ProDescriptions
            column={1}
            emptyText="-"
            dataSource={params}
            columns={[
              {
                title: 'Status',
                renderText: () => renderDropStatus(params.drop),
              },
              {
                title: 'Reveal status',
                renderText: () => renderRevealStatus(params.drop),
              },
            ]}
          />
        </ProCard>
      </ProCard>
      <ProCard colSpan={4}>
        <Image
          src={`${process.env.AWS_S3_BUCKET_ENDPOINT}/${params.drop?.dropTagImage}`}
        />
      </ProCard>
      <ProCard colSpan={4}>
        <Image
          src={`${process.env.AWS_S3_BUCKET_ENDPOINT}/${params.drop?.dropNotificationImage}`}
        />
      </ProCard>
    </ProCard>
  );
}

export default DropDetails;
