import { Layout } from 'antd';
import classNames from 'classnames';
import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { mainnet, bscTestnet, sepolia, goerli } from 'wagmi/chains';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Header from 'src/components/Header';
import Sider from 'src/components/Sider';
import { selectIsSiderCollapse } from 'src/store/selectors';

import styles from './App.module.scss';

const { Footer } = Layout;

const { provider, webSocketProvider } = configureChains(
  [mainnet, bscTestnet, sepolia, goerli],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        let http;

        if (chain.id === 11155111)
          http =
            'https://sepolia.infura.io/v3/48c040c780b34ed9acce325dffdc7faf';

        if (chain.id === 5)
          http = 'https://goerli.infura.io/v3/48c040c780b34ed9acce325dffdc7faf';

        if (chain.id === 1 || chain.id === 97)
          http = chain.rpcUrls.default.http[0];

        if (!http) return null;

        return { http };
      },
    }),
  ],
);

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
});

function App() {
  // const navigate = useNavigate();
  // Local states
  const year = new Date().getFullYear();
  // Global states
  const isSiderCollapsed = useSelector(selectIsSiderCollapse);
  console.log(process.env);

  return (
    <WagmiConfig client={client}>
      <Layout className={styles.page} hasSider>
        <Sider />
        <Layout
          className={classNames(
            styles.contentContainer,
            !isSiderCollapsed && styles.contentContainerSiderOpen,
          )}
        >
          <Header />
          <Layout.Content className={styles.content}>
            <Outlet />
          </Layout.Content>
          <Footer className={styles.footer}>Plantet Tota ©{year}</Footer>
        </Layout>
      </Layout>
    </WagmiConfig>
  );
}

export default App;
