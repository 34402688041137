import { Button, Modal, ModalProps, Space } from 'antd';
import { useState } from 'react';
import { Tag } from 'src/types';
import UserTable, { UserTableProps } from '../UserTable';

export type AssignTagValue = {
  tagId: number;
  userIds: string[];
};

export type TagAssignModalProps = Omit<ModalProps, 'onOk'> & {
  tag: Tag;
  onOk?: (value: AssignTagValue) => void | Promise<void>;
};

export default function TagAssignModal(props: TagAssignModalProps) {
  const { tag, onOk = () => {}, onCancel = () => {}, ...otherProps } = props;

  // Local states
  const [selectedUserIds, setSelectedUserIds] = useState<React.Key[]>([]);

  const resetState = () => {
    setSelectedUserIds([]);
  };

  const userTableSelection: UserTableProps['rowSelection'] = {
    selectedRowKeys: selectedUserIds,
    onChange: (newKeys: React.Key[]) => setSelectedUserIds(newKeys),
  };

  const handleOk: ModalProps['onOk'] = async () => {
    await onOk({
      tagId: tag.id,
      userIds: selectedUserIds.map((key) => key.toString()),
    });
    resetState();
  };

  const handleCancel: ModalProps['onCancel'] = (e) => {
    onCancel(e);
    resetState();
  };

  const renderTitle = () => (
    <Space
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      Assign
      <Space style={{ marginRight: '30px' }}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleOk} type="primary">
          {' '}
          Assign
        </Button>
      </Space>
    </Space>
  );

  return (
    <Modal
      {...otherProps}
      title={renderTitle()}
      centered
      width={1024}
      footer={false}
      onCancel={handleCancel}
    >
      {tag.name}
      <UserTable isSelect rowSelection={userTableSelection} />
    </Modal>
  );
}
