import { createSlice } from '@reduxjs/toolkit';
import { Collaborator } from 'src/types/Collaborator';
import { getCollaboratorProfile } from '../actions';

export type CollaboratorInitialState = {
  collaborator?: Collaborator;
  isLoading: boolean;
  error: unknown;
};
const COLLABORATOR_INITIAL_STATE: CollaboratorInitialState = {
  isLoading: true,
  error: null,
};

const collaboratorSlice = createSlice({
  name: 'collaborator',
  initialState: COLLABORATOR_INITIAL_STATE,
  reducers: {
    clearCollaboratorProfile: (state) => {
      state.collaborator = undefined;
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCollaboratorProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.collaborator = action.payload;
      });
  },
});

export const { clearCollaboratorProfile } = collaboratorSlice.actions;
const collaboratorReducer = collaboratorSlice.reducer;
export default collaboratorReducer;
