import { useMemo } from 'react';
import { Image, ImageProps } from 'antd';

type ViewImageProps = ImageProps & {
  src?: string;
};

function ViewImage(props: ViewImageProps) {
  const { src, ...otherProps } = props;

  const imageUrl = useMemo(() => {
    if (src == null) {
      return undefined;
    }

    const url = new URL(`v2.0/files/${src}`, process.env.REACT_APP_API_URL);
    return url.toString();
  }, [src]);

  return (
    <Image
      src={imageUrl}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
}

export default ViewImage;
