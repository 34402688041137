import { Button, Col, Row, Typography } from 'antd';
import useWeb3 from 'src/hooks/useWeb3';
import { useSwitchNetwork } from 'wagmi';
import styles from './Web3Panel.module.scss';

const { Text } = Typography;

export default function Web3Panel() {
  // TODO: remove me once env is update
  const chainId = process.env.REACT_APP_CHAIN_ID;
  console.log('Web3Panel');
  console.log(process.env.REACT_APP_CHAIN_ID);
  const { isConnected, connect, disconnect, address, chain } = useWeb3();

  const { switchNetwork } = useSwitchNetwork();

  const handleConnectWallet = () => {
    connect();
  };

  const handleDisconnectWallet = () => {
    disconnect();
  };

  const handleSwitchChain = () => {
    if (!switchNetwork) return;
    switchNetwork(Number(chainId));
  };

  if (!isConnected) {
    return (
      <Col span={24}>
        <Button onClick={handleConnectWallet}>Connect wallet</Button>
      </Col>
    );
  }

  if (chain?.id.toString() !== chainId) {
    return (
      <Col span={24}>
        <Button onClick={handleSwitchChain}>Switch chain</Button>
      </Col>
    );
  }

  return (
    <Col span={24} className={styles.web3PanelContainer}>
      <Row gutter={16} align="middle">
        <Col>
          <Text>Connected address: </Text>
          <Text copyable strong>
            {address}
          </Text>
        </Col>
        <Col>
          <Button onClick={handleDisconnectWallet}>Disconnect</Button>
        </Col>
      </Row>
    </Col>
  );
}
