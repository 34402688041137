import {
  Button, Card, Col, Input, notification, Row, Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import CreateRole from 'src/components/RoleDetail/CreateRole';
import {
  useCreateRole,
  useFetchPermissions, UseFetchRoleParams, useFetchRoles, useUpdateRole,
} from 'src/hooks/api/role';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { Role } from 'src/types/Admin';
import { ErrorDataResponse } from 'src/typing';

export const initialRole: Role = {
  name: '',
  description: '',
  key: '',
  permissions: [],
};

export default function RolePage() {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<Role>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [role, setRole] = useState<Role>(initialRole);
  const [edit, setEdit] = useState(false);

  // Server states
  const params: UseFetchRoleParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);

  const dataSource = useFetchRoles(params);

  const permissions = useFetchPermissions();

  const mutationCreateRole = useCreateRole({
    key: role.key,
    name: role.name,
    description: role.description,
    permissions: role.permissions.map((permission) => permission.id) as number[],
  });

  const mutationUpdateRole = useUpdateRole({
    id: role.id,
    key: role.key,
    name: role.name,
    description: role.description,
    permissions: role.permissions.map((permission) => permission.id),
  });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  useEffect(() => {
    if (!edit) {
      setRole(initialRole);
    }
  }, [edit]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSubmit = async () => {
    if (!edit) {
      try {
        await mutationCreateRole.mutateAsync();
        notification.success({ message: 'Role created successfully' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response?.data.message;
        notification.error({ message: `Role create failed: ${errorMessage}` });
      }
    }

    if (edit) {
      try {
        await mutationUpdateRole.mutateAsync();
        notification.success({ message: 'Role updated success' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response?.data.message;
        notification.error({ message: `Role update failed: ${errorMessage}` });
      }
    }
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<Role> => [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Key',
      dataIndex: 'key',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      key: 'actions',
      width: '1%',
      render: (_, record) => (
        <Button onClick={() => {
          setRole({
            id: record.id,
            key: record.key,
            name: record.name,
            permissions: record.permissions,
            description: record.description,

          });
          setEdit(true);
        }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col flex="0 0 0">
        <CreateRole
          edit={edit}
          setRole={setRole}
          role={role}
          permissions={permissions?.data?.items || []}
          onSubmit={handleSubmit}
          setEdit={setEdit}
        />
      </Col>
      <Col span={24}>
        <Card title="Roles" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
    </Row>
  );
}
