import {
  Button, Card, Col, Input, notification, Row, Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import CreateAccount from 'src/components/AdminDetail/CreateAccount';
import formatDate from 'src/helper/formatDate';
import {
  useCreateAdmin, UseFetchAdminParams, useFetchAdmins, useUpdateAdmin,
} from 'src/hooks/api/admin';
import { useFetchRoles } from 'src/hooks/api/role';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { Admin, Role } from 'src/types/Admin';
import { ErrorDataResponse } from 'src/typing';

export const initialAdmin: Admin = {
  username: '',
  name: '',
  email: '',
  password: '',
  roles: [],
};

export default function AdminPage() {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<Admin>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [admin, setAdmin] = useState<Admin>(initialAdmin);
  const [edit, setEdit] = useState(false);

  // Server states
  const params: UseFetchAdminParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);
  const dataSource = useFetchAdmins({
    ...params,
  });
  const roles = useFetchRoles();
  const mutationCreateAdmin = useCreateAdmin({
    name: admin.name,
    username: admin.username,
    email: admin.email,
    password: admin.password as string,
    roles: admin?.roles?.map((role) => role.id) as number[],
  });
  const mutationUpdateAdmin = useUpdateAdmin({
    id: admin.id,
    username: admin.username,
    name: admin.name,
    email: admin.email,
    password: admin.password,
    roles: admin?.roles?.map((role) => role.id) as number[],
  });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  useEffect(() => {
    if (!edit) {
      setAdmin(initialAdmin);
    }
  }, [edit]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSubmit = async () => {
    if (!edit) {
      try {
        await mutationCreateAdmin.mutateAsync();
        notification.success({ message: 'Admin created successfully' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response?.data.message;
        notification.error({ message: `Admin create failed:${errorMessage}` });
      }
    }

    if (edit) {
      try {
        await mutationUpdateAdmin.mutateAsync();
        notification.success({ message: 'Admin updated successfully' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response?.data.message;
        notification.error({ message: `Admin update failed: ${errorMessage}` });
      }
    }
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<Admin> => [

    {
      title: 'User Name',
      dataIndex: 'username',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Role',
      dataIndex: 'roles',
      filters: roles.data?.items.map((item) => ({
        text: item.name,
        value: item.key,
      })),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => record.roles[0].key === value,
      render: (value) => value?.map((role: Role, index: number) => (index !== value.length - 1 ? `${role.name}, ` : role.name)),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (value) => formatDate(value.toString()),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'actions',
      width: '1%',
      render: (_, record) => (
        <Button onClick={() => {
          setAdmin({
            id: record.id as number,
            email: record.email,
            username: record.username,
            roles: record.roles,
          });
          setEdit(true);
        }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col flex="0 0 0">
        <CreateAccount
          edit={edit}
          setAdmin={setAdmin}
          admin={admin}
          roles={roles?.data?.items || []}
          onSubmit={handleSubmit}
          setEdit={setEdit}
        />
      </Col>
      <Col span={24}>
        <Card title="Admins" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
    </Row>
  );
}
