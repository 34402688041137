import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  notification,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import CreateAllegiance from 'src/components/AllegianceDetail/AllegianceDetail';
import {
  UseFetchAllegianceParams,
  useCreateAllegiance,
  useDeleteAllegiance,
  useFetchAllegiances,
  useUpdateAllegiance,
} from 'src/hooks/api/allegiance';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { Allegiance } from 'src/types/Allegiance';
import { ErrorDataResponse } from 'src/typing';
import { filterProofTypes } from './Activity';

export default function AllegiancePage() {
  // Hooks
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<Allegiance>();
  const { mapTableParams } = useMapTableParam();

  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [allegiance, setAllegiance] = useState<Allegiance | undefined>(
    undefined,
  );
  const [edit, setEdit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const params: UseFetchAllegianceParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);

  const dataSource = useFetchAllegiances(params);
  const { mutateAsync: mutationCreateAllegiance } = useCreateAllegiance();
  const { mutateAsync: mutationUpdateAllegiance } = useUpdateAllegiance();
  const { mutateAsync: mutationDeleteAllegiance } = useDeleteAllegiance();

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleAddAllegiance = () => {
    setAllegiance(undefined);
    setEdit(false);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleSubmit = async (formValues: Allegiance) => {
    if (!edit) {
      try {
        await mutationCreateAllegiance(formValues);
        setOpenDrawer(false);
        notification.success({ message: 'Allegiance created successfully' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notification.error({
          message: `Allegiance create failed: ${errorMessage}`,
        });
      }
    }

    if (edit) {
      try {
        await mutationUpdateAllegiance(formValues);
        setOpenDrawer(false);
        notification.success({ message: 'Allegiance updated success' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notification.error({
          message: `Allegiance update failed: ${errorMessage}`,
        });
      }
    }
  };

  const handleViewAllegiance = (newAllegiance: Allegiance) => () => {
    setAllegiance(newAllegiance);
    setEdit(true);
    setOpenDrawer(true);
  };

  const handleUpdateStatusAllegiance = async (
    record: Allegiance,
    status: boolean,
  ) => {
    try {
      await mutationUpdateAllegiance({
        ...record,
        status,
      });
      notification.success({
        message: 'Allegiance updated status successfully',
      });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Allegiance updated status failed: ${errorMessage}`,
      });
    }
  };

  const handleUpdateOGStatusAllegiance = async (
    record: Allegiance,
    status: boolean,
  ) => {
    try {
      await mutationUpdateAllegiance({
        ...record,
        isOg: status,
      });
      notification.success({
        message: 'Allegiance updated OG status successfully',
      });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Allegiance updated OG status failed: ${errorMessage}`,
      });
    }
  };

  const handleUpdateAutoDeleteAllegiance = async (
    record: Allegiance,
    status: boolean,
  ) => {
    try {
      await mutationUpdateAllegiance({
        ...record,
        submissionType: {
          ...record.submissionType,
          isAutoDeleteSubmission: status,
        },
      });
      notification.success({
        message: 'Allegiance updated auto delete successfully',
      });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Allegiance updated auto delete failed: ${errorMessage}`,
      });
    }
  };

  const handleDeleteAllegiance = async (deleteAllegiance: Allegiance) => {
    try {
      await mutationDeleteAllegiance(deleteAllegiance);
      notification.success({ message: 'Allegiance deleted successfully' });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Allegiance deleted failed: ${errorMessage}`,
      });
    }
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<Allegiance> => [
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: true,
      filters: filterProofTypes,
      onFilter: (value, record): boolean =>
        record.submissionType.proofType === value,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'XP',
      dataIndex: 'xpAward',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 100,
      responsive: ['lg'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 110,
      render: (_, record) => (
        <Switch
          defaultChecked={record.status}
          onChange={(checked) => handleUpdateStatusAllegiance(record, checked)}
        />
      ),
    },
    {
      title: 'OG status',
      dataIndex: 'ogStatus',
      width: 110,
      render: (_, record) => (
        <Switch
          defaultChecked={record.isOg}
          onChange={(checked) =>
            handleUpdateOGStatusAllegiance(record, checked)
          }
        />
      ),
    },
    {
      title: 'Auto delete',
      dataIndex: 'autoDelete',
      width: 110,
      render: (_, record) => (
        <Switch
          defaultChecked={record.submissionType.isAutoDeleteSubmission}
          onChange={(checked) =>
            handleUpdateAutoDeleteAllegiance(record, checked)
          }
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      width: '15%',
      render: (_, record) => (
        <Space>
          <Button onClick={handleViewAllegiance(record)}>Edit</Button>
          <Popconfirm
            title="Delete the allegiance"
            description="Are you sure to delete this allegiance?"
            onConfirm={async () => handleDeleteAllegiance(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row justify="end" gutter={[0, 16]}>
        <Col flex="0 0 0">
          <Button
            type="primary"
            onClick={handleAddAllegiance}
            icon={<PlusOutlined />}
          >
            Add allegiance
          </Button>
        </Col>
        <Col span={24}>
          <Card title="Allegiance" extra={renderSearch()}>
            <Table
              columns={renderColumns()}
              dataSource={dataSource.data?.items}
              loading={dataSource.isLoading}
              pagination={pagination}
              onChange={handleParamsChange}
            />
          </Card>
        </Col>
      </Row>
      <CreateAllegiance
        open={openDrawer}
        allegiance={allegiance}
        onClose={handleClose}
        onSubmit={(activityData) => handleSubmit(activityData)}
      />
    </>
  );
}
