import {
  Tabs, TabsProps,
} from 'antd';
import { ReactElement } from 'react';
import AllUsers from './AllUsers';
import SendAirdropHistory from './SendAirdropHistory';
import UserClaimedAirdrop from './UserClaimedAirdrop';

function Airdrop(): ReactElement {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Users',
      children: <AllUsers />,
    },
    {
      key: '2',
      label: 'Airdrop eligibility claimed',
      children: <UserClaimedAirdrop />,
    },
    {
      key: '3',
      label: 'Mint history',
      children: <SendAirdropHistory />,
    },
  ];

  return (
    <Tabs defaultActiveKey="1" items={items} />
  );
}

export default Airdrop;
