import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ProColumns, ProTable } from '@ant-design/pro-components';
import {
  App,
  Button,
  Card,
  Col,
  Dropdown,
  notification,
  Row,
  Space,
  Tag,
} from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GiveAwayForm from 'src/components/From/GiveAwayForm';
import {
  useCreateGiveAway,
  useDeleteGiveAway,
  useFetchGiveAway,
  UseFetchGiveAwayParams,
  useUpdateGiveAway,
} from 'src/hooks/api';
import useRenderItemName from 'src/hooks/give-away/useRenderItemName';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { GiveAway } from 'src/types/GiveAway';
import { ErrorDataResponse } from 'src/typing';

export const filterProofTypes = [
  {
    text: 'Photo',
    value: 'photo',
  },
  {
    text: 'Video',
    value: 'video',
  },
  {
    text: 'Discord',
    value: 'discord',
  },
  {
    text: 'Text',
    value: 'text',
  },
  {
    text: 'Audio',
    value: 'audio',
  },
];

export default function GiveAwayPage() {
  // Hooks
  const navigate = useNavigate();
  const app = App.useApp();
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<GiveAway>();
  const { mapTableParams } = useMapTableParam();
  const { renderItemName } = useRenderItemName();
  // Local states
  const [filters, setFilters] = useState<Record<string, unknown>>({});
  const [giveAway, setGiveAway] = useState<GiveAway | undefined>(undefined);
  const [edit, setEdit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  // Server states
  const params: UseFetchGiveAwayParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
      ...filters,
    };
  }, [mapTableParams, pagination, sorters, filters]);
  const dataSource = useFetchGiveAway({ ...params });
  const { mutateAsync: createGiveAway } = useCreateGiveAway();
  const { mutateAsync: updateGiveAway } = useUpdateGiveAway();
  const { mutateAsync: deleteGiveAway } = useDeleteGiveAway();

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setFilters({
      ...filters,
      search: value,
    });
  };

  const handleAddActivity = () => {
    setGiveAway(undefined);
    setEdit(false);
    setOpenDrawer(true);
  };

  const handleViewGiveAway = (selectedGiveAway: GiveAway) => {
    navigate(`/give-aways/${selectedGiveAway.id}`);
  };

  const handleEditGiveAway = (newGiveAway: GiveAway) => {
    setGiveAway(newGiveAway);
    setEdit(true);
    setOpenDrawer(true);
  };

  const handleDeleteGiveAway = async (seletedGiveAway: GiveAway) => {
    app.modal.confirm({
      icon: <DeleteOutlined />,
      title: (
        <span>
          Are you sure you want to DELETE <i>{seletedGiveAway.name}</i> give
          away?
        </span>
      ),
      okText: 'Delete',
      okButtonProps: { danger: true },
      onOk: async () => {
        await deleteGiveAway(seletedGiveAway.id!);
        app.message.success('Delete give away successfully!');
      },
    });
  };

  const handleActionMenuClick = (
    selectedGiveAway: GiveAway,
    menuKey: string,
  ) => {
    switch (menuKey) {
      case 'edit': {
        handleEditGiveAway(selectedGiveAway);
        break;
      }
      case 'delete': {
        handleDeleteGiveAway(selectedGiveAway);
        break;
      }
      default: {
        throw new Error('Not implemented');
      }
    }
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleSubmit = async (formValues: GiveAway) => {
    if (!edit) {
      try {
        await createGiveAway(formValues);
        setOpenDrawer(false);
        notification.success({ message: 'Activity created successfully' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notification.error({
          message: `Activity create failed: ${errorMessage}`,
        });
      }
    }

    if (edit) {
      try {
        await updateGiveAway(formValues);
        notification.success({ message: 'Activity updated success' });
        setOpenDrawer(false);
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notification.error({
          message: `Activity update failed: ${errorMessage}`,
        });
      }
    }
  };

  const handleEndStatusChange = (key: string) => {
    if (key === 'ongoing') {
      setFilters({
        ...filters,
        hasEnded: undefined,
      });
    } else {
      setFilters({
        ...filters,
        hasEnded: true,
      });
    }
  };

  const renderColumns = (): ProColumns<GiveAway>[] => [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Item',
      dataIndex: 'type',
      renderText: (_, data) => renderItemName(data),
    },
    {
      title: 'Collaborator',
      dataIndex: 'collaboratorId',
      renderText: (_, data) => (data.collaboratorId ? 'YES' : 'NO'),
    },
    {
      title: 'Order',
      dataIndex: 'order',
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',

      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 120,
      responsive: ['lg'],
      renderText: (value) => (
        <Tag color="green">
          {`${new Date(value).toLocaleDateString()} - ${new Date(
            value,
          ).toLocaleTimeString()}`}
        </Tag>
      ),
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 120,
      responsive: ['lg'],
      renderText: (value) => (
        <Tag color="red">
          {`${new Date(value).toLocaleDateString()} - ${new Date(
            value,
          ).toLocaleTimeString()}`}
        </Tag>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: '1%',
      render: (_, record) => (
        <Space>
          <Dropdown.Button
            menu={{
              items: [
                {
                  label: 'Edit',
                  key: 'edit',
                  icon: <EditOutlined />,
                },
                {
                  label: 'Delete',
                  key: 'delete',
                  danger: true,
                  icon: <DeleteOutlined />,
                },
              ],
              onClick: (e) => handleActionMenuClick(record, e.key),
            }}
            onClick={() => handleViewGiveAway(record)}
          >
            View
          </Dropdown.Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row justify="end" gutter={[0, 16]}>
        <Col flex="0 0 0">
          <Button
            type="primary"
            onClick={handleAddActivity}
            icon={<PlusOutlined />}
          >
            Add give away
          </Button>
        </Col>
        <Col span={24}>
          <Card title="Activities">
            <ProTable
              ghost
              search={false}
              options={false}
              toolbar={{
                search: { onSearch: handleSearch },
                menu: {
                  items: [
                    {
                      key: 'ongoing',
                      label: 'Ongoing',
                    },
                    {
                      key: 'ended',
                      label: 'Ended',
                    },
                  ],
                  onChange: (key) => handleEndStatusChange(key as string),
                },
              }}
              columns={renderColumns()}
              dataSource={dataSource.data?.items}
              loading={dataSource.isLoading}
              pagination={pagination}
              onChange={handleParamsChange}
            />
          </Card>
        </Col>
      </Row>
      <GiveAwayForm
        giveAway={giveAway}
        onClose={handleClose}
        open={openDrawer}
        onSubmit={(giveAwayData) => handleSubmit(giveAwayData)}
      />
    </>
  );
}
