export default function convertDateToTimestamp(
  date: Date | undefined | null,
): number | null {
  if (typeof date === 'string') {
    const newDate = new Date(date);
    return date
      ? Math.floor(new Date(newDate.toISOString()).getTime() / 1000)
      : null;
  }

  return date
    ? Math.floor(new Date(date.toISOString()).getTime() / 1000)
    : null;
}
