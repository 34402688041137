import { Card, Descriptions } from 'antd';
import { User } from 'src/types';

export type UserDetailCardProps = {
  user?: User;
};

export default function UserDetailCard(props: UserDetailCardProps) {
  const { user } = props;

  return (
    <Card title="User details">
      <Descriptions column={2}>
        <Descriptions.Item label="User name">
          {user?.username || 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={2}>
          {user?.email || 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Total XP Tokens" span={2}>
          {user?.xp || 'N/A'}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
}
