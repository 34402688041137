import { Space } from 'antd';
import NFTMintersTable from 'src/components/NFTMintersTable/NFTMinterTable';

export default function NFTMintersPage() {
  return (
    <Space
      direction="vertical"
      size="large"
      style={{ display: 'flex' }}
      className="dropContainer"
    >
      <NFTMintersTable />
    </Space>
  );
}
