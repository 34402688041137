import { Button, Space } from 'antd';
import { ReactElement } from 'react';
import { Drop } from 'src/hooks/api/typing';
import styles from './index.module.scss';

export interface DropActionProps {
  drop: Drop;
  onView(drop: Drop): void;
  onEdit(drop: Drop): void;
}

function DropAction(props: DropActionProps): ReactElement {
  const { drop, onView: onViewCollection, onEdit: onEditDrop } = props;

  const onView = () => {
    onViewCollection(drop);
  };

  const onEdit = () => {
    onEditDrop(drop);
  };

  return (
    <Space
      className={styles.dropActionContainer}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Button type="default" onClick={onView}>View</Button>
      <Button type="primary" onClick={onEdit}>Edit</Button>
    </Space>
  );
}

export default DropAction;
