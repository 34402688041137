export const getChainId = () => {
  const chainId = process.env.REACT_APP_CHAIN_ID;

  if (chainId) {
    return parseInt(chainId, 10);
  }

  return 1;
};

export const getExplorerUrl = () => {
  const chainId = getChainId();

  if (chainId === 1) {
    return 'https://etherscan.io';
  }

  if (chainId === 5) {
    return 'https://goerli.etherscan.io';
  }

  if (chainId === 11155111) {
    return 'https://sepolia.etherscan.io';
  }

  console.log('getExplorerUrl: unknown chainId');
  return '';
};
