import { FileImageOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import
{
  RcFile, UploadChangeParam, UploadFile as UploadFileAntd, UploadProps,
} from 'antd/es/upload';
import { useMemo, useState } from 'react';
import { UploadImageV2Response, useUploadImageV2 } from 'src/hooks/api/useUploadImage';

const { Dragger } = Upload;

type UploadFileProps = {
  value?: string;
  prefix?: string;
  onChange?: (newValue: string) => void;
};

function UploadFile(props: UploadFileProps) {
  const {
    value,
    prefix,
    onChange = () => {},
  } = props;

  const [loading, setLoading] = useState(false);

  const fileUrl = useMemo(() => {
    if (value == null) {
      return null;
    }

    const url = new URL(`v2.0/files/${value}`, process.env.REACT_APP_API_URL);
    return url.toString();
  }, [value]);

  const { mutateAsync: uploadMutate } = useUploadImageV2();

  const handleChangeFile: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFileAntd<UploadImageV2Response>>,
  ) => {
    if (info?.file?.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info?.file?.status === 'done') {
      onChange(info.file.response?.key || '');
      setLoading(false);
    }

    if (info?.file?.status === 'error') {
      setLoading(false);
      message.error(`${info?.file?.name} file upload failed.`);
    }
  };

  const handleUploadFile = async (file: string | RcFile | Blob) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      if (prefix != null) {
        formData.append('prefix', prefix);
      }

      const response = await uploadMutate(formData);
      return response.data;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleUploadRequest: UploadProps['customRequest'] = ({ file, onSuccess, onError }) => {
    handleUploadFile(file)
      .then((data) => {
        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
      });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Dragger
      name="file"
      customRequest={handleUploadRequest}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      onChange={handleChangeFile}
    >
      {fileUrl ? (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <FileImageOutlined />
        </div>
      ) : (
        uploadButton
      )}
    </Dragger>
  );
}

export default UploadFile;
