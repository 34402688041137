import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Checkbox, Col, Drawer, Form, Input, Row, Space,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormInstance } from 'antd/es/form';
import type { Store } from 'antd/lib/form/interface';
import { useEffect, useState } from 'react';
import { Permission, Role } from 'src/types/Admin';

interface FormValues {
  name: string;
  description: string;
  key: string;
  permissions: number[];
}

export type RoleDetailProps = {
  role: Role;
  setRole: React.Dispatch<React.SetStateAction<Role>>;
  permissions: Permission[] | [];
  onSubmit: () => void;
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const textUpdate = {
  buttonAction: 'Update role',
  title: 'Update the role',
};
const textAddNew = {
  buttonAction: 'Add role',
  title: 'Create new role',
};

export default function CreateRole(props: RoleDetailProps) {
  const {
    role, edit, permissions, setRole, onSubmit, setEdit,
  } = props;

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (edit) {
      setOpen(true);
    }
  }, [edit]);

  useEffect(() => {
    form.setFieldsValue({ name: role.name });
    form.setFieldsValue({ description: role.description });
    form.setFieldsValue({ key: role.key });
    form.setFieldsValue({ permissions: role.permissions?.map((permission) => permission.id) });
  }, [role, form]);

  const showDrawer = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  const handleFormChange = (changedValues: Store) => {
    setRole((prevFormData) => {
      if (changedValues?.permissions) {
        const updatedPermissions = changedValues.permissions.map((permission: number) => {
          const changedPermission = permissions?.find((r: Permission) => r.id === permission);
          return changedPermission;
        });

        if (updatedPermissions) {
          return {
            ...prevFormData,
            ...changedValues,
            permissions: updatedPermissions,
          };
        }
      }

      return {
        ...prevFormData,
        ...changedValues,
      };
    });
  };

  const validateAllFields = <T extends FormValues>(formInstance: FormInstance<T>) => {
    formInstance.validateFields()
      .then(() => onSubmit())
      .catch((error) => Promise.reject(new Error(error)));
  };

  const handlePermissionClick = (e: CheckboxChangeEvent) => {
    const permissionId = e.target.value.id;
    const permissionIndex = role.permissions.findIndex((permission) => permission.id === permissionId);

    if (e.target.checked) {
      if (permissionIndex === -1) {
        role.permissions.push(e.target.value);
      }
    } else if (permissionIndex !== -1) {
      role.permissions.splice(permissionIndex, 1);
    }

    setRole({
      ...role,
    });
  };

  const permissionIds = role.permissions.map((permission) => permission.id);
  return (
    <>
      <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
        {edit ? textUpdate.buttonAction : textAddNew.buttonAction}
      </Button>
      <Drawer
        title={edit ? textUpdate.title : textAddNew.title}
        width={720}
        onClose={handleClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={(
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                validateAllFields(form);
              }}
              type="primary"
            >
              Submit
            </Button>
          </Space>
        )}
      >
        <Form id="create-role" form={form} initialValues={role} layout="vertical" onValuesChange={handleFormChange}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="key"
                label="Key"
                help="Key role is used for checking
                a user role within the system.
                Please enter the role name without spaces, for example: SuperAdmin"
                rules={[{
                  required: true,
                  message: 'Key not empty',
                }]}
              >
                <Input placeholder="Please enter key" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{
                  required: true,
                  message: 'Name not empty',
                }]}
              >
                <Input placeholder="Please enter name" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{
                  required: true,
                  message: 'Description not empty',
                }]}
              >
                <Input placeholder="Please enter description" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {permissions.length > 0 ? (
          <Row>
            <Col span={4}>Permissions:</Col>
            <Col span={20}>
              <Row>
                {permissions.map((permission) => (
                  <Col key={permission.id} span={12}>
                    <Checkbox
                      key={permission.id}
                      checked={permissionIds.includes(permission.id)}
                      onChange={handlePermissionClick}
                      value={permission}
                    >
                      {permission?.name}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ) : null}
      </Drawer>
    </>
  );
}
