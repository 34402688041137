import { Row, Space } from 'antd';

export default function NoAccess() {
  return (
    <Row style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
    >
      <Space
        direction="vertical"
        style={{ fontSize: 18 }}
      >
        <span>{'Sorry! You don\'t have permission to view this page.'}</span>
      </Space>
    </Row>
  );
}
