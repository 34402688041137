import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';

import { useState } from 'react';
import styles from './style.module.scss';

interface TokenRedeem {
  id: number;
  username: string;
  mobileNumber: string;
  tokenCode: string;
  pointType: string;
  pointRedeem: number;
  xp: number;
  totam: string;
}

const dummyData: TokenRedeem[] = [
  {
    id: 1,
    username: 'Alice',
    mobileNumber: '123456789',
    tokenCode: 'ABCD12',
    pointType: 'BONSTRI',
    pointRedeem: 5500,
    xp: 7500,
    totam: '-',
  },
  {
    id: 2,
    username: 'Bob',
    mobileNumber: '987654321',
    tokenCode: 'EFGH34',
    pointType: 'IMPOIN',
    pointRedeem: 2000,
    xp: 3000,
    totam: '-',
  },
  {
    id: 3,
    username: 'Charlie',
    mobileNumber: '987654123',
    tokenCode: 'IJKL56',
    pointType: 'BONSTRI',
    pointRedeem: 5000,
    xp: 7000,
    totam: '-',
  },
  {
    id: 4,
    username: 'Diana',
    mobileNumber: '456123789',
    tokenCode: 'MNOP78',
    pointType: 'IMPOIN',
    pointRedeem: 4000,
    xp: 6000,
    totam: '-',
  },
  {
    id: 5,
    username: 'Eva',
    mobileNumber: '987321654',
    tokenCode: 'QRST90',
    pointType: 'BONSTRI',
    pointRedeem: 3000,
    xp: 4000,
    totam: '-',
  },
  {
    id: 6,
    username: 'Frank',
    mobileNumber: '321654987',
    tokenCode: 'UVWX12',
    pointType: 'IMPOIN',
    pointRedeem: 7000,
    xp: 9000,
    totam: '-',
  },
  {
    id: 7,
    username: 'Grace',
    mobileNumber: '987123654',
    tokenCode: 'YZAB34',
    pointType: 'BONSTRI',
    pointRedeem: 8000,
    xp: 9500,
    totam: '-',
  },
  {
    id: 8,
    username: 'Henry',
    mobileNumber: '321987654',
    tokenCode: 'CDEF56',
    pointType: 'IMPOIN',
    pointRedeem: 1500,
    xp: 2000,
    totam: '-',
  },
  {
    id: 9,
    username: 'Ivy',
    mobileNumber: '654123987',
    tokenCode: 'GHJK78',
    pointType: 'BONSTRI',
    pointRedeem: 6000,
    xp: 8000,
    totam: '-',
  },
  {
    id: 10,
    username: 'Jack',
    mobileNumber: '321987456',
    tokenCode: 'LMNO90',
    pointType: 'IMPOIN',
    pointRedeem: 300,
    xp: 400,
    totam: '-',
  },
  {
    id: 11,
    username: 'Kevin',
    mobileNumber: '789654123',
    tokenCode: 'PQRS12',
    pointType: 'BONSTRI',
    pointRedeem: 9000,
    xp: 9800,
    totam: '-',
  },
  {
    id: 12,
    username: 'Lily',
    mobileNumber: '987456321',
    tokenCode: 'TUVW34',
    pointType: 'IMPOIN',
    pointRedeem: 10000,
    xp: 10000,
    totam: '-',
  },
  {
    id: 13,
    username: 'Mike',
    mobileNumber: '987321456',
    tokenCode: 'XYZA56',
    pointType: 'BONSTRI',
    pointRedeem: 100,
    xp: 150,
    totam: '-',
  },
  {
    id: 14,
    username: 'Nina',
    mobileNumber: '654987321',
    tokenCode: 'BCDE78',
    pointType: 'IMPOIN',
    pointRedeem: 4200,
    xp: 5600,
    totam: '-',
  },
  {
    id: 15,
    username: 'Oliver',
    mobileNumber: '321654987',
    tokenCode: 'FGHI90',
    pointType: 'BONSTRI',
    pointRedeem: 2500,
    xp: 3200,
    totam: '-',
  },
  {
    id: 16,
    username: 'Penny',
    mobileNumber: '456789123',
    tokenCode: 'JKLM12',
    pointType: 'IMPOIN',
    pointRedeem: 7100,
    xp: 8300,
    totam: '-',
  },
  {
    id: 17,
    username: 'Quincy',
    mobileNumber: '789321654',
    tokenCode: 'NOPQ34',
    pointType: 'BONSTRI',
    pointRedeem: 400,
    xp: 550,
    totam: '-',
  },
  {
    id: 18,
    username: 'Rachel',
    mobileNumber: '456987321',
    tokenCode: 'RSTU56',
    pointType: 'IMPOIN',
    pointRedeem: 8200,
    xp: 9200,
    totam: '-',
  },
  {
    id: 19,
    username: 'Sam',
    mobileNumber: '789456123',
    tokenCode: 'VWXY78',
    pointType: 'BONSTRI',
    pointRedeem: 6700,
    xp: 8000,
    totam: '-',
  },
  {
    id: 20,
    username: 'Tina',
    mobileNumber: '654321987',
    tokenCode: 'ZABC90',
    pointType: 'IMPOIN',
    pointRedeem: 5300,
    xp: 6500,
    totam: '-',
  },
];

export default function CollaboratorTokenRedeem() {
  const [isXpFilter, setIsXpFilter] = useState(true);

  const toggleFilter = () => {
    setIsXpFilter(!isXpFilter);
  };

  const renderColumns = (): ColumnsType<TokenRedeem> => [
    {
      title: 'User Handle',
      dataIndex: ['username'],
      width: '17%',
    },
    {
      title: 'Mobile Number',
      dataIndex: ['mobileNumber'],
    },
    {
      title: 'Redeem Token Code',
      dataIndex: ['tokenCode'],
    },
    {
      title: 'Loyalty Point Type',
      dataIndex: ['pointType'],
    },
    {
      title: 'Loyalty Points Redeemed',
      dataIndex: ['pointRedeem'],
    },
    {
      title: isXpFilter ? 'XPs Issued' : 'Totam Issued',
      dataIndex: [isXpFilter ? 'pointRedeem' : 'totam'],
    },
  ];

  return (
    <div className={styles.container}>
      <div className="pageHeader">
        <h5>
          Redeem Tokens Used
          <strong>20</strong>
        </h5>
      </div>
      <div className={styles.tabWrapper}>
        <button
          className={isXpFilter ? styles.active : ''}
          type="button"
          onClick={toggleFilter}
        >
          For XPs
        </button>
        <button
          className={!isXpFilter ? styles.active : ''}
          type="button"
          onClick={toggleFilter}
        >
          For Totam
        </button>
      </div>
      <div
        className={classNames(styles.bodyColumnWrapper, ' awesome-scrollbar')}
      >
        <Table
          className={styles.table}
          columns={renderColumns()}
          dataSource={dummyData}
          loading={false}
          pagination={false}
        />
      </div>
    </div>
  );
}
