import { ProCard, ProColumns, ProTable } from '@ant-design/pro-components';
import {
  App,
  Button,
  Modal,
  ModalProps,
  Popconfirm,
  Space,
  Typography,
  notification,
} from 'antd';
import { useMemo, useState } from 'react';

import useTableHelper from 'src/hooks/useTableHelpers';
import { User } from 'src/types';

import { AxiosError, isAxiosError } from 'axios';
import UserTableV2 from 'src/components/UserTableV2/UserTableV2';
import DropErrorMessage from 'src/constants/error/give-away-error';
import {
  useAddUserPrivateSaleEligible,
  useDeletePrivateSaleEligible,
  useFetchDropDetails,
  useFetchPrivateEligibles,
} from 'src/hooks/api';
import { PrivateEligibleUser } from 'src/types/Drop';
import { ErrorDataResponse } from 'src/typing';

type SelectUserModalProps = Omit<ModalProps, 'onOk' | 'onCancel'> & {
  excludeUserIds?: string[];
  onCancel?: () => void;
  onSelect?: (users: User[]) => void;
};

function SelectUserModal(props: SelectUserModalProps) {
  const {
    excludeUserIds,
    onCancel = () => {},
    onSelect = () => {},
    ...otherProps
  } = props;

  const [selectedUser, setSelectedUsers] = useState<User[]>([]);

  const handleUserSelectionChange = (_: React.Key[], selectedRows: User[]) => {
    setSelectedUsers(selectedRows);
  };

  const handleOk = () => {
    onSelect(selectedUser);
  };

  return (
    <Modal
      {...otherProps}
      closeIcon={false}
      onCancel={onCancel}
      onOk={handleOk}
      width={1024}
    >
      <UserTableV2
        filters={{ excludeUserIds }}
        rowSelection={{ onChange: handleUserSelectionChange }}
        rowKey="id"
      />
    </Modal>
  );
}

type EligibleUsersProps = {
  dropId: string;
};

function PrivateSaleEligibleUsers(props: EligibleUsersProps) {
  const { dropId } = props;

  // Hooks
  const app = App.useApp();
  const { pagination, handleParamsChange } =
    useTableHelper<PrivateEligibleUser>();
  // Local states

  const [isOpenUserSelectModal, setIsOpenUserSelectModal] = useState(false);
  // Server state
  const dropDetail = useFetchDropDetails(dropId);
  const allUsers = useFetchPrivateEligibles({
    page: 1,
    limit: 0,
    dropId,
  });

  const { mutateAsync: addUserPrivateSaleEligible } =
    useAddUserPrivateSaleEligible();

  const { mutateAsync: removeUserPrivateSaleEligible } =
    useDeletePrivateSaleEligible();

  const handleDeleteEligible = async (deleteEligible: PrivateEligibleUser) => {
    try {
      await removeUserPrivateSaleEligible({
        privateSaleEligibleId: deleteEligible.id,
        dropId,
      });
      notification.success({
        message: 'Private sale eligible removed successfully',
      });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.error[0].message;
      notification.error({
        message: `Private sale eligible removing failed: ${errorMessage}`,
      });
    }
  };

  const remainingSlot = useMemo(
    () =>
      dropDetail?.data?.privateSale?.totalQty &&
      allUsers?.data?.meta?.totalItems
        ? dropDetail.data.privateSale.totalQty - allUsers.data.meta.totalItems
        : null,
    [dropDetail.data, allUsers.data],
  );

  const toggleUserSelectModal = () => {
    setIsOpenUserSelectModal(!isOpenUserSelectModal);
  };

  const displayErrorMessage = (err: any) => {
    let errorMessage = 'Something is wrong! Please try again';

    if (isAxiosError(err)) {
      if (err.response?.data.message != null) {
        errorMessage = DropErrorMessage[err.response.data.message];
      }

      if (
        err.response?.data.error != null &&
        Array.isArray(err.response.data.error) &&
        err.response.data.error.length
      ) {
        errorMessage = DropErrorMessage[err.response.data.error[0].message];
      }
    }

    app.message.error(errorMessage);
  };

  const handleAddUserEligibility = async (selectedUsers: User[]) => {
    try {
      await addUserPrivateSaleEligible({
        dropId,
        userIds: selectedUsers.map((user) => user.id),
      });
      app.message.success('add success');
      toggleUserSelectModal();
    } catch (err) {
      displayErrorMessage(err);
    }
  };

  const renderColumns = (): ProColumns<any>[] => [
    {
      title: 'UserId',
      dataIndex: 'userId',
    },
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Xp',
      dataIndex: 'xp',
    },

    {
      title: 'Actions',
      key: 'actions',
      valueType: 'option',
      width: '1%',
      render: (_, user) => (
        <Space>
          <Popconfirm
            title="Remove the Private Sale Eligible"
            description="Are you sure you want to remove?"
            onConfirm={async () => handleDeleteEligible(user)}
            okText="Yes"
            cancelText="No"
            disabled={user.hasClaimed}
          >
            <Button danger>Remove</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const renderToolbar = () => [
    <Space>
      <Typography.Text>{remainingSlot} slot(s) left</Typography.Text>
      <Button
        type="primary"
        disabled={remainingSlot != null && remainingSlot === 0}
        onClick={toggleUserSelectModal}
      >
        Add
      </Button>
    </Space>,
  ];

  return (
    <>
      <ProCard title="Private sale eligible users">
        <ProTable
          ghost
          search={false}
          options={false}
          toolBarRender={renderToolbar}
          toolbar={{
            multipleLine: true,
            menu: {
              items: [
                {
                  key: 'all',
                  label: 'All',
                },
              ],
            },
          }}
          columnEmptyText="-"
          columns={renderColumns()}
          dataSource={allUsers.data?.items || []}
          loading={allUsers.isLoading}
          pagination={pagination}
          onChange={handleParamsChange}
        />
      </ProCard>
      <SelectUserModal
        open={isOpenUserSelectModal}
        onCancel={toggleUserSelectModal}
        onSelect={handleAddUserEligibility}
      />
    </>
  );
}

export default PrivateSaleEligibleUsers;
