import {
  Card, Col, Input,
  Row, Space, Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import UserFilter, { FormDataFilter } from 'src/components/UserFilter';
import formatDate from 'src/helper/formatDate';
import { FilterAdvancedUser } from 'src/hooks/api';
import { useFetchAllegiances } from 'src/hooks/api/allegiance';
import { UseFetchReferralLedgerParams, useFetchReferralLedger } from 'src/hooks/api/referral-ledger';
import { useFetchTags } from 'src/hooks/api/tag';

import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { ReferralLedger } from 'src/types';

export default function ReferralLedgerPage() {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<ReferralLedger>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [filter, setFilter] = useState < FilterAdvancedUser | null >(null);
  const [openFilter, setOpenFilter] = useState(false);

  // Server states
  const params: UseFetchReferralLedgerParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      ...filter,
      search,
    };
  }, [mapTableParams, pagination, sorters, search, filter]);

  const dataSource = useFetchReferralLedger({ ...params });
  const dataAllegianceSource = useFetchAllegiances();
  const dataTagSource = useFetchTags();

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
  };

  const handleSubmitFilter = (data: FormDataFilter) => {
    setFilter({
      isAirdropped: data.options?.includes('isAirdropped'),
      isOg: data.options?.includes('isOg'),
      isWhitelisted: data.options?.includes('isWhitelisted'),
      startCreatedAt: data.createdAt ? data.createdAt[0] : null,
      endCreatedAt: data.createdAt ? data.createdAt[1] : null,
      allegiance: data.allegiance,
    });
  };

  const handleResetFilter = () => {
    setFilter(null);
  };

  const renderSearchAndUserFilter = () => (
    <Row>
      <Space size="small">
        <Col>
          <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <UserFilter
            onOpen={handleOpenFilter}
            open={openFilter}
            onCancel={handleCancelFilter}
            onSubmit={(data: FormDataFilter) => handleSubmitFilter(data)}
            allegiances={dataAllegianceSource?.data?.items || []}
            tags={dataTagSource.data?.items || []}
            onReset={handleResetFilter}
          />
        </Col>
      </Space>
    </Row>
  );

  const renderColumns = (): ColumnsType<ReferralLedger> => [
    {
      title: ' Referrer user name',
      dataIndex: ['referral', 'username'],
    },
    {
      title: 'Referrer email',
      dataIndex: ['referral', 'email'],
    },
    {
      title: 'Referral xp',
      dataIndex: ['referral', 'referralXP'],
      render: (value) => value || 50,
    },
    {
      title: 'Referral code',
      dataIndex: ['referral', 'refCode'],
    },
    {
      title: 'Referred user name',
      dataIndex: ['username'],
    },
    {
      title: 'Referred email',
      dataIndex: ['email'],
    },
    {
      title: 'Referred XP',
      dataIndex: ['referral', 'referralXP'],
      render: (value) => value || 50,
    },
    {
      title: 'Created at',
      dataIndex: ['createdAt'],
      width: 150,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => formatDate(value),
    },

  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col span={24}>
        <Card title="Referral ledger" extra={renderSearchAndUserFilter()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
    </Row>
  );
}
