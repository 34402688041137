/* eslint-disable no-restricted-syntax */
import {
  Button,
  Col,
  Row,
  Form,
  Typography,
  Select,
  // message,
  Space,
  Input,
} from 'antd';
import { ethers } from 'ethers';
import { useMemo, useState } from 'react';
import Web3Panel from 'src/components/Web3Panel';
import { useFetchDrops, updateDrop } from 'src/hooks/api';
import { Drop } from 'src/hooks/api/typing';
import useWeb3 from 'src/hooks/useWeb3';
import { createRequester } from 'src/hooks/useApi';
import ABI from 'src/abi/private-sale.json';
import ByteCode from 'src/abi/privateSaleByteCode';
import { useAppDispatch } from 'src/store/dispatch';
import convertDateToTimestamp from 'src/helper/Timestamp';
import { getExplorerUrl } from 'src/helper/web3helper';
import Withdraw from 'src/components/PrivateSale/Withdraw';
import { Address } from 'wagmi';
import styles from './PrivateSaleManager.module.scss';

const { Paragraph, Text } = Typography;
interface FormValues {
  collectionId: number;
  // baseURI: string;
}

export default function PrivateSaleManager() {
  const [hash, setHash] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [button, setButton] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const dispatch = useAppDispatch();
  const { isConnected, chain } = useWeb3();
  const { data: collections } = useFetchDrops();

  // Check if collections is defined before iterating

  const undeployedCollections =
    // eslint-disable-next-line max-len
    collections?.filter(
      (collection: Drop) => collection?.privateSale?.smartContractId === null,
    ) || [];
  const [selectedCollection, setSelectedCollection] = useState<Drop | null>(
    null,
  );
  const shouldShowForm = useMemo(() => {
    if (!isConnected) return false;
    if (chain?.id.toString() !== process.env.REACT_APP_CHAIN_ID) return false;
    return true;
  }, [isConnected, chain]);

  const handleDeployContract = async () => {
    try {
      if (typeof window === 'undefined' || !window.ethereum) {
        console.log('MetaMask not detected or not available');
        return;
      }

      const startDate = convertDateToTimestamp(
        selectedCollection?.privateSale?.startDate,
      );
      const endDate = convertDateToTimestamp(
        selectedCollection?.privateSale?.endDate,
      );
      const claimStartDate = convertDateToTimestamp(
        selectedCollection?.privateSale?.claimStartDate,
      );
      const claimEndDate = convertDateToTimestamp(
        selectedCollection?.privateSale?.claimEndDate,
      );

      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const account = accounts[0];
      const provider = new ethers.providers.Web3Provider(
        window.ethereum as ethers.providers.ExternalProvider,
      );
      // Getting the signer
      const signer = provider.getSigner();
      const constructorParams = [
        account,
        startDate,
        endDate,
        claimStartDate,
        claimEndDate,
      ]; // Replace with your actual constructor parameters
      const contractFactory = new ethers.ContractFactory(ABI, ByteCode, signer);

      // Deploying the contract
      const contract = await contractFactory.deploy(...constructorParams);
      setHash(contract.deployTransaction.hash);
      setContractAddress(contract.address);
      const requester = createRequester({ dispatch });
      const drop = {
        id: selectedCollection?.id,
        name: selectedCollection?.name,
        maxWhitelistNFTPerPerson: selectedCollection?.maxWhitelistNFTPerPerson,
        maxAirdropNFTPerPerson: selectedCollection?.maxAirdropNFTPerPerson,
        maxPreSaleQty: selectedCollection?.maxPreSaleQty,
        presaleStart: selectedCollection?.presaleStart,
        presaleEnd: selectedCollection?.presaleEnd,
        publicSaleEnd: selectedCollection?.publicSaleEnd,
        publicSaleStart: selectedCollection?.publicSaleStart,
        claimStartDate: selectedCollection?.claimStartDate,
        claimEndDate: selectedCollection?.claimEndDate,
        qty: selectedCollection?.qty,
        mActive: selectedCollection?.mActive,
        preSalePrice: selectedCollection?.preSalePrice,
        publicSalePrice: selectedCollection?.publicSalePrice,
        whitelistXpFee: selectedCollection?.whitelistXpFee,
        airdropXpFee: selectedCollection?.airdropXpFee,
        privateSale: {
          ...selectedCollection?.privateSale,
          smartContractId: contract?.address,
          totalQty: selectedCollection?.privateSale?.totalQty ?? 0,
          minQtyPerUser: selectedCollection?.privateSale?.minQtyPerUser ?? 0,
          maxQtyPerUser: selectedCollection?.privateSale?.maxQtyPerUser ?? 0,
          price: selectedCollection?.privateSale?.price ?? 0,
        },
      };
      await updateDrop({
        requester,
        drop,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormChange = (changedValues: FormValues) => {
    if (!changedValues.collectionId || !collections) {
      return false;
    }

    const collection = collections.find(
      (clt: Drop) => clt.id === Number(changedValues.collectionId),
    );
    if (!collection) return false;
    setSelectedCollection(collection);
    return true;
  };

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Col span={12}>
          <Web3Panel />
        </Col>
      </Col>
      {shouldShowForm === true && (
        <>
          <Col span={24}>
            <Col span={12}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={handleFormChange}
              >
                <Col span={24}>
                  <Form.Item
                    label="Private Sale"
                    name="collectionId"
                    rules={[
                      {
                        required: true,
                        message: 'Please select collection!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      options={undeployedCollections.map(
                        (collection: Drop) => ({
                          value: collection.id,
                          label: collection.name,
                        }),
                      )}
                      onChange={setButton}
                    />
                  </Form.Item>
                </Col>
              </Form>
            </Col>
          </Col>
          <Col span={24}>
            {!selectedCollection?.privateSale?.smartContractId ? (
              <Button
                type="primary"
                onClick={handleDeployContract}
                disabled={!button}
              >
                Deploy contract
              </Button>
            ) : (
              <Space direction="vertical" />
            )}
          </Col>
          <Col span={24}>
            {contractAddress ? (
              <div className={styles.successResultContainer}>
                <Col span={24}>
                  <Paragraph>
                    <Text>Transaction Hash: {hash}</Text>
                  </Paragraph>
                </Col>
                <Col span={24}>
                  <Paragraph>
                    <Text>Private Sale Address: </Text>
                    <Text copyable strong>
                      {contractAddress}
                    </Text>
                  </Paragraph>
                  <Paragraph>
                    <Text>Explorer URL : </Text>
                    <a href={`${getExplorerUrl()}/tx/${hash}`} target="blank">
                      Explorer URL
                    </a>
                  </Paragraph>
                </Col>
              </div>
            ) : (
              <Space direction="vertical" />
            )}
          </Col>
          <Col span={24}>
            <Paragraph
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Text strong style={{ paddingBottom: '10px' }}>
                Enter Your Private Sale Address :{' '}
              </Text>
              <Input
                style={{ width: '500px' }}
                value={withdrawAddress}
                onChange={(e) => {
                  setWithdrawAddress(e.target.value);
                }}
              />
            </Paragraph>
            <Paragraph>
              <Withdraw privateSaleAddress={withdrawAddress as Address} />
            </Paragraph>
          </Col>
        </>
      )}
    </Row>
  );
}
