import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import {
  useFetchCollaboratorDashboard,
  useFetchCollaboratorXpRecords,
} from 'src/hooks/api/collaborator-dashboard';
import { Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectCollaboratorProfile } from 'src/store/selectors';

import { Collaborator } from 'src/types/Collaborator';
import { useEffect } from 'react';
import { useAppDispatch } from 'src/store/dispatch';
import { getCollaboratorProfile } from 'src/store';
import CustomTable from '../_shared/components/Table';
import styles from './style.module.scss';

const { Text } = Typography;
export interface XpRecordDataType {
  title: string;
  dataIndex: string;
  key: string;
}
export interface XpRecordColumnsTypes {
  key?: string;
  createdAt: Date | string;
  recordsLength: number;
  totalAmount: number;
}

const columns: ColumnsType<XpRecordColumnsTypes> = [
  {
    title: 'DATE',
    dataIndex: 'createdAt',
    key: 'date',
  },
  {
    title: 'LINK/CODE USAGE',
    dataIndex: 'recordsLength',
    key: 'referralUses',
  },
  {
    title: 'XPs AWARDED',
    dataIndex: 'totalAmount',
    key: 'xpAwarded',
  },
];

function DashboardContent(props: { collaboratorProfile: Collaborator }) {
  const dispatch = useAppDispatch();
  const { collaboratorProfile } = props;
  const { data: xpRecords, isLoading } = useFetchCollaboratorXpRecords(
    collaboratorProfile.id,
  );
  const { data: statistics } = useFetchCollaboratorDashboard(
    collaboratorProfile.id,
  );

  useEffect(() => {
    dispatch(getCollaboratorProfile());
  }, [dispatch]);

  return (
    <>
      <div className={styles.sideContent}>
        <div className={styles.sideBlock}>
          <div className={styles.title}>Total Link/Code Usage</div>
          <div className={styles.number}>{statistics?.totalRefCodeUsage}</div>
        </div>
        <div className={styles.sideBlock}>
          <div className={styles.title}>Total XPs Awarded</div>
          <div className={styles.number}>{statistics?.totalXpsAwarded}</div>
        </div>
        {collaboratorProfile.allegianceId ? (
          <div className={styles.sideBlock}>
            <div className={styles.title}>Allegiance Declared</div>
            <div className={styles.number}>
              {statistics?.totalUsersAllegiance}
            </div>
          </div>
        ) : null}
        {collaboratorProfile.allegianceId && collaboratorProfile.name.toUpperCase() === 'IOH' ? (
          <>
            <div className={styles.sideBlock}>
              <div className={styles.title}>Redeem Tokens Used</div>
              <div className={styles.number}>
                {statistics?.totalUsersAllegiance}
              </div>
            </div>
            <div className={styles.sideBlock}>
              <div className={styles.title}>Loyalty Points Redeemed</div>
              <div className={styles.number}>
                0
              </div>
            </div>
          </>
        ) : null}
        <div className={styles.sideBlock}>
          <div className={styles.title}>Whitelist Mint Spots Available</div>
          <div className={styles.number}>{statistics?.amountUserWhitelist}</div>
        </div>
        <div className={styles.sideBlock}>
          <div className={styles.title}>Airdrops Available</div>
          <div className={styles.number}>{statistics?.amountUserAirdrop}</div>
        </div>
        <div className={styles.sideBlock}>
          <div className={styles.title}>Merch Available</div>
          <div className={styles.number}>0</div>
        </div>
        <div className={styles.sideBlock}>
          <div className={styles.title}>Total Discounts Offered</div>
          <div className={styles.number}>0</div>
        </div>
      </div>
      <div className={styles.mainContent}>
        <CustomTable
          columns={columns}
          dataSource={xpRecords?.items || []}
          pagination={false}
          scroll={{ y: 700 }}
          loading={isLoading}
        />
      </div>
    </>
  );
}

export default function MasterCardDashboard() {
  const profile = useSelector(selectCollaboratorProfile);

  return (
    <div className={styles.dashboardContent}>
      <div className={styles.header}>
        <div className={styles.referralWrapper}>
          <span className={classNames(styles.title, 'body1')}>
            COLLAB CODE:
          </span>
          <span className={classNames(styles.titleDescription, 'body1')}>
            {profile?.refCode}
          </span>
        </div>
        <div className={styles.referralWrapper}>
          <span className={classNames(styles.title, 'body1')}>
            COLLAB LINK:
          </span>
          <span className={classNames(styles.titleDescription, 'body1')}>
            <Text className={styles.copyBtn} copyable>
              {`${process.env.REACT_APP_WEB_URL}collab/${profile?.refCode}`}
            </Text>
          </span>
        </div>
      </div>
      <div className={classNames('awesome-scrollbar', styles.body)}>
        {profile ? (
          <DashboardContent collaboratorProfile={profile} />
        ) : (
          <Spin />
        )}
      </div>
    </div>
  );
}
