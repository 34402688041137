export enum GiveAwayType {
  Physical = 'physical',
  DropWhitelist = 'dropWhitelist',
  DropAirdrop = 'dropAirdrop',
}

export type GiveAway = {
  id?: string;
  name: string;
  description?: string;
  collaboratorId?: string;
  maxAmount: number;
  xpFee: number;
  startDate?: Date | null;
  endDate?: Date | null;
  posterImage?: string;
  mobilePosterImage?: string;
  notificationImage?: string;
  createdAt: Date;
  updatedAt: Date;
  type: GiveAwayType;
  itemName?: string;
  dropId?: string;
  dropUrl?: string;
  order?: number;
};

export type GiveAwayUser = {
  id: string;
  username: string;
  email: string;
  xp: number;
  hasClaimed: boolean;
};
