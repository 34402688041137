import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import { FormInstance, RuleObject } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import slugify from 'slugify';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { SLUG_REGEX } from 'src/constants/regex';
import { Activity, ActivityStatus } from 'src/types/Activity';
import { Allegiance, SubmissionProcessType } from 'src/types';
import UploadImage from '../UploadImage';

const { Option } = Select;

const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);

type ActivityForm = Omit<Activity, 'startDate' | 'endDate'> & {
  dateRange: [Dayjs, Dayjs];
};

const typeOptions = [
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Referral',
    value: 'ref',
  },
];

const deleteOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const proofType = [
  {
    value: 'photo',
    label: 'Photo (jpg, png, pdf)',
  },
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'discord',
    label: 'Discord',
  },
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'audio',
    label: 'Audio',
  },
  {
    value: 'textAndPhoto',
    label: 'Text and photo',
  },
];

const submissionProcessType = [
  {
    value: SubmissionProcessType.All,
    label: 'Upload and click/capture/record both',
  },
  {
    value: SubmissionProcessType.UploadOnly,
    label: 'File upload only',
  },
  {
    value: SubmissionProcessType.ClickCaptureRecord,
    label: 'Click/capture/record file only',
  },
];

export type CreateActivityProps = {
  open: boolean;
  activity?: Activity;
  onClose?: () => void;
  onSubmit?: (values: Activity) => void;
  allegiances?: Allegiance[];
};

export default function CreateActivity(props: CreateActivityProps) {
  const {
    open,
    activity,
    onSubmit = () => {},
    onClose = () => {},
    allegiances,
  } = props;

  const [form] = Form.useForm<ActivityForm>();
  const formData: ActivityForm | undefined = useMemo(() => {
    if (activity == null) {
      return undefined;
    }

    return {
      ...activity,
      dateRange: [dayjs(activity.startDate), dayjs(activity.endDate)],
    };
  }, [activity]);

  useEffect(() => {
    if (formData != null) {
      form.setFieldsValue(formData);
    } else {
      form.resetFields();
    }
  }, [form, formData]);

  const handleClose = () => {
    onClose();
  };

  const validateDisclaimer = (
    _: RuleObject,
    value: any,
    callback: (error?: string) => void,
  ) => {
    const regex = /<p>(\s*|&nbsp;|<br\s*\/?>)<\/p>/g;

    if (regex.test(value) || (value && value.trim().length === 0)) {
      callback('Please input activity disclaimer');
    } else {
      callback();
    }
  };

  const handleSubmit = async (formInstance: FormInstance<ActivityForm>) => {
    await formInstance.validateFields();
    const formValues = form.getFieldsValue();
    onSubmit({
      ...formValues,
      id: formData?.id,
      slug: formValues.slug || slugify(formValues.title, { lower: true }),
      status: formData?.status || ActivityStatus.Draft,
      startDate: formValues.dateRange[0].toDate(),
      endDate: formValues.dateRange[1].toDate(),
    });
  };

  return (
    <Drawer
      open={open}
      title={activity != null ? 'Update activity' : 'Add activity'}
      size="large"
      extra={
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit(form);
            }}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      }
      onClose={handleClose}
    >
      <Form id="create-activity" layout="vertical" form={form}>
        <Space direction="vertical" size="large">
          <Card title=" Activity details">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={['title']}
                  label="Activity title"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: ' Activity title cannot be empty',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Please enter activity title" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['slug']}
                  label="Activity slug"
                  rules={[
                    {
                      pattern: SLUG_REGEX,
                      type: 'string',
                      message: 'Invalid slug',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Please enter activity slug" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['type']}
                  label=" Activity type"
                  initialValue="normal"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Type not empty',
                    },
                  ]}
                >
                  <RadioGroup options={typeOptions} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['xpAward']}
                  label="Activity reward XP tokens"
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      message: 'Activity reward XP cannot be empty',
                      whitespace: true,
                    },
                  ]}
                >
                  <InputNumber placeholder="100" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['dateRange']}
                  label="Activity start date - end date"
                  rules={[
                    {
                      required: true,
                      message: 'Please select activity start date and end date',
                    },
                  ]}
                >
                  <RangePicker showTime />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['image']}
                  label="Activity Poster"
                  help=" Please upload image with width of 741.6px and height of 1091.2px"
                >
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['notificationImage']}
                  label="Notification Image"
                >
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['description']}
                  label="Activity description"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: ' Activity description cannot be empty',
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Please enter description" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title=" Activity submission details">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'title']}
                  label="Submission title"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please input activity submission title',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Please enter activity title" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'proofType']}
                  label="Submission type"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please select activity submission type',
                    },
                  ]}
                >
                  <Select
                    style={{ width: 200 }}
                    placeholder="Select type"
                    options={proofType}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'isAutoDeleteSubmission']}
                  label="Auto delete"
                  initialValue={false}
                  rules={[
                    {
                      required: true,
                      type: 'boolean',
                      message: 'Type not empty',
                    },
                  ]}
                >
                  <RadioGroup options={deleteOptions} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'isRecurring']}
                  label="Recurring"
                  initialValue={false}
                  rules={[
                    {
                      required: true,
                      type: 'boolean',
                      message: 'Type not empty',
                    },
                  ]}
                >
                  <RadioGroup options={deleteOptions} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'submissionProcess']}
                  label="Submission process"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Submission process not empty',
                    },
                  ]}
                >
                  <Select
                    style={{ width: 300 }}
                    placeholder="Select type"
                    options={submissionProcessType}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'maxAllowed']}
                  label="Max submissions allowed"
                  rules={[
                    {
                      type: 'number',
                      message: 'Max submissions cannot be empty',
                    },
                  ]}
                >
                  <InputNumber min={1} defaultValue={1} placeholder="100" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'requiredEntries']}
                  label="Required entries"
                  rules={[
                    {
                      type: 'number',
                      message: 'Required Entries submissions cannot be empty',
                    },
                  ]}
                >
                  <InputNumber min={1} defaultValue={1} placeholder="100" />
                </Form.Item>
                <Form.Item
                  name={['submissionType', 'maxEntries']}
                  label="Max entries"
                  rules={[
                    {
                      type: 'number',
                      message: 'Max submissions cannot be empty',
                    },
                  ]}
                >
                  <InputNumber min={1} defaultValue={1} placeholder="100" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Allegiance" name="allegianceId">
                  <Select allowClear>
                    {allegiances?.map((allegiance: Allegiance) => (
                      <Option
                        key={allegiance.id}
                        value={allegiance.id?.toString()}
                      >
                        {allegiance?.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['allegianceUrl']}
                  label="Allegiance URL"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['submissionType', 'proofImage']}
                  label="Submission pop up image"
                  help=" Please upload image with width of 741.6px and height of
                  1091.2px"
                >
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'text']}
                  label=" Submission text"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: ' Activity submission text cannot be empty',
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Please enter  Submission text"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'disclaimer']}
                  label="Submission disclaimer"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please input activity disclaimer',
                      whitespace: true,
                    },
                    {
                      required: true,
                      validator: validateDisclaimer,
                    },
                  ]}
                >
                  <ReactQuill />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Space>
      </Form>
    </Drawer>
  );
}
