import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Typography,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ReactElement, useEffect, useState } from 'react';
import UploadImage from 'src/components/UploadImage';
import { updateDrop, useFetchCollections } from 'src/hooks/api';
import { Collection, Drop } from 'src/hooks/api/typing';
import convertDateToTimestamp from 'src/helper/Timestamp';
import { contractInstance } from 'src/helper/wagmicontract';
import ABI from 'src/abi/private-sale.json';
import { useAppDispatch } from 'src/store/dispatch';
import { createRequester } from 'src/hooks/useApi';
import styles from './index.module.scss';

export interface DropFormProps {
  onFinish(drop: Partial<Drop>): Promise<void>;
  dropData: Drop | null;
}

interface DropFormData {
  name: string;
  maxWhitelistNFTPerPerson: number;
  maxAirdropNFTPerPerson: number;
  maxAirdropQty?: number;
  maxPreSaleQty: number;
  presaleTime: Date[];
  publicSaleTime: Date[];
  qty: number;
  collectionId: number;
  preSalePrice: number;
  publicSalePrice: number;
  whitelistXpFee: number;
  airdropXpFee: number;
  dropTagImage?: string;
  dropNotificationImage?: string;
  privateSale?: {
    dateRange: [Dayjs | undefined, Dayjs | undefined];
    claimDateRange: [Dayjs | undefined, Dayjs | undefined];
    isActivated?: boolean;
    totalQty: number;
    smartContractId?: string;
    minQtyPerUser: number;
    maxQtyPerUser: number;
    price: number;
  };
}

const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

function DropForm(props: DropFormProps): ReactElement {
  const { onFinish, dropData } = props;

  const dispatch = useAppDispatch();
  const requester = createRequester({ dispatch });

  const [form] = Form.useForm();
  const [presaleDate, setPresaleDate] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [publicSaleDate, setPublicSaleDate] = useState<
    [Dayjs | null, Dayjs | null]
  >([null, null]);

  const [privateStartDate, setPrivateStartDate] = useState<
    [Dayjs | null, Dayjs | null]
  >([null, null]);

  const [privateClaimDate, setPrivateClaimDate] = useState<
    [Dayjs | null, Dayjs | null]
  >([null, null]);

  let startDateStamp = convertDateToTimestamp(privateStartDate?.[0]?.toDate());
  let endDateStamp = convertDateToTimestamp(privateStartDate?.[1]?.toDate());
  let claiStartDateStamp = convertDateToTimestamp(
    privateClaimDate?.[0]?.toDate(),
  );
  let claimEndDateStamp = convertDateToTimestamp(
    privateClaimDate?.[1]?.toDate(),
  );

  const { data: collections } = useFetchCollections();

  const onChangeImage = (value: string, field: string) => {
    form.setFieldValue(field, value);
  };

  const dataBaseTimeStamp = {
    startDate: dropData?.privateSale?.startDate
      ? convertDateToTimestamp(dropData?.privateSale?.startDate)
      : '',
    endDate: dropData?.privateSale?.endDate
      ? convertDateToTimestamp(dropData?.privateSale?.endDate)
      : '',
    claimStartDate: dropData?.privateSale?.claimStartDate
      ? convertDateToTimestamp(dropData?.privateSale?.claimStartDate)
      : '',
    claimEndDate: dropData?.privateSale?.claimEndDate
      ? convertDateToTimestamp(dropData?.privateSale?.claimEndDate)
      : '',
  };

  const dropUpdate = async (drop: Partial<Drop>) => {
    await updateDrop({
      requester,
      drop,
    });
  };

  let flag: boolean;

  const onSubmit = async (dropFormData: DropFormData): Promise<void> => {
    const {
      name,
      maxWhitelistNFTPerPerson,
      maxAirdropNFTPerPerson,
      maxAirdropQty,
      maxPreSaleQty,
      presaleTime,
      publicSaleTime,
      qty,
      collectionId,
      preSalePrice,
      publicSalePrice,
      whitelistXpFee,
      airdropXpFee,
      dropNotificationImage,
      dropTagImage,
    } = dropFormData;

    const drop: Partial<Drop> = {
      name,
      maxWhitelistNFTPerPerson,
      maxAirdropNFTPerPerson,
      maxAirdropQty,
      maxPreSaleQty,
      qty,
      collectionId,
      preSalePrice,
      publicSalePrice,
      whitelistXpFee,
      airdropXpFee,
      dropTagImage,
      dropNotificationImage,
    };

    if (
      dataBaseTimeStamp.startDate === startDateStamp &&
      dataBaseTimeStamp.endDate === endDateStamp
    ) {
      startDateStamp = 0;
      endDateStamp = 0;
    } else {
      flag = true;
    }

    if (
      dataBaseTimeStamp.claimStartDate === claiStartDateStamp &&
      dataBaseTimeStamp.claimEndDate === claimEndDateStamp
    ) {
      claiStartDateStamp = 0;
      claimEndDateStamp = 0;
    } else {
      flag = true;
    }

    if (presaleTime) {
      if (presaleTime[0] && presaleTime[1]) {
        const [start, end] = presaleTime;
        drop.presaleStart = start;
        drop.presaleEnd = end;
      }
    }

    if (publicSaleTime) {
      if (publicSaleTime[0] && publicSaleTime[1]) {
        const [start, end] = publicSaleTime;

        drop.publicSaleStart = start;
        drop.publicSaleEnd = end;
      }
    }

    if (dropData) {
      drop.id = dropData.id;
    }

    onFinish({
      ...drop,
      privateSale: dropFormData.privateSale
        ? {
            ...dropFormData.privateSale,
            startDate: privateStartDate[0]?.toDate() ?? undefined,
            endDate: privateStartDate[1]?.toDate() ?? undefined,
            claimStartDate: privateClaimDate[0]?.toDate() ?? undefined,
            claimEndDate: privateClaimDate[1]?.toDate() ?? undefined,
          }
        : undefined,
    });

    const newData: Partial<Drop> = {
      ...drop,
      privateSale: dropFormData.privateSale
        ? {
            ...dropFormData.privateSale,
            startDate: privateStartDate[0]?.toDate() ?? undefined,
            endDate: privateStartDate[1]?.toDate() ?? undefined,
            claimStartDate: privateClaimDate[0]?.toDate() ?? undefined,
            claimEndDate: privateClaimDate[1]?.toDate() ?? undefined,
          }
        : undefined,
    };

    const smartContractId: string | undefined =
      dropFormData?.privateSale?.smartContractId;

    if (dropData) {
      if (smartContractId && flag) {
        try {
          const contractInst = await contractInstance(smartContractId, ABI);
          const nftTxn = await contractInst?.changeBlockTime(
            startDateStamp,
            endDateStamp,
            claiStartDateStamp,
            claimEndDateStamp,
          );

          await nftTxn.wait();
          const txnHash = nftTxn.hash;

          if (txnHash) {
            await dropUpdate(newData);
          } else {
            console.log('Transaction Failed');
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        await dropUpdate(newData);
      }
    }
  };

  const getTime = (e: Date[]) => {
    const time = [new Date(e[0]), new Date(e[1])];
    return time;
  };

  useEffect(() => {
    if (dropData) {
      form.setFieldsValue({
        name: dropData.name,
        maxWhitelistNFTPerPerson: dropData.maxWhitelistNFTPerPerson,
        maxAirdropNFTPerPerson: dropData.maxAirdropNFTPerPerson,
        maxAirdropQty: dropData.maxAirdropQty,
        maxPreSaleQty: dropData.maxPreSaleQty,
        qty: dropData.qty,
        collectionId: dropData.collectionId,
        preSalePrice: dropData.preSalePrice,
        publicSalePrice: dropData.publicSalePrice,
        whitelistXpFee: dropData.whitelistXpFee,
        airdropXpFee: dropData.airdropXpFee,
        dropTagImage: dropData.dropTagImage,
        dropNotificationImage: dropData.dropNotificationImage,
        privateSale: {
          ...dropData.privateSale,
          dateRange: dropData.privateSale
            ? [
                dropData.privateSale.startDate != null
                  ? dayjs(dropData.privateSale.startDate)
                  : undefined,
                dropData.privateSale.endDate != null
                  ? dayjs(dropData.privateSale.endDate)
                  : undefined,
              ]
            : undefined,
        },
      });

      setPresaleDate([
        dayjs(dropData.presaleStart),
        dayjs(dropData.presaleEnd),
      ]);
      setPublicSaleDate([
        dayjs(dropData.publicSaleStart),
        dayjs(dropData.publicSaleEnd),
      ]);
      setPrivateStartDate([
        dayjs(dropData.privateSale?.startDate),
        dayjs(dropData.privateSale?.endDate),
      ]);
      setPrivateClaimDate([
        dayjs(dropData.privateSale?.claimStartDate),
        dayjs(dropData.privateSale?.claimEndDate),
      ]);
    }
  }, [form, dropData]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      className={styles.dropFormContainer}
      onFinish={onSubmit}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Collapse defaultActiveKey={['1', '2', '3', '4', '5']}>
          <Collapse.Panel header="Detail" key="1">
            <Form.Item
              label="Collection"
              name="collectionId"
              rules={[
                {
                  required: true,
                  message: 'Please select collection!',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                options={
                  collections
                    ? collections.map((collection: Collection) => ({
                        value: collection.id,
                        label: collection.name,
                      }))
                    : []
                }
                disabled={dropData !== null}
              />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input drop name!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['dropTagImage']}
              label="Drop Tag Image"
              help=" Please upload image with width of 741.6px and height of 1091.2px"
            >
              <UploadImage
                value={form.getFieldValue('dropTagImage')}
                onChange={(value: string) =>
                  onChangeImage(value, 'dropTagImage')
                }
              />
            </Form.Item>
            <Form.Item
              name={['dropNotificationImage']}
              label="Drop Notification Image"
              help=" Please upload image with width of 741.6px and height of 1091.2px"
            >
              <UploadImage
                value={form.getFieldValue('dropNotificationImage')}
                onChange={(value: string) =>
                  onChangeImage(value, 'dropNotificationImage')
                }
              />
            </Form.Item>
            <Form.Item
              label="Quantity"
              name="qty"
              rules={[
                {
                  required: true,
                  message: 'Please input quantity!',
                },
              ]}
              extra="Should be less than available vouchers of collection sellected"
            >
              <Input />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel header="NFT config" key="2">
            <Form.Item
              label="Total NFTs reserved for Airdrop"
              name="maxAirdropQty"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Max Whitelist NFT Per Person"
              name="maxWhitelistNFTPerPerson"
              rules={[
                {
                  required: true,
                  message: 'Please input max NFT per person!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Max Airdrop NFT Per Person"
              name="maxAirdropNFTPerPerson"
              rules={[
                {
                  required: true,
                  message: 'Please input max airdrop NFT per person!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel header="Pre sale config" key="3">
            <Form.Item
              label="Max Pre Sale Quantity"
              name="maxPreSaleQty"
              rules={[
                {
                  required: true,
                  message: 'Please input max pre sale quantity!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            {dropData ? (
              <>
                <Form.Item
                  label="Pre Sale Time"
                  name="presaleTime"
                  rules={[
                    {
                      required: !dropData,
                      message: 'Please input pre sale time!',
                    },
                  ]}
                  valuePropName="presaleTime"
                  getValueFromEvent={getTime}
                >
                  <RangePicker
                    value={presaleDate}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value) => {
                      if (value) {
                        setPresaleDate([value[0] as Dayjs, value[1] as Dayjs]);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Public Sale Time"
                  name="publicSaleTime"
                  rules={[
                    {
                      required: !dropData,
                      message: 'Please input public sale time!',
                    },
                  ]}
                  valuePropName="publicSaleTime"
                  getValueFromEvent={getTime}
                >
                  <RangePicker
                    value={publicSaleDate}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value) => {
                      if (value) {
                        setPublicSaleDate([
                          value[0] as Dayjs,
                          value[1] as Dayjs,
                        ]);
                      }
                    }}
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  label="Pre Sale Time"
                  name="presaleTime"
                  rules={[
                    {
                      required: !dropData,
                      message: 'Please input pre sale time!',
                    },
                  ]}
                  valuePropName="presaleTime"
                  getValueFromEvent={getTime}
                >
                  <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
                <Form.Item
                  label="Pre Sale Price"
                  name="preSalePrice"
                  rules={[
                    {
                      required: true,
                      message: 'Please input pre sale price!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
          </Collapse.Panel>
          <Collapse.Panel header="Public sale config" key="3">
            {dropData ? (
              <Form.Item
                label="Public Sale Time"
                name="publicSaleTime"
                rules={[
                  {
                    required: !dropData,
                    message: 'Please input public sale time!',
                  },
                ]}
                valuePropName="publicSaleTime"
                getValueFromEvent={getTime}
              >
                <RangePicker
                  value={publicSaleDate}
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={(value) => {
                    if (value) {
                      setPublicSaleDate([value[0] as Dayjs, value[1] as Dayjs]);
                    }
                  }}
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  label="Public Sale Time"
                  name="publicSaleTime"
                  rules={[
                    {
                      required: !dropData,
                      message: 'Please input public sale time!',
                    },
                  ]}
                  valuePropName="publicSaleTime"
                  getValueFromEvent={getTime}
                >
                  <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
                <Form.Item
                  label="Public Sale Price"
                  name="publicSalePrice"
                  rules={[
                    {
                      required: true,
                      message: 'Please input public sale price!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
          </Collapse.Panel>
          <Collapse.Panel header="XPs config" key="4">
            <Form.Item
              label="Whitelist Xp Fee"
              name="whitelistXpFee"
              rules={[
                {
                  required: true,
                  message: 'Please input whitelist xp fee!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Airdrop Xp Fee"
              name="airdropXpFee"
              rules={[
                {
                  required: true,
                  message: 'Please input airdrop xp fee!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel header="Private sale config" key="5">
            {dropData ? (
              <>
                <Form.Item
                  label="Private Sale Date"
                  name="privateSaleTime"
                  rules={[
                    {
                      required: !dropData,
                      message: 'Please input public sale time!',
                    },
                  ]}
                  valuePropName="privateSaleDate"
                  getValueFromEvent={getTime}
                >
                  <RangePicker
                    value={privateStartDate}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value) => {
                      if (value) {
                        setPrivateStartDate([
                          value[0] as Dayjs,
                          value[1] as Dayjs,
                        ]);
                      }
                    }}
                  />
                </Form.Item>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Paragraph style={{ paddingLeft: '10px' }}>
                    {' '}
                    {startDateStamp}{' '}
                  </Paragraph>
                  <Paragraph style={{ paddingLeft: '120px' }}>
                    {endDateStamp}
                  </Paragraph>
                </div>
              </>
            ) : (
              <>
                <Form.Item
                  label="Private Sale Date"
                  name="privateSaleTime"
                  rules={[
                    {
                      required: !dropData,
                      message: 'Please input  sale date!',
                    },
                  ]}
                  valuePropName="privateSaleDate"
                  getValueFromEvent={getTime}
                >
                  <RangePicker
                    value={privateStartDate}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value) => {
                      if (value) {
                        setPrivateStartDate([
                          value[0] as Dayjs,
                          value[1] as Dayjs,
                        ]);
                      }
                    }}
                  />
                </Form.Item>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Paragraph style={{ paddingLeft: '10px' }}>
                    {startDateStamp}
                  </Paragraph>
                  <Paragraph style={{ paddingLeft: '120px' }}>
                    {endDateStamp}
                  </Paragraph>
                </div>
              </>
            )}
            {dropData ? (
              <>
                <Form.Item
                  label="Private Claim Date"
                  name="privateClaimTime"
                  rules={[
                    {
                      required: !dropData,
                      message: 'Please input Private claim time!',
                    },
                  ]}
                  valuePropName="privateClaimDate"
                  getValueFromEvent={getTime}
                >
                  <RangePicker
                    value={privateClaimDate}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value) => {
                      if (value) {
                        setPrivateClaimDate([
                          value[0] as Dayjs,
                          value[1] as Dayjs,
                        ]);
                      }
                    }}
                  />
                </Form.Item>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Paragraph style={{ paddingLeft: '10px' }}>
                    {claiStartDateStamp}
                  </Paragraph>
                  <Paragraph style={{ paddingLeft: '120px' }}>
                    {claimEndDateStamp}
                  </Paragraph>
                </div>
              </>
            ) : (
              <>
                <Form.Item
                  label="Private Claim Date"
                  name="privateClaimTime"
                  rules={[
                    {
                      required: !dropData,
                      message: 'Please input Private claim date!',
                    },
                  ]}
                  valuePropName="privateClaimDate"
                  getValueFromEvent={getTime}
                >
                  <RangePicker
                    value={privateClaimDate}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value) => {
                      if (value) {
                        setPrivateClaimDate([
                          value[0] as Dayjs,
                          value[1] as Dayjs,
                        ]);
                      }
                    }}
                  />
                </Form.Item>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Paragraph style={{ paddingLeft: '10px' }}>
                    {claiStartDateStamp}
                  </Paragraph>
                  <Paragraph style={{ paddingLeft: '120px' }}>
                    {claimEndDateStamp}
                  </Paragraph>
                </div>
              </>
            )}
            <Form.Item
              label="Price"
              name={['privateSale', 'price']}
              rules={[
                {
                  required: true,
                  message: 'Please input total price!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Total Quantity"
              name={['privateSale', 'totalQty']}
              rules={[
                {
                  required: true,

                  message: 'Please input total quantity!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Min quantity per user"
              name={['privateSale', 'minQtyPerUser']}
              rules={[
                {
                  required: true,
                  message: 'Please input min quantity per user!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Max quantity per user"
              name={['privateSale', 'maxQtyPerUser']}
              rules={[
                {
                  required: true,
                  message: 'Please input total max quantity per user!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              name={['privateSale', 'isActivated']}
              label="Is actived"
              initialValue={false}
              rules={[
                {
                  required: false,
                  type: 'boolean',
                  message: 'Type not empty',
                },
              ]}
            >
              <Radio.Group
                value={dropData?.privateSale?.isActivated}
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Smart contract id"
              name={['privateSale', 'smartContractId']}
              rules={[
                {
                  required: false,
                  message: 'Please input smart contact id!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
}

export default DropForm;
