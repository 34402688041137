import {
  Table, Tag, Typography,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ReactElement } from 'react';
import { Collection } from 'src/hooks/api/typing';
import useTableHelper from 'src/hooks/useTableHelpers';
import CollectionAction from './CollectionAction';

export interface CollectionListColumnsTypes {
  title: string;
  dataIndex: string;
  key: string;
}

export interface CollectionListProps {
  collections: Collection[];
  onView(collection: Collection): void;
  onEdit(collection: Collection): void;
}

const { Text } = Typography;

function CollectionList(props: CollectionListProps): ReactElement {
  const {
    collections = [], onView, onEdit,
  } = props;

  const { pagination, handleParamsChange } = useTableHelper<Collection>();

  const columns: ColumnsType<Collection> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name) => (
        <Text type="success">{name}</Text>
      ),
    },
    {
      title: 'Total Supply',
      dataIndex: 'totalSupply',
      key: 'totalSupply',
    },
    {
      title: 'Contract Address',
      dataIndex: 'contractAddress',
      key: 'contractAddress',
      render: (contractAddress) => (
        <Tag color="gold">
          {contractAddress || 'undefined'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (x: Collection) => (
        <CollectionAction
          collection={x}
          onView={onView}
          onEdit={onEdit}
        />
      ),
    },
  ];

  return (
    <Table
      dataSource={collections || []}
      columns={columns}
      rowKey="id"
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}

export default CollectionList;
