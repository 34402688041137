import {
  Button, Card, Col, Input, message, Radio, RadioChangeEvent, Row, Space, Switch, Table,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ReactElement, useMemo, useState } from 'react';
import {
  addAirdropEligibleUsers,
  FilterAdvancedUser,
  removeAirdropEligibleUsers,
  useFetchAdvancedFilterUsers,
  UseFetchUserParams,
} from 'src/hooks/api';
import { User } from 'src/types';
import { createRequester } from 'src/hooks/useApi';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { useAppDispatch } from 'src/store/dispatch';
import { useFetchAllegiances } from 'src/hooks/api/allegiance';
import { TypeAirdropUser } from 'src/hooks/api/typing';
import { useFetchTags } from 'src/hooks/api/tag';
import UserFilter, { FormDataFilter } from '../UserFilter';
import BasicModal from '../Modal';

function AllUsers(): ReactElement {
  const [search, setSearch] = useState<string | undefined>();
  const [selectUsers, setSelectUsers] = useState<User[]>([]);
  const [selectedUserKeys, setSelectedUserKeys] = useState<React.Key[]>([]);
  const [modalXpWillBurn, setModalXpWillBurn] = useState<boolean>(false);
  const [burnXp, setBurnXp] = useState<number>(0);

  const onChange = (e: RadioChangeEvent) => {
    setBurnXp(e.target.value);
  };

  const dispatch = useAppDispatch();
  const requester = createRequester({ dispatch });

  const {
    pagination,
    sorters,
    handleParamsChange,
  } = useTableHelper<User>();
  const { mapTableParams } = useMapTableParam();

  const [filter, setFilter] = useState < FilterAdvancedUser | null >(null);
  const [openFilter, setOpenFilter] = useState(false);

  // Server states
  const params: UseFetchUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      ...filter,
      search,
    };
  }, [mapTableParams, pagination, sorters, search, filter]);
  const { data: usersData, isLoading, refetch } = useFetchAdvancedFilterUsers({ ...params });
  const dataAllegianceSource = useFetchAllegiances();
  const dataTagSource = useFetchTags();

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
  };

  const handleSubmitFilter = (data: FormDataFilter) => {
    console.log(data);
    setFilter({
      isAirdropped: data.options?.includes('isAirdropped'),
      isOg: data.options?.includes('isOg'),
      isWhitelisted: data.options?.includes('isWhitelisted'),
      startCreatedAt: data.createdAt ? data.createdAt[0] : null,
      endCreatedAt: data.createdAt ? data.createdAt[1] : null,
      allegiance: data.allegiance,
      tagIds: data.tagIds,
    });
  };

  const handleResetFilter = () => {
    setFilter(null);
  };

  const renderSearchAndUserFilter = () => (
    <Row>
      <Space size="small">
        <Col>
          <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <UserFilter
            onOpen={handleOpenFilter}
            open={openFilter}
            onCancel={handleCancelFilter}
            onSubmit={(data: FormDataFilter) => handleSubmitFilter(data)}
            allegiances={dataAllegianceSource?.data?.items || []}
            onReset={handleResetFilter}
            tags={dataTagSource?.data?.items || []}

          />
        </Col>
      </Space>
    </Row>
  );

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedUserKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: User[]) => {
      setSelectedUserKeys(selectedRowKeys);
      setSelectUsers(selectedRows);
    },
    getCheckboxProps: (record: User) => ({
      name: record.username || 'Untitled',
    }),
  };

  const onSortCreatedAt = (userA: User, userB: User) => {
    const createdAtA = Math.floor(new Date(userA.createdAt).getTime() / 1000);
    const createdAtB = Math.floor(new Date(userB.createdAt).getTime() / 1000);

    return createdAtA - createdAtB;
  };

  const renderColumsn = (): ColumnsType<User> => [
    {
      title: 'Wallet address',
      dataIndex: 'walletAddress',
      render: (_, record) => record?.walletAddress || record?.custodialWalletAddress,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Discord',
      dataIndex: 'discordUsername',
    },
    {
      title: 'Airdrop Eligible',
      dataIndex: 'isAirdropWhitelistEligible',
      render: (value) => <Switch checked={value} disabled />,
    },
    {
      title: 'XP',
      dataIndex: 'mXp',
      sorter: (userA, userB) => userA.xp! - userB.xp!,
    },
    {
      title: 'OG',
      dataIndex: 'isOg',
      render: (value) => <Switch checked={value} />,
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      render: (value) => (new Date(value)).toLocaleString(),
      sorter: (userA, userB) => onSortCreatedAt(userA, userB),
    },
  ];

  const [messageApi, contextHolder] = message.useMessage();

  const success = (content: string) => {
    messageApi.open({
      type: 'success',
      content,
      duration: 1,
    });
  };

  const failed = (content: string) => {
    messageApi.open({
      type: 'error',
      content,
      duration: 2,
    });
  };

  const onClickAddEligibleUsers = () => {
    setModalXpWillBurn(true);
  };

  const onSubmitEligiblesUsers = async () => {
    const isInvalidInput = selectUsers.some((user: User) => user.isAirdropEligible);

    if (isInvalidInput) {
      failed(`The only users who have not been granted eligible can be granted eligible.
        Please double-check the users you have selected!`);
      return;
    }

    const userIds: number[] = [];

    selectUsers.forEach((user: User) => userIds.push(user.id));

    await addAirdropEligibleUsers({
      userIds,
      requester,
      type: burnXp === 0 ? TypeAirdropUser.normal : TypeAirdropUser.claimFree,
    });

    success('Add eligible users success');
    refetch();

    setSelectUsers([]);
    setBurnXp(0);
    setModalXpWillBurn(false);
    setSelectedUserKeys([]);
  };

  const onRemoveEligiblesUsers = async () => {
    const isInvalidInput = selectUsers.some((user: User) => !user.isAirdropWhitelistEligible || user.isAirdropWhitelistClaimed);

    if (isInvalidInput) {
      failed(`The only users who have not been granted eligible and have not claimed can be removed.
        Please double-check the users you have selected!`);
      return;
    }

    const userIds: number[] = [];

    selectUsers.forEach((user: User) => userIds.push(user.id));

    await removeAirdropEligibleUsers({
      userIds,
      requester,
    });

    success('Remove eligible users success');
    refetch();

    setSelectUsers([]);
    setSelectedUserKeys([]);
  };

  return (
    <>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        {contextHolder}
        <Space style={{ display: 'flex' }}>
          <Button
            type="primary"
            onClick={onClickAddEligibleUsers}
            disabled={selectUsers.length === 0}
          >
            Give airdrop eligibility
          </Button>
          <Button
            type="dashed"
            danger
            onClick={onRemoveEligiblesUsers}
            disabled={selectUsers.length === 0}
          >
            Remove airdrop eligibility
          </Button>
        </Space>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Card title="Users" extra={renderSearchAndUserFilter()}>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                columns={renderColumsn()}
                dataSource={usersData?.items}
                loading={isLoading}
                rowKey="id"
                pagination={pagination}
                onChange={handleParamsChange}
              />
            </Card>
          </Col>
        </Row>
      </Space>
      <BasicModal
        title="Xps will burn or not?"
        open={modalXpWillBurn}
        onClose={(): void => {
          setModalXpWillBurn(false);
        }}
      >
        <Space direction="vertical">
          <Radio.Group onChange={onChange} value={burnXp}>
            <Radio value={0}>Yes</Radio>
            <Radio value={1}>No</Radio>
          </Radio.Group>
          <Button
            type="primary"
            onClick={onSubmitEligiblesUsers}
          >
            Submit
          </Button>
        </Space>
      </BasicModal>
    </>
  );
}

export default AllUsers;
