import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo } from 'react';
import formatDate from 'src/helper/formatDate';
import { UseFetchUserParams, useFetchAdvancedFilterUsers } from 'src/hooks/api';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { User } from 'src/types';

export type UserDetailProps = {
  user: User;
};

export default function UserReferral(props: UserDetailProps) {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<User>();
  const { mapTableParams } = useMapTableParam();
  const { user } = props;
  // Server states
  const params: UseFetchUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
      refTypeIds: user?.id,
    };
  }, [mapTableParams, pagination, sorters, user]);
  const dataSource = useFetchAdvancedFilterUsers({ ...params });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const renderColumns = (): ColumnsType<User> => [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'XPs',
      dataIndex: 'xp',
      sorter: true,
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => formatDate(value),

    },
  ];

  return (
    <Table
      style={{ width: '100%' }}
      columns={renderColumns()}
      dataSource={dataSource.data?.items}
      loading={dataSource.isLoading}
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}
