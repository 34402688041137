import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useMemo, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { CreateEmailMessagePayload } from 'src/hooks/api/email-message';
import { User } from 'src/types';
import { EmailMessage, TypeEmailMessage } from 'src/types/EmailMessage';
import UserTable, { TypeUserTable } from '../UserTable';
import PreviewContent from './PreviewContent';
import UploadImage from '../UploadImage';

type EmailMessageForm = CreateEmailMessagePayload & {
  id?: string;
  selectedAllUser?: boolean;
  filterUser?: any;
};

export type CreateEmailMessageProps = {
  open: boolean;
  emailMessage?: EmailMessage;
  onClose?: () => void;
  onSubmit?: (values: EmailMessageForm) => void;
};

const typeOptions = [
  {
    label: 'Global',
    value: 'global',
  },
  {
    label: 'User',
    value: 'user',
  },
];

export default function CreateEmailMessage(props: CreateEmailMessageProps) {
  const { open, emailMessage, onSubmit = () => {}, onClose = () => {} } = props;
  const [selectedAllUser, setSelectedAllUser] = useState(false);
  const [filterUser, setFilterUser] = useState(null);

  const [selectedType, setSelectedType] = useState<TypeEmailMessage>(
    TypeEmailMessage.USER,
  );
  const [selectedUserIds, setSelectedUserIds] = useState<string[] | undefined>(
    undefined,
  );

  const [openPreview, setOpenPreView] = useState(false);

  const [form] = Form.useForm<EmailMessageForm>();
  const content = Form.useWatch('content', form) || '';

  const formData = useMemo(() => {
    if (emailMessage == null) {
      return undefined;
    }

    return {
      ...emailMessage,
      id: emailMessage.id?.toString(),
      userIds: emailMessage.userIds,
    };
  }, [emailMessage]);

  useEffect(() => {
    if (formData != null) {
      form.setFieldsValue(formData);
      setSelectedType(formData.type);

      if (formData.type === TypeEmailMessage.USER) {
        setSelectedUserIds(formData?.userIds);
      }
    } else {
      form.resetFields();
    }
  }, [form, formData]);

  const handleClose = () => {
    onClose();
  };

  const handleChangeType = (value: TypeEmailMessage) => {
    setSelectedType(value);
  };

  const handleSubmit = async (formInstance: FormInstance<EmailMessageForm>) => {
    await formInstance.validateFields();
    const formValues = form.getFieldsValue();
    onSubmit({
      id: emailMessage?.id?.toString(),
      ...formValues,
      userIds: selectedUserIds,
      type: selectedType,
      selectedAllUser,
      filterUser,
    });
  };

  const handleOpenPreview = () => {
    setOpenPreView(true);
  };

  const handleClosePreview = () => {
    setOpenPreView(false);
  };

  const handleFilterUser = (value: any) => {
    setFilterUser(value);
  };

  const rowSelection = {
    selectedRowKeys: selectedUserIds?.map((id) => +id),
    onChange: (selectedRowKeys: React.Key[], selectedRows: User[]) => {
      setSelectedUserIds(selectedRows?.map((item) => item?.id?.toString()));
    },
  };

  const handleSelectedAllUser = (value: boolean) => {
    setSelectedAllUser(value);
  };

  return (
    <Drawer
      open={open}
      title={emailMessage != null ? 'Update emailMessage ' : 'Add emailMessage'}
      size="large"
      extra={
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit(form);
            }}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      }
      onClose={handleClose}
    >
      <Form id="create-emailMessage" layout="vertical" form={form}>
        <Space direction="vertical" size="large">
          <Card title=" EmailMessage details">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={TypeEmailMessage.USER}
                >
                  <Select
                    style={{ width: 200 }}
                    placeholder="Select type"
                    options={typeOptions}
                    onChange={handleChangeType}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="icon" label="Icon">
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="subject"
                  label="Subject"
                  rules={[
                    {
                      required: true,
                      message: 'Please select subject',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  initialValue="Planet Tota"
                >
                  <Input defaultValue="Planet Tota" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="content"
                  label={
                    <Space>
                      Content
                      <Button onClick={handleOpenPreview}>PreView</Button>
                    </Space>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Please select content',
                    },
                  ]}
                >
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          {selectedType === TypeEmailMessage.USER && (
            <UserTable
              type={TypeUserTable.DRAWER}
              isSelect
              rowSelection={rowSelection}
              onSelectedAll={handleSelectedAllUser}
              selectedAllUser={selectedAllUser}
              onFilter={handleFilterUser}
            />
          )}
        </Space>
      </Form>
      <PreviewContent
        htmlContent={content}
        open={openPreview}
        onClose={handleClosePreview}
      />
    </Drawer>
  );
}
