import {
  Col, Descriptions, Row,
} from 'antd';
import { NFT } from 'src/hooks/api/typing';
import BlockchainDetail from 'src/components/Airdrop/BlockchainDetail';
import { UserAirdropHistory } from 'src/components/Airdrop/SendAirdropHistory';

export type AirdropHistoryDetailProps = {
  userAirdropHistory: UserAirdropHistory;
};

export default function AirdropHistoryDetail(props: AirdropHistoryDetailProps) {
  const { userAirdropHistory } = props;
  return (
    <Row>
      <Col span={24}>
        <Descriptions title="Profile" column={2}>
          <Descriptions.Item label="CustodialWalletAddress" span={2}>
            {userAirdropHistory?.custodialWalletAddress || 'N/A' }
          </Descriptions.Item>
          <Descriptions.Item label="Wallet address" span={2}>
            {userAirdropHistory?.walletAddress || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={2}>{userAirdropHistory?.email || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label="Username">{userAirdropHistory?.username || 'N/A'}</Descriptions.Item>
        </Descriptions>
        <BlockchainDetail
          nfts={userAirdropHistory?.nfts.map((nft: NFT) => ({
            ...nft,
            to: userAirdropHistory?.walletAddress as string,
          }))}
        />
      </Col>
    </Row>
  );
}
