import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

const selectAuthState = (state: RootState) => state.auth;
const selectAppState = (state: RootState) => state.app;
const selectCollaboratorState = (state: RootState) => state.collaborator;

export const selectIsSignedIn = createDraftSafeSelector(
  selectAuthState,
  (authState) => authState.accessToken != null,
);

export const selectIsSiderCollapse = createDraftSafeSelector(
  selectAppState,
  (appState) => appState.isSiderCollapse,
);

export const selectPermissionsState = createDraftSafeSelector(
  selectAuthState,
  (authState) => authState.permissions,
);

export const selectAdminInfo = createDraftSafeSelector(
  selectAuthState,
  (authState) => authState.info,
);

export const selectHasPermission = (requiredPermission: string | undefined) => createDraftSafeSelector(
  selectAuthState,
  (authState) => {
    if (!requiredPermission) return false;

    const { permissions } = authState;

    return permissions?.includes(requiredPermission) ?? false;
  },
);

export const selectCollaboratorProfile = createDraftSafeSelector(
  selectCollaboratorState,
  (profile) => profile.collaborator,
);
