import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, notification, App, Typography } from 'antd';
import { AxiosError, isAxiosError } from 'axios';
import { useState } from 'react';
import TagAssignModal, {
  AssignTagValue,
} from 'src/components/Tag/TagAssignModal';
import { TagTable } from 'src/components/Tag/TagTable';
import CreateTag from 'src/components/TagCreate';
import {
  useAddTagUser,
  useCreateTag,
  useDeleteTag,
  useUpdateTag,
} from 'src/hooks/api/tag';
import { Tag, TagStatus } from 'src/types/Tag';
import { ErrorDataResponse } from 'src/typing';

const { Text } = Typography;

export default function ManageTagTab() {
  // Hooks
  const app = App.useApp();
  // Local states
  const [tag, setTag] = useState<Tag | undefined>(undefined);
  const [edit, setEdit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAssignTag, setOpenAssignTagModal] = useState(false);
  // Server states
  const { mutateAsync: mutationCreateTag } = useCreateTag();
  const { mutateAsync: mutationUpdateTag } = useUpdateTag();
  const { mutateAsync: mutationDeleteTag } = useDeleteTag();
  const { mutateAsync: assignTag } = useAddTagUser();

  const handleAddTag = () => {
    setTag(undefined);
    setEdit(false);
    setOpenDrawer(true);
  };

  const handleUpdate = (record: Tag) => {
    setEdit(true);
    setTag(record);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setTag(undefined);
    setEdit(false);
  };

  const handleSubmit = async (formValues: PartialBy<Tag, 'id'>) => {
    if (!edit) {
      try {
        await mutationCreateTag(formValues);
        setOpenDrawer(false);
        notification.success({ message: 'Tag created successfully' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notification.error({ message: `Tag create failed: ${errorMessage}` });
      }
    }

    if (edit) {
      try {
        await mutationUpdateTag(formValues as Tag);
        notification.success({ message: 'Tag updated success' });
        setOpenDrawer(false);
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notification.error({ message: `Tag update failed: ${errorMessage}` });
      }
    }
  };

  const handleDelete = async (deletingTag: Tag) => {
    app.modal.confirm({
      icon: <DeleteOutlined />,
      title: (
        <Text>
          Deleting&nbsp;
          <Text strong italic>
            {deletingTag.name}
          </Text>
          &nbsp;tag
        </Text>
      ),
      content: 'Are you sure?',
      okText: 'Delete',
      okButtonProps: { danger: true },
      onOk: async () => {
        try {
          await mutationDeleteTag(deletingTag);
          notification.success({ message: 'Tag deleted successfully' });
        } catch (error: any) {
          const errorMessage = isAxiosError(error)
            ? error.response?.data?.message
            : error.message;
          notification.error({
            message: `Tag deleted failed: ${errorMessage}`,
          });
        }
      },
    });
  };

  const handleUpdateStatus = async (record: Tag, status: TagStatus) => {
    try {
      await mutationUpdateTag({
        ...record,
        status,
      });
      notification.success({ message: 'Tag status updated  success' });
      setOpenDrawer(false);
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Tag status updated failed: ${errorMessage}`,
      });
    }
  };

  const handleAssignButtonClick = (assigningTag: Tag) => {
    setTag(assigningTag);
    setOpenAssignTagModal(true);
  };

  const handleAssignTag = async (value: AssignTagValue) => {
    try {
      await assignTag({
        id: value.tagId,
        userIds: value.userIds,
      });
      app.message.success('Success');
    } catch (err: any) {
      const errorMessage = isAxiosError(err)
        ? err.response?.data?.message
        : err.message;
      app.message.error(errorMessage);
    } finally {
      setOpenAssignTagModal(false);
    }
  };

  return (
    <>
      <Row justify="end" gutter={[0, 16]}>
        <Col flex="0 0 0">
          <Space>
            <Button
              type="primary"
              onClick={handleAddTag}
              icon={<PlusOutlined />}
            >
              Add tag
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <TagTable
            onAssign={handleAssignButtonClick}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            onUpdateStatus={handleUpdateStatus}
          />
        </Col>
      </Row>
      <CreateTag
        tag={tag}
        onClose={handleClose}
        open={openDrawer}
        onSubmit={(data) => handleSubmit(data)}
      />
      {tag != null && (
        <TagAssignModal
          tag={tag}
          open={openAssignTag}
          onOk={handleAssignTag}
          onCancel={() => setOpenAssignTagModal(false)}
        />
      )}
    </>
  );
}
