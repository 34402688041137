export enum XpRecordDirection {
  Debit = 1,
  Credit = -1,
}

export type XpProfile = {
  walletAddress?: string;
  username?: string;
  email?: string;
};

export type XPLedger = {
  id: number;
  from: string;
  to: string;
  direction: XpRecordDirection;
  description: string;
  amount: number;
  createdAt: Date;
};

export enum XpDescription {
  SignInBonus = 'xp.sign_in_bonus',
  CollaboratorRefereeReward = 'xp.collaborator_referee_reward',
  RefereeReward = 'xp.referee_reward',
  ReferalReward = 'xp.referal_reward',
  ClaimWhitelist = 'xp.claim_whitelist',
  ActivityReward = 'xp.activity_reward',
  AllegianceReward = 'xp.allegiance_reward',
  CollaboratorReward = 'xp.collaborator_approved_reward',
  AdminGrant = 'xp.admin_grant',
  Revert = 'xp.revert',
}
