import { useEffect, useMemo, useState } from 'react';
import {
  Input,
  Image,
  Col,
  Dropdown,
  MenuProps,
  Row,
  Space,
  Switch,
  Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import formatDate from 'src/helper/formatDate';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { UseFetchTagParams, useFetchTags } from 'src/hooks/api/tag';
import { Tag, TagStatus } from 'src/types';

type ButtonGroupsProp = {
  tag: Tag;
  onAssign?: (tag: Tag) => void | Promise<void>;
  onEdit?: (tag: Tag) => void | Promise<void>;
  onDelete?: (tag: Tag) => void | Promise<void>;
};

function ButtonGroup(props: ButtonGroupsProp) {
  const {
    tag,
    onAssign = () => {},
    onEdit = () => {},
    onDelete = () => {},
  } = props;

  const menuItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'update',
        label: 'Edit',
      },
      {
        key: 'delete',
        label: 'Delete',
      },
    ],
    [],
  );

  const handleButtonClick = () => {
    onAssign(tag);
  };

  const handleMenuItemClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'update': {
        onEdit(tag);
        break;
      }
      case 'delete': {
        onDelete(tag);
        break;
      }
      default: {
        throw new Error('Invalid key');
      }
    }
  };

  return (
    <Dropdown.Button
      menu={{
        items: menuItems,
        onClick: handleMenuItemClick,
      }}
      onClick={handleButtonClick}
    >
      Assign
    </Dropdown.Button>
  );
}

export type TagTableProps = {
  onAssign?: (tag: Tag) => void | Promise<void>;
  onUpdate?: (tag: Tag) => void | Promise<void>;
  onDelete?: (tag: Tag) => void | Promise<void>;
  onUpdateStatus?: (tag: Tag, status: TagStatus) => void | Promise<void>;
};

export function TagTable(props: TagTableProps) {
  const {
    onAssign = () => {},
    onUpdate = () => {},
    onDelete = () => {},
    onUpdateStatus = () => {},
  } = props;

  // Hooks
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<Tag>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  // Server states
  const params: UseFetchTagParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);
  const dataSource = useFetchTags({ ...params });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const renderTitle = () => (
    <Row>
      <Col flex={1} />
      <Col flex={0}>
        <Space>
          <Input.Search
            allowClear
            placeholder="Search"
            onSearch={handleSearch}
          />
        </Space>
      </Col>
    </Row>
  );

  const renderColumns = (): ColumnsType<Tag> => [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Icon',
      dataIndex: ['icon'],
      render: (value) => (
        <Image
          style={{
            width: 100,
            height: 100,
          }}
          src={`${process.env.REACT_APP_API_URL}v2.0/files/${value}`}
        />
      ),
    },
    {
      title: 'Notification icon',
      dataIndex: ['notificationIcon'],
      render: (value) => (
        <Image
          style={{
            width: 100,
            height: 100,
          }}
          src={`${process.env.REACT_APP_API_URL}v2.0/files/${value}`}
        />
      ),
    },
    {
      title: 'Amount user',
      dataIndex: 'amountUser',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render: (value) => formatDate(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '1%',
      render: (_, record) => (
        <Switch
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
          checked={record.status === TagStatus.Enabled}
          onChange={(value) =>
            onUpdateStatus(
              record,
              value ? TagStatus.Enabled : TagStatus.Disabled,
            )
          }
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      width: '1%',
      render: (_, record) => (
        <ButtonGroup
          tag={record}
          onAssign={onAssign}
          onEdit={onUpdate}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <Table
      title={renderTitle}
      columns={renderColumns()}
      dataSource={dataSource.data?.items}
      loading={dataSource.isLoading}
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}
