import { createRequester } from 'src/hooks/useApi';
import createAppAsyncThunk from 'src/store/createAppAsyncThunk';

export type AdminSession = {
  accessToken: string;
};
export type Credential = {
  username: string;
  password: string;
};
export const signIn = createAppAsyncThunk(
  'auth/signIn',
  async (credential: Credential, { dispatch }) => {
    const requester = createRequester({ dispatch });
    const res = await requester.post<AdminSession>('/admins/sessions', {
      username: credential.username,
      password: credential.password,
    });
    return res.data;
  },
);
