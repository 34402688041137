import { Totam } from 'src/types/Totam';
import { Page, PaginationParams, SearchParams, SortParams } from './typing';
import useFetch from './useFetch';

const KEY_FAQ_QUERIES = ['totams'];

export type UseFetchTotamParams = PaginationParams & SortParams & SearchParams;

export const useFetchTotams = (params?: UseFetchTotamParams) =>
  useFetch<Page<Totam>>({
    key: [...KEY_FAQ_QUERIES, params],
    url: '/admin/totams',
    searchParams: params,
  });
