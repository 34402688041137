import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GiveAway, GiveAwayType, GiveAwayUser } from 'src/types/GiveAway';
import useApi from '../useApi';
import { Page, PaginationParams, SearchParams, SortParams } from './typing';
import useFetch from './useFetch';

const QUERY_KEY_GIVE_AWAY = ['giveAway'];
const QUERY_KEY_GIVE_AWAY_DETAIL = (id: string) => [...QUERY_KEY_GIVE_AWAY, id];
const QUERY_KEY_GIVE_AWAY_USER = [...QUERY_KEY_GIVE_AWAY, 'user'];

type CreateGiveAwayRequest = {
  name: string;
  description?: string;
  collaboratorId?: string;
  maxAmount: number;
  xpFee: number;
  startDate?: Date | null;
  endDate?: Date | null;
  posterImage?: string;
  notiImage?: string;
  type: GiveAwayType;
  itemName?: string;
  dropId?: string;
  order?: number;
};

type UpdateGiveAwayRequest = Partial<CreateGiveAwayRequest> & {
  id: string;
};

type AddUserEligibleRequest = {
  userIds: string[];
  shouldBurnXp?: boolean;
};

export type UseFetchGiveAwayParams = PaginationParams &
  SortParams &
  SearchParams;

export const useFetchGiveAway = (params?: UseFetchGiveAwayParams) =>
  useFetch<Page<GiveAway>>({
    key: [...QUERY_KEY_GIVE_AWAY, params],
    url: 'admin/give-aways',
    searchParams: params,
  });

export const useFetchGiveAwayDetail = (id: string) =>
  useFetch<GiveAway>({
    key: QUERY_KEY_GIVE_AWAY_DETAIL(id),
    url: `admin/give-aways/${id}`,
  });

export type UseFetchGiveAwayUserParams = PaginationParams & {
  id: string;
  hasClaimed?: boolean | null;
};

export const useFetchGiveAwayUser = (params: UseFetchGiveAwayUserParams) => {
  const { id, ...otherParams } = params;

  return useFetch<Page<GiveAwayUser>>({
    key: [...QUERY_KEY_GIVE_AWAY_USER, params],
    url: `admin/give-aways/${id}/users`,
    searchParams: otherParams,
  });
};

export const useCreateGiveAway = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: GiveAway) => {
      const payload: CreateGiveAwayRequest = { ...data };

      return requester.post<void, CreateGiveAwayRequest>(
        '/admin/give-aways',
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_GIVE_AWAY,
        });
      },
    },
  );
};

export const useUpdateGiveAway = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: GiveAway) => {
      if (data.id == null) {
        throw new Error('Empty id');
      }

      const payload: UpdateGiveAwayRequest = {
        ...data,
        id: data.id,
      };

      await requester.patch<void, UpdateGiveAwayRequest>(
        `/admin/give-aways/${data.id}`,
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_GIVE_AWAY,
        });
      },
    },
  );
};

export type EndGiveAwayCommand = {
  id: string;
  endDate?: Date;
};

export const useEndGiveAway = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: EndGiveAwayCommand) => {
      const payload: UpdateGiveAwayRequest = {
        id: data.id,
        endDate: data.endDate ?? new Date(),
      };

      await requester.patch<void, UpdateGiveAwayRequest>(
        `/admin/give-aways/${data.id}`,
        payload,
      );
    },
    {
      onSuccess: (_, data: EndGiveAwayCommand) => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_GIVE_AWAY_DETAIL(data.id),
        });
      },
    },
  );
};

export const useDeleteGiveAway = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (id: string) => {
      await requester.delete(`/admin/give-aways/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_GIVE_AWAY,
        });
      },
    },
  );
};

export type UseAddUserEligibilityCommand = {
  giveAwayId: string;
  userIds: string[];
  shouldBurnXp?: boolean;
};

export const useAddUserEligibility = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (command: UseAddUserEligibilityCommand) => {
      const payload: AddUserEligibleRequest = {
        userIds: command.userIds,
        shouldBurnXp: command.shouldBurnXp,
      };

      return requester.post<void, AddUserEligibleRequest>(
        `/admin/give-aways/${command.giveAwayId}/users`,
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_GIVE_AWAY,
        });
      },
    },
  );
};

export type UseRemoveUserEligibilityCommand = {
  giveAwayId: string;
  userId: string;
};

export const useRemoveUserEligibility = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (command: UseRemoveUserEligibilityCommand) =>
      requester.delete<void, AddUserEligibleRequest>(
        `/admin/give-aways/${command.giveAwayId}/users/${command.userId}`,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_GIVE_AWAY,
        });
      },
    },
  );
};
