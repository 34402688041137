import { Col, Row, Space, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  UseFetchUserParams,
  useFetchAdvancedFilterUsers,
  useFetchAirdropEligibleForDrop,
} from 'src/hooks/api';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';

export interface AirdropEligibleUserLine {
  userId: number;
  walletAddress: string;
  isAirDropEligible: boolean;
  isAirDropEligibilityClaimed: boolean;
  isNFTAirdropped: boolean;
}

export interface AirdropEligibleUserProp {
  dropId?: string;
}

function AirdropEligibleUser({
  dropId,
}: AirdropEligibleUserProp): ReactElement {
  const { pagination, sorters, handleParamsChange } =
    useTableHelper<AirdropEligibleUserLine>();
  const { mapTableParams } = useMapTableParam();

  const [airdropEligibleUserLines, setAirdropEligibleUserLines] = useState<
    AirdropEligibleUserLine[]
  >([]);

  // Server states
  const params: UseFetchUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return baseParams;
  }, [mapTableParams, pagination, sorters]);
  const { data: usersData, isLoading } = useFetchAdvancedFilterUsers({
    ...params,
  });

  const { data: airdropEligibleUsers } = useFetchAirdropEligibleForDrop(dropId);

  useEffect(() => {
    if (usersData && usersData.items.length > 0) {
      setAirdropEligibleUserLines(
        usersData.items.map((item) => ({
          userId: item.id,
          walletAddress:
            item.walletAddress || item.custodialWalletAddress || '',
          isAirDropEligible: false,
          isAirDropEligibilityClaimed: false,
          isNFTAirdropped: false,
        })),
      );

      if (usersData && airdropEligibleUsers) {
        setAirdropEligibleUserLines(
          usersData.items.map((item) => {
            const eligibleUser =
              Array.isArray(airdropEligibleUsers) &&
              airdropEligibleUsers.find((user) => user.userId === item.id);

            return {
              userId: item.id,
              walletAddress:
                item.walletAddress || item.custodialWalletAddress || '',
              isAirDropEligible: !!eligibleUser,
              isAirDropEligibilityClaimed: eligibleUser
                ? eligibleUser.isClaimed
                : false,
              isNFTAirdropped: false,
            };
          }),
        );
      }
    }
  }, [airdropEligibleUsers, usersData]);

  const renderColumsn = (): ColumnsType<AirdropEligibleUserLine> => [
    {
      title: 'Wallet address',
      dataIndex: 'walletAddress',
    },
    {
      title: 'Airdrop Eligibility',
      dataIndex: 'isAirDropEligible',
      render: (value) => <Switch checked={value} disabled />,
    },
    {
      title: 'Airdrop Eligibility Claimed NFT',
      dataIndex: 'isAirDropEligibilityClaimed',
      render: (value) => <Switch checked={value} disabled />,
    },
    {
      title: 'NFT Airdropped',
      dataIndex: 'isAirdropWhitelistEligible',
      render: (value) => <Switch checked={value} disabled />,
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Table
            columns={renderColumsn()}
            dataSource={airdropEligibleUserLines}
            loading={isLoading}
            rowKey="id"
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Col>
      </Row>
    </Space>
  );
}

export default AirdropEligibleUser;
