import Icon from '@ant-design/icons';
import { ReactComponent as LineIcon } from 'src/assets/icons/line.svg';

interface LineProps {
  className?: string;
}

function Line(props: LineProps) {
  const { className } = props;
  return <Icon component={LineIcon} className={className} />;
}

export default Line;
