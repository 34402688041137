import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';

export default function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <main className={styles.pageLayout}>
      <div className={styles.backIcon}>
        <button type="button" onClick={() => navigate(-1)}>
          <span className="mr-4">{'<<'}</span>
          Back
        </button>
      </div>
      <div className={styles.pageContent}>
        <h1>PRIVACY POLICY</h1>
        <p className="mb-40">Last Updated: April 26,2023</p>
        <p>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          {' '}
          <strong>
            <a href="/">www.planettota.com</a>
          </strong>
          {' '}
          (the “Site”).
        </p>
        <h2>PERSONAL INFORMATION WE COLLECT</h2>
        <p>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this automatically
          collected information as “Device Information.”
        </p>
        <p>We collect Device Information using the following technologies:</p>
        <p>
          <strong>“Log files”</strong>
          : track actions occurring on the Site, and
          collect data including your IP address, browser type, Internet service
          provider, referring/exit pages, and date/time stamps.
        </p>
        <p>
          <strong>“Web beacons,”</strong>
          {' '}
          <strong>“tags,”</strong>
          {' '}
          and
          {' '}
          <strong>“pixels”:</strong>
          {' '}
          are electronic files used to record
          information about how you browse the Site.
        </p>
        <p>
          Additionally when you become a member and make a purchase or attempt
          to make a purchase through the Site, we collect certain information
          from you, including your name, email IDs, user IDs, social media IDs,
          billing address, shipping address, phone number, nationality, gender,
          date of birth and payment information (including but not limited to
          credit card numbers, email address, Metamask, WalletConnect, Other
          ERC20 Wallet/Crypto Wallets, ETH address). We refer to this
          information as
          {' '}
          <strong>“Order Information.”</strong>
        </p>
        <p>
          When we talk about
          {' '}
          <strong>“Personal Information”</strong>
          {' '}
          in this
          Privacy Policy, we are talking both about Device Information and Order
          Information.
        </p>
        <h2>HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>
        <p>
          We use the Order Information that we collect generally to fulfil any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations). Additionally, we use this Order
          Information to:
        </p>
        <ul>
          <li>
            <p>Communicate with you;</p>
          </li>
          <li>
            <p>Screen for potential risk or fraud;</p>
          </li>
          <li>
            <p>
              When in line with the preferences you have shared with us, provide
              you with information or advertising relating to our products or
              services;
            </p>
          </li>
          <li>
            <p>
              Fulfil our obligations with respect to the reason you voluntarily
              provided the data (such as responding to an inquiry or provide you
              with information, products, or services that you request from us
              or that may be of interest to you)
            </p>
          </li>
          <li>
            <p>Allow you to participate in certain features of the Site;</p>
          </li>
          <li>
            <p>
              Improve the Site or our products, events, and services and for
              other business or commercial purposes;
            </p>
          </li>
          <li>
            <p>
              Optimize your experience on the Site (such as troubleshooting
              technical programs or storing your preferences);
            </p>
          </li>
          <li>
            <p>
              Provide co-branded services and features (such as contests,
              sweepstakes, or other promotions);
            </p>
          </li>
          <li>
            <p>
              Notify you about changes to the Site (including this Privacy
              Policy);
            </p>
          </li>
          <li>
            <p>
              Carry out any other purpose described to you at the time the data
              was collected; and
            </p>
          </li>
          <li>
            <p>
              Carry out any other purpose with your consent or as permitted by
              law.
            </p>
          </li>
        </ul>
        <p>
          Additionally, we may use the Device Information that we collect to
          help us screen for potential risk and fraud (in particular, your IP
          address), and more generally to improve and optimize our Site (for
          example, by generating analytics about how our customers browse and
          interact with the Site, and to assess the success of our marketing and
          advertising campaigns).
        </p>
        <h2>SHARING YOUR PERSONAL INFORMATION</h2>
        <p>
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above.
        </p>
        <p>
          We may transfer or assign this Privacy Policy and any Personal
          Information to a third-party entity in connection with a
          collaboration, partnership, merger, acquisition, consolidation,
          restructuring, the sale of substantially all of our shares and/or
          assets, in connection with bankruptcy proceedings, reorganization, or
          other change of control events.
        </p>
        <p>
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </p>
        <h2>BEHAVIOURAL ADVERTISING</h2>
        <p>
          As described above, we may use your Personal Information to provide
          you with targeted advertisements or marketing communications we
          believe may be of interest to you.
        </p>
        <h2>DO NOT TRACK</h2>
        <p>
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </p>
        <h2>DATA RETENTION</h2>
        <p>
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </p>
        <h2>MINORS</h2>
        <p>
          We respect children’s privacy. We do not target or knowingly or
          intentionally collect personal data from children under the age of 13.
          By using the Site, you have represented and warranted that you are
          either at least 18 years of age or using the Site with the supervision
          of a parent or guardian. If you are under the age of 13, you may not
          use the Site. If you become aware that your child has provided us with
          personal data without your consent, please contact us at
          {' '}
          <strong>
            <a href="mailto:hello@planettota.com">hello@planettota.com</a>
          </strong>
        </p>
        <h2>CHANGES</h2>
        <p>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
        <h2>CONTACT US</h2>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at
          {' '}
          <strong>
            <a href="mailto:hello@planettota.com">hello@planettota.com</a>
          </strong>
          .
        </p>
      </div>
    </main>
  );
}
