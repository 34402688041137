import { PlusOutlined } from '@ant-design/icons';

import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  notification as notificationAntd,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import CreateEmailMessage from 'src/components/EmailMessageCreate';
import formatDate from 'src/helper/formatDate';
import {
  CreateEmailMessagePayload,
  UpdateEmailMessagePayload,
  UseFetchEmailMessageParams,
  useCreateEmailMessage,
  useDeleteEmailMessage,
  useFetchEmailMessages,
  useUpdateEmailMessage,
  useUpdateSendAllEmailMessage,
} from 'src/hooks/api/email-message';

import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { EmailMessage, TypeEmailMessage } from 'src/types/EmailMessage';
import { ErrorDataResponse } from 'src/typing';

export default function EmailMessagePage() {
  // Hooks
  const {
    pagination,
    sorters,
    filters,
    syncDataPagination,
    handleParamsChange,
  } = useTableHelper<EmailMessage>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [emailMessage, setEmailMessage] = useState<EmailMessage | undefined>(
    undefined,
  );
  const [edit, setEdit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  // Server states
  const params: UseFetchEmailMessageParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
      filters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search, filters]);

  const dataSource = useFetchEmailMessages({ ...params });
  const { mutateAsync: mutationCreateEmailMessage } = useCreateEmailMessage();
  const { mutateAsync: mutationUpdateEmailMessage } = useUpdateEmailMessage();
  const { mutateAsync: mutationDeleteEmailMessage } = useDeleteEmailMessage();
  const { mutateAsync: mutationUpdateSendAllEmailMessage } =
    useUpdateSendAllEmailMessage();

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleAddEmailMessage = () => {
    setEmailMessage(undefined);
    setEdit(false);
    setOpenDrawer(true);
  };

  const handleEdit = (record: EmailMessage) => {
    setEdit(true);
    setEmailMessage(record);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setEmailMessage(undefined);
    setEdit(false);
  };

  const handleSubmit = async (formValues: CreateEmailMessagePayload) => {
    if (!edit) {
      try {
        await mutationCreateEmailMessage(formValues);
        setOpenDrawer(false);
        notificationAntd.success({
          message: 'EmailMessage created successfully',
        });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notificationAntd.error({
          message: `EmailMessage create failed: ${errorMessage}`,
        });
      }
    }

    if (edit) {
      try {
        await mutationUpdateEmailMessage(
          formValues as UpdateEmailMessagePayload,
        );
        notificationAntd.success({ message: 'Email message updated success' });
        setOpenDrawer(false);

        console.log(formValues);

        if (formValues?.selectedAllUser) {
          console.log('here');
          await mutationUpdateSendAllEmailMessage(
            formValues as UpdateEmailMessagePayload,
          );
        }
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notificationAntd.error({
          message: `Email message update failed: ${errorMessage}`,
        });
      }
    }
  };

  const handleDeleteNoti = async (deleteNoti: EmailMessage) => {
    try {
      await mutationDeleteEmailMessage(deleteNoti);
      notificationAntd.success({
        message: 'Email message deleted successfully',
      });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notificationAntd.error({
        message: `Email message deleted failed: ${errorMessage}`,
      });
    }
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<EmailMessage> => [
    {
      title: 'Subject',
      dataIndex: 'subject',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: 'Global',
          value: 'global',
        },
        {
          text: 'User',
          value: 'user',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.type === value,
      render: (value) =>
        value === TypeEmailMessage.GLOBAL ? 'global' : 'user',
    },

    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: true,
      render: (value) => formatDate(value),
    },
    {
      title: 'Action',
      key: 'actions',
      width: '15%',
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete the email message"
            description="Are you sure to delete this email message?"
            onConfirm={async () => handleDeleteNoti(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col flex="0 0 0">
        <Button
          type="primary"
          onClick={handleAddEmailMessage}
          icon={<PlusOutlined />}
        >
          Add email message
        </Button>
      </Col>
      <Col span={24}>
        <Card title="Email Messages" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
            scroll={{ x: true }}
          />
        </Card>
      </Col>
      <CreateEmailMessage
        emailMessage={emailMessage}
        onClose={handleClose}
        open={openDrawer}
        onSubmit={(assetData) => handleSubmit(assetData)}
      />
    </Row>
  );
}
