// import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DiscountSetting } from 'src/types/DiscountSetting';
import useApi from '../useApi';
import useFetch from './useFetch';

const KEY_DISCOUNT_QUERIES = ['discount'];

export const useFetchDiscountSettings = () => useFetch<DiscountSetting[]>({
  key: [...KEY_DISCOUNT_QUERIES],
  url: '/discount',
});

interface DiscountUpdateItem {
  key: string;
  discountPercent: number;
  discountByEach: number;
}

export const useUpdateDiscountSettings = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: DiscountUpdateItem[]) => {
      const payload = {
        items: data,
      };
      return requester.put('/discount/batch-update', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_DISCOUNT_QUERIES });
      },
    },
  );
};
