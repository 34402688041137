export enum StatusEmailMessage {
  CREATED = 'created',
  READ = 'read',
  ARCHIVED = 'archived',
}

export enum TypeEmailMessage {
  GLOBAL = 'global',
  USER = 'user',
}

export type EmailMessage = {
  id?: number;

  userIds: string[];

  icon: string;
  title: string;

  subject: string;

  content: string;

  template?: string;

  status: StatusEmailMessage;

  type: TypeEmailMessage;

  readAt?: Date;

  archivedAt?: Date;

  createdAt?: Date;

  updatedAt?: Date;
};
