import { Card, Col, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import { useFetchCollections, useFetchDrops } from 'src/hooks/api';
import {
  FilterAdvancedNFTMinter,
  UseFetchNFTMintersParams,
  useFetchNFTMinters,
} from 'src/hooks/api/nft';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { NFTPerTransaction } from 'src/types/NFT';
import NFTMinterFilter, { NFTMinterFormDataFilter } from './NFTMinterFilter';

const columns: ColumnsType<NFTPerTransaction> = [
  {
    title: 'Minter Username',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: 'Mint date',
    dataIndex: 'mintDate',
    key: 'mintDate',
    sorter: true,
    sortDirections: ['ascend', 'descend'],
    render: (mintDate) => (
      <Tag color="red">
        {`${new Date(mintDate * 1000).toLocaleDateString()}`}
      </Tag>
    ),
  },
  {
    title: ' NFT collection',
    dataIndex: 'collectionName',
    key: 'collectionName',
  },
  {
    title: ' NFT drop',
    dataIndex: 'dropName',
    key: 'dropName',
  },
  {
    title: ' Wallet',
    dataIndex: 'walletAddress',
    key: 'walletAddress',
  },
  {
    title: ' MINT NFT quantity',
    dataIndex: 'mintQty',
    key: 'mintQty',
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
];

export default function NFTMintersTable() {
  // Hooks
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<NFTPerTransaction>();
  const { mapTableParams } = useMapTableParam();

  const [filter, setFilter] = useState<FilterAdvancedNFTMinter | null>(null);
  const [openFilter, setOpenFilter] = useState(false);

  const params: UseFetchNFTMintersParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      ...filter,
    };
  }, [mapTableParams, pagination, sorters, filter]);

  const { data: NFTMinters } = useFetchNFTMinters(params);
  const { data: collections } = useFetchCollections();
  const { data: drops } = useFetchDrops();

  useEffect(() => {
    syncDataPagination(NFTMinters);
  }, [syncDataPagination, NFTMinters]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    setFilter(null);
  };

  const handleSubmitFilter = (data: NFTMinterFormDataFilter) => {
    const dataFilter = {
      dropId: data.dropId,
      collectionId: data.collectionId,
    };
    setFilter(dataFilter);
  };

  const renderFilter = () => (
    <Space size="small">
      <Col>
        <NFTMinterFilter
          onOpen={handleOpenFilter}
          open={openFilter}
          onCancel={handleCancelFilter}
          onSubmit={(data: NFTMinterFormDataFilter) => handleSubmitFilter(data)}
          collections={collections || []}
          drops={drops || []}
          onReset={handleResetFilter}
        />
      </Col>
    </Space>
  );

  return (
    <Card title="NFT Minters" extra={renderFilter()}>
      <Table
        dataSource={NFTMinters?.items}
        columns={columns}
        rowKey="id"
        pagination={pagination}
        onChange={handleParamsChange}
      />
    </Card>
  );
}
