import {
  Card, Col, Row, Space, Statistic,
} from 'antd';
import useFetchDashboardData from 'src/hooks/api/dashboard';

export default function Dashboard() {
  const dataSource = useFetchDashboardData();

  return (
    <Space direction="vertical" size="large">
      <Row gutter={24}>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Registered users"
              value={dataSource.data?.totalRegisteredUser}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Activities live"
              value={dataSource.data?.totalActivity}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Collaborators"
              value={dataSource.data?.totalCollaborator}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="XPs Rewarded"
              value={dataSource.data?.totalXPsAwarded}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Total activity submission"
              value={dataSource.data?.totalActivitySubmission}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Total user who have add both wallets"
              value={dataSource.data?.totalUserExternalWallet}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Total users who have joined using referral"
              value={dataSource.data?.totalUserRegisteredViaReferral}
            />
          </Card>
        </Col>
      </Row>
    </Space>
  );
}
