import { useQuery, QueryKey, UseQueryOptions } from '@tanstack/react-query';
import qs from 'qs';
import useApi from '../useApi';

export type UseFetchOptions<Res, ParseFunction extends (r: Res) => any> = {
  key: QueryKey;
  url: string;
  searchParams?: Record<string, unknown>;
  queryOptions?: UseQueryOptions<
    ParseFunction extends (res: Res) => infer ParsedRes ? ParsedRes : Res
  >;
  parseFn?: ParseFunction;
};
// eslint-disable-next-line max-len
export type UseFetchWithParseOptions<
  Res,
  ParseFunction extends (res: Res) => any,
> = ParseFunction extends (res: Res) => any
  ? // eslint-disable-next-line max-len
    Omit<UseFetchOptions<Res, ParseFunction>, 'parseFn'> &
      Required<Pick<UseFetchOptions<Res, ParseFunction>, 'parseFn'>>
  : UseFetchOptions<Res, ParseFunction>;

function useFetch<Res>(
  options: UseFetchOptions<Res, (res: Res) => Res>,
): ReturnType<typeof useQuery<Res>>;
// eslint-disable-next-line max-len
function useFetch<Res, ParsedRes>(
  options: UseFetchWithParseOptions<Res, (res: Res) => ParsedRes>,
): ReturnType<typeof useQuery<ParsedRes>>;

// eslint-disable-next-line max-len
function useFetch<Res, ParsedRes = unknown>(
  options: UseFetchOptions<
    Res,
    ParsedRes extends unknown ? (res: Res) => Res : (res: Res) => ParsedRes
  >,
) {
  const { parseFn = (res: any) => res } = options;
  const { requester } = useApi();

  return useQuery(
    options.key,
    async () => {
      let { url } = options;

      if (options.searchParams != null) {
        const searchParams = qs.stringify(options.searchParams, {
          skipNulls: true,
          arrayFormat: 'brackets',
          encode: false,
        });
        url = `${options.url}?${searchParams}`;
      }

      const response = await requester.get(url);
      return parseFn(response.data);
    },
    options.queryOptions,
  );
}

export default useFetch;
