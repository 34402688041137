export const SystemPermissions = {
  BASE_PERMISSIONS: {
    key: 'BASE_PERMISSIONS',
    name: 'Base permissions',
    description: 'Allow base no need check permissions',
  },

  VIEW_ALL_USER: {
    key: 'VIEW_ALL_USER',
    name: 'View user',
    description: 'View all user',
  },
  VIEW_ALL_WHITELIST_USER: {
    key: 'VIEW_ALL_WHITELIST_USER',
    name: 'View whitelist users',
    description: 'View whitelisted users',
  },

  VIEW_ALL_AIRDROP_USER: {
    key: 'VIEW_ALL_AIRDROP_USER',
    name: 'View airdrop users',
    description: 'View airdrop users',
  },
  TAG_USER: {
    key: 'TAG_USER',
    name: 'OG Tag User',
    description: 'Give OG Status',
  },
  FILTER_USER: {
    key: 'FILTER_USER',
    name: 'Filter Users',
    description: 'Filter/search users',
  },
  VIEW_PENDING_WHITELIST_USER: {
    key: 'VIEW_PENDING_WHITELIST_USER',
    name: 'View pending whitelist users',
    description: 'View pending to whitelist users',
  },
  VIEW_WHITELISTED_USER: {
    key: 'VIEW_WHITELISTED_USER',
    name: 'View whitelisted users',
    description: 'The users who have claimed their whitelist spot',
  },
  GIVE_WHITELIST_ELIGIBILITY: {
    key: 'GIVE_WHITELIST_ELIGIBILITY',
    name: 'Give whitelist eligibility',
    description: 'Give whitelist eligibility',
  },
  VIEW_AIRDROP_ELIGIBILITY_USERS: {
    key: 'VIEW_AIRDROP_ELIGIBILITY_USERS',
    name: 'View airdrop eligible users',
    description: 'View airdrop eligible users',
  },
  VIEW_AIRDROPPED_USER: {
    key: 'VIEW_AIRDROPPED_USER',
    name: 'View airdropped users',
    description: 'View list of users who have received Airdrop',
  },
  VIEW_ALL_ACTIVITY: {
    key: 'VIEW_ALL_ACTIVITY',
    name: 'View activity',
    description: 'View all activity',
  },
  CREATE_ACTIVITY: {
    key: 'CREATE_ACTIVITY',
    name: 'Create activity',
    description: 'Create new activities',
  },
  UPDATE_ACTIVITY: {
    key: 'UPDATE_ACTIVITY',
    name: 'Edit activity',
    description: 'Edit activities',
  },
  UPDATE_STATUS_ACTIVITY: {
    key: 'UPDATE_STATUS_ACTIVITY',
    name: 'Update status activity',
    description: 'Publish or draft activities',
  },
  DELETE_ACTIVITY: {
    key: 'DELETE_ACTIVITY',
    name: 'Delete Activity',
    description: 'Delete activity',
  },
  VIEW_ALL_SUBMISSION: {
    key: 'VIEW_ALL_SUBMISSION',
    name: 'View activity submission',
    description: 'View all activity submissions (Activity Proof)',
  },
  UPDATE_STATUS_ACTIVITY_SUBMISSION: {
    key: 'UPDATE_STATUS_ACTIVITY_SUBMISSION',
    name: 'Update status activity submission',
    description: 'Verify/deny activity submissions',
  },

  VIEW_ALL_DASHBOARD_USER: {
    key: 'VIEW_ALL_DASHBOARD_USER',
    name: 'View dashboard user',
    description: 'View all dashboard users',
  },

  CREATE_DASHBOARD_USER: {
    key: 'CREATE_DASHBOARD_USER',
    name: 'Create dashboard user',
    description: 'Create new Dashboard user',
  },

  VIEW_ALL_PRE_SALE: {
    key: 'VIEW_ALL_PRE_SALE',
    name: 'View whitelist sale',
    description: 'View all whitelist sales( Pre Sale (Whitelist Sale))',
  },
  CREATE_PRE_SALE: {
    key: 'CREATE_PRE_SALE',
    name: 'Create whitelist sale',
    description: 'Create whitelist Sale( Pre Sale (Whitelist Sale))',
  },
  UPDATE_PRE_SALE: {
    key: 'UPDATE_PRE_SALE',
    name: 'Update whitelist sale',
    description: 'Edit whitelist sale( Pre Sale (Whitelist Sale))',
  },
  DELETE_PRE_SALE: {
    key: 'DELETE_PRE_SALE',
    name: 'Delete whitelist sale',
    description: 'Delete whitelist sale( Pre Sale (Whitelist Sale))',
  },
  VIEW_PRE_SALE_DETAIL: {
    key: 'VIEW_PRE_SALE_DETAIL',
    name: 'View whitelist sale details',
    description: 'View WL sale details ',
  },
  VIEW_PRE_SALE_USER_DETAIL: {
    key: 'VIEW_PRE_SALE_USER_DETAIL',
    name: 'View whitelist sale user details ',
    description:
      'View WL sale user details (minted users, new users, whitelisted users, total users)',
  },
  VIEW_ALL_PUBLIC_SALE: {
    key: 'VIEW_ALL_PUBLIC_SALE',
    name: 'View public sale',
    description: 'View all public sales',
  },
  VIEW_PUBLIC_SALE_DETAIL: {
    key: 'VIEW_PUBLIC_SALE_DETAIL',
    name: 'View public sale details',
    description: 'View public sales details',
  },
  EDIT_PUBLIC_SALE: {
    key: 'EDIT_PUBLIC_SALE',
    name: 'Edit sale',
    description: 'Edit public sales',
  },
  DELETE_PUBLIC_SALE: {
    key: 'DELETE_PUBLIC_SALE',
    name: 'Delete sale',
    description: 'Delete public sales',
  },
  VIEW_PUBLIC_SALE_USER_DETAIL: {
    key: 'VIEW_PUBLIC_SALE_USER_DETAIL',
    name: 'View public sale user details ',
    description: 'View public sale user details',
  },
  VIEW_ALL_ADMIN: {
    key: 'VIEW_ALL_ADMIN',
    name: 'View admin ',
    description: 'View all admin',
  },
  CREATE_ADMIN: {
    key: 'CREATE_ADMIN',
    name: 'Create admin',
    description: 'Create new admin',
  },
  UPDATE_ADMIN: {
    key: 'UPDATE_ADMIN',
    name: 'Update admin',
    description: 'Update admin',
  },

  UPDATE_STATUS_ADMIN: {
    key: 'UPDATE_STATUS_ADMIN',
    name: 'Update Status Admin',
    description: 'Disable admin dashboard users',
  },

  UPDATE_USER_CREDENTIALS: {
    key: 'UPDATE_USER_CREDENTIALS',
    name: 'Update user credentials',
    description: 'Edit user details - credentials',
  },
  VIEW_ALL_ALLEGIANCE: {
    key: 'VIEW_ALL_ALLEGIANCE',
    name: 'View allegiance',
    description: 'View all allegiances',
  },
  VIEW_ALLEGIANCE_DETAIL: {
    key: 'VIEW_ALLEGIANCE_DETAIL',
    name: 'View detail allegiance',
    description: 'View detail allegiance',
  },
  CREATE_ALLEGIANCE: {
    key: 'CREATE_ALLEGIANCE',
    name: 'Create allegiance',
    description: 'Create new allegiance',
  },
  UPDATE_ALLEGIANCE: {
    key: 'UPDATE_ALLEGIANCE',
    name: 'Update allegiance',
    description: 'Edit allegiance',
  },
  DELETE_ALLEGIANCE: {
    key: 'DELETE_ALLEGIANCE',
    name: 'Delete allegiance',
    description: 'Edit allegiance',
  },

  VIEW_ALL_ALLEGIANCE_SUBMISSION: {
    key: 'VIEW_ALL_ALLEGIANCE_SUBMISSION',
    name: 'View allegiance Submission',
    description: 'View all allegiance submissions',
  },
  VERIFY_ALLEGIANCE_SUBMISSIONS: {
    key: 'VERIFY_ALLEGIANCE_SUBMISSIONS',
    name: 'Verify/deny allegiance submission',
    description: 'Verify/deny allegiance submissions',
  },
  VIEW_ALL_XP_LEDGERS: {
    key: 'VIEW_ALL_XP_LEDGERS',
    name: 'View xp ledger',
    description: 'View XP Ledgers',
  },
  VIEW_ALL_INFORMATION_USER: {
    key: 'VIEW_ALL_INFORMATION_USER',
    name: 'View information user',
    description: 'View list of all users and their total tokens',
  },
  SELECT_USER_GRANT_XP: {
    key: 'SELECT_USER_GRANT_XP',
    name: 'Select user(s) - grant XPs',
    description: 'Select user(s) - grant XPs',
  },

  VIEW_ALL_APPROVAL: {
    key: 'VIEW_ALL_APPROVAL',
    name: 'View approval',
    description: 'View list of all approvals',
  },
  VIEW_PENDING_APPROVAL: {
    key: 'VIEW_PENDING_APPROVAL',
    name: 'View pending approval',
    description: 'View all pending approvals',
  },
  VIEW_ALL_APPROVED: {
    key: 'VIEW_ALL_APPROVED',
    name: 'View approved',
    description: 'View list of all approved',
  },
  VIEW_DETAIL_APPROVAL: {
    key: 'VIEW_DETAIL_APPROVAL',
    name: 'View details approval',
    description: 'Approve or deny approval request',
  },
  UPDATE_STATUS_APPROVAL: {
    key: 'UPDATE_STATUS_APPROVAL',
    name: 'Update approval',
    description: 'Approve or deny approval request',
  },
  UPDATE_DETAIL_APPROVAL: {
    key: 'UPDATE_DETAIL_APPROVAL',
    name: 'Update detail approval',
    description: 'If denied, they can edit the details and submit',
  },
  CREATE_DROP: {
    key: 'CREATE_DROP',
    name: 'Create new drop',
    description: 'Create new drop',
  },
  UPDATE_DROP: {
    key: 'UPDATE_DROP',
    name: 'Edit drop',
    description: 'Edit Drop',
  },
  VIEW_DETAIL_DROP: {
    key: 'VIEW_DETAIL_DROP',
    name: 'View drop details',
    description: 'View details',
  },
  VIEW_AIR_DROP_DETAIL: {
    key: 'VIEW_AIR_DROP_DETAIL',
    name: 'Airdrop details',
    description: 'Airdrop details',
  },
  VIEW_MINT_DETAIL: {
    key: 'VIEW_MINT_DETAIL',
    name: 'Mint details',
    description: 'Mint details',
  },
  GIVE_AIRDROP_ELIGIBILITY: {
    key: 'GIVE_AIR_DROP_ELIGIBILITY',
    name: 'Give airdrop eligibility',
    description: 'Give airdrop eligibility',
  },
  REQUEST_WHITELIST_ELIGIBILITY: {
    key: 'REQUEST_WHITELIST_ELIGIBILITY',
    name: 'Request whitelist eligibility',
    description: 'Request whitelist eligibility for a user',
  },
  REQUEST_AIR_DROP_ELIGIBILITY: {
    key: 'REQUEST_AIR_DROP_ELIGIBILITY',
    name: 'Request airdrop eligibility',
    description: 'Request airdrop eligibility for a user',
  },
  VIEW_ALL_ACTIVITY_SUBMISSION: {
    key: 'VIEW_ALL_ACTIVITY_SUBMISSION',
    name: 'View activity submission',
    description: 'Vew all activity submissions (Activity Proof)',
  },
  VIEW_ALL_ROLE: {
    key: 'VIEW_ALL_ROLE',
    name: 'View role',
    description: 'View all roles and permissions ',
  },
  CREATE_ROLE: {
    key: 'CREATE_ROLE',
    name: 'Create role',
    description: 'Create role and permissions ',
  },
  UPDATE_ROLE: {
    key: 'UPDATE_ROLE',
    name: 'Update role',
    description: 'Update role role and permissions',
  },
  DELETE_ROLE: {
    key: 'DELETE_ROLE',
    name: 'Delete role',
    description: 'Delete role and permissions',
  },
  SYNC_ROLE: {
    key: 'SYNC_ROLE',
    name: 'Sync roles',
    description: 'Sync roles and permissions',
  },
  VIEW_DETAIL_ACTIVITY: {
    key: 'VIEW_DETAIL_ACTIVITY',
    name: 'View detail activity',
    description: 'View detail activity',
  },
  CREATE_ACTIVITY_SUBMISSION: {
    key: 'CREATE_ACTIVITY_SUBMISSION',
    name: 'Create activity submission',
    description: 'Create activity submission',
  },
  UPDATE_ACTIVITY_SUBMISSION: {
    key: 'UPDATE_ACTIVITY_SUBMISSION',
    name: 'Update activity submission',
    description: 'Update activity submission',
  },
  VIEW_DETAIL_WL_SALE: {
    key: 'VIEW_DETAIL_WL_SALE',
    name: 'View detail whitelist sale',
    description: 'View detail WL sale',
  },
  CREATE_WL_SALE: {
    key: 'CREATE_WL_SALE',
    name: 'Create whitelist sale',
    description: 'Create WL Sale( Pre Sale (Whitelist Sale))',
  },
  VIEW_PUBLIC_SALE_NEW_USER: {
    key: 'VIEW_PUBLIC_SALE_NEW_USER',
    name: 'View public sale new user',
    description: 'View pubic sale new user',
  },
  VIEW_PUBLIC_SALE_TOTAL_USER: {
    key: 'VIEW_PUBLIC_SALE_TOTAL_USER',
    name: 'View public sale total user',
    description: 'View public sale total user',
  },
  VIEW_PUBLIC_SALE_WHITELISTED_USER: {
    key: 'VIEW_PUBLIC_SALE_WHITELISTED_USER',
    name: 'View public sale whitelisted user',
    description: 'View public sale whitelisted user',
  },
  VIEW_PUBLIC_SALE_MINTED_USER: {
    key: 'VIEW_PUBLIC_SALE_MINTED_USER',
    name: 'View public sale minted user',
    description: 'View public sale minted user',
  },
  UPDATE_DASHBOARD_USER_ACCESS_STATUS: {
    key: 'UPDATE_DASHBOARD_USER_ACCESS_STATUS',
    name: 'Update dashboard user access status',
    description: 'Disable admin dashboard users',
  },
  UPDATE_DASHBOARD_USER_CREDENTIALS: {
    key: 'UPDATE_DASHBOARD_USER_CREDENTIALS',
    name: 'Edit user details - credentials',
    description: 'Edit user details - credentials',
  },
  VIEW_DETAIL_ALLEGIANCE: {
    key: 'VIEW_DETAIL_ALLEGIANCE',
    name: 'View detail allegiance',
    description: 'View detail allegiance',
  },
  VIEW_DETAIL_ALLEGIANCE_SUBMISSION: {
    key: 'VIEW_DETAIL_ALLEGIANCE_SUBMISSION',
    name: 'View detail allegiance submission',
    description: 'View detail allegiance',
  },
  CREATE_COLLABORATOR: {
    key: 'CREATE_COLLABORATOR',
    name: 'Create collaborator',
    description: 'Create collaborator',
  },
  VIEW_ALL_COLLABORATOR: {
    key: 'VIEW_ALL_COLLABORATOR',
    name: 'View all collaborator',
    description: 'View all collaborator',
  },
  VIEW_DETAIL_COLLABORATOR: {
    key: 'VIEW_DETAIL_COLLABORATOR',
    name: 'View detail collaborator',
    description: 'View detail collaborator',
  },
  UPDATE_COLLABORATOR: {
    key: 'UPDATE_COLLABORATOR',
    name: 'Update collaborator',
    description: 'Update collaborator',
  },
  UPDATE_STATUS_COLLABORATOR: {
    key: 'UPDATE_STATUS_COLLABORATOR',
    name: 'Update status collaborator',
    description: 'Update Collaborator',
  },
  VIEW_ALL_DASHBOARD_SETTING: {
    key: 'VIEW_ALL_DASHBOARD_SETTING',
    name: 'View all dashboard settings',
    description:
      'Dashboard settings like - Number of XPs to be granted as joining bonus',
  },
  UPDATE_DASHBOARD_SETTING: {
    key: 'UPDATE_DASHBOARD_SETTING',
    name: 'Modify settings',
    description: 'Dashboard users can modify these settings',
  },
  REQUEST_APPROVAL_SETTING: {
    key: 'REQUEST_APPROVAL_SETTING',
    name: 'Request approval for a change in settings ',
    description: 'Dashboard users can modify these settings',
  },
  CREATE_NOTIFICATION: {
    key: 'CREATE_NOTIFICATION',
    name: 'Send notifications to users',
    description: 'Admins can send notifications to users from Dashboard',
  },
  VIEW_ALL_NOTIFICATION: {
    key: 'VIEW_ALL_NOTIFICATION',
    name: 'Send notifications to users',
    description: 'Admins can send notifications to users from Dashboard',
  },
  VIEW_DETAIL_USER: {
    key: 'VIEW_DETAIL_USER',
    name: 'View user details',
    description: 'View detail user',
  },
  UPDATE_WHITELIST_STATUS: {
    key: 'UPDATE_WHITELIST_STATUS',
    name: 'Update whitelist status',
    description: 'Update Whitelist Status',
  },
  UPDATE_AIRDROP_STATUS: {
    key: 'UPDATE_AIRDROP_STATUS',
    name: 'Update airdrop status',
    description: 'Update Airdrop Status',
  },
  UPDATE_OG_STATUS: {
    key: 'UPDATE_OG_STATUS',
    name: 'Update OG status',
    description: 'Update OG Status',
  },
  VIEW_DETAIL_ACTIVITY_SUBMISSION: {
    key: 'VIEW_DETAIL_ACTIVITY_SUBMISSION',
    name: 'View detail activity submission',
    description: 'View detail activity submission',
  },
  UPDATE_ALLEGIANCE_SUBMISSION: {
    key: 'UPDATE_ALLEGIANCE_SUBMISSION',
    name: 'Update allegiance submission',
    description: 'Update allegiance submission',
  },
  VIEW_ALL_GRANT_XP: {
    key: 'VIEW_ALL_GRANT_XP',
    name: 'View all grant xp',
    description: 'View all grant xp',
  },
  VIEW_ALL_DISCOUNT: {
    key: 'VIEW_ALL_DISCOUNT',
    name: 'View all tool discount',
    description: 'View all tool discount',
  },
  VIEW_ALL_TOOL: {
    key: 'VIEW_ALL_TOOL',
    name: 'View all tool dashboard',
    description: 'View all tool dashboard',
  },
  VIEW_ALL_DASHBOARD_INFO: {
    key: 'VIEW_ALL_DASHBOARD_INFO',
    name: 'View all dashboard info',
    description: 'View all dashboard info',
  },
  VIEW_PAGE_DASHBOARD: {
    key: 'VIEW_PAGE_DASHBOARD',
    name: 'View page dashboard',
    description: 'View page dashboard',
  },
  VIEW_PAGE_USER: {
    key: 'VIEW_PAGE_USER',
    name: 'View page user',
    description: 'View page user',
  },
  VIEW_PAGE_LEDGER: {
    key: 'VIEW_PAGE_LEDGER',
    name: 'View page ledger',
    description: 'View page ledger',
  },
  VIEW_PAGE_GRANT_XP: {
    key: 'VIEW_PAGE_GRANT_XP',
    name: 'View page grant xp',
    description: 'View page grant xp',
  },
  VIEW_PAGE_ACTIVITY: {
    key: 'VIEW_PAGE_ACTIVITY',
    name: 'View page activity',
    description: 'View page activity',
  },
  VIEW_PAGE_ACTIVITY_SUBMISSION: {
    key: 'VIEW_PAGE_ACTIVITY_SUBMISSION',
    name: 'View page activity submission',
    description: 'View page activity submission',
  },
  VIEW_PAGE_ALLEGIANCE: {
    key: 'VIEW_PAGE_ALLEGIANCE',
    name: 'View page allegiance',
    description: 'View page allegiance',
  },
  VIEW_PAGE_ALLEGIANCE_SUBMISSION: {
    key: 'VIEW_PAGE_ALLEGIANCE_SUBMISSION',
    name: 'View page allegiance submission',
    description: 'View page allegiance submission',
  },
  VIEW_PAGE_COLLECTION: {
    key: 'VIEW_PAGE_COLLECTION',
    name: 'View page collection',
    description: 'View page collection',
  },
  VIEW_PAGE_DROP: {
    key: 'VIEW_PAGE_DROP',
    name: 'View page drop',
    description: 'View page drop',
  },
  VIEW_PAGE_WHITELIST_USER: {
    key: 'VIEW_PAGE_WHITELIST_USER',
    name: 'View page whitelist user',
    description: 'View page whitelist user',
  },
  VIEW_PAGE_AIRDROP: {
    key: 'VIEW_PAGE_AIRDROP',
    name: 'View page page airdrop',
    description: 'View page airdrop ',
  },
  VIEW_PAGE_COLLABORATOR: {
    key: 'VIEW_PAGE_COLLABORATOR',
    name: 'View page collaborator',
    description: 'View page collaborator',
  },
  VIEW_PAGE_GIVE_AWAY: {
    key: 'VIEW_PAGE_GIVE_AWAY',
    name: 'View page give away',
    description: 'View page give away',
  },
  VIEW_PAGE_DISCOUNT: {
    key: 'VIEW_PAGE_DISCOUNT',
    name: 'View page discount',
    description: 'View page discount',
  },
  VIEW_PAGE_ADMIN: {
    key: 'VIEW_PAGE_ADMIN',
    name: 'View page admin',
    description: 'View page admin',
  },
  VIEW_PAGE_ROLE: {
    key: 'VIEW_PAGE_ROLE',
    name: 'View page role',
    description: 'View page role',
  },
  VIEW_PAGE_DEPLOY_COLLECTION_NFT: {
    key: 'VIEW_PAGE_DEPLOY_COLLECTION_NFT',
    name: 'View page deploy collection nft',
    description: 'View page deploy collection nft',
  },
  VIEW_PAGE_COLLECTION_MANAGER: {
    key: 'VIEW_PAGE_COLLECTION_MANAGER',
    name: 'View page collection manager',
    description: 'View page collection manager',
  },
  VIEW_PAGE_PRIVATE_SALE_MANAGER: {
    key: 'VIEW_PAGE_PRIVATE_SALE_MANAGER',
    name: 'View page PRIVATE_SALE manager',
    description: 'View page private sale manager',
  },
  VIEW_COLLABORATOR_DASHBOARD: {
    key: 'VIEW_COLLABORATOR_DASHBOARD',
    name: 'View collaborator dashboard',
    description: 'View collaborator dashboard',
  },
  VIEW_PAGE_FAQ: {
    key: 'VIEW_PAGE_FAQ',
    name: 'View page faq',
    description: 'View page faq',
  },
  VIEW_PAGE_REFERRAL_LEDGER: {
    key: 'VIEW_PAGE_REFERRAL_LEDGER',
    name: 'View page referral ledger',
    description: 'View page referral ledger',
  },
  VIEW_PAGE_REFERRAL_BOARD: {
    key: 'VIEW_PAGE_REFERRAL_BOARD',
    name: 'View page referral board',
    description: 'View page referral board',
  },
  VIEW_PAGE_NOTIFICATION: {
    key: 'VIEW_PAGE_NOTIFICATION',
    name: 'View page notification',
    description: 'View page notification',
  },
  VIEW_PAGE_TAG: {
    key: 'VIEW_PAGE_TAG',
    name: 'View page tag',
    description: 'View page tag',
  },
  VIEW_PAGE_ASSET: {
    key: 'VIEW_PAGE_ASSET',
    name: 'View page asset',
    description: 'View page asset',
  },
  CREATE_TAG: {
    key: 'CREATE_TAG',
    name: 'Create tag',
    description: 'Create tag',
  },
  UPDATE_TAG: {
    key: 'UPDATE_TAG',
    name: 'Update tag',
    description: 'Update tag',
  },
  DELETE_TAG: {
    key: 'DELETE_TAG',
    name: 'Delete tag',
    description: 'Delete tag',
  },
  CREATE_ASSET: {
    key: 'CREATE_ASSET',
    name: 'Create asset',
    description: 'Create asset',
  },
  UPDATE_ASSET: {
    key: 'UPDATE_ASSET',
    name: 'Update asset',
    description: 'Update asset',
  },
  DELETE_ASSET: {
    key: 'DELETE_ASSET',
    name: 'Delete asset',
    description: 'Delete asset',
  },
  VIEW_PAGE_EMAIL_MESSAGE: {
    key: 'VIEW_PAGE_EMAIL_MESSAGE',
    name: 'View page email message',
    description: 'View page email message tag',
  },
  VIEW_PAGE_TOTAM: {
    key: 'VIEW_PAGE_TOTAM',
    name: 'View page totam',
    description: 'View page totam',
  },
};
export const SystemRoles = {
  BaseRole: [SystemPermissions.VIEW_ALL_DASHBOARD_SETTING],
  SuperAdmin: Object.values(SystemPermissions),
  Admin1: [
    SystemPermissions.VIEW_ALL_DASHBOARD_SETTING,
    SystemPermissions.VIEW_ALL_USER,
    SystemPermissions.VIEW_DETAIL_USER,
    SystemPermissions.VIEW_ALL_WHITELIST_USER,
    SystemPermissions.VIEW_ALL_AIRDROP_USER,
    SystemPermissions.UPDATE_AIRDROP_STATUS,
    SystemPermissions.VIEW_PENDING_WHITELIST_USER,
    SystemPermissions.VIEW_WHITELISTED_USER,
    SystemPermissions.GIVE_WHITELIST_ELIGIBILITY,
    SystemPermissions.UPDATE_WHITELIST_STATUS,
    SystemPermissions.VIEW_AIRDROP_ELIGIBILITY_USERS,
    SystemPermissions.VIEW_AIRDROPPED_USER,
    SystemPermissions.GIVE_AIRDROP_ELIGIBILITY,
    SystemPermissions.VIEW_ALL_ACTIVITY,
    SystemPermissions.CREATE_ACTIVITY,
    SystemPermissions.UPDATE_ACTIVITY,
    SystemPermissions.UPDATE_STATUS_ACTIVITY,
    SystemPermissions.VIEW_ALL_ACTIVITY_SUBMISSION,
    SystemPermissions.UPDATE_STATUS_ACTIVITY_SUBMISSION,
    SystemPermissions.VIEW_ALL_ADMIN,
    SystemPermissions.VIEW_ALL_ALLEGIANCE,
    SystemPermissions.CREATE_ALLEGIANCE,
    SystemPermissions.UPDATE_ALLEGIANCE,
    SystemPermissions.VIEW_ALL_ALLEGIANCE_SUBMISSION,
    SystemPermissions.VERIFY_ALLEGIANCE_SUBMISSIONS,
    SystemPermissions.VIEW_ALL_XP_LEDGERS,
    SystemPermissions.VIEW_ALL_INFORMATION_USER,
    SystemPermissions.SELECT_USER_GRANT_XP,
    SystemPermissions.FILTER_USER,
    SystemPermissions.VIEW_ALL_APPROVAL,
    SystemPermissions.VIEW_PENDING_APPROVAL,
    SystemPermissions.VIEW_ALL_APPROVED,
    SystemPermissions.VIEW_DETAIL_APPROVAL,
    SystemPermissions.UPDATE_STATUS_APPROVAL,
    SystemPermissions.UPDATE_DETAIL_APPROVAL,
    SystemPermissions.CREATE_COLLABORATOR,
    SystemPermissions.UPDATE_COLLABORATOR,
    SystemPermissions.VIEW_ALL_COLLABORATOR,
    SystemPermissions.VIEW_DETAIL_COLLABORATOR,
    SystemPermissions.UPDATE_STATUS_COLLABORATOR,
    SystemPermissions.VIEW_DETAIL_ACTIVITY_SUBMISSION,
    SystemPermissions.VIEW_ALL_GRANT_XP,
  ],
  Admin2: [
    SystemPermissions.VIEW_ALL_DASHBOARD_SETTING,
    SystemPermissions.VIEW_ALL_USER,
    SystemPermissions.VIEW_ALL_WHITELIST_USER,
    SystemPermissions.UPDATE_WHITELIST_STATUS,
    SystemPermissions.VIEW_ALL_AIRDROP_USER,
    SystemPermissions.UPDATE_AIRDROP_STATUS,
    SystemPermissions.VIEW_PENDING_WHITELIST_USER,
    SystemPermissions.VIEW_WHITELISTED_USER,
    SystemPermissions.GIVE_WHITELIST_ELIGIBILITY,
    SystemPermissions.VIEW_AIRDROP_ELIGIBILITY_USERS,
    SystemPermissions.VIEW_ALL_ACTIVITY,
    SystemPermissions.CREATE_ACTIVITY,
    SystemPermissions.UPDATE_ACTIVITY,
    SystemPermissions.UPDATE_STATUS_ACTIVITY,
    SystemPermissions.VIEW_ALL_ACTIVITY_SUBMISSION,
    SystemPermissions.VIEW_ALL_ALLEGIANCE,
    SystemPermissions.CREATE_ALLEGIANCE,
    SystemPermissions.UPDATE_ALLEGIANCE,
    SystemPermissions.VIEW_ALL_ALLEGIANCE_SUBMISSION,
    SystemPermissions.CREATE_ALLEGIANCE,
    SystemPermissions.UPDATE_ALLEGIANCE,
    SystemPermissions.VIEW_ALL_XP_LEDGERS,
    SystemPermissions.VIEW_ALL_INFORMATION_USER,
    SystemPermissions.SELECT_USER_GRANT_XP,
    SystemPermissions.FILTER_USER,
    SystemPermissions.VIEW_ALL_APPROVAL,
    SystemPermissions.VIEW_PENDING_APPROVAL,
    SystemPermissions.VIEW_ALL_APPROVED,
    SystemPermissions.VIEW_DETAIL_APPROVAL,
    SystemPermissions.CREATE_COLLABORATOR,
    SystemPermissions.UPDATE_COLLABORATOR,
    SystemPermissions.VIEW_ALL_COLLABORATOR,
    SystemPermissions.VIEW_DETAIL_COLLABORATOR,
    SystemPermissions.UPDATE_STATUS_COLLABORATOR,
    SystemPermissions.VIEW_DETAIL_ACTIVITY_SUBMISSION,
    SystemPermissions.GIVE_AIRDROP_ELIGIBILITY,
    SystemPermissions.VIEW_ALL_GRANT_XP,
  ],
  CommunityHead: [
    SystemPermissions.VIEW_ALL_DASHBOARD_SETTING,
    SystemPermissions.VIEW_ALL_ACTIVITY,
    SystemPermissions.CREATE_ACTIVITY,
    SystemPermissions.UPDATE_ACTIVITY,
    SystemPermissions.UPDATE_STATUS_ACTIVITY,
    SystemPermissions.DELETE_ACTIVITY,
    SystemPermissions.VIEW_ALL_ALLEGIANCE,
    SystemPermissions.CREATE_ALLEGIANCE,
    SystemPermissions.UPDATE_ALLEGIANCE,
    SystemPermissions.DELETE_ALLEGIANCE,
  ],
  Collaborator: [SystemPermissions.VIEW_COLLABORATOR_DASHBOARD],
};
