import { ProCard, ProDescriptions } from '@ant-design/pro-components';
import { Drop, DropStatistics } from 'src/hooks/api/typing';
import SendAirdropHistory from '../Airdrop/SendAirdropHistory';
import AirdropEligibleUser from './AirdropUserList';

type AirdropStatusProps = {
  dropId: string | undefined;
  drop: Drop | undefined;
  dropStatistics: DropStatistics | undefined;
};

function AirdropStatus(param: AirdropStatusProps) {
  return (
    <ProCard title="AIRDROP STATUS" split="horizontal" bordered>
      <ProCard>
        <ProDescriptions
          column={1}
          emptyText="-"
          dataSource={param}
          columns={[
            {
              title: 'Total Airdrop NFTs',
              dataIndex: ['dropStatistics', 'totalNFTAirDropped'],
            },
            {
              title: 'Number of users who have Airdrop Eligibility',
              dataIndex: ['dropStatistics', 'totalAirdropEligibleUser'],
            },
            {
              title: 'Number of users who have claimed the Airdrop eligibility',
              dataIndex: ['dropStatistics', 'totalAirdropClaimedUser'],
            },
            {
              title: 'Number of NFTs Airdropped',
              dataIndex: ['dropStatistics', 'totalNFTAirDropped'],
            },
            {
              title: 'Number of users who have received Airdrop NFTs',
              dataIndex: ['dropStatistics', 'totalAirdropNFTReceivedUser'],
            },
          ]}
        />
      </ProCard>
      <ProCard title="AIRDROP USERS">
        <AirdropEligibleUser dropId={param.dropId} />
      </ProCard>
      <ProCard title="AIRDROP NFT LEDGER">
        <SendAirdropHistory dropId={param.dropId} />
      </ProCard>
      <ProCard title="Private save eligibles">
        <SendAirdropHistory dropId={param.dropId} />
      </ProCard>
    </ProCard>
  );
}

export default AirdropStatus;
