import Icon from '@ant-design/icons';
import { ReactComponent as YelllowTriangle } from 'src/assets/icons/yellow-triangle.svg';

interface YellowTriangleProps {
  className?: string;
}

function YellowTriangle(props: YellowTriangleProps) {
  const { className } = props;
  return <Icon component={YelllowTriangle} className={className} />;
}

export default YellowTriangle;
