import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Role } from 'src/types/Admin';
import useApi from '../useApi';
import {
  Page, PaginationParams, SearchParams, SortParams,
} from './typing';
import useFetch from './useFetch';
import usePost from './usePost';

export type UseFetchRoleParams = PaginationParams & SortParams & SearchParams;

export type CreateRole = {
  key: string;
  name: string;
  description?: string;
  permissions: number[];
};

export type UpdateRole = {
  id?: number;
  key?: string;
  name?: string;
  description?: string;
  permissions?: number[];
};

const KEY_ROLE_QUERIES = ['roles'];
const KET_PERMISSION_QUERIES = ['permissions'];

export const useFetchPermissions = () => {
  const { requester } = useApi();
  return useQuery(KET_PERMISSION_QUERIES, async () => {
    const response = await requester.get('/roles/permissions');
    return response.data;
  });
};

export const useFetchRoles = (params?: UseFetchRoleParams) => useFetch<Page<Role>>({
  key: KEY_ROLE_QUERIES,
  url: '/roles',
  searchParams: params,
});

export const useCreateRole = (
  data: CreateRole,
) => {
  const queryClient = useQueryClient();

  return usePost<Role, CreateRole>({
    method: 'POST',
    url: '/roles',
    data: {
      key: data.key,
      name: data.name,
      description: data.description,
      permissions: data.permissions,
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: KEY_ROLE_QUERIES });
    },

  });
};

export const useUpdateRole = (
  data: UpdateRole,
) => {
  const queryClient = useQueryClient();

  return usePost<void, Omit<UpdateRole, 'id'>>({
    method: 'PUT',
    url: `/roles/${data.id}`,
    data: {
      key: data.key,
      name: data.name,
      description: data.description,
      permissions: data.permissions,
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: KEY_ROLE_QUERIES });
    },
  });
};
