import dayjs from 'dayjs';
import {
  App,
  Button,
  Col,
  Modal,
  ModalProps,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import {
  ProCard,
  ProColumns,
  ProDescriptions,
  ProTable,
} from '@ant-design/pro-components';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useAddUserEligibility,
  useEndGiveAway,
  useFetchGiveAwayDetail,
  useFetchGiveAwayUser,
  useRemoveUserEligibility,
} from 'src/hooks/api';
import { useFetchCollaboratorDetail } from 'src/hooks/api/collaborator';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { User } from 'src/types';
import { GiveAwayUser } from 'src/types/GiveAway';
import useGetFileByKey from 'src/hooks/useGetFileByKey';
import useRenderItemName from 'src/hooks/give-away/useRenderItemName';
import useHasEndedGiveAway from 'src/hooks/give-away/useHasEndedGiveAway';
import UserTableV2 from 'src/components/UserTableV2/UserTableV2';
import { isAxiosError } from 'axios';
import GiveAwayErrorMessage from 'src/constants/error/give-away-error';
import useGetWebUrl from 'src/hooks/useGetWebUrl';

const { Text } = Typography;

type RouteParams = {
  id: string;
};

type SelectUserModalProps = Omit<ModalProps, 'onOk' | 'onCancel'> & {
  excludeUserIds?: string[];
  onCancel?: () => void;
  onSelect?: (users: User[]) => void;
};

function SelectUserModal(props: SelectUserModalProps) {
  const {
    excludeUserIds,
    onCancel = () => {},
    onSelect = () => {},
    ...otherProps
  } = props;

  const [selectedUser, setSelectedUsers] = useState<User[]>([]);

  const handleUserSelectionChange = (_: React.Key[], selectedRows: User[]) => {
    setSelectedUsers(selectedRows);
  };

  const handleOk = () => {
    onSelect(selectedUser);
  };

  return (
    <Modal
      {...otherProps}
      closeIcon={false}
      onCancel={onCancel}
      onOk={handleOk}
      width={1024}
    >
      <UserTableV2
        filters={{ excludeUserIds }}
        rowSelection={{ onChange: handleUserSelectionChange }}
        rowKey="id"
      />
    </Modal>
  );
}

type EligibleUsersProps = {
  giveAwayId: string;
};

function EligibleUsers(props: EligibleUsersProps) {
  const { giveAwayId } = props;

  // Hooks
  const app = App.useApp();
  const { pagination, handleParamsChange } = useTableHelper<GiveAwayUser>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [claimStatusFilter, setClaimStatusFilter] = useState<boolean | null>(
    null,
  );
  const [isOpenUserSelectModal, setIsOpenUserSelectModal] = useState(false);
  // Server state
  const giveAwayDetail = useFetchGiveAwayDetail(giveAwayId);
  const allUsers = useFetchGiveAwayUser({
    id: giveAwayId,
    page: 1,
    limit: 0,
  });
  const usersParams = useMemo(() => {
    const baseParams = mapTableParams({ pagination });
    return { ...baseParams };
  }, [mapTableParams, pagination]);
  const users = useFetchGiveAwayUser({
    ...usersParams,
    id: giveAwayId,
    hasClaimed: claimStatusFilter,
  });
  const { mutateAsync: addUserGiveAwayEligibility } = useAddUserEligibility();
  const { mutateAsync: removeUserEligibility } = useRemoveUserEligibility();
  // Deprived state
  const remainingSlot = useMemo(
    () =>
      giveAwayDetail.data != null && allUsers.data != null
        ? giveAwayDetail.data.maxAmount - allUsers.data.meta.totalItems
        : null,
    [giveAwayDetail.data, allUsers.data],
  );

  const toggleUserSelectModal = () => {
    setIsOpenUserSelectModal(!isOpenUserSelectModal);
  };

  const displayErrorMessage = (err: any) => {
    let errorMessage = 'Something is wrong! Please try again';

    if (isAxiosError(err)) {
      if (err.response?.data.message != null) {
        errorMessage = GiveAwayErrorMessage[err.response.data.message];
      }

      if (
        err.response?.data.error != null &&
        Array.isArray(err.response.data.error) &&
        err.response.data.error.length
      ) {
        errorMessage = GiveAwayErrorMessage[err.response.data.error[0].message];
      }
    }

    app.message.error(errorMessage);
  };

  const handleClaimStatusChange = (value?: string) => {
    if (value === 'claimed') {
      setClaimStatusFilter(true);
    } else if (value === 'notClaimed') {
      setClaimStatusFilter(false);
    } else {
      setClaimStatusFilter(null);
    }
  };

  const handleAddUserEligibility = async (selectedUsers: User[]) => {
    try {
      await addUserGiveAwayEligibility({
        giveAwayId,
        userIds: selectedUsers.map((user) => user.id.toString()),
      });
      toggleUserSelectModal();
    } catch (err) {
      displayErrorMessage(err);
    }
  };

  const handleRemoveGiveAwayUser = async (selectedUser: GiveAwayUser) => {
    app.modal.confirm({
      title: (
        <span>
          Are you sure you want to REMOVE <i>{selectedUser.username}</i>&apos;s
          eligibilty
        </span>
      ),
      okText: 'Remove',
      okButtonProps: { danger: true },
      onOk: async () => {
        await removeUserEligibility({
          giveAwayId,
          userId: selectedUser.id,
        });
        app.message.success('Remove user successfully!');
      },
    });
  };

  const renderColumns = (): ProColumns<GiveAwayUser>[] => [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Xp',
      dataIndex: 'xp',
    },
    {
      title: 'Claimed',
      dataIndex: 'hasClaimed',
      valueEnum: {
        true: <Text type="success">YES</Text>,
        false: <Text>NO</Text>,
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      valueType: 'option',
      width: '1%',
      render: (_, user) => (
        <Space>
          <Button
            type="primary"
            danger
            disabled={user.hasClaimed}
            onClick={() => handleRemoveGiveAwayUser(user)}
          >
            Remove
          </Button>
        </Space>
      ),
    },
  ];

  const renderToolbar = () => [
    <Space>
      <Typography.Text>{remainingSlot} slot(s) left</Typography.Text>
      <Button
        type="primary"
        disabled={remainingSlot != null && remainingSlot === 0}
        onClick={toggleUserSelectModal}
      >
        Add
      </Button>
    </Space>,
  ];

  return (
    <>
      <ProCard title="Eligible users">
        <ProTable
          ghost
          search={false}
          options={false}
          toolBarRender={renderToolbar}
          toolbar={{
            multipleLine: true,
            menu: {
              items: [
                {
                  key: 'all',
                  label: 'All',
                },
                {
                  key: 'claimed',
                  label: 'Claimed',
                },
                {
                  key: 'notClaimed',
                  label: 'Not claimed',
                },
              ],
              onChange: (key) => handleClaimStatusChange(key as string),
            },
          }}
          columnEmptyText="-"
          columns={renderColumns()}
          dataSource={users.data?.items}
          loading={users.isLoading}
          pagination={pagination}
          onChange={handleParamsChange}
        />
      </ProCard>
      <SelectUserModal
        open={isOpenUserSelectModal}
        onCancel={toggleUserSelectModal}
        onSelect={handleAddUserEligibility}
      />
    </>
  );
}

type GiveAwayDetailProps = {
  id: string;
};

function GiveAwayDetail(props: GiveAwayDetailProps) {
  const { id } = props;

  // Helper
  const app = App.useApp();
  const { hasEnded } = useHasEndedGiveAway();
  const { renderItemName } = useRenderItemName();
  const { getFileByKey } = useGetFileByKey();
  const getWebUrl = useGetWebUrl();
  // Server state
  const { data: giveAway, isSuccess: hasLoadedGiveAway } =
    useFetchGiveAwayDetail(id!);
  const { data: collaborator, isSuccess: hasLoadedCollaborator } =
    useFetchCollaboratorDetail(
      giveAway?.collaboratorId != null ? +giveAway.collaboratorId : null,
    );
  const { mutateAsync: endGiveAway } = useEndGiveAway();
  // Deprived state
  const dataSource = useMemo(
    () =>
      hasLoadedGiveAway && hasLoadedCollaborator
        ? {
            ...giveAway,
            collaborator,
            posterImage:
              giveAway.posterImage && getFileByKey(giveAway.posterImage),
            mobilePosterImage:
              giveAway.mobilePosterImage &&
              getFileByKey(giveAway.mobilePosterImage),
            notificationImage:
              giveAway.notificationImage &&
              getFileByKey(giveAway.notificationImage),
            date: [
              giveAway.startDate != null
                ? dayjs(giveAway.startDate)
                : undefined,
              giveAway.endDate != null ? dayjs(giveAway.endDate) : undefined,
            ],
            dropUrl:
              giveAway.dropUrl != null
                ? getWebUrl(giveAway.dropUrl)
                : undefined,
          }
        : undefined,
    [
      getFileByKey,
      getWebUrl,
      hasLoadedGiveAway,
      hasLoadedCollaborator,
      giveAway,
      collaborator,
    ],
  );

  if (!hasLoadedGiveAway || !hasLoadedGiveAway) {
    return <ProCard loading title="Give away detail" />;
  }

  const handleEndNowButtonClick = async () => {
    app.modal.confirm({
      title: (
        <span>
          Are you sure you want to END <i>{giveAway.name}</i> give away now?
        </span>
      ),
      okText: 'End',
      okButtonProps: { danger: true },
      onOk: async () => {
        await endGiveAway({
          id,
          endDate: new Date(),
        });
        app.message.success('End give away successfully!');
      },
    });
  };

  const renderStatus = () => {
    if (hasEnded(giveAway)) {
      return <Tag color="warning">Ended</Tag>;
    }

    return <Tag color="processing">Active</Tag>;
  };

  const renderTitle = () => (
    <Space>
      <Typography.Title level={5}>
        {giveAway.name} {renderStatus()}
      </Typography.Title>
    </Space>
  );

  const renderActions = () => {
    const result = hasEnded(giveAway);

    if (result) {
      return null;
    }

    return (
      <Button danger type="primary" onClick={handleEndNowButtonClick}>
        End now
      </Button>
    );
  };

  return (
    <ProCard wrap title="Give away detail" extra={renderActions()}>
      <ProDescriptions
        title={renderTitle()}
        column={4}
        emptyText="-"
        dataSource={dataSource}
        columns={[
          {
            title: 'Item',
            renderText: () => renderItemName(giveAway),
          },
          {
            title: 'Period',
            dataIndex: 'date',
            valueType: 'dateRange',
          },
          {
            title: 'Collaborator',
            dataIndex: ['collaborator', 'name'],
            span: 2,
          },
          {
            title: 'Xp fee',
            dataIndex: ['xpFee'],
          },
          {
            title: 'Max eligible users',
            dataIndex: ['maxAmount'],
            span: 3,
          },
          {
            title: 'Poster image',
            dataIndex: ['posterImage'],
            valueType: 'image',
          },
          {
            title: 'Mobile poster image',
            dataIndex: ['mobilePosterImage'],
            valueType: 'image',
          },
          {
            title: 'Notification image',
            dataIndex: ['notificationImage'],
            valueType: 'image',
            span: 3,
          },
          {
            title: 'Descriptios',
            dataIndex: ['description'],
            span: 4,
          },
          {
            title: 'Drop URL',
            dataIndex: ['dropUrl'],
            span: 4,
            copyable: true,
          },
        ]}
      />
    </ProCard>
  );
}

export default function GiveAwayDetailPage() {
  const { id } = useParams<RouteParams>();

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <GiveAwayDetail id={id!} />
      </Col>
      <Col span={24}>
        <EligibleUsers giveAwayId={id!} />
      </Col>
    </Row>
  );
}
