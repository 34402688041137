import { Button, Card, Col, Drawer, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { ReactElement, useState } from 'react';
import AirdropHistoryDetail from 'src/components/Airdrop/AirdropHistoryDetail';
import formatDate from 'src/helper/formatDate';
import { useFetchAirdropHistory, useFetchDrops } from 'src/hooks/api';
import { NFT } from 'src/hooks/api/typing';
import { User } from 'src/types';

export type UserAirdropHistory = User & {
  nfts: NFT[];
};

export type SendAirdropHistoryProps = {
  dropId?: string;
};

function SendAirdropHistory(props: SendAirdropHistoryProps): ReactElement {
  const { dropId } = props;

  const { data: drops } = useFetchDrops();

  const { data: usersNFTLedger, isLoading: isLoadingHistory } =
    useFetchAirdropHistory(dropId);

  const [selectedUser, setSelectedUser] = useState<UserAirdropHistory | null>(
    null,
  );

  const dropNames: ColumnFilterItem[] | undefined = (drops?.map((drop) => ({
    text: drop.name,
    value: drop.id,
  })) || []) as ColumnFilterItem[];

  const onView = (user: UserAirdropHistory) => {
    setSelectedUser(user);
  };

  const renderColumns = (): ColumnsType<User> => [
    {
      title: 'To',
      dataIndex: 'walletAddress',
    },
    {
      title: 'NFT Count',
      dataIndex: 'totalNftFromAirdrop',
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      render: (value) => formatDate(value),
    },
    {
      title: 'Drop',
      dataIndex: ['nfts'],
      render: (value) => value[0]?.drop?.name,
      filters: dropNames,
      onFilter: (value, record) =>
        record?.nfts?.some((nft) => nft.dropId === value) || false,
    },

    {
      title: 'Action',
      dataIndex: '',
      render: (x: UserAirdropHistory) => (
        <Button onClick={() => onView(x)}>View</Button>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Card title="NFT airdrop ledger">
            <Table
              columns={renderColumns()}
              dataSource={usersNFTLedger}
              loading={isLoadingHistory}
              rowKey="id"
              // pagination={pagination}
              // onChange={handleParamsChange}
            />
          </Card>
        </Col>
      </Row>
      {selectedUser ? (
        <Drawer open size="large" onClose={() => setSelectedUser(null)}>
          <AirdropHistoryDetail userAirdropHistory={selectedUser} />
        </Drawer>
      ) : null}
    </Space>
  );
}

export default SendAirdropHistory;
