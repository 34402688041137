import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import { ProofType, SubmissionProof } from 'src/types/Submission';
import { Image } from 'antd';
import { useEffect, useRef } from 'react';
import styles from './style.module.scss';

type SubmissionPreviewProps = {
  type?: string;
  proof?: SubmissionProof;
  open?: boolean;
};

export default function SubmissionPreview(props: SubmissionPreviewProps) {
  const {
    type, proof, open,
  } = props;
  const audioPlayerRef = useRef<ReactAudioPlayer>(null);

  const pauseAudio = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.audioEl.current?.pause();
    }
  };

  useEffect(() => {
    if (!open) {
      pauseAudio();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      pauseAudio();
    }
  }, [open]);

  return (
    <div className={styles.proofView}>
      {type === ProofType.Text && (
        <div className={styles.proofViewText}>
          <p>{proof?.text}</p>
        </div>
      )}
      {type === ProofType.Photo && (
        <div className={styles.proofViewImage}>
          <Image
            src={`${process.env.REACT_APP_API_URL}v2.0/files/${proof?.file}`}
          />
        </div>
      )}
      {type === ProofType.Video && (
        <div className={styles.proofViewVideo}>
          <ReactPlayer
            url={`${process.env.REACT_APP_API_URL}v2.0/files/${proof?.file}`}
            controls
            className={styles.proofViewVideoPlayer}
            playing={open}
          />
        </div>
      )}
      {type === ProofType.Audio && (
        <div className={styles.proofViewAudio}>
          <ReactAudioPlayer
            ref={audioPlayerRef}
            src={`${process.env.REACT_APP_API_URL}v2.0/files/${proof?.file}`}
            autoPlay={false}
            controls
          />
        </div>
      )}
    </div>
  );
}

SubmissionPreview.defaultProps = {
  type: ProofType.Text,
  proof: {
    file: '',
    text: '',
  },
};
