import { FilterOutlined } from '@ant-design/icons';
import { Button, Form, Popover, Select, Space } from 'antd';
import { ReactNode } from 'react';
import { Collection, Drop } from 'src/hooks/api/typing';

export type NFTMinterFormDataFilter = {
  collectionId?: number;
  dropId?: number;
};

export type FilterProps = {
  open: boolean;
  onOpen: () => void;
  onCancel: () => void;
  onReset: () => void;
  onSubmit: (data: NFTMinterFormDataFilter) => void;
  collections?: Collection[];
  drops?: Drop[];
  children?: ReactNode;
};

const { Option } = Select;

export default function NFTMinterFilter(props: FilterProps) {
  const { onCancel, onSubmit, open, onOpen, onReset, collections, drops } =
    props;

  const [form] = Form.useForm();

  const handleSubmit = (data: any) => {
    onSubmit(data);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleReset = () => {
    form.resetFields();
    onReset();
  };

  const hasData = () => {
    const values = form.getFieldsValue();
    return Object.values(values).some(
      (value) => value !== undefined && value !== '',
    );
  };

  const renderContent = () => (
    <Form form={form} onFinish={handleSubmit}>
      <Form.Item label="Collection" name="collectionId">
        <Select>
          {collections?.map((collection: Collection) => (
            <Option key={collection.id} value={collection.id}>
              {collection?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Drop" name="dropId">
        <Select>
          {drops?.map((drop: Drop) => (
            <Option key={drop.id} value={drop.id}>
              {drop?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item style={{ textAlign: 'right' }}>
        <Space size="small">
          <Button type="dashed" onClick={handleReset}>
            Reset
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );

  return (
    <Popover
      content={renderContent}
      title="Filter"
      trigger="click"
      open={open}
      onOpenChange={onOpen}
    >
      <Button type={hasData() ? 'primary' : 'default'}>
        <FilterOutlined />
      </Button>
    </Popover>
  );
}
