import { Col, Descriptions, Row } from 'antd';
import { User } from 'src/types';
import { useFetchUserSessions } from 'src/hooks/api';
import UserXpLedger from './UserXpLedger';
import UserActivitySubmission from './UserActivitySubmission';
import UserAllegianceSubmission from './UserAllegianceSubmission';
import UserReferral from './UserRefferal';
import UserTag from './UserTags';

export type UserDetailProps = {
  user: User;
};

export default function UserDetail(props: UserDetailProps) {
  const { user } = props;

  const sessions = useFetchUserSessions({ userId: user.id });

  return (
    <Row>
      <Col span={24}>
        <Descriptions title="Profile" column={2}>
          <Descriptions.Item label="CustodialWalletAddress" span={2}>
            {user.custodialWalletAddress || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Wallet address" span={2}>
            {user.walletAddress || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={2}>
            {user.email || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Username">
            {user.username || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Twitter">
            {user.twitterHandle || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Instagram">
            {user.instagramUsername || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Telegram">
            {user.telegramUsername || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Shipping address">
            {user.shippingAddress?.address1 || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Referral code">
            {user.refCode || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Referral link">
            <a
              href={`${process.env.REACT_APP_WEB_URL}get-some/${user.refCode}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${process.env.REACT_APP_WEB_URL}get-some/${user.refCode}`}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="IP address">
            {sessions.data != null && sessions.data.items[0]?.ip}
          </Descriptions.Item>
          {user.isBanned === true && (
            <Descriptions.Item label="Ban reason">
              {user.banReason}
            </Descriptions.Item>
          )}
        </Descriptions>
        <Descriptions title="XPs" column={2}>
          <Descriptions.Item label="Total XPs" span={2}>
            {user.xp}
          </Descriptions.Item>
          <Descriptions.Item span={2}>
            <UserXpLedger user={user} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Referrals" column={2}>
          <Descriptions.Item span={2}>
            <UserReferral user={user} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Activity" column={2}>
          <Descriptions.Item span={2}>
            <UserActivitySubmission user={user} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Allegiance" column={2}>
          <Descriptions.Item span={2}>
            <UserAllegianceSubmission user={user} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Tags" column={2}>
          <Descriptions.Item span={2}>
            <UserTag user={user} />
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}
