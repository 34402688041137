import { XpRecordColumnsTypes } from 'src/pages/CollaboratorDashboard/Dashboard';
import {
  ActivityStatus,
  ActivitySubmission,
  AllegianceSubmission,
  SubmissionProof,
  SubmissionStatus,
  XpProfile,
  XpRecordDirection,
} from 'src/types';
import { Asset } from 'src/types/Asset';
import { UseFetchAllegianceSubmissionsParams } from './allegiance-submission';
import {
  Page,
  PaginationParams,
  SearchParams,
  SortParams,
  User,
} from './typing';
import useFetch from './useFetch';
import { ActivityResponse, SubmissionTypeResponse } from './activity';

type DashboardData = {
  amountUserAirdrop: number;
  amountUserWhitelist: number;
  totalRefCodeUsage: number;
  totalXpsAwarded: number;
  totalUsersAllegiance: number;
};

export const useFetchCollaboratorDashboard = (collaboratorId?: number) => useFetch<DashboardData>({
  key: ['collaboratorDashboard'],
  url: `/collaborator/${collaboratorId}/dashboard`,
});

const KEY_XP_LEDGER_QUERIES = ['collaboratorXpRecords'];

type XPLedgerTypeResponse = {
  id: number;
  from: XpProfile;
  to: XpProfile;
  direction: XpRecordDirection;
  description: string;
  amount: number;
  createdAt: Date;
};

export type UseFetchXPLegerParams = PaginationParams &
SortParams &
SearchParams;

export const useFetchCollaboratorXpRecords = (
  collaboratorId: number,
  params?: UseFetchXPLegerParams,
) => {
  const searchParams = { ...params };

  return useFetch<Page<XPLedgerTypeResponse>, Page<XpRecordColumnsTypes>>({
    searchParams,
    key: [...KEY_XP_LEDGER_QUERIES, params],
    url: `/collaborator/${collaboratorId}/xpRecords`,
    parseFn: (data) => {
      const groupedItems = data.items.reduce((result, res) => {
        // Grouping by date (ignoring time)
        const createdAt = new Date(res.createdAt).toDateString();

        if (!result.has(createdAt)) {
          result.set(createdAt, {
            records: [],
            totalAmount: 0,
          });
        }

        const group = result.get(createdAt);
        group.records.push(res);

        group.totalAmount += res.amount;

        return result;
      }, new Map());

      const items = Array.from(groupedItems).map(
        ([createdAt, group], index) => ({
          createdAt,
          key: `group_${index}`,
          records: group.records,
          recordsLength: group.records.length,
          totalAmount: group.totalAmount,
        }),
      );

      return {
        ...data,
        items,
      };
    },
  });
};

export type UseFetchUserParams = PaginationParams & SortParams & SearchParams;

export const useFetchCollaboratorUserList = (
  collaboratorId?: number,
  params?: UseFetchUserParams,
) => useFetch<Page<User>>({
  key: ['collaboratorUsers', params],
  url: `/collaborator/${collaboratorId}/users`,
  searchParams: params,
});

const KEY_COLLABORATOR_ASSETS_QUERIES = ['collaboratorAssets'];

export type CollaboratorParam = {
  collaboratorId?: string;
};

export type UseFetchAssetsParams = CollaboratorParam;

export const useFetchCollaboratorAsset = (params?: UseFetchAssetsParams) => useFetch<Page<Asset>>({
  key: [...KEY_COLLABORATOR_ASSETS_QUERIES, params],
  url: '/assets',
  searchParams: params,
});

const KEY_COLLABORATOR_ALLEGIANCE_QUERIES = ['collaboratorAllegiance'];

export const useFetchCollaboratorAllegianceSubmissions = (
  params?: UseFetchAllegianceSubmissionsParams,
) => {
  const searchParams = {
    ...params,
    startSubmittedDate: params?.startSubmittedDate?.toISOString(),
    endSubmittedDate: params?.endSubmittedDate?.toISOString(),
    statues: [SubmissionStatus.Accepted.toString()].join(','),
  };

  return useFetch<Page<AllegianceSubmission>, Page<AllegianceSubmission>>({
    key: [...KEY_COLLABORATOR_ALLEGIANCE_QUERIES, params],
    url: '/admin/allegiance-submissions',
    searchParams,
    parseFn: (data) => {
      const allegianceSubmissions: AllegianceSubmission[] = data.items || [];

      const items: AllegianceSubmission[] = allegianceSubmissions.map(
        (allegianceSubmission) => ({
          ...allegianceSubmission,
          user: {
            ...allegianceSubmission.user,
            walletAddress: allegianceSubmission.user.custodialWalletAddress,
          },
        }),
      );

      return {
        ...data,
        items,
      };
    },
  });
};

export const KEY_COLLABORATOR_ACTIVITY_QUERIES = ['collaboratorActivities'];

export type UseFetchActivitySubmissionsParams = PaginationParams &
SortParams &
SearchParams & {
  startSubmittedDate?: Date;
  endSubmittedDate?: Date;
  activityId?: string;
  activityIds?: any;
  status?: SubmissionStatus;
  statues?: SubmissionStatus[];
  isFavorite?: boolean;
};

export type ActivitySubmissionResponse = {
  id: number;
  activity: ActivityResponse;
  user: User;
  type: SubmissionTypeResponse;
  proof: SubmissionProof;
  submitCount: number;
  submittedDate: string;
  status: SubmissionStatus;
  verificationNote?: string;
  isFavorite: boolean;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
};

export const useFetchActivitySubmissions = (
  params?: UseFetchActivitySubmissionsParams,
) => {
  const searchParams = {
    ...params,
    startSubmittedDate: params?.startSubmittedDate?.toISOString(),
    endSubmittedDate: params?.endSubmittedDate?.toISOString(),
    statues: [SubmissionStatus.Accepted.toString()].join(','),
  } as never;

  return useFetch<Page<ActivitySubmissionResponse>, Page<ActivitySubmission>>({
    searchParams,
    key: [...KEY_COLLABORATOR_ACTIVITY_QUERIES, params],
    url: '/admin/activity-submissions',
    parseFn: (data) => {
      const activitySubmissions: ActivitySubmissionResponse[] = data.items || [];

      let totalXpAwarded = 0;

      const items: ActivitySubmission[] = activitySubmissions.map(
        (activitySubmission) => {
          const xpAward = activitySubmission.activity.xpAward || 0;
          totalXpAwarded += xpAward;

          return {
            id: activitySubmission.id,
            title: activitySubmission.type.title,
            status: activitySubmission.status,
            submittedDate: activitySubmission.submittedDate
              ? new Date(activitySubmission.submittedDate)
              : null,
            verificationNote: activitySubmission.verificationNote,
            type: {
              id: activitySubmission.type.id,
              proofLogo: activitySubmission.type.proofLogo,
              proofImage: activitySubmission.type.proofImage,
              proofType: activitySubmission.type.proofType,
              title: activitySubmission.type.title,
              text: activitySubmission.type.text,
              disclaimer: activitySubmission.type.disclaimer,
              isAutoDeleteSubmission:
                activitySubmission.type.isAutoDeleteSubmission,
              isRecurring: activitySubmission.type.isRecurring,

              createdAt: new Date(activitySubmission.type.createdAt),
              updatedAt:
                activitySubmission.type.updatedAt != null
                  ? new Date(activitySubmission.type.updatedAt)
                  : undefined,
              deletedAt:
                activitySubmission.deletedAt != null
                  ? new Date(activitySubmission.deletedAt)
                  : undefined,
            },
            user: {
              ...activitySubmission.user,
            },
            proof: activitySubmission.proof,
            activity: {
              id: activitySubmission.activity.id,
              type: activitySubmission.activity.type,
              slug: activitySubmission.activity.slug,
              title: activitySubmission.activity.title,
              description: activitySubmission.activity.description,
              image: activitySubmission.activity.image,
              notificationImage: activitySubmission.activity.notificationImage,
              startDate: new Date(activitySubmission.activity.startDate),
              endDate: new Date(activitySubmission.activity.endDate),
              xpAward: activitySubmission.activity.xpAward,
              status: ActivityStatus.Publish,
              createdAt: new Date(activitySubmission.activity.createdAt),
              updatedAt:
                activitySubmission.activity.updatedAt != null
                  ? new Date(activitySubmission.activity.updatedAt)
                  : undefined,
              deletedAt:
                activitySubmission.activity.deletedAt != null
                  ? new Date(activitySubmission.activity.deletedAt)
                  : undefined,
            },
            isFavorite: activitySubmission.isFavorite,
            updatedAt: activitySubmission.updatedAt,
          };
        },
      );

      return {
        ...data,
        items,
        totalXpAwarded,
      };
    },
  });
};
