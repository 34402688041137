import { ReactComponent as DoubleArrow } from 'src/assets/icons/double-arrow.svg';
import classNames from 'classnames';
import { CollapsePanelProps, Spin, notification } from 'antd';
import { useFetchActivitySubmissions } from 'src/hooks/api/collaborator-dashboard';
import { selectCollaboratorProfile } from 'src/store/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store/dispatch';
import { getCollaboratorProfile } from 'src/store';

import { Activity, ActivitySubmission, SubmissionStatus } from 'src/types';
import HeartIcon from 'src/components/Icons/HeartIcon';
import { useUpdateActivitySubmission } from 'src/hooks/api/activity-submission';
import CustomCollapse from '../_shared/components/Collapse';
import ButtonStyled from '../_shared/components/Button';
import styles from './style.module.scss';
import SubmissionPreview from './SubmissionPreview';

function ActivitySubmissionList(props: {
  submissions: ActivitySubmission[];
  backToActivity: () => void;
  activityId?: number;
  onToggleFavorite: (submission: ActivitySubmission) => void;
}) {
  const {
    submissions, backToActivity, activityId, onToggleFavorite,
  } = props;
  const { data: activitySubmissionsFavorite } = useFetchActivitySubmissions({
    status: SubmissionStatus.Accepted,
    activityIds: [activityId],
    isFavorite: true,
  });

  const { mutateAsync: updateActivitySubmission } = useUpdateActivitySubmission();

  const onFavoriteSubmission = (submission: ActivitySubmission) => async () => {
    await updateActivitySubmission({
      id: submission.id,
      isFavorite: !submission.isFavorite,
    });

    if (submission.isFavorite) {
      notification.success({
        message: 'Activity submission removed from favorites',
      });
    } else {
      notification.success({
        message: 'Activity submission added to favorites',
      });
    }

    onToggleFavorite({
      ...submission,
      isFavorite: !submission.isFavorite,
    });
  };

  return (
    <div className={styles.submissionWrapper}>
      <div className="flex items-start w-full justify-between">
        <div className={classNames(styles.backButton, 'flex items-start')}>
          <div className={styles.backIcon}>{'<<'}</div>
          <div className="subtitle1 ml-4" onClick={backToActivity}>
            Back to activities
          </div>
        </div>
        <div className="flex items-center">
          {activitySubmissionsFavorite?.meta.totalItems}
          {' '}
          {' '}
          <HeartIcon fill="var(--color-primary)" color="var(--color-primary)" className="ml-4" />
        </div>
      </div>
      <ul className={styles.submissionList}>
        {submissions ? (
          submissions.map((submission) => (
            <li key={submission.id}>
              <div className="flex items-center">
                <h5>
                  {submission.user.username}
                  {' '}
                  <HeartIcon
                    onClick={onFavoriteSubmission(submission)}
                    fill={
                      submission?.isFavorite ? 'var(--color-primary)' : 'none'
                    }
                    color={
                      submission?.isFavorite ? 'var(--color-primary)' : 'white'
                    }
                  />
                </h5>
              </div>
              <SubmissionPreview
                type={submission.type.proofType}
                proof={submission.proof}
                open={false}
              />
            </li>
          ))
        ) : (
          <Spin />
        )}
      </ul>
    </div>
  );
}

function PanelHeader(props: { title: string }) {
  return (
    <div className={styles.panelHeader}>
      <div>{props.title}</div>
      <div>
        <DoubleArrow />
      </div>
    </div>
  );
}

function ActivityParticipationInformation(props: {
  activityId?: number;
  onSubmissionPreview: (submissions: ActivitySubmission[]) => void;
}) {
  const { activityId, onSubmissionPreview = () => {} } = props;
  const { data: submissions } = useFetchActivitySubmissions({
    status: SubmissionStatus.Accepted,
    activityIds: [activityId],
  });

  return (
    <>
      <div className={styles.otherInfo}>
        <p>
          Total Participation
          <span>{submissions?.items.length}</span>
        </p>
        <p>
          Total XPs awarded
          <span>{submissions?.totalXpAwarded}</span>
        </p>
      </div>
      {submissions?.items.length ? (
        <div className={styles.reviewButton}>
          <ButtonStyled
            onClick={() => onSubmissionPreview(submissions?.items as never)}
          >
            Review Submission
          </ButtonStyled>
        </div>
      ) : null}
    </>
  );
}

function ActivityItem(props: { activity: Activity }) {
  const { activity } = props;
  const [isSubmissionView, setIsSubmissionView] = useState(false);
  const [submissions, setSubmissions] = useState<ActivitySubmission[]>([]);

  const onToggleFavorite = (updatedSubmission: ActivitySubmission) => {
    const updatedSubmissions = [...submissions].map((item) => (item.id === updatedSubmission.id ? updatedSubmission : item));

    setSubmissions(updatedSubmissions);
  };

  const toggleSubmissionView = (activitySubmissions: ActivitySubmission[]) => {
    setIsSubmissionView(!isSubmissionView);
    setSubmissions(activitySubmissions);
  };

  if (!isSubmissionView) {
    return (
      <div className={styles.contentWrapper}>
        <div className={styles.imgWrapper}>
          <img
            src={`${process.env.REACT_APP_API_URL}v2.0/files/${activity.image}`}
            alt={activity.title}
          />
        </div>
        <div className={styles.content}>
          <div className={classNames(styles.title, 'subTitle1')}>
            {activity.title}
          </div>
          <div className={styles.descriptionWrapper}>
            <div className={classNames(styles.title, 'subTitle1')}>
              Description
            </div>
            <div className={classNames(styles.description, 'body2')}>
              {activity.description}
            </div>
            <ActivityParticipationInformation
              activityId={activity.id}
              onSubmissionPreview={toggleSubmissionView}
            />
          </div>
        </div>
      </div>
    );
  }

  if (isSubmissionView && submissions) {
    return (
      <ActivitySubmissionList
        submissions={submissions}
        onToggleFavorite={onToggleFavorite}
        activityId={activity.id}
        backToActivity={() => setIsSubmissionView(false)}
      />
    );
  }

  return null;
}

function ActivityCollapsePanel(props: { activities: Activity[] }) {
  const { activities } = props;
  const [activityPanels, setActivityPanels] = useState<CollapsePanelProps[]>();

  useEffect(() => {
    if (activities) {
      const buildCollapsePanelData = activities.map(
        (activity, index: number) => ({
          key: index,
          header: <PanelHeader title={activity.title} />,
          children: <ActivityItem activity={activity} />,
        }),
      );

      setActivityPanels(buildCollapsePanelData);
    }
  }, [activities]);

  return (
    <CustomCollapse
      activeKey={['0']}
      className={styles.accordionWrapper}
      collapseClassName={styles.accordionWrapper}
      panelClassName={styles.panelWrapper}
      panelItems={activityPanels || []}
    />
  );
}

export default function CollaboratorActivities() {
  const dispatch = useAppDispatch();
  const activities = useSelector(selectCollaboratorProfile)?.activities;

  useEffect(() => {
    dispatch(getCollaboratorProfile());
  }, [dispatch]);

  return activities ? (
    <div className={classNames('awesome-scrollbar', styles.scrollableContent)}>
      <ActivityCollapsePanel activities={activities} />
    </div>
  ) : (
    <Spin />
  );
}
