import { XPLedger, XpProfile, XpRecordDirection } from 'src/types';
import
{
  Page,
  PaginationParams,
  SearchParams,
  SortParams,
} from './typing';
import useFetch from './useFetch';

const KEY_XP_LEDGER_QUERIES = ['xpLedger'];

export type UseFetchXPLegerParams = PaginationParams & SortParams & SearchParams;

type XPLedgerTypeResponse = {
  id: number;
  from: XpProfile;
  to: XpProfile;
  direction: XpRecordDirection;
  description: string;
  amount: number;
  createdAt: Date;
};

export const useFetchXPLedgers = (
  params?: UseFetchXPLegerParams,
) => {
  const searchParams = {
    ...params,
  };

  return useFetch<Page<XPLedgerTypeResponse>, Page<XPLedger>>({
    searchParams,
    key: [...KEY_XP_LEDGER_QUERIES, params],
    url: '/admin/xp-records',
    parseFn: (data) => {
      const items = data.items.map((res): XPLedger => ({
        id: res.id,
        direction: res.direction,
        amount: res.amount,
        description: res.description,
        to: res?.to?.username || res?.to?.email || res?.to?.walletAddress || 'System',
        from: res?.from?.username || res?.from?.email || res?.from?.walletAddress || 'System',
        createdAt: res.createdAt,
      }));

      return {
        ...data,
        items,
      };
    },
  });
};
