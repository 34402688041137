import { Submission, SubmissionType } from './Submission';

export enum ActivityStatus {
  Draft = 'draft',
  Publish = 'publish',
}

export enum ActivityType {
  Normal = 'normal',
  Ref = 'ref',
}

type BaseActivity = {
  id?: number;
  type: ActivityType;
  slug: string;
  title: string;
  image: string;
  notificationImage: string;
  description: string;
  xpAward: number;
  startDate: Date;
  endDate: Date;
  status: ActivityStatus;
  allegianceId?: string;
  allegianceUrl?: string;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
};

export type Activity = BaseActivity & {
  submissionType: SubmissionType;
};

export type ActivitySubmission = Submission & {
  activity: BaseActivity;
};

export type ActivityWinner = {
  id: number;
  username: string;
  email: string;
  userId: string;
  activity: BaseActivity;
};
