import {
  Button,
  Col,
  Row,
  Form,
  Typography,
  Select,
  message,
  Space,
} from 'antd';

import { useMemo, useState } from 'react';
import Web3Panel from 'src/components/Web3Panel';
import { updateCollection, useFetchCollections } from 'src/hooks/api';
import { Collection } from 'src/hooks/api/typing';
import { createRequester } from 'src/hooks/useApi';
import useNFTFactoryContract from 'src/hooks/useNFTFactoryContract';
import useWeb3 from 'src/hooks/useWeb3';
import { useAppDispatch } from 'src/store/dispatch';
import useWithdrawTotaNFTContract from 'src/hooks/useWithdrawTotaNFTContract';
import styles from './DeployCollectionNFT.module.scss';

const { Paragraph, Text } = Typography;

interface FormValues {
  collectionId: number;
  // baseURI: string;
}

const APP_URL = process.env.REACT_APP_API_URL;

export default function ToolsPage() {
  const [nftAddress, setNFTAddress] = useState('');
  const [form] = Form.useForm<FormValues>();
  const dispatch = useAppDispatch();

  const { isConnected, chain } = useWeb3();
  const { data: collections } = useFetchCollections();

  const undeployedCollections =
    collections?.filter(
      (collection: Collection) => collection.contractAddress === null,
    ) || [];

  const [selectedCollection, setSelectedCollection] =
    useState<Collection | null>(null);

  const {
    withdraw,
    contractBalance,
    contextHolder: withdrawContextHolder,
  } = useWithdrawTotaNFTContract(selectedCollection?.contractAddress);

  const shouldShowForm = useMemo(() => {
    if (!isConnected) return false;
    if (chain?.id.toString() !== process.env.REACT_APP_CHAIN_ID) return false;

    return true;
  }, [isConnected, chain]);

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Deployed Smart contract',
      duration: 1,
    });
  };

  const onReceiveNFTAddress = async (address: string) => {
    setNFTAddress(address);

    if (!selectedCollection || !selectedCollection.id) return;

    const requester = createRequester({ dispatch });

    await updateCollection({
      id: selectedCollection.id,
      requester,
      contractAddress: address,
    });

    setSelectedCollection(null);
    form.resetFields();
    success();
  };

  const { newCollection, dataNewCollection, isLoading } = useNFTFactoryContract(
    {
      name: selectedCollection?.name ?? 'TOTA',
      symbol: selectedCollection?.name ?? 'TOTA',
      maxSupply: selectedCollection ? selectedCollection?.totalSupply : 0,
      baseURI: `${APP_URL}v2.0/collection/${selectedCollection?.id}/metadata/`,
      onReceiveNFTAddress,
    },
  );

  const handleDeployContract = () => {
    console.log('handleDeployContract');
    if (!newCollection) return;
    newCollection();
  };

  const handleWithdraw = () => {
    if (!withdraw) return;
    withdraw();
  };

  const handleFormChange = (changedValues: FormValues) => {
    if (!changedValues.collectionId || !collections) {
      return false;
    }

    const collection = collections.find(
      (clt: Collection) => clt.id === Number(changedValues.collectionId),
    );
    if (!collection) return false;

    setSelectedCollection(collection);
    return true;
  };

  return (
    <Row gutter={[0, 16]}>
      {contextHolder}
      {withdrawContextHolder}
      <Col span={24}>
        <Col span={12}>
          <Web3Panel />
        </Col>
      </Col>
      {shouldShowForm === true && (
        <>
          <Col span={24}>
            <Col span={12}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={handleFormChange}
              >
                <Col span={24}>
                  <Form.Item
                    label="Collection"
                    name="collectionId"
                    rules={[
                      {
                        required: true,
                        message: 'Please select collection!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      options={undeployedCollections.map(
                        (collection: Collection) => ({
                          value: collection.id,
                          label: collection.name,
                        }),
                      )}
                    />
                  </Form.Item>
                </Col>
              </Form>
            </Col>
          </Col>
          <Col span={24}>
            {!selectedCollection?.contractAddress ? (
              <Button
                disabled={selectedCollection === null || isLoading}
                type="primary"
                loading={isLoading}
                onClick={handleDeployContract}
              >
                Deploy contract
              </Button>
            ) : (
              <Space direction="vertical">
                <p>Balance: {contractBalance?.formatted} ETH</p>
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={handleWithdraw}
                  disabled={
                    !selectedCollection ||
                    Number(contractBalance?.formatted) === 0
                  }
                >
                  Withdraw
                </Button>
              </Space>
            )}
          </Col>
          {Boolean(dataNewCollection?.hash) && Boolean(nftAddress) && (
            <div className={styles.successResultContainer}>
              <Col span={24}>
                <Paragraph>
                  <Text>Transaction Hash: </Text>
                  <Text copyable strong>
                    {dataNewCollection?.hash}
                  </Text>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Paragraph>
                  <Text>NFT Address: </Text>
                  <Text copyable strong>
                    {nftAddress}
                  </Text>
                </Paragraph>
              </Col>
            </div>
          )}
        </>
      )}
    </Row>
  );
}
