const GiveAwayErrorMessage: Record<string, string> = {
  'error.already_eligible': 'Already eligible',
  'error.not_eligible': 'Not eligible',
  'error.max_user_exceed': 'Max user exceed',
  'error.remove_claimed_user': 'Remove claimed user',
  'error.update_collaborator': 'Update collaborator',
  // Assign
  'error.not_collaborator_user': 'Not collaborator user',
  // Claim
  'error.ended': 'Ended',
  'error.claimed': 'Claimed',
  'error.empty_address': 'Empty address',
} as const;

export default GiveAwayErrorMessage;
