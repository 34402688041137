import { Form, Checkbox, notification } from 'antd';
import { Link, Navigate } from 'react-router-dom';
import classNames from 'classnames';

import { useState } from 'react';
import { useAppDispatch } from 'src/store/dispatch';
import { signIn, getCollaboratorProfile } from 'src/store';

import Input from 'src/pages/CollaboratorDashboard/_shared/components/Input';
import Button from 'src/pages/CollaboratorDashboard/_shared/components/Button';

import { AxiosError } from 'axios';
import { ErrorDataResponse } from 'src/typing';

import { SignInFormData } from 'src/pages/SignIn';
import { useSelector } from 'react-redux';
import { selectIsSignedIn } from 'src/store/selectors';
import styles from './style.module.scss';

export default function MasterCardLogin() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<SignInFormData>();
  const isSignedIn = useSelector(selectIsSignedIn);
  const [isSigningIn, setIsSigningIn] = useState(false);

  if (isSignedIn) {
    return <Navigate to="/" replace />;
  }

  const handleSubmit = async (data: SignInFormData) => {
    setIsSigningIn(true);

    try {
      await dispatch(
        signIn({
          username: data.username,
          password: data.password,
        }),
      ).unwrap();
      await dispatch(getCollaboratorProfile());

      setIsSigningIn(false);
    } catch (error) {
      const errorMessage = error as AxiosError<ErrorDataResponse>;

      notification.error({
        message:
          errorMessage.code === 'ERR_BAD_REQUEST'
            ? 'Invalid email or password'
            : errorMessage.message,
      });
      setIsSigningIn(false);
    }
  };

  return (
    <main className={classNames(styles.main, 'font_Quicksand')}>
      <div className={styles.blockerMessage}>
        <p>
          Kindly access the Collab Portal from a desktop or laptop for optimal
          viewing experience
        </p>
      </div>
      <div className={classNames(styles.container, 'flex-col m-auto')}>
        <img className={styles.logo} src="logo.svg" alt="Planet Tota" />
        <h3 className={classNames(styles.title, 'mb-48')}>
          HELLO AND WELCOME TO
          <br />
          THE COLLABORATOR’S CORNER
        </h3>
        <h3 className={classNames(classNames(styles.title, styles.titleMobile), 'mb-48')}>
          HELLO AND
          <br />
          WELCOME TO THE
          <br />
          COLLABORATOR’S CORNER
        </h3>
        <Form
          className={styles.formWrapper}
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item
            className="mb-24"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please enter your email address.',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address!',
              },
            ]}
          >
            <Input placeholder="xyz@gmail.com" className="mb-16" />
          </Form.Item>
          <Form.Item
            className="mb-12"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please enter your password.',
              },
            ]}
          >
            <Input placeholder="Password" type="password" />
          </Form.Item>
          <div
            className={classNames(
              styles.link,
              'mt-12 mb-16 color-white text-right',
            )}
          >
            <Link to="/forgot-password" className="body2">
              Forgot password?
            </Link>
          </div>
          <Button
            htmlType="submit"
            disabled={isSigningIn}
            className={styles.loginButton}
          >
            LOG IN
          </Button>
          <Form.Item className="align-self-start" name="agreement">
            <Checkbox className={styles.terms} checked disabled>
              By clicking on Login, you agree to Planet Tota&rsquo;s&nbsp;
              <a href="/terms-and-conditions">Terms and Conditions</a>
              &nbsp;and&nbsp;
              <a href="/privacy-policy">Privacy Policy</a>
            </Checkbox>
          </Form.Item>
        </Form>
      </div>
    </main>
  );
}
