import { useMutation, useQueryClient } from '@tanstack/react-query';
import { User } from 'src/types';
import { PrivateEligibleUser } from 'src/types/Drop';
import useApi from '../useApi';
import {
  ActivateDropArg,
  AddAirdropUsersArg,
  AddEligibleUsersArg,
  AirdropArg,
  AirdropEligibleUser,
  Collection,
  CollectionDeleteArg,
  CollectionPostArg,
  CollectionPutArg,
  Drop,
  DropPostArg,
  DropPutArg,
  DropStatistics,
  NFT,
  Page,
  PaginationParams,
  RevealDropArg,
} from './typing';
import useFetch from './useFetch';

const QUERY_KEY_DROP = ['drops'];
const QUERY_KEY_DROP_STATISTICS = (id: string | undefined) => [
  ...QUERY_KEY_DROP,
  id,
  'statistics',
];

const QUERY_KEY_AIRDROP_ELIGIBLE = (id: string | undefined) => [
  ...QUERY_KEY_DROP,
  id,
  'airdrop-eligible-users',
];
export const QUERY_KEY_DROP_DETAILS = (id: string | undefined) => [
  ...QUERY_KEY_DROP,
  id,
];

const QUERY_KEY_PRIVATE_SALE_ELIGIBLE = (id: string | undefined) => [
  ...QUERY_KEY_DROP,
  id,
  'private-sale-eligible',
];

const QUERY_KEY_COLLECTIONS = ['collections'];

const QUERY_KEY_COLLECTIONS_DETAIL = (id: string | undefined) => [
  ...QUERY_KEY_DROP,
  id,
  'collections',
];

// TODO: Create a usePost | useDelete hook for post and delete action
const createCollection = async (params: CollectionPostArg): Promise<void> => {
  const { requester, collection } = params;
  const {
    name,
    totalSupply,
    defaultNftImg,
    imageFolderURL,
    collectionTagImage = null,
    collectionNotificationImage = null,
    collectionDescription,
    nftDescription,
  } = collection;

  await requester.post('/collection', {
    name,
    totalSupply,
    defaultNftImg,
    imageFolderURL,
    collectionTagImage,
    collectionNotificationImage,
    nftDescription,
    collectionDescription,
  });
};

const updateCollection = async (params: CollectionPutArg): Promise<void> => {
  const {
    requester,
    id,
    contractAddress,
    name,
    totalSupply,
    imageFolderURL,
    collectionTagImage,
    collectionNotificationImage,
    defaultNftImg,
    nftDescription,
    collectionDescription,
  } = params;

  await requester.put(`/collection/${id}`, {
    contractAddress,
    name,
    totalSupply,
    imageFolderURL,
    collectionTagImage,
    collectionNotificationImage,
    defaultNftImg,
    nftDescription,
    collectionDescription,
  });
};

const createDrop = async (params: DropPostArg): Promise<void> => {
  const { requester, drop } = params;

  await requester.post('/collection/drop', drop);
};

const updateDrop = async (params: DropPutArg): Promise<void> => {
  const { requester, drop } = params;

  await requester.put(`/collection/drop/${drop.id}`, drop);
};

const deleteCollection = async (params: CollectionDeleteArg): Promise<void> => {
  const { requester, id } = params;

  await requester.delete(`/collection/${id}`);
};

const addEligibleUsers = async (params: AddEligibleUsersArg): Promise<void> => {
  const { userIds, requester, type } = params;

  await requester.post('/collection/eligible-user', {
    userIds,
    type,
  });
};

const addAirdropUsers = async (params: AddAirdropUsersArg): Promise<void> => {
  const { userIds, requester, type } = params;

  await requester.post('/collection/airdrop-eligible', {
    userIds,
    type,
  });
};

const revealDrop = async (params: RevealDropArg): Promise<void> => {
  const { id, requester } = params;
  await requester.put(`/collection/drop/${id}/reveal`);
};

const activateDrop = async (params: ActivateDropArg): Promise<void> => {
  const { id, requester } = params;
  await requester.put(`/collection/drop/${id}/activate`);
};

const addAirdropEligibleUsers = async (
  params: AddAirdropUsersArg,
): Promise<void> => {
  const { userIds, requester, type } = params;

  await requester.post('/collection/airdrop-eligible', {
    userIds,
    type,
  });
};

const removeAirdropEligibleUsers = async (
  params: AddEligibleUsersArg,
): Promise<void> => {
  const { userIds, requester } = params;

  await requester.post('/collection/airdrop-eligible/remove', {
    userIds,
  });
};

const airdrop = async (params: AirdropArg): Promise<void> => {
  const { requester, airdropNFTInfo } = params;

  await requester.post('/collection/airdrop/nft', {
    airdropNFTInfo,
  });
};

const useFetchAirdropHistory = (dropId?: string) =>
  useFetch<User[]>({
    key: ['collectionAirdropHistory'],
    url: `/collection/airdrop-history${dropId ? `?dropId=${dropId}` : ''}`,
  });

const useFetchCollections = () =>
  useFetch<Collection[]>({
    key: QUERY_KEY_COLLECTIONS,
    url: '/collection',
  });

const useFetchCollectionDetails = (id: number | undefined) =>
  useFetch<Collection>({
    key: QUERY_KEY_COLLECTIONS_DETAIL(id?.toString()),
    url: `/collection/${id}`,
    queryOptions: {
      enabled: !!id,
    },
  });

const useFetchActiveCollection = () =>
  useFetch<Collection>({
    key: ['latestCollection'],
    url: '/collection/active',
  });

const useFetchActiveDrop = () =>
  useFetch<Drop>({
    key: ['latestDrop'],
    url: '/collection/active-drop',
  });

const useFetchDrops = () =>
  useFetch<Drop[]>({
    key: QUERY_KEY_DROP,
    url: '/drops',
  });

const useFetchDropDetails = (id: string | undefined) =>
  useFetch<Drop>({
    key: QUERY_KEY_DROP_DETAILS(id),
    url: `/drops/${id}`,
  });

const useFetchDropStatistics = (dropId: string | undefined) =>
  useFetch<DropStatistics>({
    key: QUERY_KEY_DROP_STATISTICS(dropId?.toString()),
    url: `/drops/${dropId}/statistics`,
    queryOptions: {
      enabled: !!dropId,
    },
  });

const useFetchAirdropEligibleForDrop = (dropId: string | undefined) =>
  useFetch<AirdropEligibleUser[]>({
    key: QUERY_KEY_AIRDROP_ELIGIBLE(dropId?.toString()),
    url: `/drops/${dropId}/airdrop-eligible-users`,
    queryOptions: {
      enabled: !!dropId,
    },
  });

const useFetchWhitelistUsersForDrop = (dropId: number | undefined) =>
  useFetch<User[]>({
    key: ['whitelistUsersForDrop'],
    url: `collection/drop/${dropId}/whitelist-users`,
    queryOptions: {
      enabled: !!dropId,
    },
  });

const useFetchNFTMintedForDrop = (dropId: string | undefined) =>
  useFetch<NFT[]>({
    key: ['nftMintedForDrop'],
    url: `collection/drop/${dropId}/nft?nftType=mint`,
    queryOptions: {
      enabled: !!dropId,
    },
  });

export type UseFetchPrivateEligiblesParams = PaginationParams & {
  dropId?: string;
};

const useFetchPrivateEligibles = (params: UseFetchPrivateEligiblesParams) =>
  useFetch<Page<PrivateEligibleUser>>({
    key: QUERY_KEY_PRIVATE_SALE_ELIGIBLE(params.dropId),
    url: `collection/drop/${params.dropId}/private-sale-eligibles`,
    queryOptions: {
      enabled: !!params.dropId,
    },
  });

type AddUserPrivateEligibleRequest = {
  userIds: number[];
  dropId?: string;
};

const useAddUserPrivateSaleEligible = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (payload: AddUserPrivateEligibleRequest) =>
      requester.post<void, AddUserPrivateEligibleRequest>(
        '/collection/private-sale-eligibles',
        payload,
      ),
    {
      onSuccess: (_, data: AddUserPrivateEligibleRequest) => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_PRIVATE_SALE_ELIGIBLE(data?.dropId?.toString()),
        });
      },
    },
  );
};

export type RemovePrivateEligibleRequest = {
  privateSaleEligibleId: string;
  dropId: string;
};

export const useDeletePrivateSaleEligible = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: RemovePrivateEligibleRequest) => {
      await requester.delete<void>(
        `/collection/private-sale-eligible/${data.privateSaleEligibleId}`,
      );
    },
    {
      onSuccess: (_, data: RemovePrivateEligibleRequest) => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_PRIVATE_SALE_ELIGIBLE(data?.dropId),
        });
      },
    },
  );
};

export type UseActiveDropParams = {
  dropId?: string;
};

const useActiveDrop = ({ dropId }: UseActiveDropParams) => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async () => {
      await requester.put<void>(`/collection/drop/${dropId}/activate`);
    },
    {
      onSuccess: (_, data: UseActiveDropParams) => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_DROP_DETAILS(data?.dropId),
        });
      },
    },
  );
};

export type UseRevealDropParams = {
  dropId?: string;
};

const useRevealDrop = ({ dropId }: UseRevealDropParams) => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async () => {
      await requester.put<void>(`/collection/drop/${dropId}/reveal`);
    },
    {
      onSuccess: (_, data: UseActiveDropParams) => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_DROP_DETAILS(data?.dropId),
        });
      },
    },
  );
};

export {
  activateDrop,
  addAirdropEligibleUsers,
  addAirdropUsers,
  addEligibleUsers,
  airdrop,
  createCollection,
  createDrop,
  deleteCollection,
  removeAirdropEligibleUsers,
  revealDrop,
  updateCollection,
  updateDrop,
  useActiveDrop,
  useAddUserPrivateSaleEligible,
  useFetchActiveCollection,
  useFetchActiveDrop,
  useFetchAirdropEligibleForDrop,
  useFetchAirdropHistory,
  useFetchCollectionDetails,
  useFetchCollections,
  useFetchDropDetails,
  useFetchDropStatistics,
  useFetchDrops,
  useFetchNFTMintedForDrop,
  useFetchPrivateEligibles,
  useFetchWhitelistUsersForDrop,
  useRevealDrop,
};
