import Icon from '@ant-design/icons';
import { ReactComponent as RightTriangleIcon } from 'src/assets/icons/right-triangle.svg';

interface RightTriangleProps {
  className?: string;
}

function RightTriangle(props: RightTriangleProps) {
  const { className } = props;
  return <Icon component={RightTriangleIcon} className={className} />;
}

export default RightTriangle;
