import { Button, Form, Popover, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ReactNode } from 'react';

export type SubmissionDenyProps = {
  open?: boolean;
  onSubmit: (note?: string) => void;
  onClose: () => void;
  children: ReactNode;
};

interface FormValues {
  note: string;
}

export default function SubmissionNoteDeny(props: SubmissionDenyProps) {
  const { onSubmit, onClose, children, open } = props;

  const [form] = Form.useForm();

  const handleSubmit = (values: FormValues) => {
    const { note } = values;
    onSubmit(note);
  };

  const handleClose = () => {
    onClose();
  };

  const renderForm = () => (
    <Form form={form} onFinish={handleSubmit} validateTrigger="onSubmit">
      <Form.Item
        label="Note"
        name="note"
        rules={[
          {
            required: true,
            type: 'string',
            message: 'Please input note',
            whitespace: true,
          },
        ]}
      >
        <TextArea autoSize rows={4} />
      </Form.Item>
      <Form.Item style={{ textAlign: 'right' }}>
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );

  return (
    <Popover content={renderForm} title="Note" trigger="click" open={open}>
      {children}
    </Popover>
  );
}
