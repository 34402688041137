import { FileOutlined, PlusOutlined } from '@ant-design/icons';
import
{
  Button,
  Card, Col, Image, Input,
  List,
  Popconfirm,
  Row, Space, Table, notification,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import CreateAsset from 'src/components/AssetCreate';
import {
  UseFetchAssetsParams, useCreateAsset, useFetchAssets, useUpdateAsset,
} from 'src/hooks/api/assets';
import { useFetchCollaborators } from 'src/hooks/api/collaborator';

import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { Asset } from 'src/types/Asset';
import { Collaborator } from 'src/types/Collaborator';
import { ErrorDataResponse } from 'src/typing';

export default function AssetsPage() {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<Asset>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [asset, setAsset] = useState<Asset | undefined>(undefined);
  const [edit, setEdit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  // Server states
  const params: UseFetchAssetsParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);

  const dataSource = useFetchAssets({ ...params });
  const dataSourceCollaborator = useFetchCollaborators();

  const { mutateAsync: mutationCreateAsset } = useCreateAsset();
  const { mutateAsync: mutationUpdateAsset } = useUpdateAsset();

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleAddActivity = () => {
    setAsset(undefined);
    setEdit(false);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setAsset(undefined);
    setEdit(false);
  };

  const handleEdit = (record: Asset) => {
    setEdit(true);
    setAsset(record);
    setOpenDrawer(true);
  };

  const handleSubmit = async (formValues: Asset) => {
    if (!edit) {
      try {
        await mutationCreateAsset(formValues);
        setOpenDrawer(false);
        notification.success({ message: 'Asset created successfully' });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response?.data.message;
        notification.error({ message: `Asset create failed: ${errorMessage}` });
      }
    }

    if (edit) {
      try {
        await mutationUpdateAsset(formValues);
        notification.success({ message: 'Asset updated success' });
        setOpenDrawer(false);
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response?.data.message;
        notification.error({ message: `Asset update failed: ${errorMessage}` });
      }
    }
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<Asset> => [
    {
      title: 'Name',
      dataIndex: ['name'],
    },
    {
      title: 'Image',
      dataIndex: ['image'],
      render: (value) => (
        <Image
          style={{
            width: 100,
            height: 100,
          }}
          src={`${process.env.REACT_APP_API_URL}v2.0/files/${value}`}
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: ['description'],
    },

    {
      title: 'File',
      dataIndex: ['file'],
      render: (value) => (
        <List.Item.Meta
          title={(
            <a href={`${process.env.REACT_APP_API_URL}v2.0/files/${value}`} target="_blank" rel="noopener noreferrer">
              <FileOutlined />
            </a>
          )}
        />
      ),
    },

    {
      title: 'Collaborators',
      dataIndex: ['collaborators'],
      render: (value) => value.map((item: Collaborator) => item?.name).filter(Boolean).join(', '),
    },

    {
      title: 'Action',
      key: 'actions',
      width: '15%',
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete asset"
            description="Are you sure to delete asset ?"
            onConfirm={async () => console.log('delete)')}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },

  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col flex="0 0 0">
        <Button type="primary" onClick={handleAddActivity} icon={<PlusOutlined />}>
          Add assets repository
        </Button>
      </Col>
      <Col span={24}>
        <Card title="Assets Repository" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
      <CreateAsset
        asset={asset}
        onClose={handleClose}
        open={openDrawer}
        onSubmit={(assetData) => handleSubmit(assetData)}
        collaborators={dataSourceCollaborator?.data?.items.filter(Boolean)}
      />
    </Row>
  );
}
