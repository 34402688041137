import {
  LightFilter,
  ProColumns,
  ProFormSelect,
  ProFormSelectProps,
  ProTable,
  ProTableProps,
} from '@ant-design/pro-components';
import { useEffect, useMemo, useState } from 'react';
import {
  UseFetchFilterAdvancedUserParams,
  useFetchAdvancedFilterUsers,
} from 'src/hooks/api';
import { useFetchCollaborators } from 'src/hooks/api/collaborator';
import { useFetchTags } from 'src/hooks/api/tag';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { User } from 'src/types';

export type UserFilters = {
  excludeUserIds?: string[];
};

function CollaboratorSelect(
  props: Omit<ProFormSelectProps, 'options' | 'loading'>,
) {
  // Server state
  const collaborators = useFetchCollaborators({
    page: 1,
    limit: 30,
  });
  // Deprived state
  const options = useMemo<ProFormSelectProps['options']>(() => {
    if (collaborators.data == null) {
      return undefined;
    }

    return collaborators.data.items.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [collaborators.data]);

  return (
    <ProFormSelect
      {...props}
      placeholder="Select collaborator"
      options={options}
    />
  );
}

function TagSelect(props: Omit<ProFormSelectProps, 'options' | 'loading'>) {
  // Server state
  const tags = useFetchTags({
    page: 1,
    limit: 30,
  });
  // Deprived state
  const options = useMemo<ProFormSelectProps['options']>(() => {
    if (tags.data == null) {
      return undefined;
    }

    return tags.data.items.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [tags.data]);

  return (
    <ProFormSelect
      mode="multiple"
      {...props}
      placeholder="Select tags"
      options={options}
    />
  );
}

export type UserTableProps = ProTableProps<User, any> & {
  filters?: UserFilters;
};

export default function UserTableV2(props: UserTableProps) {
  const { filters: userFilters = {}, ...otherProps } = props;

  // Hooks
  const {
    pagination,
    sorters,
    filters: tableFilter,
    handleParamsChange,
  } = useTableHelper<User>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [filters, setFilters] = useState<Record<string, unknown>>({});
  const params: UseFetchFilterAdvancedUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      ...tableFilter,
      ...filters,
      ...userFilters,
    };
  }, [mapTableParams, pagination, sorters, tableFilter, filters, userFilters]);
  const users = useFetchAdvancedFilterUsers({ ...params });

  useEffect(() => {
    console.log('tableFilter', tableFilter);
  }, [tableFilter]);

  const handleFilterChange = (
    _: unknown,
    allValue: Record<string, unknown>,
  ) => {
    setFilters({
      ...filters,
      ...allValue,
    });
  };

  const handleSearch = (value: string) => {
    setFilters({
      ...filters,
      search: value,
    });
  };

  const renderColumns = (): ProColumns<User>[] => [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Xp',
      dataIndex: 'xp',
    },
  ];

  const renderFilter = () => (
    <LightFilter onValuesChange={handleFilterChange}>
      <CollaboratorSelect name="collaboratorId" label="Collaborator" />
      <TagSelect name="tagIds" label="Tag" />
    </LightFilter>
  );

  return (
    <ProTable
      ghost
      {...otherProps}
      search={false}
      options={false}
      toolbar={{
        search: {
          allowClear: true,
          onSearch: handleSearch,
        },
        filter: renderFilter(),
      }}
      columnEmptyText="-"
      columns={renderColumns()}
      dataSource={users.data?.items}
      loading={users.isLoading}
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}
