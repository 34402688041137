import dayjs from 'dayjs';
import { useCallback } from 'react';
import { GiveAway } from 'src/types/GiveAway';

export default function useHasEndedGiveAway() {
  const hasEnded = useCallback((giveAway: GiveAway) => {
    if (giveAway == null || giveAway.endDate == null) {
      return false;
    }

    return dayjs(giveAway.endDate).isBefore(new Date());
  }, []);

  return { hasEnded };
}
