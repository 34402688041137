import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { useResponsive } from 'ahooks';
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Row,
  Space,
  Table,
  Tabs,
  notification,
} from 'antd';
import { ButtonType } from 'antd/es/button';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import ActivitySubmissionFilter, {
  ActivitySubmissionFilterValue,
} from 'src/components/Filter/ActivitySubmissionFilter';
import ActivitySubmissionDetail from 'src/components/SubmissionDetail/ActivitySubmissionDetail';
import { useFetchActivities } from 'src/hooks/api/activity';
import {
  FilterActivitySubmission,
  UseFetchActivitySubmissionsParams,
  useExportActivitySubmissions,
  useFetchActivitySubmissions,
  useUpdateActivitySubmission,
  useUpdateActivitySubmissionStatus,
} from 'src/hooks/api/activity-submission';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { ActivitySubmission } from 'src/types/Activity';
import { SubmissionStatus } from 'src/types/Submission';
import { ErrorDataResponse } from 'src/typing';

const dateFormat = 'DD-MM-YYYY';
const { RangePicker } = DatePicker;

interface FilterDropdownProps {
  confirm: () => void;
  clearFilters?: () => void;
}

const formatDate = (date: string) => dayjs(date).format('DD-MM-YYYY');

type ChildrenTabProps = {
  status?: SubmissionStatus;
};

function ChildrenTab(props: ChildrenTabProps) {
  const { status } = props;
  // Hooks
  const {
    pagination,
    sorters,
    filters,
    syncDataPagination,
    handleParamsChange,
  } = useTableHelper<ActivitySubmission>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [filterDate, setFilterDate] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [view, setView] = useState<boolean>(false);
  const [activitySubmission, setActivitySubmission] =
    useState<ActivitySubmission | null>(null);
  const [filter, setFilter] = useState<FilterActivitySubmission | null>(null);
  // Server states
  const params: UseFetchActivitySubmissionsParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
      filters,
    });
    const results: UseFetchActivitySubmissionsParams = {
      ...baseParams,
      ...filter,
      search,
      status,
    };

    if (filterDate[0] && filterDate[1]) {
      results.startSubmittedDate = filterDate[0].toDate();
      results.endSubmittedDate = filterDate[1].toDate();
    }

    if (filters && filters['activity.title']) {
      Object.assign(filters, { activityIds: filters['activity.title'] });
    }

    return results;
  }, [
    mapTableParams,
    pagination,
    sorters,
    search,
    status,
    filterDate,
    filter,
    filters,
  ]);
  const dataSource = useFetchActivitySubmissions({
    ...params,
    isMultipleEntries: true,
  } as never);

  const dataSourceActivity = useFetchActivities();

  const responsive = useResponsive();

  const { mutateAsync: updateStatusActivitySubmission } =
    useUpdateActivitySubmissionStatus();
  const { mutateAsync: updateActivitySubmission } =
    useUpdateActivitySubmission();

  useEffect(() => {
    syncDataPagination(dataSource.data as never);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleClickView = (record: ActivitySubmission) => {
    setActivitySubmission(record);
    setView(true);
  };

  const handleClickClose = () => {
    setView(false);
  };

  const handleUpdateStatus = async (
    id: number,
    updateStatus: string,
    note?: string,
  ) => {
    try {
      await updateStatusActivitySubmission({
        id,
        status: updateStatus,
        note,
      });

      notification.success({
        message: 'Activity submission updated successfully',
      });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: ` Activity submission create failed: ${errorMessage}`,
      });
    }
  };

  const handleFavoriteClick = async (record: ActivitySubmission) => {
    await updateActivitySubmission({
      id: record.id,
      isFavorite: !record.isFavorite,
    });

    if (record.isFavorite) {
      notification.success({
        message: 'Activity submission removed from favorites',
      });
    } else {
      notification.success({
        message: 'Activity submission added to favorites',
      });
    }
  };

  const handleSubmitFilter = (data: ActivitySubmissionFilterValue) => {
    setFilter({
      startCreatedAt: data.createdAt ? data.createdAt[0] : null,
      endCreatedAt: data.createdAt ? data.createdAt[1] : null,
      isFavorite: data.options?.[0] ?? undefined,
      proofType: data.proofType,
    });
  };

  const renderSearchAndUserFilter = () => (
    <Row>
      <Space size="small">
        <Col>
          <Input.Search
            allowClear
            placeholder="Search"
            onSearch={handleSearch}
          />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <ActivitySubmissionFilter onSubmit={handleSubmitFilter} />
        </Col>
      </Space>
    </Row>
  );

  const renderFilterDropdown = ({
    confirm,
    clearFilters,
  }: FilterDropdownProps) => (
    <div className="ant-table-filter-dropdown">
      <div style={{ padding: 4 }}>
        <RangePicker
          allowClear={false}
          format={dateFormat}
          onChange={(value) => {
            if (value) {
              setFilterDate([value[0] as Dayjs, value[1] as Dayjs]);
              confirm();
            }
          }}
          value={filterDate}
        />
      </div>
      <div aria-hidden="true" style={{ display: 'none' }} />
      <div className="ant-table-filter-dropdown-btns">
        <Button
          className="ant-btn css-dev-only-do-not-override-ar9dpx ant-btn-link ant-btn-sm"
          onClick={() => {
            if (clearFilters) {
              clearFilters();
              setFilterDate([null, null]);
            }
          }}
          type="link"
          disabled={!filterDate[0] && !filterDate[1]}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const renderStatusDropdown = (record: ActivitySubmission) => {
    const statusMap: {
      [key in SubmissionStatus]: {
        type?: ButtonType;
        danger?: boolean;
        text?: string;
      };
    } = {
      [SubmissionStatus.Partaken]: {
        type: 'default',
        text: 'Partaken',
      },
      [SubmissionStatus.Rejected]: {
        danger: true,
        text: 'Rejected',
      },
      [SubmissionStatus.Accepted]: {
        type: 'primary',
        text: 'Approved',
      },
      [SubmissionStatus.Pending]: {
        type: 'default',
        text: 'Pending',
      },
    };

    const { type, danger, text } = statusMap[record.status];

    return (
      <Button type={type || 'default'} danger={danger}>
        <Space>{text}</Space>
      </Button>
    );
  };

  const renderColumns = (): ColumnsType<ActivitySubmission> => [
    {
      title: 'Username',
      dataIndex: ['user', 'username'],
      width: 110,
    },
    {
      title: 'Activity',
      filters: dataSourceActivity?.data?.items?.map((item) => ({
        text: item.title || '',
        value: item.id || '',
      })),
      dataIndex: ['activity', 'title'],
    },
    {
      title: 'XP tokens',
      dataIndex: ['activity', 'xpAward'],
      width: 100,
      responsive: ['xl'],
    },
    {
      title: 'Submitted date',
      dataIndex: 'submittedDate',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 180,
      filterDropdown: ({ confirm, clearFilters }) =>
        renderFilterDropdown({
          confirm,
          clearFilters,
        }),
      render: (value) => (value ? formatDate(value) : 'N/A'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      width: 180,
      render: (value) => (value ? formatDate(value) : 'N/A'),
    },
    {
      title: 'Approver',
      dataIndex: ['admin', 'username'],
      width: 50,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '1%',
      render: (_, record) => renderStatusDropdown(record),
      responsive: ['xl'],
    },
    {
      title: '',
      key: 'actions',
      width: '1%',
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleClickView(record)}>View</Button>
          <Button
            icon={record.isFavorite ? <HeartFilled /> : <HeartOutlined />}
            onClick={() => handleFavoriteClick(record)}
          />
        </Space>
      ),
    },
  ];

  const renderExpandableRow = (record: ActivitySubmission) => {
    const columns: ColumnsType<ActivitySubmission> = [
      {
        title: 'Discord',
        dataIndex: ['user', 'discordUsername'],
      },
      {
        title: 'XP tokens',
        dataIndex: ['activity', 'xpAward'],
        width: 100,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '1%',
        render: (_, nestedRecord) => renderStatusDropdown(nestedRecord),
      },
    ];

    return <Table columns={columns} dataSource={[record]} pagination={false} />;
  };

  const renderTitle = () => (
    <Space>
      Activity submission
      <Badge
        overflowCount={999999}
        count={dataSource.data?.meta?.totalItems || 0}
      />
    </Space>
  );

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Card title={renderTitle()} extra={renderSearchAndUserFilter()}>
            <Table
              rowKey="id"
              expandable={
                !responsive.xl
                  ? { expandedRowRender: renderExpandableRow }
                  : undefined
              }
              columns={renderColumns()}
              dataSource={dataSource.data?.items as never}
              loading={dataSource.isLoading}
              pagination={pagination}
              onChange={handleParamsChange}
            />
          </Card>
        </Col>
      </Row>
      <ActivitySubmissionDetail
        activitySubmission={activitySubmission}
        open={view}
        onClose={handleClickClose}
        onUpdateStatus={handleUpdateStatus}
      />
    </>
  );
}

export default function ActivitySubmissionsPage() {
  const { mutateAsync: mutationExportActivitySubmissions } =
    useExportActivitySubmissions();

  const handleExportActivitySubmissions = async () => {
    mutationExportActivitySubmissions().then((res: any) => {
      const csvData = res.data;

      const blob = new Blob([csvData], { type: 'text/csv' });

      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'submissions.csv');
      document.body.appendChild(link); // Required for Firefox
      link.click();

      URL.revokeObjectURL(url);
    });
  };

  const pendingDataSource = useFetchActivitySubmissions({
    status: SubmissionStatus.Pending,
    isMultipleEntries: true,
  } as never);
  const approvedDataSource = useFetchActivitySubmissions({
    status: SubmissionStatus.Accepted,
    isMultipleEntries: true,
  } as never);
  const rejectedDataSource = useFetchActivitySubmissions({
    status: SubmissionStatus.Rejected,
    isMultipleEntries: true,
  } as never);

  const tabs = [
    {
      key: '1',
      label: 'ALL SUBMISSIONS',
      children: <ChildrenTab key="all" />,
    },
    {
      key: '2',
      label: 'PENDING',
      children: <ChildrenTab key="pending" status={SubmissionStatus.Pending} />,
      count: pendingDataSource?.data?.meta?.totalItems || 0,
    },
    {
      key: '3',
      label: 'APPROVED',
      children: (
        <ChildrenTab key="accepted" status={SubmissionStatus.Accepted} />
      ),
      count: approvedDataSource?.data?.meta?.totalItems || 0,
    },
    {
      key: '4',
      label: 'REJECTED',
      children: <ChildrenTab key="denied" status={SubmissionStatus.Rejected} />,
      count: rejectedDataSource?.data?.meta?.totalItems || 0,
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Button onClick={handleExportActivitySubmissions}>Export</Button>
      <Col span={24}>
        <Tabs defaultActiveKey="1">
          {tabs?.map((tab) => (
            <Tabs.TabPane
              key={tab.key}
              tab={
                <Space>
                  {tab.label}
                  <Badge overflowCount={999999} count={tab.count} />
                </Space>
              }
            >
              {tab.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
}
