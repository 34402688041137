import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useMemo } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Asset } from 'src/types/Asset';
import { Collaborator } from 'src/types/Collaborator';
import UploadImage from '../UploadImage';
import UploadFile from '../UploadFile';

type AssetForm = Asset;
const { Option } = Select;

export type CreateAssetProps = {
  open: boolean;
  asset?: Asset;
  collaborators?: Collaborator[];
  onClose?: () => void;
  onSubmit?: (values: Asset) => void;
};

export default function CreateAsset(props: CreateAssetProps) {
  const {
    open,
    asset,
    collaborators,
    onSubmit = () => {},
    onClose = () => {},
  } = props;

  const [form] = Form.useForm<AssetForm>();
  const formData: AssetForm | undefined = useMemo(() => {
    if (asset == null) {
      return undefined;
    }

    return {
      ...asset,
    };
  }, [asset]);

  useEffect(() => {
    if (formData != null) {
      form.setFieldsValue(formData);
      form.setFieldsValue({
        collaborators: formData?.collaborators?.map((item) => item?.id) as any,
      });
    } else {
      form.resetFields();
    }
  }, [form, formData]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (formInstance: FormInstance<AssetForm>) => {
    await formInstance.validateFields();
    const formValues = form.getFieldsValue();
    onSubmit({
      id: asset?.id,
      ...formValues,
    });
  };

  const handleSelect = (value: string) => {
    if (value === 'all') {
      const allOptionValues = collaborators?.map((collaborator) => collaborator.id) || [];

      if (allOptionValues) {
        form.setFieldsValue({ collaborators: allOptionValues as any });
      }
    }
  };

  return (
    <Drawer
      open={open}
      title={asset != null ? 'Update assets repository' : 'Add assets repository'}
      size="large"
      extra={(
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit(form);
            }}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      )}
      onClose={handleClose}
    >
      <Form id="create-asset" layout="vertical" form={form}>
        <Space direction="vertical" size="large">
          <Card title=" Assets details">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name={['name']}
                  label="Asset name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: ' Asset name cannot be empty',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Please enter asset name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="collaborators"
                  label="Collaborators"
                  rules={[
                    {
                      required: true,
                      message: 'Please select collaborator',
                    },
                  ]}
                  initialValue={asset?.collaborators?.map((item) => item?.id)}
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select an collaborator"
                    allowClear
                    showSearch
                    onSelect={handleSelect}
                  >
                    <Option key="all" value="all" label="Select All">
                      All
                    </Option>
                    {collaborators?.map((collaborator) => (
                      <Option key={collaborator?.id} value={collaborator?.id}>
                        {collaborator?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['image']}
                  label="Asset image"
                  help=" Please upload image with width of 741.6px and height of 1091.2px"
                >
                  <UploadImage prefix="collaborator-assets" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={['file']} label="Asset file">
                  <UploadFile prefix="collaborator-assets" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['label']}
                  label="Asset label"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Please enter asset label" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['link']}
                  label="Asset link"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Please enter asset link" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['description']}
                  label="Asset description"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: ' Asset description cannot be empty',
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Please enter description" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Space>
      </Form>
    </Drawer>
  );
}
