import { Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo } from 'react';
import {
  UseFetchCollaboratorUserParams,
  useFetchCollaboratorUsers,
} from 'src/hooks/api/collaborator';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { User } from 'src/types';
import { Collaborator } from 'src/types/Collaborator';

export type CollaboratorUserTableProps = {
  collaborator: Collaborator;
  onWhiteUser: (record: User) => void;
  onAirdropUser: (record: User) => void;
};

export default function CollaboratorUserTable(
  props: CollaboratorUserTableProps,
) {
  const { collaborator, onWhiteUser, onAirdropUser } = props;

  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<User>();

  const { mapTableParams } = useMapTableParam();

  const params: UseFetchCollaboratorUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
    };
  }, [mapTableParams, pagination, sorters]);

  const dataSourceUser = useFetchCollaboratorUsers(collaborator.id, {
    ...params,
  });

  useEffect(() => {
    syncDataPagination(dataSourceUser.data);
  }, [syncDataPagination, dataSourceUser.data]);

  const handleWhiteUser = (record: User) => {
    onWhiteUser(record);
    dataSourceUser.refetch();
  };

  const handleAirdropUser = (record: User) => {
    onAirdropUser(record);
    dataSourceUser.refetch();
  };

  const columns: ColumnsType<User> = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'XP',
      dataIndex: 'xp',
      key: 'XP',
      width: '15%',
    },
    {
      title: 'Whitelist',
      dataIndex: 'isWhitelistEligible',
      key: 'Whitelist',
      width: '15%',
      render: (value: boolean, _: User) => (
        <Switch checked={value} onChange={() => handleWhiteUser(_)} />
      ),
    },
    {
      title: 'Airdrop',
      dataIndex: 'isAirdropWhitelistEligible',
      key: 'Airdrop',
      width: '15%',
      render: (Airdrop: boolean, _: User) => (
        <Switch checked={Airdrop} onChange={() => handleAirdropUser(_)} />
      ),
    },
  ];

  return (
    <Table
      style={{ width: '100%' }}
      dataSource={dataSourceUser?.data?.items}
      columns={columns}
      onChange={handleParamsChange}
      pagination={pagination}
    />
  );
}
