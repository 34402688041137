import { Collapse, CollapsePanelProps, CollapseProps } from 'antd';

interface CustomCollapseProps extends CollapseProps {
  panelItems: CollapsePanelProps[];
  panelClassName?: string;
  collapseClassName?: string;
}

function CustomCollapse(props: CustomCollapseProps) {
  const {
    activeKey, onChange, panelItems, panelClassName, collapseClassName,
  } = props;
  const { Panel } = Collapse;

  return (
    <Collapse
      defaultActiveKey={[`${activeKey}`]}
      onChange={onChange}
      className={collapseClassName}
    >
      {panelItems ? panelItems.map((panelItem) => (
        <Panel
          key={panelItem.key}
          header={panelItem.header}
          className={panelClassName}
        >
          {panelItem.children}
        </Panel>
      )) : 'No Data'}
    </Collapse>
  );
}

export default CustomCollapse;
