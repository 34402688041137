import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Checkbox, Col, Drawer, Form, Input, Row, Select, Space,
} from 'antd';
import { FormInstance, Rule } from 'antd/es/form';
import type { Store } from 'antd/lib/form/interface';
import { useEffect, useState } from 'react';
import { Admin, Role } from 'src/types/Admin';

const { Option } = Select;

interface FormValues {
  username: string;
  password: string;
  confirmPassword: string;
  roles: string[];
}

export type AdminDetailProps = {
  admin: Admin;
  setAdmin: React.Dispatch<React.SetStateAction<Admin>>;
  roles: Role[] | [];
  onSubmit: () => void;
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const textUpdate = {
  buttonAction: 'Update admin',
  title: 'Update the admin',
};
const textAddNew = {
  buttonAction: 'Add admin',
  title: 'Create new admin',
};

export default function CreateAccount(props: AdminDetailProps) {
  const {
    admin, edit, setAdmin, roles, onSubmit, setEdit,
  } = props;

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (edit) {
      setOpen(true);
    }
  }, [edit]);

  useEffect(() => {
    form.setFieldsValue({ username: admin.username });
    form.setFieldsValue({
      roles: admin.roles != null
        ? admin.roles
          .map((role) => role.id?.toString())
          .filter((roleId): roleId is string => roleId != null)
        : undefined,
    });
  }, [admin, form]);

  const showDrawer = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  const handleFormChange = (changedValues: Store) => {
    setAdmin((prevFormData) => {
      if (changedValues?.roles) {
        const updatedRoles = roles?.find((r: Role) => r.id === changedValues.roles);

        if (updatedRoles) {
          return {
            ...prevFormData,
            ...changedValues,
            roles: [updatedRoles],
          };
        }
      }

      return {
        ...prevFormData,
        ...changedValues,
      };
    });
  };

  const validateConfirmPassword = <T extends FormValues>(formInstance: FormInstance<T>): Rule => ({

    validator(_, value) {
      if (edit && !value) return Promise.resolve();

      if (!value || formInstance.getFieldValue('password') === value) {
        return Promise.resolve();
      }

      return Promise.reject(new Error('Sorry, the passwords you entered don\'t match. Please try again.'));
    },
  });

  const validateAllFields = <T extends FormValues> (formInstance: FormInstance<T>) => {
    formInstance.validateFields()
      .then(() => onSubmit())
      .catch((error) => Promise.reject(new Error(error)));
  };

  const validateStrongPassword = (): Rule => ({
    validator(_, value) {
      if (edit && !value) return Promise.resolve();

      const regex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;

      if (regex.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(new Error('Password is weak. Please use strong one'));
    },
  });

  const permissions = admin?.roles?.flatMap((role) => role.permissions);

  return (
    <>
      <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />} className="right-button">
        {edit ? textUpdate.buttonAction : textAddNew.buttonAction}
      </Button>
      <Drawer
        title={edit ? textUpdate.title : textAddNew.title}
        width={720}
        onClose={handleClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={(
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                validateAllFields(form);
              }}
              type="primary"
            >
              Submit
            </Button>
          </Space>
        )}
      >
        <Form form={form} initialValues={admin} layout="vertical" onValuesChange={handleFormChange}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="roles"
                label="Role"
                rules={[{
                  required: true,
                  message: 'Please select an role',
                }]}
              >
                <Select
                  placeholder="Please select an role"
                >
                  {roles?.map((role) => <Option key={role.id} value={role.id}>{role.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{
                  required: false,
                  message: 'User Name not empty',
                }]}
              >
                <Input value={admin.username} placeholder="Please enter name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="username"
                label="User Name"
                rules={[{
                  required: true,
                  message: 'User Name not empty',
                }]}
              >
                <Input value={admin.username} placeholder="Please enter user name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{
                  required: true,
                  message: 'Email not empty',
                }]}
              >
                <Input value={admin.username} placeholder="Please enter email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[{
                  required: !edit,
                }, validateStrongPassword()]}
                help="Your password must be 8 to 15 characters long
                and should contain atleast 1 smallcase, atleast 1 uppercase,
                1 digit and 1 special character [ @ $ ! * # ? & _ ]"
              >
                <Input type="password" placeholder="Please enter password" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                rules={[{
                  required: !edit,
                  message: 'Please confirm your password!',

                }, validateConfirmPassword(form)]}
              >
                <Input type="password" placeholder="Please enter confirm password" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {permissions && permissions.length > 0 ? (
          <Row>
            <Col span={4}>Permissions:</Col>
            <Col span={20}>
              <Row>
                {permissions?.map((permission) => (
                  <Col span={12}>
                    <Checkbox key={permission.id} checked>{permission?.name}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ) : null }
      </Drawer>
    </>
  );
}
