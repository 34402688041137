import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

type Props = {
  className?: string;
  children?: ReactNode;
  size?: 'small' | 'middle' | 'large';
  flex?: number;
};

export default function HeaderColumn(props: Props) {
  return (
    <div
      {...props}
      className={classNames(
        styles.button,
        styles[props.size!],
        props.className,
      )}
      style={{
        flex: props.flex,
      }}
    >
      {props.children}
    </div>
  );
}

HeaderColumn.defaultProps = {
  size: 'large',
  flex: 1,
};
