import { Switch, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ReactElement } from 'react';
import useTableHelper from 'src/hooks/useTableHelpers';
import { User } from 'src/types';

export interface EligibleUserListColumnsTypes {
  title: string;
  dataIndex: string;
  key: string;
}

export interface EligibleUserListDataType {
  key: string;
  userName: string;
  dropName: string;
  isWhitelisted: boolean;
}

export interface EligibleUserListProps {
  eligibleUsers: User[];
  loading: boolean;
}

function EligibleUserList(props: EligibleUserListProps): ReactElement {
  const { eligibleUsers = [], loading } = props;
  const { pagination, handleParamsChange } = useTableHelper<User>();

  const columns: ColumnsType<User> = [
    {
      title: 'Wallet Address',
      dataIndex: ['mCustodialWallet', 'mWallet', 'address'],
      key: 'walletAddress',
    },
    {
      title: 'Email',
      dataIndex: ['mCredential', 'mEmail', 'mValue'],
      key: 'email',
    },

    {
      title: 'Whitelist Eligible',
      dataIndex: 'isWhitelistEligible',
      key: 'isWhitelistEligible',
      render: (value) => <Switch checked={value} disabled />,
    },
    {
      title: 'Whitelist Claimed',
      dataIndex: 'isWhitelistClaimed',
      key: 'isWhitelistClaimed',
      render: (value) => <Switch checked={value} disabled />,
    },
    {
      title: 'XP',
      dataIndex: 'mXp',
      sorter: (userA, userB) => userA.xp! - userB.xp!,
    },
  ];

  return (
    <Table
      dataSource={eligibleUsers || []}
      columns={columns}
      rowKey="id"
      loading={loading}
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}

export default EligibleUserList;
