import { ProColumns, ProTable } from '@ant-design/pro-components';
import { Button, Space } from 'antd';
import { useEffect, useMemo } from 'react';
import {
  UseFetchUserTagParams,
  useFetchUserTags,
  useSyncTag,
} from 'src/hooks/api/tag';
import { Page } from 'src/hooks/api/typing';
import useGetFileByKey from 'src/hooks/useGetFileByKey';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { User, UserTag } from 'src/types';

type SyncButtonProps = {
  userId: string;
  tagId: string;
};

function SyncButton(props: SyncButtonProps) {
  const { userId, tagId } = props;

  const { mutateAsync: syncTag, isLoading: isSyncing } = useSyncTag();

  const handleSyncTag = async () => {
    await syncTag({
      tagId,
      userId,
    });
  };

  return (
    <Button type="primary" loading={isSyncing} onClick={handleSyncTag}>
      Sync
    </Button>
  );
}

export type UserTagProps = {
  user: User;
};

export default function UserTagTable(props: UserTagProps) {
  // Hooks
  const { user } = props;
  const { getFileByKey } = useGetFileByKey();
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<UserTag>();
  const { mapTableParams } = useMapTableParam();
  // Server states
  const params: UseFetchUserTagParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
      userId: user?.id.toString(),
    };
  }, [mapTableParams, pagination, sorters, user]);
  const { data: tagPage, isLoading: isLoadingTag } = useFetchUserTags(params);
  // Deprived states
  const dataSource: Page<UserTag> | undefined = useMemo(() => {
    if (tagPage == null) {
      return undefined;
    }

    return {
      ...tagPage,
      items: tagPage?.items.map((item) => ({
        ...item,
        icon: item.icon != null ? getFileByKey(item.icon) : undefined,
      })),
    };
  }, [getFileByKey, tagPage]);

  useEffect(() => {
    syncDataPagination(tagPage);
  }, [syncDataPagination, tagPage]);

  const renderColumns = (): ProColumns<UserTag>[] => [
    {
      title: 'Icon',
      dataIndex: 'icon',
      valueType: 'image',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Count',
      dataIndex: 'count',
    },
    {
      title: 'Actions',
      key: 'actions',
      valueType: 'option',
      width: '1%',
      render: (_, tag) => (
        <Space>
          <SyncButton userId={user.id.toString()} tagId={tag.id.toString()} />
        </Space>
      ),
    },
  ];

  return (
    <ProTable
      style={{ width: '100%' }}
      ghost
      search={false}
      options={false}
      columns={renderColumns()}
      dataSource={dataSource?.items}
      loading={isLoadingTag}
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}
