// TODO: make common view for MERCH AIRDROP & WHITELIST

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Dropdown, MenuProps, Space, message,
} from 'antd';
import classNames from 'classnames';

import { addEligibleUsers } from 'src/hooks/api';
import { useAppDispatch } from 'src/store/dispatch';
import { getCollaboratorProfile } from 'src/store/actions';
import { createRequester } from 'src/hooks/useApi';
import {
  UseFetchUserParams,
  useFetchActivitySubmissions,
  useFetchCollaboratorUserList,
} from 'src/hooks/api/collaborator-dashboard';
import { TypeEligibleUser, User } from 'src/hooks/api/typing';
import { selectCollaboratorProfile } from 'src/store/selectors';

import BodyColumn from 'src/pages/CollaboratorDashboard/_shared/components/BodyColumn';
import Button from 'src/pages/CollaboratorDashboard/_shared/components/Button';
import HeaderColumn from 'src/pages/CollaboratorDashboard/_shared/components/HeaderColumn';
import Switch from 'src/pages/CollaboratorDashboard/_shared/components/Switch';
import { AxiosError } from 'axios';
import { ErrorDataResponse } from 'src/typing';
import { DownOutlined } from '@ant-design/icons';
import HeartIcon from 'src/components/Icons/HeartIcon';
import SearchInput from '../_shared/components/SearchInput';

import styles from './styles.module.scss';

export type FilterAdvancedCollaboratorUser = {
  isReferral?: boolean;
  isWhitelisted?: boolean;
  activityIds?: number[];
  isAllegiance?: boolean;
  isRedeemed?: boolean;
  isFavorite?: boolean;
};

export default function MinSpots() {
  const dispatch = useAppDispatch();
  const requester = createRequester({ dispatch });

  const profile = useSelector(selectCollaboratorProfile);
  const [search, setSearch] = useState<string | undefined>();
  const [filter, setFilter] = useState<FilterAdvancedCollaboratorUser | null>(
    null,
  );
  const [selectedActivityFilterTitle, setSelectedActivityFilterTitle] = useState('Activity Participants');
  const [activityFilters, setActivityFilters] = useState<
  {
    label: string;
    key: string;
  }[]
  >([
    {
      label: 'All Participants',
      key: '0',
    },
  ]);

  const params: UseFetchUserParams = useMemo(
    () => ({
      search,
      ...filter,
    }),
    [search, filter],
  );
  const { data: submissions } = useFetchActivitySubmissions({ isFavorite: true });
  const { data: users, refetch } = useFetchCollaboratorUserList(profile?.id, {
    ...params,
  });

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [isSelectedUserList, setIsSelectedUserList] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const likedUsers = submissions?.items.map((item) => item.user) as User[];
  const isFavoriteFiltered = filter?.isFavorite;

  const noticeMessage = (type: 'error' | 'success', content: string) => {
    messageApi.open({
      type,
      content,
      duration: 2,
    });
  };

  const handleActivityFilter = (activityId: string) => {
    if (activityId === '0') {
      setFilter({ activityIds: [] });
    } else {
      setFilter({ activityIds: [+activityId] });
    }
  };

  const handleResetFilter = () => {
    setSelectedActivityFilterTitle('Activity Participants');
    setFilter(null);
  };

  const handleSwitchToggle = (isChecked: boolean, selectedUser: User) => {
    const spotsAvailable = profile?.remainingWhitelistUser || 0;

    if (isChecked && selectedUsers.length >= spotsAvailable) {
      noticeMessage(
        'error',
        spotsAvailable === 0
          ? 'Sorry no whitelist spots are available'
          : `Only ${spotsAvailable} spots are available`,
      );
      return;
    }

    if (isChecked) {
      setSelectedUsers([...selectedUsers, selectedUser]);
    } else {
      const filteredItems = selectedUsers.filter(
        (user) => user.id !== selectedUser.id,
      );
      setSelectedUsers(filteredItems);
    }
  };

  const onSubmitEligiblesUsers = async () => {
    try {
      const userIds: number[] = [];

      selectedUsers.forEach((user: User) => userIds.push(user.id));

      setIsSubmitting(true);

      await addEligibleUsers({
        userIds,
        type: TypeEligibleUser.collaborator,
        requester,
      });

      noticeMessage(
        'success',
        'Whitelist mint eligibility awarded successfully',
      );

      await dispatch(getCollaboratorProfile());

      refetch();

      setSelectedUsers([]);
      setIsSelectedUserList(false);
      setIsSubmitting(false);
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      noticeMessage('error', `Submission failed:${errorMessage}`);
    }
  };

  const handleToggleUserList = () => {
    setIsSelectedUserList(!isSelectedUserList);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    if (profile?.activities && profile?.activities.length) {
      const activities: {
        label: string;
        key: string;
      }[] = [];

      profile.activities.map((activity) => activities.push({
        label: `${activity.title} Participants`,
        key: activity.id ? activity.id.toString() : '0',
      }));

      setActivityFilters((prevFilters) => {
        const uniqueActivities = activities.filter(
          (newActivity) => !prevFilters.some(
            (existingActivity) => existingActivity.key === newActivity.key,
          ),
        );

        return [...prevFilters, ...uniqueActivities];
      });
    }
  }, [profile]);

  const checkUserIsSelected = (user: User) => selectedUsers.some((selectedUser) => selectedUser.id === user.id);

  const items: MenuProps['items'] = [...activityFilters];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const selectedActivity = profile?.activities?.filter((activity) => activity.id?.toString() === e.key)[0];
    setSelectedActivityFilterTitle(selectedActivity ? selectedActivity.title : 'All Participants');
    handleActivityFilter(e.key);
  };

  const renderUserList = (userList: User[]) => (
    <div className={classNames(styles.bodyColumnWrapper, ' awesome-scrollbar')}>
      {userList.map((user: User) => (
        <div
          key={user.id}
          className={classNames(styles.bodyColumn, 'flex my-8')}
        >
          <BodyColumn flex={1}>
            <div className={classNames(styles.user, 'body3 px-24')}>
              {user.username}
              {!!filter?.isFavorite && <HeartIcon fill="var(--color-primary)" color="var(--color-primary)" className="ml-4" />}
            </div>
          </BodyColumn>
          <BodyColumn flex={2}>
            <div className="body3 px-24 mx-auto">
              {!user.isWhitelistEligible ? (
                <Switch
                  key={user.id}
                  checked={
                    checkUserIsSelected(user) || user.isWhitelistEligible
                  }
                  disabled={user.isWhitelistEligible}
                  onChange={(status) => handleSwitchToggle(status, user)}
                />
              ) : (
                <span className={styles.awardStatus}>Awarded</span>
              )}
            </div>
          </BodyColumn>
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className="pageHeader justify-between">
        <div>
          <div className="flex items-center">
            <h6>
              Whitelist Mint Spots Available
              <strong>
                {profile?.remainingWhitelistUser}
                /
                {profile?.amountUserWhitelist}
              </strong>
            </h6>
          </div>
          <div className="caption mt-8 flex font-weight-600 w-max-content">
            How to award them?
            <Link to="/give-away/whitelist-faq" className="flex items-center">
              <div className={classNames(styles.noteIcon, 'ml-4')}>i</div>
            </Link>
          </div>
        </div>
        <div className=" w-half text-right">
          <SearchInput onSearch={handleSearch} />
          <div className="caption text-right mt-8">
            Search by Planet Tota handle
          </div>
        </div>
      </div>
      <h5 className={styles.tabWrapperTitle}>Filter By:</h5>
      <div className={styles.tabWrapper}>
        <button
          className={!filter ? styles.active : ''}
          type="button"
          onClick={handleResetFilter}
        >
          Show all
        </button>
        <button
          className={filter?.isReferral ? styles.active : ''}
          type="button"
          onClick={() => {
            setFilter({ isReferral: true }); setSelectedActivityFilterTitle('Activity Participants');
          }}
        >
          Collab Code Users
        </button>
        {profile?.allegianceId ? (
          <>
            <button
              className={filter?.isAllegiance ? styles.active : ''}
              type="button"
              onClick={() => {
                setFilter({ isAllegiance: true }); setSelectedActivityFilterTitle('Activity Participants');
              }}
            >
              Allegiance Declared Users
            </button>
            <Dropdown
              menu={{
                items,
                onClick: handleMenuClick,
              }}
            >
              <button className={filter?.activityIds ? styles.active : ''} type="button">
                <Space>
                  {selectedActivityFilterTitle}
                  <DownOutlined />
                </Space>
              </button>
            </Dropdown>
          </>
        ) : (
          ''
        )}
        {profile?.allegianceId && profile.name.toUpperCase() === 'IOH' ? (
          <button
            className={filter?.isRedeemed ? styles.active : ''}
            type="button"
            onClick={() => setFilter({ isRedeemed: true })}
          >
            Redeemers
          </button>
        ) : (
          ''
        )}
        <button
          className={filter?.isFavorite ? styles.active : ''}
          type="button"
          onClick={() => setFilter({ isFavorite: true })}
        >
          Show liked submissions
        </button>
      </div>
      <div
        className={classNames(
          styles.toolPanelWrapper,
          'flex justify-between my-24',
        )}
      >
        <div className="text-left flex items-center justify-start">
          {isSelectedUserList ? (
            <div className={classNames(styles.backButton, 'flex items-center')}>
              <div className={styles.backIcon}>{'<<'}</div>
              <div className="subtitle1 ml-4" onClick={handleToggleUserList}>
                Back to full list
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={classNames(
            styles.toolPanel,
            'flex items-center justify-end w-max-content w-half',
          )}
        >
          <div className={classNames('flex items-center w-max-content')}>
            <div
              className={classNames(
                styles.awardedTitle,
                'subtitle1 w-max-content',
              )}
            >
              Spots Awarded
            </div>
            <p className={classNames(styles.awarded, 'mb-0 ml-24')}>
              {Number(profile?.amountUserWhitelist)
                - Number(profile?.remainingWhitelistUser)}
            </p>
          </div>
          <Button
            loading={isSubmitting}
            disabled={selectedUsers.length === 0}
            onClick={handleToggleUserList}
            size="middle"
          >
            Review
          </Button>
          <Button
            loading={isSubmitting}
            disabled={!isSelectedUserList}
            onClick={onSubmitEligiblesUsers}
            size="middle"
          >
            Submit
          </Button>
        </div>
      </div>
      <div className={classNames(styles.headerColumnWrapper, 'flex mb-16')}>
        <HeaderColumn flex={1}>User Handle</HeaderColumn>
        <HeaderColumn flex={2}>AWARD WHITELIST MINT SPOT</HeaderColumn>
      </div>
      {isSelectedUserList ? renderUserList(isFavoriteFiltered ? likedUsers : selectedUsers) : null}
      {!isSelectedUserList && users && users.items.length
        ? renderUserList(isFavoriteFiltered ? likedUsers : users.items)
        : null}
    </div>
  );
}
