import { Input } from 'antd';
import { SearchProps } from 'antd/es/input';
import styles from './style.module.scss';

type Props = SearchProps;

export default function SearchInputStyled(props: Props) {
  return (
    <Input.Search
      {...props}
      className={styles.searchInput}
      allowClear
      placeholder="Search"
    />
  );
}
