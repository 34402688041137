import { useMutation, useQueryClient } from '@tanstack/react-query';
import { User, UserSession } from 'src/types';
import useApi from '../useApi';
import { Page, PaginationParams, SearchParams, SortParams } from './typing';
import useFetch from './useFetch';

export type FilterAdvancedUser = {
  isOg?: boolean;
  isWhitelisted?: boolean;
  isAirdropped?: boolean;
  isBanned?: boolean;
  allegiance?: string;
  startCreatedAt?: Date | null;
  endCreatedAt?: Date | null;
  tagIds?: string[];
  excludeUserIds?: string[];
  activityIds?: number[];
};

export type UseFetchUserParams = PaginationParams & SortParams & SearchParams;
export type UseFetchFilterAdvancedUserParams = UseFetchUserParams &
  FilterAdvancedUser;

export const useFetchUsers = (params: UseFetchUserParams) =>
  useFetch<Page<User>>({
    key: ['users', params],
    url: '/admin/users',
    searchParams: params,
  });

export const useFetchAdvancedFilterUsers = (
  params: UseFetchFilterAdvancedUserParams,
) =>
  useFetch<Page<User>>({
    key: ['dashboard-user', params],
    url: '/admin/dashboard-users',
    searchParams: {
      ...params,
      isOg: params?.isOg?.toString(),
      isWhitelisted: params?.isWhitelisted?.toString(),
      isAirdropped: params?.isAirdropped?.toString(),
      isBanned: params?.isBanned?.toString(),
      allegiance: params?.allegiance,
      startCreatedAt: params.startCreatedAt?.toISOString(),
      endCreatedAt: params.endCreatedAt?.toISOString(),
      tagIds: params.tagIds?.join(','),
    },
    parseFn: (data) => {
      const items = data.items.map((item) => ({
        ...item,
      }));
      return {
        ...data,
        items,
      };
    },
  });

export type UseFetchUserSessionParams = PaginationParams & {
  userId: number;
};

export const useFetchUserSessions = (params: UseFetchUserSessionParams) => {
  const { userId, ...otherParams } = params;

  return useFetch<Page<UserSession>>({
    key: ['user-sessions', userId],
    url: `/admin/users/${userId}/sessions`,
    searchParams: otherParams,
  });
};

type UpdateUserRequest = {
  isOg?: boolean;
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (user: User) => {
      const payload: UpdateUserRequest = {
        isOg: user.isOg,
      };

      await requester.patch(`/admin/users/${user.id}`, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['dashboard-user'] });
      },
    },
  );
};

type BanCommand = {
  users: User[];
  reason?: string;
};

type BanUserRequest = {
  userIds: number[];
  reason?: string;
};

export const useBanUsers = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (command: BanCommand) => {
      const payload: BanUserRequest = {
        userIds: command.users.map((user) => user.id),
        reason: command.reason,
      };

      await requester.post('/admin/users/bans', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['dashboard-user'] });
      },
    },
  );
};

export const useUnBanUser = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (user: User) => {
      await requester.delete(`/admin/users/${user.id}/bans`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['dashboard-user'] });
      },
    },
  );
};

export const useExportUsers = () => {
  const { requester } = useApi();

  return useMutation(async () => requester.get('/admin/export/users'));
};
