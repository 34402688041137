import { Form, DatePicker, Checkbox, Select } from 'antd';
import { filterProofTypes } from 'src/pages/Activity';
import BaseFilter, { FilterProps } from './BaseFilter';

const { RangePicker } = DatePicker;

const DATE_FORMAT = 'DD-MM-YYYY';

export type ActivitySubmissionFilterValue = {
  createdAt?: [Date | null, Date | null];
  proofType?: [string];
  options?: [boolean | undefined];
};

export type ActivitySubmissionFilterProps = Omit<
  FilterProps<ActivitySubmissionFilterValue>,
  'children'
>;

export default function ActivitySubmissionFilter(
  props: ActivitySubmissionFilterProps,
) {
  const getCreatedAt = (value: Date[]) => [
    new Date(value[0]),
    new Date(value[1]),
  ];

  return (
    <BaseFilter<ActivitySubmissionFilterValue> {...props}>
      <Form.Item
        name="createdAt"
        label="Created at"
        valuePropName="createdAt"
        getValueFromEvent={getCreatedAt}
        {...props}
      >
        <RangePicker format={DATE_FORMAT} allowClear />
      </Form.Item>
      <Form.Item label="Proof type" name="proofType">
        <Select mode="multiple" allowClear>
          {filterProofTypes.map((type) => (
            <Select.Option key={type.value} value={type.value}>
              {type?.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Options" name="options">
        <Checkbox.Group
          options={[
            {
              label: 'Favorite',
              value: true,
            },
          ]}
        />
      </Form.Item>
    </BaseFilter>
  );
}
