import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EmailMessage, StatusEmailMessage } from 'src/types/EmailMessage';
import useApi from '../useApi';
import { Page, PaginationParams, SearchParams, SortParams } from './typing';
import useFetch from './useFetch';

const KEY_EMAIL_MESSAGES_QUERIES = ['email-messages'];

export type UseFetchEmailMessageParams = PaginationParams &
  SortParams &
  SearchParams;

export type CreateEmailMessagePayload = Pick<
  EmailMessage,
  'subject' | 'content' | 'type' | 'icon' | 'title'
> & { userIds?: string[]; selectedAllUser?: boolean };

export type UpdateEmailMessagePayload = CreateEmailMessagePayload & {
  status?: StatusEmailMessage;
  id: string;
};

export const useFetchEmailMessages = (params?: UseFetchEmailMessageParams) =>
  useFetch<Page<EmailMessage>>({
    key: [...KEY_EMAIL_MESSAGES_QUERIES, params],
    url: '/admin/email-messages',
    searchParams: params,
    parseFn: (data) => ({
      ...data,
    }),
  });

export const useCreateEmailMessage = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: CreateEmailMessagePayload) => {
      const payload: CreateEmailMessagePayload = {
        icon: data.icon,
        title: data.title,
        subject: data.subject,
        content: data.content,
        type: data.type,
        userIds: data.userIds,
      };

      return requester.post<void>('/admin/email-messages', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_EMAIL_MESSAGES_QUERIES });
      },
    },
  );
};

export const useUpdateEmailMessage = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: UpdateEmailMessagePayload) => {
      const payload = {
        type: data.type,
        icon: data.icon,
        title: data.title,
        subject: data.subject,
        content: data.content,
        status: data.status,
        userIds: data.userIds,
      };

      await requester.patch<void>(`/admin/email-messages/${data?.id}`, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_EMAIL_MESSAGES_QUERIES });
      },
    },
  );
};

export const useDeleteEmailMessage = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: EmailMessage) => {
      await requester.delete<void>(`/admin/email-messages/${data.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_EMAIL_MESSAGES_QUERIES,
        });
      },
    },
  );
};

export const useUpdateSendAllEmailMessage = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: any) => {
      await requester.patch<void>(`/admin/email-messages/${data?.id}/users`, {
        ...data.filterUser,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_EMAIL_MESSAGES_QUERIES });
      },
    },
  );
};
