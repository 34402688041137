import { Outlet, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Content } from 'antd/es/layout/layout';
import { Layout, MenuProps } from 'antd';

import SideBar from './SideBar';

import styles from './style.module.scss';

export default function MastercardLayout() {
  const navigate = useNavigate();

  const handleMenuClick: MenuProps['onClick'] = (menuInfo) => {
    if (menuInfo.key) {
      navigate(menuInfo.key);
    }
  };

  return (
    <main className={classNames(styles.layout, 'flex font_Quicksand')}>
      <Layout className={styles.siderWrapper}>
        <SideBar onClick={handleMenuClick} />
        <Layout className={styles.contentWrapper}>
          <Content className={styles.content}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </main>
  );
}
