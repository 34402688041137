import { Navigate, createBrowserRouter } from 'react-router-dom';

import ProtectedRoute from '../components/ProtectedRoute';
import { SystemPermissions } from '../constants/roles-permissions';

// Pages
import CollabLayout from '../pages/CollaboratorDashboard/_shared/Layout';
import CollabLogin from '../pages/CollaboratorDashboard/Login';
import CollabDashboard from '../pages/CollaboratorDashboard/Dashboard';
import CollabForgotPassword from '../pages/CollaboratorDashboard/ForgotPassword';
import CollabWhitelist from '../pages/CollaboratorDashboard/Whitelist';
import CollabWhitelistFaq from '../pages/CollaboratorDashboard/WhitelistFaq';
import CollabAirdrop from '../pages/CollaboratorDashboard/Airdrop';
import CollabAirdropFaq from '../pages/CollaboratorDashboard/AirdropFaq';
import CollabMyAccount from '../pages/CollaboratorDashboard/MyAccount';
import CollabContactUs from '../pages/CollaboratorDashboard/ContactUs';
import CollabAssetRepository from '../pages/CollaboratorDashboard/AssetRepository';
import CollaboratorAllegiance from '../pages/CollaboratorDashboard/CollaboratorAllegiance';
import CollaboratorActivities from '../pages/CollaboratorDashboard/CollaboratorActivities';
import CollaboratorMerch from '../pages/CollaboratorDashboard/CollaboratorMerch';
import CollaboratorTokenRedeem from '../pages/CollaboratorDashboard/CollaboratorTokenRedeem';
import TermsAndConditions from '../pages/CollaboratorDashboard/TermsAndConditions';
import PrivacyPolicy from '../pages/CollaboratorDashboard/PrivacyPolicy';
import NotFound from '../pages/NotFound';

const CollaboratorRouter = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <CollabLayout />
      </ProtectedRoute>
    ),
    errorElement: <Navigate to="/404" replace />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
          >
            <CollabDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'allegiance',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
          >
            <CollaboratorAllegiance />
          </ProtectedRoute>
        ),
      },
      {
        path: 'activities',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
          >
            <CollaboratorActivities />
          </ProtectedRoute>
        ),
      },
      {
        path: 'token-redeemed',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
          >
            <CollaboratorTokenRedeem />
          </ProtectedRoute>
        ),
      },
      {
        path: '/give-away',
        children: [
          {
            path: '',
            element: <Navigate to="whitelist" replace />,
          },
          {
            path: 'whitelist',
            element: (
              <ProtectedRoute
                permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
              >
                <CollabWhitelist />
              </ProtectedRoute>
            ),
          },
          {
            path: 'whitelist-faq',
            element: (
              <ProtectedRoute
                permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
              >
                <CollabWhitelistFaq />
              </ProtectedRoute>
            ),
          },
          {
            path: 'airdrop',
            element: (
              <ProtectedRoute
                permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
              >
                <CollabAirdrop />
              </ProtectedRoute>
            ),
          },
          {
            path: 'merch',
            element: (
              <ProtectedRoute
                permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
              >
                <CollaboratorMerch />
              </ProtectedRoute>
            ),
          },
          {
            path: 'airdrop-faq',
            element: (
              <ProtectedRoute
                permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
              >
                <CollabAirdropFaq />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'my-account',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
          >
            <CollabMyAccount />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contact-us',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
          >
            <CollabContactUs />
          </ProtectedRoute>
        ),
      },
      {
        path: 'assets-repository',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_COLLABORATOR_DASHBOARD.key}
          >
            <CollabAssetRepository />
          </ProtectedRoute>
        ),
      },

    ],
  },
  {
    path: '/404',
    element: <NotFound />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/forgot-password',
    element: <CollabForgotPassword />,
  },
  {
    path: '/sign-in',
    element: <CollabLogin />,
  },
]);

export default CollaboratorRouter;
