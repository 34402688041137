import { ProCard, ProDescriptions } from '@ant-design/pro-components';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import formatDateTime from 'src/helper/formatDateTime';
import { useFetchNFTMintedForDrop } from 'src/hooks/api';
import { Drop, DropStatistics } from 'src/hooks/api/typing';

type WhitelistSaleStatusProps = {
  dropId: string;
  drop: Drop | undefined;
  dropStatistics: DropStatistics | undefined;
};

interface WhitelistSaleLine {
  walletAddress: string;
  mintQty: number;
  mintDate: string;
  totalAmountPaid: number;
}

function WhitelistSaleStatus(params: WhitelistSaleStatusProps) {
  const { dropId, drop } = params;
  const { data: nfts, isLoading } = useFetchNFTMintedForDrop(dropId);
  const [whitelistSaleList, setWhitelistSaleList] = useState<
    WhitelistSaleLine[]
  >([]);

  useEffect(() => {
    if (nfts && drop) {
      const whitelistSaleHistories: WhitelistSaleLine[] = [];
      nfts.forEach((nft) => {
        const dropPreSaleStart = Math.floor(
          new Date(drop.presaleStart).getTime() / 1000,
        );
        const dropPreSaleEnd = Math.floor(
          new Date(drop.presaleEnd).getTime() / 1000,
        );

        if (
          +nft.generatedAt >= dropPreSaleStart &&
          +nft.generatedAt <= dropPreSaleEnd
        ) {
          const existedLine = whitelistSaleHistories.find(
            (item) => item.walletAddress === nft.recipientWalletAddress,
          );

          if (existedLine) {
            existedLine.mintQty += 1;
            existedLine.totalAmountPaid += nft.paidAmount;
          } else {
            whitelistSaleHistories.push({
              walletAddress: nft.recipientWalletAddress || '',
              mintQty: 1,
              mintDate: formatDateTime(
                new Date(Number(nft.generatedAt) * 1000),
              ),
              totalAmountPaid: nft.paidAmount,
            });
          }
        }
      });
      setWhitelistSaleList(whitelistSaleHistories);
    }
  }, [nfts, drop]);

  const whitelistSaleListColumns: ColumnsType<WhitelistSaleLine> = [
    {
      title: 'Wallet Address',
      dataIndex: ['walletAddress'],
      key: 'walletAddress',
    },
    {
      title: 'MINT quantity',
      dataIndex: ['mintQty'],
      key: 'mintQty',
    },

    {
      title: 'MINT date',
      dataIndex: 'mintDate',
      key: 'mintDate',
    },
    {
      title: 'Total amount paid',
      dataIndex: 'totalAmountPaid',
      key: 'totalAmountPaid',
    },
    {
      title: 'Action',
      render: () => (
        <Button type="default" onClick={() => {}}>
          View
        </Button>
      ),
    },
  ];

  return (
    <ProCard title="WHITELIST SALE STATS" split="horizontal" bordered>
      <ProCard>
        <ProDescriptions
          column={1}
          emptyText="-"
          dataSource={params}
          columns={[
            {
              title: 'Presale start',
              dataIndex: ['drop', 'presaleStart'],
              valueType: 'dateTime',
            },
            {
              title: 'Presale end',
              dataIndex: ['drop', 'presaleEnd'],
              valueType: 'dateTime',
            },
            {
              title: 'Number of WL spots given',
              dataIndex: ['dropStatistics', 'totalWhitelistEligibleUsers'],
            },
            {
              title: 'Number of users who claimed the WL spot',
              dataIndex: ['dropStatistics', 'totalWhitelistClaimedUsers'],
            },
            {
              title: 'Number of NFTs minted in WL sale',
              dataIndex: ['dropStatistics', 'totalNFTMinted'],
            },
          ]}
        />
      </ProCard>
      <Table
        dataSource={whitelistSaleList}
        columns={whitelistSaleListColumns}
        rowKey="id"
        loading={isLoading}
      />
    </ProCard>
  );
}

export default WhitelistSaleStatus;
