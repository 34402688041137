import { read, WorkBook } from 'xlsx';

export default async function readFile(file: Blob): Promise<WorkBook | undefined> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (evt) => {
      if (!evt) resolve(undefined);
      if (!evt.target) resolve(undefined);
      const data = evt?.target?.result;
      const workbook = read(data);
      resolve(workbook);
    };

    reader.onerror = () => {
      reject(new Error('error reading file'));
    };
  });
}
