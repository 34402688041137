import { Button, Form, Input } from 'antd';
import { ReactElement } from 'react';
import { useFetchActiveDrop } from 'src/hooks/api';

export interface NumberToAirdropProps {
  onFinish(): Promise<void>;
  setAirdropQuantity(airdropQuantity: number): void;
}

export interface NumberToAirdropFormData {
  numberToAirdrop: number;
}

function NumberToAirdrop(props: NumberToAirdropProps): ReactElement {
  const { onFinish, setAirdropQuantity } = props;
  const [form] = Form.useForm();

  const { data: activeDrop } = useFetchActiveDrop();

  const onSubmit = async () => {
    await onFinish();
    form.resetFields();
  };

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const changedNumber = parseInt(value, 10);

    if (changedNumber <= 0) {
      return;
    }

    setAirdropQuantity(changedNumber);
  };

  const renderHelp = () =>
    activeDrop?.maxAirdropQty && activeDrop.nfts
      ? `Max airdrop quantity ${activeDrop?.maxAirdropQty} and the NFTs remaining: ${
          activeDrop.maxAirdropQty - activeDrop.nfts.length
        }`
      : `Total airdrop quantity ${activeDrop?.qty} and the NFTs remaining: ${
          activeDrop?.qty &&
          activeDrop?.nfts &&
          activeDrop.qty - activeDrop.nfts.length
        }`;
  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <Form.Item
        label="Enter number of NFTs to airdrop"
        name="numberToAirdrop"
        rules={[
          {
            required: true,
            message: 'Please enter number NFTs to airdrop',
          },
        ]}
        help={renderHelp()}
      >
        <Input
          placeholder="Enter number NFTs to airdrop"
          onChange={onQuantityChange}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default NumberToAirdrop;
