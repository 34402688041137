import {
  Button, Card, Col, Input, message, Radio, RadioChangeEvent, Row, Space, Switch, Table,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  ReactElement, useMemo, useState,
} from 'react';
import {
  addEligibleUsers,
  FilterAdvancedUser,
  useFetchAdvancedFilterUsers,
  UseFetchUserParams,
} from 'src/hooks/api';
import { User } from 'src/types';
import { createRequester } from 'src/hooks/useApi';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { useAppDispatch } from 'src/store/dispatch';
import UserFilter, { FormDataFilter } from 'src/components/UserFilter';
import { useFetchAllegiances } from 'src/hooks/api/allegiance';
import BasicModal from 'src/components/Modal';
import { TypeEligibleUser } from 'src/hooks/api/typing';
import { useFetchTags } from 'src/hooks/api/tag';

function WhitelistUsers(): ReactElement {
  const [search, setSearch] = useState<string | undefined>();

  const dispatch = useAppDispatch();
  const requester = createRequester({ dispatch });

  const {
    pagination,
    sorters,
    handleParamsChange,
  } = useTableHelper<User>();
  const { mapTableParams } = useMapTableParam();

  const [filter, setFilter] = useState < FilterAdvancedUser | null >(null);
  const [openFilter, setOpenFilter] = useState(false);

  // Server states
  const params: UseFetchUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      ...filter,
      search,
    };
  }, [mapTableParams, pagination, sorters, search, filter]);
  const { data: usersData, isLoading, refetch } = useFetchAdvancedFilterUsers({ ...params });
  const dataAllegianceSource = useFetchAllegiances();
  const dataTagSource = useFetchTags();

  const [selectUsers, setSelectUsers] = useState<User[]>([]);
  const [selectedUserKeys, setSelectedUserKeys] = useState<React.Key[]>([]);
  const [burnXp, setBurnXp] = useState<number>(0);
  const [modalXpWillBurn, setModalXpWillBurn] = useState<boolean>(false);

  const onChange = (e: RadioChangeEvent) => {
    setBurnXp(e.target.value);
  };

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Add eligible users success',
      duration: 1,
    });
  };

  const onSubmitEligiblesUsers = async () => {
    const userIds: number[] = [];

    selectUsers.forEach((user: User) => userIds.push(user.id));

    await addEligibleUsers({
      userIds,
      requester,
      type: burnXp === 0 ? TypeEligibleUser.normal : TypeEligibleUser.claimFree,
    });

    success();
    refetch();

    setSelectUsers([]);
    setSelectedUserKeys([]);
    setBurnXp(0);
    setModalXpWillBurn(false);
  };

  const onClickAddEligibleUsers = () => {
    setModalXpWillBurn(true);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
  };

  const handleSubmitFilter = (data: FormDataFilter) => {
    setFilter({
      isAirdropped: data.options?.includes('isAirdropped'),
      isOg: data.options?.includes('isOg'),
      isWhitelisted: data.options?.includes('isWhitelisted'),
      startCreatedAt: data.createdAt ? data.createdAt[0] : null,
      endCreatedAt: data.createdAt ? data.createdAt[1] : null,
      allegiance: data.allegiance,
    });
  };

  const handleResetFilter = () => {
    setFilter(null);
  };

  const renderSearchAndUserFilter = () => (
    <Row>
      <Space size="small">
        <Col>
          <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <UserFilter
            onOpen={handleOpenFilter}
            open={openFilter}
            onCancel={handleCancelFilter}
            onSubmit={(data: FormDataFilter) => handleSubmitFilter(data)}
            allegiances={dataAllegianceSource?.data?.items || []}
            tags={dataTagSource?.data?.items || []}
            onReset={handleResetFilter}
          />
        </Col>
      </Space>
    </Row>
  );

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedUserKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: User[]) => {
      setSelectedUserKeys(selectedRowKeys);
      setSelectUsers(selectedRows);
    },
    getCheckboxProps: (record: User) => ({
      name: record.username || 'Untitled',
    }),
  };

  const onSortCreatedAt = (userA: User, userB: User) => {
    const createdAtA = Math.floor(new Date(userA.createdAt).getTime() / 1000);
    const createdAtB = Math.floor(new Date(userB.createdAt).getTime() / 1000);

    return createdAtA - createdAtB;
  };

  const renderColumsn = (): ColumnsType<User> => [
    {
      title: 'Wallet address',
      dataIndex: ['custodialWalletAddress'],
      render: (_, record) => record?.walletAddress || record?.custodialWalletAddress,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Whitelist Eligible',
      dataIndex: 'isWhitelistEligible',
      render: (value) => <Switch checked={value} disabled />,
    },
    {
      title: 'Whitelist Claimed',
      dataIndex: 'isWhitelistClaimed',
      render: (value) => <Switch checked={value} disabled />,
    },
    {
      title: 'XP',
      dataIndex: 'mXp',
      sorter: (userA, userB) => userA.xp! - userB.xp!,
    },
    {
      title: 'OG',
      dataIndex: 'isOg',
      render: (value) => <Switch checked={value} />,
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      render: (value) => (new Date(value)).toLocaleString(),
      sorter: (userA, userB) => onSortCreatedAt(userA, userB),
    },
  ];

  return (
    <>
      <Space direction="vertical" size="large" style={{ display: 'flex' }} className="dropContainer">
        {contextHolder}
        <Button
          type="primary"
          onClick={onClickAddEligibleUsers}
          disabled={selectUsers.length === 0}
        >
          Add eligible users
        </Button>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Card title="Users" extra={renderSearchAndUserFilter()}>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                columns={renderColumsn()}
                dataSource={usersData?.items}
                loading={isLoading}
                rowKey="id"
                pagination={pagination}
                onChange={handleParamsChange}
              />
            </Card>
          </Col>
        </Row>
      </Space>
      <BasicModal
        title="Xps will burn or not?"
        open={modalXpWillBurn}
        onClose={(): void => {
          setModalXpWillBurn(false);
        }}
      >
        <Space direction="vertical">
          <Radio.Group onChange={onChange} value={burnXp}>
            <Radio value={0}>Yes</Radio>
            <Radio value={1}>No</Radio>
          </Radio.Group>
          <Button
            type="primary"
            onClick={onSubmitEligiblesUsers}
          >
            Submit
          </Button>
        </Space>
      </BasicModal>
    </>
  );
}

export default WhitelistUsers;
