import { useMutation } from '@tanstack/react-query';
import { AxiosResponse, Method } from 'axios';

import useApi from '../useApi';

export type UsePostOptions<RequestData> = {
  url: string;
  method?: Method;
  data?: RequestData;
  onSuccess?: () => void;
};

export default function usePost<ResponseData, RequestData>(options: UsePostOptions<RequestData>) {
  const { requester } = useApi();
  return useMutation(
    async () => {
      const { url, data, method } = options;

      const response = await requester.request<ResponseData, AxiosResponse<ResponseData>, RequestData>({
        url,
        method,
        data,
      });
      return response.data;
    },
    {
      onSuccess: options.onSuccess,
    },
  );
}
