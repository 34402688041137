import { useMutation } from '@tanstack/react-query';
import useApi from '../useApi';

export type UploadImageResponse = {
  url: string;
};

export const useUploadImage = () => {
  const { requester } = useApi();
  return useMutation(async (file: FormData) => requester.post<UploadImageResponse>('/upload', file));
};

export type UploadImageV2Response = {
  key: string;
};

export const useUploadImageV2 = () => {
  const { requester } = useApi();

  return useMutation(
    async (file: FormData) => requester.post<UploadImageResponse>(
      '/files',
      file,
    ),
  );
};

export default useUploadImage;
