import { useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button, Card, Col, Form, Input, Row, theme,
} from 'antd';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'src/store/dispatch';
import { selectIsSignedIn } from 'src/store/selectors';
import { signIn } from 'src/store/actions';

export type SignInFormData = {
  username: string;
  password: string;
};

export default function SignIn() {
  const dispatch = useAppDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [form] = Form.useForm<SignInFormData>();

  const isSignedIn = useSelector(selectIsSignedIn);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const handleSubmit = async (data: SignInFormData) => {
    setIsSigningIn(true);
    await dispatch(signIn({
      username: data.username,
      password: data.password,
    }));
    setIsSigningIn(false);
  };

  if (isSignedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <Row
      style={{
        height: '100vh',
        background: colorBgContainer,
      }}
      justify="center"
      align="middle"
    >
      <Col md={6} sm={12} xs={20}>
        <Card title="Sign in">
          <Form
            name="login"
            form={form}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username',
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Username" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSigningIn}>
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
