import { Button, Col, Row, Form, Select, Space } from 'antd';

import { useMemo, useState } from 'react';
import Web3Panel from 'src/components/Web3Panel';
import { useFetchCollections } from 'src/hooks/api';
import { Collection } from 'src/hooks/api/typing';
import useWeb3 from 'src/hooks/useWeb3';
import useWithdrawTotaNFTContract from 'src/hooks/useWithdrawTotaNFTContract';

interface FormValues {
  collectionId: number;
  // baseURI: string;
}

export default function CollectionManager() {
  const [form] = Form.useForm<FormValues>();

  const { isConnected, chain } = useWeb3();
  const { data: collections } = useFetchCollections();

  const deployedCollections =
    collections?.filter(
      (collection: Collection) => collection.contractAddress !== null,
    ) || [];

  const [selectedCollection, setSelectedCollection] =
    useState<Collection | null>(null);

  const {
    withdraw,
    contractBalance,
    contextHolder: withdrawContextHolder,
    isLoading,
  } = useWithdrawTotaNFTContract(selectedCollection?.contractAddress);

  const shouldShowForm = useMemo(() => {
    if (!isConnected) return false;
    if (chain?.id.toString() !== process.env.REACT_APP_CHAIN_ID) return false;

    return true;
  }, [isConnected, chain]);

  const handleWithdraw = () => {
    if (!withdraw) return;
    withdraw();
  };

  const handleFormChange = (changedValues: FormValues) => {
    if (!changedValues.collectionId || !collections) {
      return false;
    }

    const collection = collections.find(
      (clt: Collection) => clt.id === Number(changedValues.collectionId),
    );
    if (!collection) return false;

    setSelectedCollection(collection);
    return true;
  };

  return (
    <Row gutter={[0, 16]}>
      {withdrawContextHolder}
      <Col span={24}>
        <Col span={12}>
          <Web3Panel />
        </Col>
      </Col>
      {shouldShowForm === true && (
        <>
          <Col span={24}>
            <Col span={12}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={handleFormChange}
              >
                <Col span={24}>
                  <Form.Item
                    label="Collection"
                    name="collectionId"
                    rules={[
                      {
                        required: true,
                        message: 'Please select collection!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      options={deployedCollections.map(
                        (collection: Collection) => ({
                          value: collection.id,
                          label: collection.name,
                        }),
                      )}
                    />
                  </Form.Item>
                </Col>
              </Form>
            </Col>
          </Col>
          {/* {selectedCollection ? (
            <CommissionPanel collection={selectedCollection} />
          ) : null} */}
          <Col span={24}>
            <Space direction="vertical" />
          </Col>
          <Col span={24}>
            <Space direction="vertical">
              <p>Balance: {contractBalance?.formatted} ETH</p>
              <Button
                type="primary"
                loading={isLoading}
                onClick={handleWithdraw}
                disabled={
                  !selectedCollection ||
                  Number(contractBalance?.formatted) === 0 ||
                  isLoading
                }
              >
                Withdraw
              </Button>
            </Space>
          </Col>
        </>
      )}
    </Row>
  );
}
