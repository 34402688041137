import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppInitState = {
  isSiderCollapse: boolean;
};
const APP_INIT_STATE: AppInitState = {
  isSiderCollapse: false,
};

const appSlice = createSlice({
  name: 'auth',
  initialState: APP_INIT_STATE,
  reducers: {
    setSiderCollapse(state, action: PayloadAction<boolean>) {
      state.isSiderCollapse = action.payload;
    },
  },
});

export const {
  setSiderCollapse,
} = appSlice.actions;
const appReducer = appSlice.reducer;
export default appReducer;
