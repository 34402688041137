import { useCallback } from 'react';

const useGetFileByKey = () => {
  const getFileByKey = useCallback((key: string) => {
    const url = new URL(`v2.0/files/${key}`, process.env.REACT_APP_API_URL);
    return url.toString();
  }, []);

  return { getFileByKey };
};

export default useGetFileByKey;
