export type TagUser = {
  id: string;
  createdAt: Date;
};

export type EmailConfig = {
  banner: string;
  content: string[];
};

export type SyncWindow = {
  startTime: string;
  endTime: string;
};

export type TagCollection = {
  collectionId: string;
  isRemoveOnTransfer: boolean;
  syncWindow: SyncWindow;
};

export enum TagStatus {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

export type Tag = {
  id: number;
  name?: string;
  icon?: string;
  notificationIcon?: string;
  amountUser?: number;
  emailConfig: EmailConfig;
  collection?: TagCollection;
  status?: TagStatus;
  createdAt?: Date;
  updatedAt?: Date;
};

export type UserTag = Tag & {
  count: number;
};

export type TagUserDetail = {
  id: number;
  username: string;
  email: string;
  userId: string;
  tag: Tag;
};
