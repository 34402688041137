/* eslint-disable */
import { ethers } from 'ethers';

export const contractInstance = async (
  contractAddress: string,
  privateSaleABI: any[],
) => {
  try {
    const { ethereum }: any = window;
    console.log(ethereum);
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    if (accounts.length) {
      const account = accounts[0];
      console.log('Found an authorized account: ', account);
    } else {
      console.log('No authorized account found');
    }

    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      return new ethers.Contract(contractAddress, privateSaleABI, signer);
    } else {
      console.log('Ethereum object does not exist');
    }
  } catch (err) {
    console.log(err);
  }
};
