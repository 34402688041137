import axios from 'axios';
import store from 'src/store';
import { AppDispatch, useAppDispatch } from 'src/store/dispatch';
import { signOut } from 'src/store/slices/auth-slice';

export type CreateRequesterOptions = {
  apiVersion?: string;
  dispatch: AppDispatch;
};

export const createRequester = (options: CreateRequesterOptions) => {
  const { apiVersion = 'v2.0' } = options;
  const url = new URL(apiVersion, process.env.REACT_APP_API_URL);
  const requester = axios.create({ baseURL: url.toString() });
  requester.interceptors.request.use(
    (config) => {
      const { accessToken } = store.getState().auth;

      if (accessToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
  );
  requester.interceptors.response.use(
    (response) => response,
    (error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          options.dispatch(signOut());
        }
      }

      return Promise.reject(error);
    },
  );
  return requester;
};

export type UseApiProps = {
  apiVersion?: string;
};

export default function useApi(props: UseApiProps = {}) {
  const { apiVersion } = props;
  const dispatch = useAppDispatch();
  const requester = createRequester({
    apiVersion,
    dispatch,
  });
  return { requester };
}
