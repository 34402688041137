import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TabsProps,
  notification,
} from 'antd';
import { ButtonType } from 'antd/es/button';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import AllegianceSubmissionDetail from 'src/components/SubmissionDetail/AllegianceSubmissionDetail';
import UserFilter, { FormDataFilter } from 'src/components/UserFilter';
import {
  FilterAllegianceSubmission,
  UseFetchAllegianceSubmissionsParams,
  useFetchAllegianceSubmissions,
  useUpdateAllegianceSubmissionStatus,
} from 'src/hooks/api/allegiance-submission';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { AllegianceSubmission, SubmissionStatus } from 'src/types';
import { ErrorDataResponse } from 'src/typing';
import { useFetchTags } from 'src/hooks/api/tag';
import { filterProofTypes } from './Activity';

const dateFormat = 'DD-MM-YYYY';
const { RangePicker } = DatePicker;
const { Option } = Select;
interface FilterDropdownProps {
  confirm: () => void;
  clearFilters?: () => void;
}

const formatDate = (date: string) => dayjs(date).format('DD-MM-YYYY');

type FormDataFilterExtra = FormDataFilter & {
  proofType?: string[];
};

type ChildrenTabProps = {
  status?: SubmissionStatus;
};

function ChildrenTab(props: ChildrenTabProps) {
  const { status } = props;
  // Hooks
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<AllegianceSubmission>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [filterDate, setFilterDate] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [view, setView] = useState<boolean>(false);
  const [allegianceSubmission, setAllegianceSubmission] =
    useState<AllegianceSubmission | null>(null);
  const [filter, setFilter] = useState<FilterAllegianceSubmission | null>(null);
  const [openFilter, setOpenFilter] = useState(false);
  // Server states
  const params: UseFetchAllegianceSubmissionsParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    const results: UseFetchAllegianceSubmissionsParams = {
      ...baseParams,
      ...filter,
      search,
      status,
    };

    if (filterDate[0] && filterDate[1]) {
      results.startSubmittedDate = filterDate[0].toDate();
      results.endSubmittedDate = filterDate[1].toDate();
    }

    return results;
  }, [mapTableParams, pagination, sorters, search, status, filterDate, filter]);
  const dataSource = useFetchAllegianceSubmissions(params);
  const dataTagSource = useFetchTags();

  const { mutateAsync: updateStatusAllegianceSubmission } =
    useUpdateAllegianceSubmissionStatus();

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleClickView = (record: AllegianceSubmission) => {
    setAllegianceSubmission(record);
    setView(true);
  };

  const handleClickClose = () => {
    setView(false);
  };

  const handleUpdateStatus = async (
    id: number,
    updateStatus: string,
    note?: string,
  ) => {
    try {
      await updateStatusAllegianceSubmission({
        id,
        status: updateStatus,
        note,
      });
      setView(false);

      notification.success({
        message: 'Allegiance submission updated successfully',
      });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: ` Allegiance submission create failed: ${errorMessage}`,
      });
    }
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
  };

  const handleSubmitFilter = (data: FormDataFilterExtra) => {
    setFilter({
      startCreatedAt: data.createdAt ? data.createdAt[0] : null,
      endCreatedAt: data.createdAt ? data.createdAt[1] : null,
      proofType: data.proofType,
    });
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    setFilter(null);
    setOpenFilter(false);
  };

  const renderSearchAndUserFilter = () => (
    <Row>
      <Space size="small">
        <Col>
          <Input.Search
            allowClear
            placeholder="Search"
            onSearch={handleSearch}
          />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <UserFilter
            onOpen={handleOpenFilter}
            open={openFilter}
            onCancel={handleCancelFilter}
            onSubmit={(data: FormDataFilter) => handleSubmitFilter(data)}
            onReset={handleResetFilter}
            isOptions={false}
            isAllegiance={false}
            tags={dataTagSource?.data?.items || []}
          >
            <Form.Item label="Proof type" name="proofType">
              <Select mode="multiple" allowClear>
                {filterProofTypes.map((type) => (
                  <Option key={type.value} value={type.value}>
                    {type?.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </UserFilter>
        </Col>
      </Space>
    </Row>
  );

  const renderFilterDropdown = ({
    confirm,
    clearFilters,
  }: FilterDropdownProps) => (
    <div className="ant-table-filter-dropdown">
      <div style={{ padding: 4 }}>
        <RangePicker
          allowClear={false}
          format={dateFormat}
          onChange={(value) => {
            if (value) {
              setFilterDate([value[0] as Dayjs, value[1] as Dayjs]);
              confirm();
            }
          }}
          value={filterDate}
        />
      </div>
      <div aria-hidden="true" style={{ display: 'none' }} />
      <div className="ant-table-filter-dropdown-btns">
        <Button
          className="ant-btn css-dev-only-do-not-override-ar9dpx ant-btn-link ant-btn-sm"
          onClick={() => {
            if (clearFilters) {
              clearFilters();
              setFilterDate([null, null]);
            }
          }}
          type="link"
          disabled={!filterDate[0] && !filterDate[1]}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  const renderStatusDropdown = (record: AllegianceSubmission) => {
    const statusMap: {
      [key in SubmissionStatus]: {
        type?: ButtonType;
        danger?: boolean;
        text?: string;
      };
    } = {
      [SubmissionStatus.Partaken]: {
        type: 'default',
        text: 'Partaken',
      },
      [SubmissionStatus.Rejected]: {
        danger: true,
        text: 'Rejected',
      },
      [SubmissionStatus.Accepted]: {
        type: 'primary',
        text: 'Approved',
      },
      [SubmissionStatus.Pending]: {
        type: 'default',
        text: 'Pending',
      },
    };

    const { type, danger, text } = statusMap[record.status];

    return (
      <Button type={type || 'default'} danger={danger}>
        <Space>{text}</Space>
      </Button>
    );
  };

  const renderColumns = (): ColumnsType<AllegianceSubmission> => [
    {
      title: 'Username',
      dataIndex: ['user', 'username'],
      width: 110,
    },
    {
      title: 'Allegiance',
      dataIndex: ['allegiance', 'title'],
    },
    {
      title: 'XP',
      dataIndex: ['allegiance', 'xpAward'],
      width: 100,
      responsive: ['xl'],
    },
    {
      title: 'Submitted date',
      dataIndex: 'submittedDate',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filterDropdown: ({ confirm, clearFilters }) =>
        renderFilterDropdown({
          confirm,
          clearFilters,
        }),
      width: 180,
      render: (value) => (value ? formatDate(value) : 'N/A'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      width: 180,
      render: (value) => (value ? formatDate(value) : 'N/A'),
    },
    {
      title: 'Approver',
      dataIndex: ['admin', 'username'],
      width: 50,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '1%',
      render: (_, record) => renderStatusDropdown(record),
      responsive: ['xl'],
    },
    {
      title: '',
      key: 'actions',
      width: '1%',
      render: (_, record) => (
        <Button onClick={() => handleClickView(record)}>View</Button>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            title="Allegiance submission"
            extra={renderSearchAndUserFilter()}
          >
            <Table
              columns={renderColumns()}
              dataSource={dataSource.data?.items}
              loading={dataSource.isLoading}
              pagination={pagination}
              onChange={handleParamsChange}
            />
          </Card>
        </Col>
      </Row>
      <AllegianceSubmissionDetail
        allegianceSubmission={allegianceSubmission}
        open={view}
        onClose={handleClickClose}
        onUpdateStatus={handleUpdateStatus}
      />
    </>
  );
}

const tabs: TabsProps['items'] = [
  {
    key: '1',
    label: 'ALL SUBMISSIONS',
    children: <ChildrenTab key="all" />,
  },
  {
    key: '2',
    label: 'PENDING',
    children: <ChildrenTab key="pending" status={SubmissionStatus.Pending} />,
  },
  {
    key: '3',
    label: 'APPROVED',
    children: <ChildrenTab key="accepted" status={SubmissionStatus.Accepted} />,
  },
  {
    key: '4',
    label: 'REJECTED',
    children: <ChildrenTab key="denied" status={SubmissionStatus.Rejected} />,
  },
];

export default function AllegianceSubmissionsPage() {
  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Tabs defaultActiveKey="1" items={tabs} />
      </Col>
    </Row>
  );
}
