import { Modal } from 'antd';
import parse from 'html-react-parser';

export type PreviewContentProps = {
  htmlContent: string;
  open: boolean;
  onClose: () => void;
};

export default function PreviewContent(props: PreviewContentProps) {
  const { htmlContent, open, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      title="Preview content"
      open={open}
      footer={null}
      centered
      width={800}
      onCancel={handleClose}
    >
      {parse(htmlContent)}
    </Modal>
  );
}
