import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Allegiance } from 'src/types/Allegiance';
import useApi from '../useApi';
import {
  Page, PaginationParams, SearchParams, SortParams,
} from './typing';
import useFetch from './useFetch';
import { CreateSubmissionRequest, SubmissionTypeResponse } from './activity';

type CreateAllegianceRequest = {
  title: string;
  image: string;
  notificationImage: string;
  status: boolean;
  xpAward: number;
  isOg: boolean;
  submissionType: CreateSubmissionRequest;
};

type UpdateAllegianceRequest = Partial<CreateAllegianceRequest>;

type AllegianceTypeResponse = {
  id: number;
  title: string;
  image: string;
  notificationImage: string;
  status: boolean;
  isOg: boolean;
  xpAward: number;
  description: string;
  submissionType: SubmissionTypeResponse;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
};

export type UseFetchAllegianceParams = PaginationParams &
SortParams &
SearchParams;

const KEY_ALLEGIANCE_QUERIES = ['allegiances'];

export const useFetchAllegiances = (params?: UseFetchAllegianceParams) => useFetch<Page<AllegianceTypeResponse>, Page<Allegiance>>({
  key: [...KEY_ALLEGIANCE_QUERIES, params],
  url: 'admin/allegiances',
  searchParams: params,
  parseFn: (data) => {
    const items = data.items.map(
      (res): Allegiance => ({
        id: res.id,
        title: res.title,
        image: res.image,
        notificationImage: res.notificationImage,
        status: res.status,
        isOg: res.isOg,
        xpAward: res.xpAward,
        description: res.description,
        createdAt: new Date(res.createdAt),
        updatedAt: new Date(res.updatedAt),
        submissionType: {
          id: res.submissionType.id,
          proofLogo: res.submissionType.proofLogo,
          proofImage: res.submissionType.proofImage,
          proofType: res.submissionType.proofType,
          title: res.submissionType.title,
          text: res.submissionType.text,
          disclaimer: res.submissionType.disclaimer,
          isAutoDeleteSubmission: res.submissionType.isAutoDeleteSubmission,
          isRecurring: res.submissionType.isRecurring,
          createdAt: new Date(res.submissionType.createdAt),
          updatedAt:
              res.submissionType.updatedAt != null
                ? new Date(res.submissionType.updatedAt)
                : undefined,
          deletedAt:
              res.deletedAt != null ? new Date(res.deletedAt) : undefined,
        },
      }),
    );

    return {
      ...data,
      items,
    };
  },
});

export const useCreateAllegiance = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Allegiance) => {
      const payload: CreateAllegianceRequest = {
        title: data.title,
        image: data.image,
        notificationImage: data.notificationImage,
        status: data.status,
        xpAward: data.xpAward,
        isOg: data.isOg,
        submissionType: {
          proofType: data.submissionType.proofType,
          proofLogo: data.submissionType.proofLogo,
          proofImage: data.submissionType.proofImage,
          title: data.submissionType.title,
          text: data.submissionType.text,
          disclaimer: data.submissionType.disclaimer,
          isAutoDeleteSubmission: data.submissionType.isAutoDeleteSubmission,
          isRecurring: false,
        },
      };

      return requester.post<void, CreateAllegianceRequest>(
        '/admin/allegiances',
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_ALLEGIANCE_QUERIES });
      },
    },
  );
};

export const useUpdateAllegiance = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Allegiance) => {
      const payload: UpdateAllegianceRequest = {
        title: data.title,
        image: data.image,
        notificationImage: data.notificationImage,
        status: data.status,
        xpAward: data.xpAward,
        isOg: data.isOg,
        submissionType: {
          proofType: data.submissionType.proofType,
          proofLogo: data.submissionType.proofLogo,
          proofImage: data.submissionType.proofImage,
          title: data.submissionType.title,
          text: data.submissionType.text,
          disclaimer: data.submissionType.disclaimer,
          isAutoDeleteSubmission: data.submissionType.isAutoDeleteSubmission,
          isRecurring: false,
        },
      };

      await requester.patch<void, UpdateAllegianceRequest>(
        `/admin/allegiances/${data.id}`,
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_ALLEGIANCE_QUERIES });
      },
    },
  );
};

export const useDeleteAllegiance = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Allegiance) => {
      await requester.delete<void>(`/admin/allegiances/${data.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_ALLEGIANCE_QUERIES });
      },
    },
  );
};
