import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

type Props = {
  className?: string;
  children?: ReactNode;
  size?: 'small' | 'middle' | 'large';
  flex?: number;
};

export default function BodyColumn(props: Props) {
  return (
    <div
      {...props}
      className={classNames(styles.col, styles[props.size!], props.className)}
      style={{
        flex: props.flex,
      }}
    >
      {props.children}
    </div>
  );
}

BodyColumn.defaultProps = {
  size: 'middle',
  flex: 1,
};
