import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';
import styles from './style.module.scss';

type Props = ButtonProps;

export default function ButtonStyled(props: Props) {
  return (
    <Button
      block
      size="large"
      color="primary"
      {...props}
      className={classNames(styles.button, props.className)}
    />
  );
}
