import {
  Col, Layout, Row, theme,
} from 'antd';
import HeaderAvatar from './HeaderAvatar';
import styles from './Header.module.scss';

export default function Header() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout.Header
      className={styles.header}
      style={{ background: colorBgContainer }}
    >
      <Row justify="end">
        <Col flex="none">
          <HeaderAvatar />
        </Col>
      </Row>
    </Layout.Header>
  );
}
