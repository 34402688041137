import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';

export default function TermsAndConditions() {
  const navigate = useNavigate();

  return (
    <main className={styles.pageLayout}>
      <div className={styles.backIcon}>
        <button type="button" onClick={() => navigate(-1)}>
          <span className="mr-4">{'<<'}</span>
          Back
        </button>
      </div>
      <div className={styles.pageContent}>
        <h1>
          TERMS &amp; CONDITIONS
        </h1>
        <p className="mb-40">Last Updated: April 15,2023</p>
        <h2>APPLICABLE TERMS</h2>
        <p>
          Your use of the website is subject to these Terms &amp; Conditions,
          and the related (i)&nbsp;Privacy Policy&nbsp;(ii) Cookies Policy and
          (iii) Data Protection Notice (hereinafter collectively referred to as
          the “
          <strong>Website Agreement</strong>
          ”). Any other links or
          references provided in these terms are for informational use only and
          are not part of the Website Agreement. By using the website, you agree
          to this Website Agreement.
        </p>
        <p>
          <strong>
            PLEASE READ THESE TERMS &amp; CONDITIONS, THE PRIVACY POLICY, THE
            COOKIES POLICY, AND THE DATA PROTECTION NOTICE CAREFULLY BEFORE
            USING THIS WEBSITE
          </strong>
          . Do not use this website if you do not agree with any of the terms of
          this Website Agreement.
        </p>
        <h2>USE OF COOKIES (COLLECTION OF DATA)</h2>
        <p>
          Tota Pte. Ltd.’s (together with its affiliates and subsidiaries,
          {' '}
          <strong>“Tota”</strong>
          ) site (
          <strong>“Site”</strong>
          ) uses cookies
          to distinguish you from other users of our Site. Your use of any Site
          indicates your consent and unconditional agreement to be bound by
          these Terms &amp; Conditions and by any policies or practices
          contained herein (including but not limited to our Privacy Policy
          {' '}
          <strong>
            <a href="/privacy-policy">(www.planettota.com/privacy-policy)</a>
          </strong>
          , Cookies Policy
          . If you do not agree to be bound by these Terms &amp; Conditions, you
          may not access or otherwise use any of the Sites. Tota reserves the
          right to modify, alter or otherwise update these Terms And Conditions
          at any time, and visitors are encouraged to review these terms from
          time to time.
        </p>
        <h2>IP PROTECTION</h2>
        <p>
          The Site(s) and all of the content and information they contain, or
          may in the future contain (including, but not limited to, text,
          photos, images, illustrations, graphics, designs, audio clips, videos,
          broadcast schedules, guides, promotional contests, press releases,
          articles, plans, recipes, reviews and information as well as
          trademarks, service marks, trade names, trade dress, copyrights,
          logos, domain names, code, patents and/or any other form of
          intellectual property) are owned or licensed by Tota or other third
          parties, and are protected by copyright and other intellectual
          property laws. Any names, titles, logos and designs displayed on the
          Site(s) that contain Tota names, titles, logos, or brands are the
          exclusive property and trademarks of Tota or its partners.
        </p>
        <p>
          Except as expressly permitted in writing by the Site host, you are not
          allowed to:
        </p>
        <ul>
          <li>
            <p>
              modify, publish, capture, perform, transmit, transfer, sell,
              license, reproduce, re-post, create derivative works from or based
              upon, reverse engineer, upload, link, distribute, exploit or alter
              any such content
            </p>
          </li>
          <li>
            <p>
              circumvent, disable, fraudulently engage with, or otherwise
              interfere with any part of the website (or attempt to do any of
              these things), including security-related features or features
              that (a) prevent or restrict the copying or other use of Content
              or (b) limit the use of the website
            </p>
          </li>
          <li>
            <p>
              use the website to distribute unsolicited promotional or
              commercial content or other unwanted or mass solicitations
            </p>
          </li>
          <li>
            <p>
              misuse any reporting, flagging, complaint, dispute, or appeals
              process, including by making groundless, vexatious, or frivolous
              submissions
            </p>
          </li>
          <li>
            <p>
              use the website to view or listen to content other than for
              personal, non-commercial use (for example, you may not publicly
              screen videos or stream music from the website)
            </p>
          </li>
        </ul>
        <p>
          UNAUTHORIZED USE, COPYING, REPRODUCTION, MODIFICATION, PUBLICATION,
          REPUBLICATION, UPLOADING, FRAMING, DOWNLOADING, POSTING, TRANSMITTING,
          DISTRIBUTING, DUPLICATING OR ANY OTHER MISUSE OF ANY OF THE MATERIAL
          IS STRICTLY PROHIBITED.
        </p>
        <h2>RESERVATION</h2>
        <p>
          Using the website does not give you ownership of or rights to any
          aspect of the website.
        </p>
        <h2>DEVELOP, IMPROVE AND UPDATE THE SERVICE</h2>
        <p>
          Tota is constantly changing and improving the website. As part of this
          continual evolution, we may make modifications or changes (to all or
          part of the website) such as adding or removing features and
          functionalities, offering new digital content or services or
          discontinuing old ones. We may also need to alter or discontinue the
          website, or any part of it, in order to make performance or security
          improvements, make changes to comply with law, or prevent illegal
          activities on or abuse of our systems. These changes may affect all
          users, some users or even an individual user. When the website
          requires or includes downloadable software, that software may update
          automatically on your device once a new version or feature is
          available, subject to your device settings.
        </p>
        <h2>DISCLAIMER</h2>
        <h3>Indemnity</h3>
        <h3>Damage from Site</h3>
        <p>
          You are visiting the Site(s) at your own risk. Tota, its subsidiaries,
          affiliates, successors, assigns, agents, officers, directors,
          employees, representatives, licensors or licensees shall not be
          responsible for any damages of any nature arising out of or relating
          to the use of the Site (s) or any hyperlinked web site(s).
        </p>
        <h3>Availability of/Access to Site</h3>
        <p>
          The Site(s) may be unavailable from time to time due to maintenance or
          malfunction of computer equipment or for various other reasons. Tota
          assumes no responsibility for any inability to access the Site(s) nor
          for any delays, interruptions, errors, defects, omissions or deletions
          users may experience. Further, Tota does not warrant that the
          availability of the Site(s) or the functions or materials contained
          therein, or the third-party hyperlinked websites will be uninterrupted
          or error-free, that defects will be corrected, or that the Site(s) or
          the serve that makes them available are free of viruses or other
          potentially harmful components.
        </p>
        <h3>Accuracy of Information</h3>
        <p>
          The material that appears on the Site(s) is for informational purposes
          only. Though it is our intention to provide accurate and useful
          information, we acknowledge that errors may appear from time to time.
          Tota, its information providers and any third parties who may have
          contributed to the Site(s) make no warranty as to the reliability,
          accuracy, timeliness, usefulness or completeness of the information on
          the Site(s). Before you make any decision or act on any information
          you&apos;ve found on the Site(s), you should confirm any facts that are
          important in your decision or planned actions.
        </p>
        <p>
          THE SITE(S) AND THE MATERIALS INCLUDED THEREON (INCLUDING ANY
          HYPERLINKED THIRD-PARTY WEBSITES) ARE PROVIDED “AS IS” “AS AVAILABLE”
          AND “WITH ALL FAULTS” AND WITHOUT WARRANTIES OR REPRESENTATIONS OF ANY
          KIND EITHER EXPRESS OR IMPLIED INCLUDING, WITHOUT LIMITATION, IMPLIED
          WARRANTIES OF TITLE, TIMELINESS, MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, NON-INFRINGEMENT OR THOSE ARISING BY STATUTE OR
          OTHERWISE IN LAW FROM A COURSE OF DEALING OR USAGE OF TRADE.
        </p>
        <h2>LIMITATION OF LIABILITY AND INDEMNITY</h2>
        <p>
          You agree that Tota and its parents, affiliates, subsidiaries,
          licensors, successors, assigns and agents and each of their respective
          officers, directors, employees, representatives and licensors (the
          “Released Parties”) are not liable to you or any third person for
          damages of any kind arising you’re your use of the Site(s). YOU
          UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT
          ARE UNKNOWN OR UNSUSPECTED AT THIS TIME. By visiting the Site(s) you
          agree to indemnify the Released Parties from and against any and all
          legal claims, actions, suits or proceedings, as well as any and all
          losses, liabilities damages, costs and expenses (including reasonable
          legal fees) arising out of: (i) any misrepresentation, act or omission
          made by you in connection with your use of the Site(s); (ii) any
          non-compliance by you of these Terms of Use; (iii) any breach by you
          of your warranties hereunder; and/or (iv) any claims brought by third
          parties arising from or related to your access or use of the Site(s).
        </p>
        <h2>LACK OF ENDORSEMENT</h2>
        <p>
          Any opinion, view or idea contained in any article, story, column or
          other material published on the Site(s) is the author&apos;s own and does
          not reflect or represent the views of Tota or any of its parents,
          affiliates, subsidiaries, licensors, successors, assigns, partners,
          licensees and agents and each of their respective officers, directors,
          employees, representatives and licensees. Reference to any product,
          process, publication or service of any third party by trade name,
          domain name, trademark, service mark, logo, manufacturer or otherwise
          does not constitute or imply its endorsement or recommendation by Tota
          or any of the Released Parties. Users are responsible for seeking the
          advice of professionals, as appropriate, regarding the information,
          opinions, advice or content available as part of the Site(s). Tota is
          not responsible for, and cannot guarantee the performance of, goods
          and services provided by our advertisers or others to whose sites we
          link. A link to another website does not constitute an endorsement of
          that site (nor of any product, service or other material offered on
          that site) by Tota or any of the Released Parties. Additionally, Tota
          does not represent, warrant or endorse any product, information or
          material that is displayed, purchased or obtained by you as a result
          of an advertisement or any other information or offer displayed on any
          of the Sites or any hyperlinked websites.
        </p>
        <h2>JURISDICTION</h2>
        <p>
          This site is controlled and operated by Tota Pte. Ltd., from its
          offices based in Vietnam and Singapore. Tota makes no representation
          that materials in the site are appropriate or available for use in
          other locations. Those who choose to access this site from other
          locations do so on their own initiative and are responsible for
          compliance with local laws, if and to the extent local laws are
          applicable.
        </p>
        <h2>SUBMISSIONS (INCLUDING UNSOLICITED SUBMISSIONS)</h2>
        <p>
          Tota is pleased to hear from its customers and users and welcomes
          input with respect to our films, television shows, content, website,
          metaverse, comics, games, merchandise, NFTs, Collectibles and other
          products.&nbsp; That being said, we require that you limit your input
          to those existing properties and do not submit any creative ideas,
          suggestions or materials which you deem to be confidential.
        </p>
        <p>
          While Tota, through its various community specific activations, may
          provide the opportunity to communicate content or materials to Tota,
          including without limitation photographs, writings, music, video
          and/or audio recordings, computer graphics, artwork, data, questions,
          comments, suggestions, or personally identifiable information
          (&lsquo;Submissions&rsquo;), Tota does not accept unsolicited Submissions
          (including ideas for motion pictures, television programs, productions
          of any kind, web sites, mobile apps, games, merchandise, NFTs, books,
          or any other products, services or ideas). We strive to avoid the
          possibility of future misunderstandings when Tota (or our partners)
          develop projects (whether internally or by third parties) which might
          seem to others to be similar to their own creative ideas or materials.
          With that in mind, please do not make any such Submissions to Tota
          through our Site(s).
        </p>
        <p>
          However, if you decide to make any such unsolicited Submission, you
          warrant that any Submission made by you: (i) is your own original work
          and you have the right to make it available to Tota for the purposes
          specified above; (ii) does not infringe the rights of any third party;
          and (iii) is not unlawful and does not contain any material which is
          obscene, offensive, hateful or inflammatory, and hereby grant to Tota
          the full, unconditional, unlimited irrevocable, worldwide right and
          license to your Submission as submitted, including the ability to use
          your Submission and distribute, sell, modify, adapt, translate,
          incorporate into other works (and otherwise create derivative works
          from), transmit, broadcast and otherwise exploit your Submission in
          any manner and for any purpose whatsoever without any notice or
          compensation to you. Additionally, you consent to all relevant acts or
          omissions in relation to your moral rights in such Submission (if any)
          which may or might otherwise constitute a breach or infringement of
          those rights, and you (to the extent permitted by law) waive all your
          moral rights in such Submission, even if such material is altered or
          changed in a manner not agreeable to you.
        </p>
        <h2>CHILDREN AND YOUNG PERSONS</h2>
        <p>
          The Site(s) are aimed at users of various ages. There are however no
          age barriers to those accessing the Site(s).
        </p>
        <p>
          Users under 18 should only use the Site(s) with the permission of a
          parent or guardian. The parents or guardians of anyone under 18 using
          the Site(s) should review these Terms &amp; Conditions carefully.
          Contributions submitted and/or read by minors are subject to the
          consent of their parent or guardian.
        </p>
        <p>
          If you are a parent or legal guardian of a minor in your country, by
          allowing your child to use the website, you are subject to the terms
          of this Website Agreement and responsible for your child’s activity on
          the website.
        </p>
        <p>
          We advise parents or guardians who permit minors to use the Site(s)
          that it is important to communicate with the minors about their safety
          online.
        </p>
        <h2>BUSINESSES</h2>
        <p>
          If you are using the website on behalf of a company or organisation,
          you represent that you have authority to act on behalf of that entity,
          and that such entity accepts this Website Agreement.
        </p>
        <h2>MISCELLANEOUS</h2>
        <h3>Entire Agreement</h3>
        <p>
          These Terms &amp; Conditions may not be amended, nor any obligation
          waived, without Tota’s written approval. These Terms &amp; Conditions
          supersede and cancel any and all prior negotiations and understandings
          between you and Tota and constitutes the entirety of your agreement
          with Tota.
        </p>
        <h3>No Waiver</h3>
        <p>
          Any failure by Tota or any party to enforce any provision of these
          Terms &amp; Conditions shall not constitute a waiver thereof or of any
          other provision thereof.
        </p>
        <h3>Severability</h3>
        <p>
          If any provision of these Terms &amp; Conditions is adjudged by a
          court to be void or unenforceable, that provision will in no way
          affect any other provision of these Terms &amp; Conditions, the
          application of that provision in any other circumstance, or the
          validity or enforceability of these Terms &amp; Conditions. Such
          provision may be amended or modified only to the extent necessary to
          bring it within legal requirements.
        </p>
        <h3>Governing Law</h3>
        <p>
          These Terms &amp; Conditions will be governed by and interpreted in
          accordance with the laws of the Republic of Singapore applicable
          therein (without regard to the conflicts of law provisions thereof)
          and each party agrees to submit to the jurisdiction of the Courts of
          the Republic of Singapore and to accept service of documents relating
          to commencement of proceedings and actions by certified or registered
          mail.
        </p>
        <p>
          BY CONTINUING TO USE THE PLANET TOTA WEBSITE(S), YOU ARE AGREEING TO
          ABIDE BY ALL THE TERMS AND CONDITIONS SET OUT HEREIN.
        </p>
      </div>
    </main>
  );
}
