import {
  Button,
  Card,
  Descriptions,
  Drawer,
  Popconfirm,
  Space,
  Typography,
} from 'antd';
import parse from 'html-react-parser';
import { AllegianceSubmission } from 'src/types';
import { SubmissionStatus } from 'src/types/Submission';
import { useState } from 'react';
import UserDetailCard from '../UserDetailCard';
import ViewImage from '../ViewImage';
import SubmissionFile from './SubmissionFile';
import SubmissionNoteDeny from './SubmissionNoteDeny';

export type AllegianceSubmissionProps = {
  allegianceSubmission: AllegianceSubmission | null;
  open: boolean;
  onUpdateStatus: (id: number, status: string, note?: string) => void;
  onClose: () => void;
};

export default function AllegianceSubmissionDetail(
  props: AllegianceSubmissionProps,
) {
  const {
    allegianceSubmission, onClose, open, onUpdateStatus,
  } = props;

  const [openNote, setOpenNote] = useState(false);

  const handleCloseNote = () => {
    setOpenNote(false);
  };

  const handleClickDeny = () => {
    setOpenNote(true);
  };

  const handleClose = () => {
    onClose();
    handleCloseNote();
  };

  const handleUpdateStatus = (status: SubmissionStatus, note?: string) => {
    if (!allegianceSubmission?.id) return;
    onUpdateStatus(allegianceSubmission.id, status, note);
    setOpenNote(false);
  };

  return (
    <Drawer
      title="Allegiance submissions"
      size="large"
      onClose={handleClose}
      open={open}
      extra={(
        <Space>
          <SubmissionNoteDeny
            onClose={handleCloseNote}
            open={openNote}
            onSubmit={(note) => handleUpdateStatus(SubmissionStatus.Rejected, note)}
          >
            <Button type="primary" danger onClick={handleClickDeny}>DENY</Button>
          </SubmissionNoteDeny>
          <Popconfirm
            title="Approve the submission"
            description="Are you sure to  this submission?"
            onConfirm={() => handleUpdateStatus(SubmissionStatus.Accepted)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">APPROVE</Button>
          </Popconfirm>
        </Space>
      )}
    >
      <Space direction="vertical" size="large">
        <Card title="Submission">
          <Descriptions column={1}>
            <Descriptions.Item>
              <SubmissionFile
                type={allegianceSubmission?.type.proofType}
                proof={allegianceSubmission?.proof}
              />
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Card title="Allegiance details">
          <Descriptions column={2}>
            <Descriptions.Item label="Title" span={2}>
              {allegianceSubmission?.allegiance.title}
            </Descriptions.Item>
            <Descriptions.Item label="Allegiance reward XP tokens" span={2}>
              {allegianceSubmission?.allegiance.xpAward}
            </Descriptions.Item>
            <Descriptions.Item label="Allegiance description" span={2}>
              {allegianceSubmission?.allegiance.description || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Allegiance poster">
              <ViewImage width={200} src={allegianceSubmission?.allegiance.image} />
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Card title="Allegiance submission details">
          <Descriptions column={2}>
            <Descriptions.Item label="Submission title" span={2}>
              {allegianceSubmission?.type.title || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Submission type" span={2}>
              {allegianceSubmission?.type.proofType || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Submission text" span={2}>
              {allegianceSubmission?.type.text || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Disclaimer" span={2}>
              <Typography.Text>
                {allegianceSubmission?.disclaimer
                  ? parse(allegianceSubmission?.type.disclaimer)
                  : 'N/A'}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Submission pop-up image" span={2}>
              <ViewImage width={200} src={allegianceSubmission?.type.proofImage} />
            </Descriptions.Item>
            <Descriptions.Item label="Submission pop-up logo" span={2}>
              <ViewImage width={200} src={allegianceSubmission?.type.proofLogo} />
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <UserDetailCard user={allegianceSubmission?.user} />
      </Space>
    </Drawer>
  );
}
