import { useQueryClient } from '@tanstack/react-query';
import { Admin } from 'src/types/Admin';
import
{
  Page, PaginationParams, SearchParams, SortParams,
} from './typing';
import useFetch from './useFetch';
import usePost from './usePost';

const KEY_QUERIES = ['admins'];

export type UseFetchAdminParams = PaginationParams & SortParams & SearchParams;

export type CreateAdmin = Omit<UpdateAdmin, 'id'> & {
  name?: string;
  email: string;
  username: string;
  password: string;
  roles: number[];
};

export type UpdateAdmin = {
  id?: number;
  name?: string;
  email?: string;
  username?: string;
  password?: string;
  roles?: number[];
};

type UpdateStatusAdmin = Pick<Admin, 'isActive'>;

export const useFetchAdmins = (
  params: UseFetchAdminParams,
) => useFetch<Page<Admin>>({
  key: [...KEY_QUERIES, params],
  url: '/admins',
  searchParams: params,

});

export const useCreateAdmin = (
  data: CreateAdmin,
) => {
  const queryClient = useQueryClient();

  return usePost<Admin, CreateAdmin>({
    method: 'POST',
    url: '/admins',
    data,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: KEY_QUERIES });
    },

  });
};

export const useUpdateAdmin = (
  data: UpdateAdmin,
) => {
  const queryClient = useQueryClient();

  return usePost<void, Omit<UpdateAdmin, 'id'>>({
    method: 'PATCH',
    url: `/admins/${data.id}`,
    data,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: KEY_QUERIES });
    },
  });
};

export const useUpdateStatusAdmin = (
  data: Admin,
) => {
  const queryClient = useQueryClient();

  return usePost<void, UpdateStatusAdmin>({
    method: 'PATCH',
    url: `/admins/${data.id}`,
    data: {
      isActive: data.isActive,
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: KEY_QUERIES });
    },
  });
};
