import { Table } from 'antd';
import { ReactElement } from 'react';
import { NFT } from 'src/hooks/api/typing';
import { ColumnsType } from 'antd/es/table';
import { getExplorerUrl } from 'src/helper/web3helper';

export type BlockchainDetailProps = {
  nfts: (NFT & {
    to: string;
  })[];
};

const shortenAddress = (address: string, charsToShow = 4) => {
  if (!address) return '';
  const prefix = address.slice(0, charsToShow);
  const suffix = address.slice(-charsToShow);

  return `${prefix}...${suffix}`;
};

const getExplorerLink = (type: 'tx' | 'address', address: string) => {
  if (!address) return '';

  const explorerLink = getExplorerUrl();
  return `${explorerLink}/${type}/${address}`;
};

function BlockchainDetail(props: BlockchainDetailProps): ReactElement {
  const { nfts } = props;
  const columns: ColumnsType<
    NFT & {
      to: string;
    }
  > = [
    {
      title: 'Tx Hash',
      dataIndex: 'txHash',
      key: 'txHash',
      render: (data) => (
        <a
          href={getExplorerLink('tx', data)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {shortenAddress(data)}
        </a>
      ),
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      render: (data) => (
        <a
          href={getExplorerLink('address', data)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {shortenAddress(data)}
        </a>
      ),
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      render: (data) => (
        <a
          href={getExplorerLink('address', data)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {shortenAddress(data)}
        </a>
      ),
    },
    {
      title: 'Token ID',
      dataIndex: 'tokenId',
      key: 'tokenId',
    },
    {
      title: 'Collection',
      dataIndex: 'collectionId',
      key: 'collectionId',
    },
    {
      title: 'Drop',
      dataIndex: 'dropId',
      key: 'dropId',
    },
    {
      title: 'Date',
      dataIndex: 'generatedAt',
      key: 'generatedAt',
      render: (data) => <p>{new Date(data * 1000).toLocaleDateString()}</p>,
    },
  ];

  return (
    <>
      <p
        style={{
          fontWeight: '600',
          fontSize: '16px',
        }}
      >
        BlockchainDetail
      </p>
      <Table dataSource={nfts} columns={columns} rowKey="id" />
    </>
  );
}

export default BlockchainDetail;
