import { Input, InputProps } from 'antd';
import classNames from 'classnames';
import styles from './style.module.scss';

type Props = InputProps;

export default function InputStyled(props: Props) {
  return (
    <Input
      size="large"
      {...props}
      className={classNames(styles.input, props.className)}
    />
  );
}
