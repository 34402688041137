import { Card, Descriptions, Drawer, Space } from 'antd';
import { useState } from 'react';
import { ActivitySubmission } from 'src/types/Activity';
import { SubmissionStatus } from 'src/types/Submission';
import UserDetailCard from '../UserDetailCard';
import SubmissionMultipleEntries from './SubmissionMutipleEntries';

export type ActivitySubmissionProps = {
  activitySubmission: ActivitySubmission | null;
  open: boolean;
  onUpdateStatus: (id: number, status: string, note?: string) => void;
  onClose: () => void;
};

export default function ActivitySubmissionDetail(
  props: ActivitySubmissionProps,
) {
  const { activitySubmission, onClose, open, onUpdateStatus } = props;
  const [openNote, setOpenNote] = useState(false);

  const handleCloseNote = () => {
    setOpenNote(false);
  };

  const handleClickDeny = () => {
    setOpenNote(true);
  };

  const handleClose = () => {
    onClose();
    handleCloseNote();
  };

  return (
    <Drawer
      title="Activity submissions"
      size="large"
      onClose={handleClose}
      open={open}
      width={1200}
    >
      <Space direction="vertical" size="large">
        <Card title="Submission">
          <Descriptions column={1}>
            <Descriptions.Item>
              <SubmissionMultipleEntries
                submission={activitySubmission}
                onApprove={(data) =>
                  onUpdateStatus(data.id, SubmissionStatus.Accepted)
                }
                onReject={(data, note) => {
                  onUpdateStatus(data.id, SubmissionStatus.Rejected, note);
                  handleCloseNote();
                }}
                onOpenNote={handleClickDeny}
                onCloseNote={() => setOpenNote(false)}
                openNote={openNote}
              />
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <UserDetailCard user={activitySubmission?.user} />
      </Space>
    </Drawer>
  );
}
