import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  SelectProps,
  Space,
  Switch,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useMemo } from 'react';
import 'react-quill/dist/quill.snow.css';
import { GiveAway, GiveAwayType } from 'src/types/GiveAway';
import { useFetchCollaborators } from 'src/hooks/api/collaborator';
import { useFetchDrops } from 'src/hooks/api';
import UploadImage from '../UploadImage';

const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);
const dateFormat = 'DD-MM-YYYY HH:mm';

const STANDARD_ITEM_OPTIONS: SelectProps['options'] = [
  {
    label: 'Shoe',
    value: 'shoe',
  },
  {
    label: 'Shirt',
    value: 'shirt',
  },
  {
    label: 'T-shirt',
    value: 't-shirt',
  },
  {
    label: 'Hoodie',
    value: 'hoodie',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const isStandardItemName = (itemName?: string): boolean => {
  if (itemName == null) {
    return true;
  }

  const index = STANDARD_ITEM_OPTIONS.findIndex(
    (option) => option.value === itemName,
  );
  return index >= 0;
};

function CollaboratorSelect(props: Omit<SelectProps, 'options' | 'loading'>) {
  // Server state
  const collaborators = useFetchCollaborators({
    page: 1,
    limit: 30,
  });
  // Deprived state
  const options = useMemo<SelectProps['options']>(() => {
    if (collaborators.data == null) {
      return undefined;
    }

    return collaborators.data.items.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [collaborators.data]);

  return (
    <Select
      {...props}
      placeholder="Select collaborator"
      options={options}
      loading={collaborators.isLoading}
    />
  );
}

function DropSelect(props: Omit<SelectProps, 'options' | 'loading'>) {
  // Server state
  const { data: drops, isLoading } = useFetchDrops();
  // Deprived state
  const options = useMemo<SelectProps['options']>(() => {
    if (drops == null) {
      return undefined;
    }

    return drops.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [drops]);

  return (
    <Select
      {...props}
      placeholder="Select collaborator"
      options={options}
      loading={isLoading}
    />
  );
}

type GiveAwayFormData = Omit<
  GiveAway,
  'startDate' | 'endDate' | 'collaboratorId' | 'dropId'
> & {
  customItemName?: string;
  collaboratorId?: number;
  dropId?: number;
  dateRange: [Dayjs | undefined, Dayjs | undefined];
  hasCollaborator: boolean;
};

export type GiveAwayFormProps = {
  open: boolean;
  giveAway?: GiveAway;
  onClose?: () => void;
  onSubmit?: (values: GiveAway) => void;
};

export default function GiveAwayForm(props: GiveAwayFormProps) {
  const { open, giveAway, onSubmit = () => {}, onClose = () => {} } = props;

  const [form] = Form.useForm<GiveAwayFormData>();
  const isEdit = useMemo(() => giveAway != null, [giveAway]);
  const formData: GiveAwayFormData | undefined = useMemo(() => {
    if (giveAway == null) {
      return undefined;
    }

    return {
      ...giveAway,
      itemName: isStandardItemName(giveAway.itemName)
        ? giveAway.itemName
        : 'other',
      customItemName: isStandardItemName(giveAway.itemName)
        ? undefined
        : giveAway.itemName,
      collaboratorId:
        giveAway.collaboratorId != null ? +giveAway.collaboratorId : undefined,
      dropId: giveAway.dropId != null ? +giveAway.dropId : undefined,
      hasCollaborator: giveAway.collaboratorId != null,
      dateRange: [
        giveAway.startDate != null ? dayjs(giveAway.startDate) : undefined,
        giveAway.endDate != null ? dayjs(giveAway.endDate) : undefined,
      ],
    };
  }, [giveAway]);

  useEffect(() => {
    if (formData != null) {
      form.setFieldsValue(formData);
    } else {
      form.resetFields();
    }
  }, [form, formData]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (formInstance: FormInstance<GiveAwayFormData>) => {
    await formInstance.validateFields();
    const formValues = form.getFieldsValue();
    onSubmit({
      ...formValues,
      id: formData?.id,
      itemName: formValues.customItemName || formValues.itemName,
      collaboratorId: formValues.collaboratorId?.toString(),
      dropId: formValues.dropId?.toString(),
      startDate: formValues.dateRange?.[0]?.toDate() ?? null,
      endDate: formValues.dateRange?.[1]?.toDate() ?? null,
    });
  };

  return (
    <Drawer
      open={open}
      title={giveAway != null ? 'Update give-away' : 'Add give-away'}
      size="large"
      extra={
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit(form);
            }}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      }
      onClose={handleClose}
    >
      <Form id="create-give-away" layout="vertical" form={form}>
        <Space direction="vertical" size="large">
          <Card title=" Give-away details">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={['name']}
                  label="Give away name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: ' Give away name cannot be empty',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Please enter give away name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      name={['hasCollaborator']}
                      label="Collaborator-specific?"
                      initialValue={false}
                      rules={[
                        {
                          required: true,
                          type: 'boolean',
                        },
                      ]}
                      valuePropName="checked"
                    >
                      <Switch disabled={isEdit} />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.hasCollaborator !==
                      currentValues.hasCollaborator
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('hasCollaborator') === true ? (
                        <Col span={16}>
                          <Form.Item
                            name={['collaboratorId']}
                            label="Collaborator"
                          >
                            <CollaboratorSelect disabled={isEdit} />
                          </Form.Item>
                        </Col>
                      ) : null
                    }
                  </Form.Item>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      name={['type']}
                      label="Give away type"
                      rules={[
                        {
                          required: true,
                          type: 'string',
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            value: GiveAwayType.Physical,
                            label: 'Physical',
                          },
                          {
                            value: GiveAwayType.DropWhitelist,
                            label: 'Whitelist spot',
                          },
                          {
                            value: GiveAwayType.DropAirdrop,
                            label: 'Airdrop spot',
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.type !== currentValues.type
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('type') === GiveAwayType.Physical ? (
                        <Col span={8}>
                          <Form.Item
                            name={['itemName']}
                            label="Merch type"
                            rules={[{ required: true }]}
                          >
                            <Select options={STANDARD_ITEM_OPTIONS} />
                          </Form.Item>
                        </Col>
                      ) : null
                    }
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.type !== currentValues.type ||
                      prevValues.itemName !== currentValues.itemName
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('type') === GiveAwayType.Physical &&
                      getFieldValue('itemName') === 'other' ? (
                        <Col span={8}>
                          <Form.Item
                            name={['customItemName']}
                            label="Custom merch type"
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      ) : null
                    }
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.type !== currentValues.type
                    }
                  >
                    {({ getFieldValue }) =>
                      [
                        GiveAwayType.DropWhitelist,
                        GiveAwayType.DropAirdrop,
                      ].includes(getFieldValue('type')) ? (
                        <>
                          <Col span={8}>
                            <Form.Item name={['dropId']} label="Drop">
                              <DropSelect />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item name={['dropUrl']} label="Drop URL">
                              <Input />
                            </Form.Item>
                          </Col>
                        </>
                      ) : null
                    }
                  </Form.Item>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['maxAmount']}
                  label="Max number of users eligible for the giveaway"
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      message: 'Value cannot be empty',
                    },
                  ]}
                >
                  <InputNumber placeholder="100" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['xpFee']}
                  label="XP fee amount"
                  rules={[
                    {
                      type: 'number',
                      message: 'Value cannot be empty',
                    },
                  ]}
                >
                  <InputNumber placeholder="100" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['order']}
                  label="Order"
                  rules={[
                    {
                      type: 'number',
                      required: false,
                    },
                  ]}
                >
                  <InputNumber placeholder="1" defaultValue={1} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['dateRange']}
                  label="Give-away start date - end date"
                >
                  <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    allowEmpty={[true, true]}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={['posterImage']} label="Give-away poster">
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['mobilePosterImage']}
                  label="Give-away poster for mobile"
                >
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['notificationImage']}
                  label="Notification image"
                >
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['description']}
                  label="Give away description"
                  rules={[
                    {
                      type: 'string',
                      message: 'Give away description cannot be empty',
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Please enter description" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Space>
      </Form>
    </Drawer>
  );
}
