import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from './dispatch';
import { RootState } from './reducers';

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();
export default createAppAsyncThunk;
