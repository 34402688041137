import classNames from 'classnames';

import {
  Button, Space, Typography, notification,
} from 'antd';
import { useAppDispatch } from 'src/store/dispatch';
import { signOut } from 'src/store/slices/auth-slice';
import { clearCollaboratorProfile } from 'src/store/slices/collaborator-slice';
import { useSelector } from 'react-redux';
import { selectCollaboratorProfile } from 'src/store/selectors';
import useSendEmail from 'src/hooks/api/email';
import { AxiosError } from 'axios';
import { ErrorDataResponse } from 'src/typing';
import styles from './styles.module.scss';

const { Text } = Typography;

export default function MyAccount() {
  const dispatch = useAppDispatch();
  const { mutateAsync: sendEmailMutate } = useSendEmail();
  const profile = useSelector(selectCollaboratorProfile);

  const handleSignOut = () => {
    dispatch(signOut());
    dispatch(clearCollaboratorProfile());
  };

  const handlePasswordReset = async () => {
    try {
      const formData = {
        subject: 'Password reset request from collaborator',
        name: profile?.name,
        email: profile?.email,
        message: `Collaborator has requested to reset his password for email ${profile?.email}`,
      };
      await sendEmailMutate(formData);
      notification.success({ message: 'Password request has been submitted' });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Password request submission failed: ${errorMessage}`,
      });
    }
  };

  return (
    <div className={classNames(styles.container, 'm-28')}>
      <div className="flex flex-start">
        <div className={classNames('w-max-content', styles.logoWrapper)}>
          <div className={styles.logoHolder}>
            <img src={`${process.env.REACT_APP_API_URL}v2.0/files/${profile?.avatar}`} alt="Logo" />
          </div>
        </div>
        <div className="color-white w-full">
          <h5 className={styles.profileName}>{profile?.name}</h5>
          <div className={classNames(styles.frame, 'w-full')}>
            <div className={classNames('flex items-start justify-start', styles.formRow)}>
              <div className={classNames(styles.label, 'body3 text-uppercase')}>
                email:
              </div>
              <div className={classNames(styles.value, 'body3 pb-10')}>
                {profile?.email}
                <div className={classNames(
                  styles.updatePassword,
                  'color-primary text-underline',
                )}
                >
                  <button
                    type="button"
                    onClick={handlePasswordReset}
                  >
                    Update password
                  </button>
                </div>
              </div>
            </div>
            <div className={classNames('flex items-start justify-start', styles.formRow)}>
              <div className={classNames(styles.label, 'body3 text-uppercase')}>
                Collab code:
              </div>
              <div className={classNames(styles.value, 'body3')}>
                {profile?.refCode}
              </div>
            </div>
            <div className={classNames('flex items-start justify-start', styles.formRow)}>
              <div className={classNames(styles.label, 'body3 text-uppercase')}>
                Collab link:
              </div>
              <div
                className={classNames(styles.value, 'body3 flex items-center')}
              >
                <Text className={styles.copyBtn} copyable>
                  {`${process.env.REACT_APP_WEB_URL}collab/${profile?.refCode}`}
                </Text>
              </div>
            </div>
            <div className="mt-40">
              <Space wrap>
                <Button className={styles.submitButton} onClick={handleSignOut}>
                  Log Out
                </Button>
              </Space>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
