import { useSelector } from 'react-redux';
import { ReactComponent as DoubleArrow } from 'src/assets/icons/double-arrow.svg';
import { selectCollaboratorProfile } from 'src/store/selectors';

import { useFetchCollaboratorAsset } from 'src/hooks/api/collaborator-dashboard';
import { useEffect, useState } from 'react';
import { Asset } from 'src/types/Asset';
import classNames from 'classnames';
import { CollapsePanelProps, Empty, Spin } from 'antd';
import CustomCollapse from '../_shared/components/Collapse';
import styles from './style.module.scss';
import ButtonStyled from '../_shared/components/Button';

function CollapsePanelHeader(title: string, date?: Date) {
  return (
    <div className={styles.panelHeader}>
      <div className="body1">
        {title}
        {' '}
        -
        {' '}
        {date ? new Date(date).toDateString() : ''}
      </div>
      <div>
        <DoubleArrow />
      </div>
    </div>
  );
}

function CollapsePanelItem(asset: Asset) {
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.imgWrapper}>
        <img
          src={`${process.env.REACT_APP_API_URL}v2.0/files/${asset.image}`}
          alt="asset-pic"
        />
      </div>
      <div className={styles.content}>
        <div className={classNames(styles.title, 'subTitle1 text-uppercase')}>
          {asset.name}
        </div>
        <div className={styles.descriptionWrapper}>
          <div className={classNames(styles.title, 'subTitle1')}>
            Description
          </div>
          <div className={classNames(styles.description, 'body2')}>
            {asset.description}
          </div>
        </div>
        <div className={styles.downloadLinkWrapper}>
          <div className={classNames(styles.title, 'subTitle1')}>
            {asset.link && asset.label ? asset.label : ''}
          </div>
          <div className={classNames(styles.link, 'body2 mb-20')}>
            {asset.link ? (
              <a href={asset.link} target="_blank" rel="noreferrer">
                {asset.link}
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={styles.downloadLinkWrapper}>
          <div className="body1 mb-20">
            {asset.file ? 'Download File' : ''}
          </div>
          <div className={classNames(styles.link, 'body2')}>
            {asset.file ? (
              <ButtonStyled
                className={styles.downloadLink}
                type="link"
                target="_blank"
                href={`${process.env.REACT_APP_API_URL}v2.0/files/${asset.file}`}
              >
                Download
              </ButtonStyled>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function AssetRepositoryCollapse(props: { id: number }) {
  const { id } = props;
  const { data: collaboratorAsset } = useFetchCollaboratorAsset({
    collaboratorId: String(id),
  });

  const [assets, setAssets] = useState<CollapsePanelProps[]>();

  useEffect(() => {
    if (collaboratorAsset && collaboratorAsset.items) {
      const items: CollapsePanelProps[] = [];
      collaboratorAsset.items.forEach((item: Asset, index: number) => {
        items.push({
          key: `asset_${index}`,
          header: CollapsePanelHeader(item.name, item.createdAt),
          children: CollapsePanelItem(item),
        });
      });
      setAssets(items);
    }
  }, [collaboratorAsset]);

  if (!assets?.length) {
    return (
      <div className={styles.noAssetMessage}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No assets have been uploaded as of now" />
      </div>
    );
  }

  return (
    <div>
      {assets ? (
        <CustomCollapse
          activeKey={['asset_0']}
          className={styles.accordionWrapper}
          collapseClassName={styles.accordionWrapper}
          panelClassName={styles.panelWrapper}
          panelItems={assets}
        />
      ) : (
        <Spin />
      )}
    </div>
  );
}

export default function MasterCardAssetRepository() {
  const profile = useSelector(selectCollaboratorProfile);

  return (
    <div className={classNames('awesome-scrollbar', styles.scrollableContent)}>
      {profile ? <AssetRepositoryCollapse id={profile.id} /> : <Spin />}
    </div>
  );
}
