import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import { Rule } from 'antd/es/form';
import { useEffect, useState } from 'react';
import readFile from 'src/helper/file';
import {
  CollaboratorForm,
  useFetchCollaboratorDetail,
} from 'src/hooks/api/collaborator';
import { Allegiance } from 'src/types/Allegiance';
import { Collaborator } from 'src/types/Collaborator';
import { WorkBook } from 'xlsx';
import { Activity, User } from 'src/types';
import CollaboratorUserTable from './CollaboratorUserTable';
import UploadImage from '../UploadImage';

const { Dragger } = Upload;
const { Option } = Select;

export type CreateCollaboratorModalProps = {
  open: boolean;
  isEdit: boolean;
  collaborator: Collaborator | null;
  onClose?: () => void;
  onSubmit?: (values: any) => Promise<void>;
  onEdit: () => void;
  allegiances?: Allegiance[];
  activities?: Activity[];
  onWhiteUser: (record: User) => void;
  onAirdropUser: (record: User) => void;
};

export default function CreateCollaboratorModal({
  open,
  onClose,
  onSubmit,
  onEdit,
  isEdit,
  collaborator,
  allegiances,
  activities,
  onAirdropUser,
  onWhiteUser,
}: CreateCollaboratorModalProps) {
  const [form] = Form.useForm<CollaboratorForm>();

  const [userListPreview, setUserListPreview] = useState<Record<string, any>[]>(
    [],
  );
  const [submitting, setSubmitting] = useState(false);

  const dataSourceCollaborator = useFetchCollaboratorDetail(
    collaborator?.id,
  );

  useEffect(() => {
    if (!collaborator) {
      form.resetFields();
      return;
    }

    form.setFieldValue('name', collaborator.name);
    form.setFieldValue('email', collaborator.email);
    form.setFieldValue('amountUserAirdrop', collaborator.amountUserAirdrop);
    form.setFieldValue('amountUserWhitelist', collaborator.amountUserWhitelist);
    form.setFieldValue('referralXP', collaborator.referralXP);
    form.setFieldValue('refCode', collaborator.refCode);
    form.setFieldValue('allegianceId', collaborator.allegianceId);
    form.setFieldValue(
      'activityIds',
      collaborator?.activities?.map((item) => item?.id?.toString()),
    );
    form.setFieldValue('avatar', collaborator.avatar);

    if (collaborator.users) {
      const userList = collaborator.users.map((i) => ({
        Wallet: i.walletAddress,
        Whitelist: i.allowWhitelist,
        Airdrop: i.allowAirdrop,
        XP: i.allowXP,
      }));
      setUserListPreview(userList);
    }
  }, [collaborator, form]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = async (formInstance: FormInstance<CollaboratorForm>) => {
    if (!onSubmit) return;

    try {
      await formInstance.validateFields();
      setSubmitting(true);
      const formValues = form.getFieldsValue();

      if (!isEdit) {
        await onSubmit({
          ...formValues,
          users: userListPreview.map((i) => ({
            walletAddress: i.Wallet,
            allowWhitelist: i.Whitelist,
            allowAirdrop: i.Airdrop,
            allowXP: i.XP,
          })),
        });
      } else {
        await onSubmit({
          ...formValues,
        });
      }

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const processCSVData = (csvContent?: WorkBook) => {
    if (!csvContent) return;
    const sheetNameToParse = csvContent.SheetNames[0];
    const sheetData = csvContent.Sheets[sheetNameToParse];

    const datas = [];
    let row = 2;

    while (true) {
      if (
        !sheetData[`A${row}`]
        || !sheetData[`B${row}`]
        || !sheetData[`C${row}`]
        || !sheetData[`D${row}`]
      ) {
        break;
      }

      const rowObj: Record<string, any> = {};
      rowObj.key = sheetData[`A${row}`].w;
      rowObj.Wallet = sheetData[`A${row}`].w;
      rowObj.Whitelist = sheetData[`B${row}`].v.toUpperCase() === 'YES';
      rowObj.Airdrop = sheetData[`C${row}`].v.toUpperCase() === 'YES';
      rowObj.XP = sheetData[`D${row}`].v.toUpperCase() === 'YES';
      datas.push(rowObj);
      row += 1;
    }

    setUserListPreview(datas.filter((i) => i.Wallet !== ''));
  };

  const validateStrongPassword = (): Rule => ({
    validator(_, value) {
      const regex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;

      if (regex.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error('Password is weak. Please use strong one'),
      );
    },
  });

  const validateConfirmPassword = <T extends CollaboratorForm>(
    formInstance: FormInstance<T>,
  ): Rule => ({
      validator(_, value) {
        if (!value || formInstance.getFieldValue('password') === value) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error(
            'Sorry, the passwords you entered don\'t match. Please try again.',
          ),
        );
      },
    });

  const handleClickEdit = () => {
    onEdit();
  };

  return (
    <Drawer
      open={open}
      size="large"
      extra={(
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          {!collaborator || isEdit ? (
            <Button
              loading={submitting}
              onClick={() => {
                handleSubmit(form);
              }}
              type="primary"
            >
              Submit
            </Button>
          ) : null}
          {collaborator && !isEdit ? (
            <Button
              loading={submitting}
              onClick={handleClickEdit}
              type="primary"
            >
              Edit
            </Button>
          ) : null}
        </Space>
      )}
      onClose={handleClose}
    >
      <Row>
        <Col span={24}>
          {!collaborator || isEdit ? (
            <Form id="create-collaborator" layout="vertical" form={form}>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <Card title="Collaborator details">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name={['name']}
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: 'Name not empty',
                          },
                        ]}
                      >
                        <Input placeholder="Please enter collaborator name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['email']}
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: 'Email not empty',
                          },
                        ]}
                      >
                        <Input
                          disabled={isEdit}
                          type="email"
                          placeholder="Please enter email"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {!isEdit && (
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name={['password']}
                          label="Password"
                          rules={[
                            {
                              required: true,
                            },
                            validateStrongPassword(),
                          ]}
                          help="Your password must be 8 to 15 characters long
                and should contain atleast 1 smallcase, atleast 1 uppercase,
                1 digit and 1 special character [ @ $ ! * # ? & _ ]"
                        >
                          <Input
                            type="password"
                            placeholder="Please enter password"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="confirmPassword"
                          label="Confirm Password"
                          rules={[
                            {
                              required: true,
                            },
                            validateConfirmPassword(form),
                          ]}
                        >
                          <Input
                            type="password"
                            placeholder="Please enter confirm password"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name={['refCode']}
                        label="Referral code"
                        rules={[
                          {
                            required: false,
                            message: 'Required field',
                          },
                        ]}
                      >
                        <Input disabled={isEdit} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['referralXP']}
                        label="Referral XP reward"
                        rules={[
                          {
                            required: true,
                            message: 'Required field',
                          },
                        ]}
                      >
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {isEdit ? (
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name={['amountUserAirdrop']}
                          label="Number of users to allot airdrop eligibility"
                          rules={[
                            {
                              required: true,
                              message: 'Required field',
                            },
                          ]}
                        >
                          <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['amountUserWhitelist']}
                          label="Number of users to allot whitelist spot"
                          rules={[
                            {
                              required: true,
                              message: 'Required field',
                            },
                          ]}
                        >
                          <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null}
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Allegiance" name="allegianceId">
                        <Select allowClear>
                          {allegiances?.map((allegiance: Allegiance) => (
                            <Option
                              key={allegiance.id}
                              value={allegiance.id?.toString()}
                            >
                              {allegiance?.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Activity"
                        name="activityIds"
                      >
                        <Select allowClear mode="multiple">
                          {activities?.map((activity: Activity) => (
                            <Option
                              key={activity.id}
                              value={activity.id?.toString()}
                            >
                              {activity?.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={['avatar']}
                        label="Avatar"
                        help=" Please upload image with width of 741.6px and height of
                  1091.2px"
                      >
                        <UploadImage />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                {isEdit ? (
                  <Card title="Collaborator member">
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{ width: '100%' }}
                    >
                      <Dragger
                        name="file"
                        accept="text/csv"
                        maxCount={1}
                        multiple={false}
                        customRequest={({ onSuccess }) => {
                          if (!onSuccess) return;
                          setTimeout(() => {
                            onSuccess('ok');
                          }, 0);
                        }}
                        onDrop={async (e) => {
                          const content = await readFile(
                            e.dataTransfer.files[0],
                          );
                          processCSVData(content);
                        }}
                        onChange={async (info) => {
                          const { status } = info.file;

                          if (status === 'done') {
                            if (!info.file.originFileObj) return;

                            const content = await readFile(
                              info.file.originFileObj,
                            );
                            processCSVData(content);
                          }
                        }}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Upload user list</p>
                        <p className="ant-upload-hint">
                          Drop your CSV file or click here
                        </p>
                      </Dragger>
                      {collaborator ? (
                        <CollaboratorUserTable
                          collaborator={collaborator}
                          onAirdropUser={onAirdropUser}
                          onWhiteUser={onWhiteUser}
                        />
                      ) : null}
                    </Space>
                  </Card>
                ) : null}
              </Space>
            </Form>
          ) : (
            <Space direction="vertical" size="middle">
              <Card>
                <Descriptions title="Collaborator details" column={2}>
                  <Descriptions.Item label="Name">
                    {collaborator?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {collaborator?.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Referral code">
                    {collaborator?.refCode || 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Referral link">
                    <a
                      href={`${process.env.REACT_APP_WEB_URL}collab/${collaborator?.refCode}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${process.env.REACT_APP_WEB_URL}collab/${collaborator?.refCode}`}
                    </a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Referral XP reward">
                    {collaborator?.referralXP}
                  </Descriptions.Item>
                  <Descriptions.Item label="Number of users to allot airdrop eligibility">
                    {collaborator?.amountUserAirdrop}
                  </Descriptions.Item>
                  <Descriptions.Item label="Number of users to allot whitelist spot">
                    {collaborator?.amountUserWhitelist}
                  </Descriptions.Item>
                  <Descriptions.Item label="Remaining airdrop eligibility">
                    {dataSourceCollaborator.data?.remainingAirdropUser}
                  </Descriptions.Item>
                  <Descriptions.Item label="Remaining whitelist eligibility">
                    {dataSourceCollaborator?.data?.remainingWhitelistUser}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <Card>
                <Descriptions title="Collaborator member" column={2}>
                  <Descriptions.Item span={2}>
                    {collaborator ? (
                      <CollaboratorUserTable
                        collaborator={collaborator}
                        onAirdropUser={onAirdropUser}
                        onWhiteUser={onWhiteUser}
                      />
                    ) : null}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Space>
          )}
        </Col>
      </Row>
    </Drawer>
  );
}
