import { Checkbox, Col, Descriptions, Row, Tabs, TabsProps } from 'antd';
import {
  useFetchNFTMintedForDrop,
  useFetchWhitelistUsersForDrop,
} from 'src/hooks/api';

import { Drop, NFT } from 'src/hooks/api/typing';
import { User } from 'src/types';

import EligibleUserList from './EligibleUserList';

export type DropDetailProps = {
  drop: Drop;
};

type WhitelistSaleTabProps = {
  usersClaimedSpot: number;
  whitelistMintedNfts: number;
};

function WhitelistSaleTab(props: WhitelistSaleTabProps) {
  const { usersClaimedSpot = 0, whitelistMintedNfts = 0 } = props;

  return (
    <Row>
      <Col span={24}>
        <Descriptions title="Whitelist Sale Stats" column={2}>
          <Descriptions.Item label="Users claimed the spot" span={2}>
            {usersClaimedSpot}
          </Descriptions.Item>
          <Descriptions.Item label="NFT minted" span={2}>
            {whitelistMintedNfts}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

type PublicSaleTabProps = {
  publicMintedNfts: number;
};

function PublicSaleTab(props: PublicSaleTabProps) {
  const { publicMintedNfts } = props;
  return (
    <Row>
      <Col span={24}>
        <Descriptions title="Public Sale Stats" column={2}>
          <Descriptions.Item label="Available NFTs" span={2}>
            100
          </Descriptions.Item>
          <Descriptions.Item label="NFT minted" span={2}>
            {publicMintedNfts}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

export default function DropDetail(props: DropDetailProps) {
  const { drop } = props;

  // eslint-disable-next-line prefer-const
  let { data: eligibleUserOfDrop, isLoading } = useFetchWhitelistUsersForDrop(
    drop.id,
  );

  const usersClaimedSpot = eligibleUserOfDrop?.filter(
    (eUser: User) => eUser.isWhitelisted === true,
  ).length;

  const { data: nfts } = useFetchNFTMintedForDrop(drop.id?.toString());
  const nftsMintedInWhitelistSale = nfts?.filter((nft: NFT) => {
    const dropPreSaleStart = Math.floor(
      new Date(drop.presaleStart).getTime() / 1000,
    );
    const dropPreSaleEnd = Math.floor(
      new Date(drop.presaleEnd).getTime() / 1000,
    );

    if (
      +nft.generatedAt >= dropPreSaleStart &&
      +nft.generatedAt <= dropPreSaleEnd
    ) {
      return nft;
    }

    return null;
  }).length;

  const nftsMintedInPublicSale = nfts?.filter((nft: NFT) => {
    const dropPublicSaleStart = Math.floor(
      new Date(drop.publicSaleStart).getTime() / 1000,
    );
    const dropPublicSaleEnd = Math.floor(
      new Date(drop.publicSaleEnd).getTime() / 1000,
    );

    if (
      +nft.generatedAt >= dropPublicSaleStart &&
      +nft.generatedAt <= dropPublicSaleEnd
    ) {
      return nft;
    }

    return null;
  }).length;

  if (!eligibleUserOfDrop) {
    eligibleUserOfDrop = [];
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Whitelist Users',
      children: (
        <EligibleUserList
          eligibleUsers={eligibleUserOfDrop}
          loading={isLoading}
        />
      ),
    },
    {
      key: '2',
      label: 'Whitelist sale',
      children: (
        <WhitelistSaleTab
          usersClaimedSpot={usersClaimedSpot || 0}
          whitelistMintedNfts={nftsMintedInWhitelistSale || 0}
        />
      ),
    },
    {
      key: '3',
      label: 'Public sale',
      children: (
        <PublicSaleTab publicMintedNfts={nftsMintedInPublicSale || 0} />
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Descriptions title="Drop" column={2}>
          <Descriptions.Item label="Name" span={2}>
            {drop.name}
          </Descriptions.Item>
          <Descriptions.Item label="Max Whitelist NFT per person" span={2}>
            {drop.maxWhitelistNFTPerPerson}
          </Descriptions.Item>
          <Descriptions.Item label="Total NFTs reserved for Airdrop" span={2}>
            {drop.maxAirdropQty}
          </Descriptions.Item>
          <Descriptions.Item label="Max Airdrop NFT per person" span={2}>
            {drop.maxAirdropNFTPerPerson}
          </Descriptions.Item>
          <Descriptions.Item label="Max presale quantity">
            {drop.maxPreSaleQty}
          </Descriptions.Item>
          <Descriptions.Item label="Quantity">{drop.qty}</Descriptions.Item>
          <Descriptions.Item label="Presale start">
            {new Date(drop.presaleStart).toLocaleDateString()}
            &nbsp;-&nbsp;
            {new Date(drop.presaleStart).toLocaleTimeString()}
          </Descriptions.Item>
          <Descriptions.Item label="Presale end">
            {new Date(drop.presaleEnd).toLocaleDateString()}
            &nbsp;-&nbsp;
            {new Date(drop.presaleEnd).toLocaleTimeString()}
          </Descriptions.Item>
          <Descriptions.Item label="Public sale start">
            {new Date(drop.publicSaleStart).toLocaleDateString()}
            &nbsp;-&nbsp;
            {new Date(drop.publicSaleStart).toLocaleTimeString()}
          </Descriptions.Item>
          <Descriptions.Item label="Public sale end">
            {new Date(drop.publicSaleEnd).toLocaleDateString()}
            &nbsp;-&nbsp;
            {new Date(drop.publicSaleEnd).toLocaleTimeString()}
          </Descriptions.Item>
          <Descriptions.Item label="Reveal" span={2}>
            <Checkbox checked={drop.isRevealed} />
          </Descriptions.Item>
          <Descriptions.Item label="Active" span={2}>
            <Checkbox checked={drop.mActive} />
          </Descriptions.Item>
          <Descriptions.Item label="Available airdrop quantity">
            0
          </Descriptions.Item>
          <Descriptions.Item label="Airdrop XP fee">
            {drop.airdropXpFee}
          </Descriptions.Item>
        </Descriptions>
        <Tabs defaultActiveKey="1" items={items} />
      </Col>
    </Row>
  );
}
