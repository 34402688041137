import { Button, Card, Drawer, Form, Input, message, Space } from 'antd';
import { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import AddEligibleUsersForm from 'src/components/Drop/AddEligibleUsersForm';
import DropDetail from 'src/components/Drop/DropDetail';
import DropForm from 'src/components/Drop/DropForm';
import DropList from 'src/components/Drop/DropList';
import BasicModal from 'src/components/Modal';
import {
  activateDrop,
  createDrop,
  revealDrop,
  useFetchDrops,
} from 'src/hooks/api';
import { Drop } from 'src/hooks/api/typing';
import { createRequester } from 'src/hooks/useApi';
import { useAppDispatch } from 'src/store/dispatch';
import { ErrorDataResponse, ErrorResponse } from 'src/typing';

type ConfirmDropNameFormData = {
  dropName: string;
};

function DropPage(): ReactElement {
  const navigate = useNavigate();

  const handleViewDropDetail = (selectedDrop: Drop) => {
    navigate(`/drops/${selectedDrop.id?.toString()}`);
  };

  const [modal2Open, setModal2Open] = useState<boolean>(false);
  const [modal2OpenEditDrop, setModal2OpenEditDrop] = useState<boolean>(false);
  const [drawer2OpenDropDetail, setDrawer2OpenDropDetail] =
    useState<boolean>(false);
  // const [addEligibleUsersModal2Open, setAddEligibleUsersModal2Open] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const requester = createRequester({ dispatch });

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Action succeed',
      duration: 1,
    });
  };

  const failed = (content: string) => {
    messageApi.open({
      type: 'error',
      content,
      duration: 1,
    });
  };

  const [selectedDrop, setSelectedDrop] = useState<Drop | null>(null);

  const { data: drops, refetch } = useFetchDrops();

  const onSubmitDrop = async (drop: Drop): Promise<void> => {
    try {
      await createDrop({
        requester,
        drop,
      });

      setModal2Open(false);
      refetch();
      success();
    } catch (error) {
      const { response } = error as AxiosError;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { message: errMes } = (
        (response?.data as ErrorResponse).error as ErrorDataResponse[]
      )?.[0];
      failed(errMes || 'Action failed');
    }
  };

  const onSubmitDropNewInfo = async (): Promise<void> => {
    setSelectedDrop(null);
    setModal2Open(false);
    setModal2OpenEditDrop(false);
    refetch();
    success();
  };

  const onViewDrop = (drop: Drop) => {
    setSelectedDrop(drop);
    handleViewDropDetail(drop);
  };

  const onEditDrop = (drop: Drop) => {
    setSelectedDrop(drop);
    setModal2OpenEditDrop(true);
  };

  const [form] = Form.useForm<ConfirmDropNameFormData>();
  const [isConfirmingDrop, setIsConfirmingDrop] = useState(false);
  const [modalVerify2Open, setModalVerify2Open] = useState(false);
  const [actionToConfirm, setActionToConfirm] = useState<'reveal' | 'active'>(
    'reveal',
  );

  const onConfirm = async (
    confirmDropNameFormData: ConfirmDropNameFormData,
  ) => {
    setIsConfirmingDrop(true);

    if (confirmDropNameFormData.dropName === selectedDrop?.name) {
      const { id: dropId } = selectedDrop as Drop;

      if (actionToConfirm === 'reveal') {
        await revealDrop({
          id: dropId!,
          requester,
        });
      }

      if (actionToConfirm === 'active') {
        await activateDrop({
          id: dropId!,
          requester,
        });
      }

      success();
      refetch();

      form.resetFields();
      setSelectedDrop(null);
      setModalVerify2Open(false);
    } else {
      failed('Wrong drop name');
    }

    setIsConfirmingDrop(false);
  };

  const onOpenConfirmDrop = (action: 'reveal' | 'active') => {
    setModalVerify2Open(true);
    setDrawer2OpenDropDetail(false);
    setActionToConfirm(action);
  };

  const renderConfirmTitle = () => (
    <>
      Type <span style={{ color: 'red' }}>{selectedDrop?.name}</span> to{' '}
      {actionToConfirm}
    </>
  );

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ display: 'flex' }}
      className="dropContainer"
    >
      {contextHolder}
      <Button type="primary" onClick={() => setModal2Open(true)}>
        New Drop
      </Button>
      <Card title="Drops">
        <DropList drops={drops || []} onView={onViewDrop} onEdit={onEditDrop} />
      </Card>
      <BasicModal
        title="Add Drop"
        open={modal2Open}
        onClose={(): void => setModal2Open(false)}
        width={800}
      >
        <DropForm onFinish={onSubmitDrop} dropData={null} />
      </BasicModal>
      <BasicModal
        title="Edit Drop"
        open={modal2OpenEditDrop}
        onClose={(): void => {
          setSelectedDrop(null);
          setModal2OpenEditDrop(false);
        }}
        width={800}
      >
        <DropForm onFinish={onSubmitDropNewInfo} dropData={selectedDrop} />
      </BasicModal>
      <BasicModal
        title={renderConfirmTitle()}
        open={modalVerify2Open}
        onClose={(): void => setModalVerify2Open(false)}
      >
        <Card>
          <Form name="Confirm Drop" form={form} onFinish={onConfirm}>
            <Form.Item
              name="dropName"
              rules={[
                {
                  required: true,
                  message: 'Please input drop name',
                },
              ]}
            >
              <Input placeholder="Drop name" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isConfirmingDrop}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </BasicModal>
      {selectedDrop && !modal2OpenEditDrop ? (
        <Drawer
          open={drawer2OpenDropDetail}
          size="large"
          onClose={() => setDrawer2OpenDropDetail(false)}
          extra={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  onOpenConfirmDrop('active');
                }}
                disabled={selectedDrop.mActive ?? false}
              >
                Active
              </Button>
              <Button
                type="default"
                onClick={() => {
                  onOpenConfirmDrop('reveal');
                }}
                disabled={selectedDrop.isRevealed ?? false}
              >
                Reveal
              </Button>
            </Space>
          }
        >
          <DropDetail drop={selectedDrop!} />
        </Drawer>
      ) : null}
    </Space>
  );
}

export default DropPage;
