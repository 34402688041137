import classNames from 'classnames';
import Sider from 'antd/es/layout/Sider';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCollaboratorProfile } from 'src/store/selectors';
import { Menu, MenuProps } from 'antd';
import RightTriangleIcon from 'src/components/Icons/RightTriangleIcon';
import Line from 'src/components/Icons/Line';
import YellowTriangle from 'src/components/Icons/TriangleYellow';
import { useLocation } from 'react-router-dom';
import styles from './style.module.scss';

interface SideBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: any;
}

type MenuItem = Required<MenuProps>['items'][number] & {
  order: number;
  permission?: string;
  isAllegianceOnlyMenu?: boolean;
  children?: MenuItem[];
};

type MenuItemLabelProps = {
  children: string;
  className?: string;
  hasArrow?: boolean;
  hasSelectedArrow?: boolean;
};

function MenuItemLabel({
  children,
  className,
  hasArrow = true,
  hasSelectedArrow = true,
}: MenuItemLabelProps) {
  return (
    <div className={classNames(styles.menuItem, className)}>
      {hasArrow ? <RightTriangleIcon /> : null}
      <div className={classNames(styles.arrowMenuItem, 'body1')}>
        <p className="body1">{children}</p>
      </div>
      {hasSelectedArrow ? (
        <>
          <Line className={styles.line} />
          <YellowTriangle className={styles.yellowArrow} />
        </>
      ) : null}
    </div>
  );
}

const items: MenuItem[] = [
  {
    order: 0,
    key: '/my-account',
    label: <MenuItemLabel hasArrow={false}>MY ACCOUNT</MenuItemLabel>,
    permission: '',
  },
  {
    order: 1,
    key: '/dashboard',
    label: <MenuItemLabel hasArrow={false}>DASHBOARD</MenuItemLabel>,
    permission: '',
  },
  {
    order: 2,
    key: '/assets-repository',
    label: <MenuItemLabel hasArrow={false}>ASSETS REPOSITORY</MenuItemLabel>,
    permission: '',
  },
  {
    order: 6,
    key: '/give-away',
    label: (
      <MenuItemLabel hasSelectedArrow={false} hasArrow={false}>
        GIVEAWAYS
      </MenuItemLabel>
    ),
    permission: '',
  },
  {
    order: 7,
    key: '/give-away/whitelist',
    label: (
      <MenuItemLabel className={styles.subMenuItem}>
        WHITELIST MINT SPOTS
      </MenuItemLabel>
    ),
    permission: '',
  },
  {
    order: 8,
    key: '/give-away/airdrop',
    label: (
      <MenuItemLabel className={styles.subMenuItem}>
        NFTs AIRDROPS
      </MenuItemLabel>
    ),
    permission: '',
  },
  {
    order: 9,
    key: '/give-away/merch',
    label: <MenuItemLabel className={styles.subMenuItem}>MERCH</MenuItemLabel>,
    permission: '',
  },
  {
    order: 11,
    key: '/contact-us',
    label: <MenuItemLabel hasArrow={false}>CONTACT US</MenuItemLabel>,
    permission: '',
  },
];

const allegianceMenuItem = [
  {
    order: 3,
    key: '/allegiance',
    label: <MenuItemLabel hasArrow={false}>ALLEGIANCE DECLARED</MenuItemLabel>,
    permission: '',
  },
  {
    order: 4,
    key: '/activities',
    label: <MenuItemLabel hasArrow={false}>ACTIVITIES</MenuItemLabel>,
    permission: '',
  },
  {
    order: 5,
    key: '/token-redeemed',
    label: <MenuItemLabel hasArrow={false}>REDEEM TOKENS</MenuItemLabel>,
    permission: '',
  },
  {
    order: 10,
    key: '',
    label: <MenuItemLabel hasArrow={false}>IDNTT</MenuItemLabel>,
    permission: '',
  },
  {
    order: 10,
    key: '',
    label: <MenuItemLabel hasArrow={false}>DISCOUNTS</MenuItemLabel>,
    permission: '',
  },
  {
    order: 10,
    key: '',
    label: <MenuItemLabel hasArrow={false}>REVENUE</MenuItemLabel>,
    permission: '',
  },
];

function SideBar(props: SideBarProps) {
  const location = useLocation();

  const { onClick } = props;
  const profile = useSelector(selectCollaboratorProfile);
  const [menuItems, setMenuItems] = useState<MenuItem[]>(items);

  useEffect(() => {
    if (profile && profile?.allegianceId) {
      const combinedItems = [...items, ...allegianceMenuItem];
      combinedItems.sort((a, b) => a.order - b.order);

      if (profile.name.toUpperCase() === 'IOH') {
        setMenuItems(combinedItems);
      } else if (profile.name.toUpperCase() === 'MASTERCARD') {
        const filteredItems = combinedItems.filter((item) => item.order !== 5);
        setMenuItems(filteredItems);
      } else {
        const filteredItems = combinedItems.filter(
          (item) => item.order !== 5 && item.order !== 10,
        );
        setMenuItems(filteredItems);
      }
    }
  }, [profile, profile?.allegianceId]);

  const getSelectedKey = () => {
    if (location.pathname.includes('/give-away')) {
      return ['/give-away', location.pathname];
    }

    return [location.pathname];
  };

  return (
    <Sider width={350} className={styles.siderNavigation}>
      <div className={styles.avatar}>
        <img
          src={`${process.env.REACT_APP_API_URL}v2.0/files/${profile?.avatar}`}
          alt="Logo"
        />
        <h5 className={styles.nickname}>{profile?.name}</h5>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['dashboard']}
        defaultOpenKeys={['dashboard']}
        selectedKeys={getSelectedKey()}
        className={classNames(styles.menuWrapper)}
        items={menuItems}
        onClick={onClick}
      />
    </Sider>
  );
}

export default SideBar;
