import {
  Col, Descriptions, Row,
} from 'antd';
import { Collection } from 'src/hooks/api/typing';

export type DropDetailProps = {
  collection: Collection;
};

export default function CollectionDetail(props: DropDetailProps) {
  const { collection } = props;

  return (
    <Row>
      <Col span={24}>
        <Descriptions title="Collection" column={2}>
          <Descriptions.Item label="Name" span={2}>{collection.name}</Descriptions.Item>
          <Descriptions.Item label="Total Supply" span={2}>{collection.totalSupply}</Descriptions.Item>
          <Descriptions.Item label="Contract Address" span={2}>{collection.contractAddress}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}
