import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import AirdropStatus from 'src/components/Drop/AirdropStatus';
import DropDetails from 'src/components/Drop/DropDetails';
import MintStatus from 'src/components/Drop/MintStatus';
import PrivateSaleEligibleUsers from 'src/components/Drop/PrivateSaleEligibleUsers';
import PublicSaleStats from 'src/components/Drop/PublicSaleStatus';
import WhitelistSaleStatus from 'src/components/Drop/WhitelistSaleStatus';
import {
  useFetchCollectionDetails,
  useFetchDropDetails,
  useFetchDropStatistics,
} from 'src/hooks/api';

type RouteParams = {
  id: string;
};

export default function DropDetailsPage() {
  const { id } = useParams<RouteParams>();

  const { data: dropDetails } = useFetchDropDetails(id);
  const { data: collectionDetails } = useFetchCollectionDetails(
    dropDetails?.collectionId,
  );
  const { data: dropStatistics } = useFetchDropStatistics(id);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <DropDetails drop={dropDetails!} collection={collectionDetails} />
      </Col>
      <Col span={24}>
        <MintStatus drop={dropDetails} dropStatistics={dropStatistics} />
      </Col>
      <Col span={24}>
        <WhitelistSaleStatus
          dropId={id!}
          drop={dropDetails}
          dropStatistics={dropStatistics}
        />
      </Col>
      <Col span={24}>
        <PublicSaleStats
          dropId={id!}
          drop={dropDetails}
          dropStatistics={dropStatistics}
        />
      </Col>
      <Col span={24}>
        <AirdropStatus
          dropId={id}
          drop={dropDetails}
          dropStatistics={dropStatistics}
        />
      </Col>
      <Col span={24}>
        {id ? <PrivateSaleEligibleUsers dropId={id?.toString()} /> : null}
      </Col>
    </Row>
  );
}
