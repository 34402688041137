import { Modal, ModalProps } from 'antd';
import { ReactElement, ReactNode } from 'react';

export interface AddDropModalProps extends ModalProps {
  open: boolean;
  title: ReactNode;
  children: ReactNode;
  onClose(): void;
}

function BasicModal(props: AddDropModalProps): ReactElement {
  const { open, title, onClose, children, ...modalProps } = props;

  return (
    <Modal
      title={title}
      centered
      open={open}
      onCancel={(): void => onClose()}
      footer={null}
      {...modalProps}
    >
      {children}
    </Modal>
  );
}

export default BasicModal;
