import { Card, Col, Input, Row, Statistic, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import formatDate from 'src/helper/formatDate';
import { UseFetchTotamParams, useFetchTotams } from 'src/hooks/api/totam';

import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { Totam } from 'src/types/Totam';

export default function TotamPage() {
  // Hooks
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<Totam>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();

  // Server states
  const params: UseFetchTotamParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);

  const dataSource = useFetchTotams({ ...params });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<Totam> => [
    {
      title: 'Username',
      dataIndex: ['to', 'username'],
    },
    {
      title: 'Email',
      dataIndex: ['to', 'email'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value) => <Statistic value={value} />,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 150,
      render: (value) => formatDate(value.toString()),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col span={24}>
        <Card title="Totams" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
    </Row>
  );
}
