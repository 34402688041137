import { createSlice } from '@reduxjs/toolkit';
import { signIn } from 'src/store/actions/auth-action';
import jwt from 'jwt-decode';

type JWTDecode = {
  permissions: string[];
  adminUsername: string;
  roles: string[];
};

export type AuthInitState = {
  accessToken?: string;
  permissions?: string[];
  info?: {
    username?: string;
    roles: string[];
  };
};
const AUTH_INIT_STATE: AuthInitState = {
};

const authSlice = createSlice({
  name: 'auth',
  initialState: AUTH_INIT_STATE,
  reducers: {
    signOut: (state) => {
      state.accessToken = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      const { accessToken } = action.payload;
      state.accessToken = accessToken;
      const decode: JWTDecode = jwt(accessToken);
      state.permissions = decode?.permissions || [];
      state.info = {
        username: decode?.adminUsername,
        roles: decode.roles,
      };
    });
  },
});

export const { signOut } = authSlice.actions;
const authReducer = authSlice.reducer;
export default authReducer;
