import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Notification } from 'src/types/Notification';
import useApi from '../useApi';
import { Page, PaginationParams, SearchParams, SortParams } from './typing';
import useFetch from './useFetch';

const KEY_NOTIFICATIONS_QUERIES = ['notifications'];

export type UseFetchNotificationParams = PaginationParams &
  SortParams &
  SearchParams;

export type CreateNotificationPayload = Notification;

export type UpdateNotificationPayload = CreateNotificationPayload;

export const useFetchNotifications = (params?: UseFetchNotificationParams) =>
  useFetch<Page<Notification>>({
    key: [...KEY_NOTIFICATIONS_QUERIES, params],
    url: '/admin/notifications',
    searchParams: params,
    parseFn: (data) => ({
      ...data,
      items: data.items.map((item) => ({
        ...item,
        metadata: JSON.stringify(item.metadata),
      })) as any,
    }),
  });

export const useCreateNotification = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Notification) => {
      const payload: CreateNotificationPayload = {
        type: data.type,
        title: data.title,
        content: data.content,
        metadata: data.metadata && JSON.parse(data?.metadata as any),
        userIds: data?.userIds,
      };

      return requester.post<void>('/admin/notifications', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_NOTIFICATIONS_QUERIES });
      },
    },
  );
};

export const useUpdateNotification = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Notification) => {
      const payload = {
        type: data.type,
        title: data.title,
        content: data.content,
        metadata: data.metadata && JSON.parse(data?.metadata as any),
        userIds: data?.userIds,
      };

      await requester.patch<void>(`/admin/notifications/${data?.id}`, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_NOTIFICATIONS_QUERIES });
      },
    },
  );
};

export const useUpdateSendAllUserNotification = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: any) => {
      await requester.patch<void>(`/admin/notifications/${data?.id}/users`, {
        ...data.filterUser,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_NOTIFICATIONS_QUERIES });
      },
    },
  );
};

export const useDeleteNotification = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Notification) => {
      await requester.delete<void>(`/admin/notifications/${data.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_NOTIFICATIONS_QUERIES,
        });
      },
    },
  );
};
