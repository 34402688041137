import { ProForm, ProFormTextArea } from '@ant-design/pro-components';
import { App, Button, Col, Drawer, Form, Row, Space } from 'antd';
import { useState } from 'react';
import UserDetail from 'src/components/UserDetail';
import UserTable from 'src/components/UserTable';
import { useExportUsers, useBanUsers, useUnBanUser } from 'src/hooks/api';
import { User } from 'src/types';

type BanUserForm = {
  reason?: string;
};

export default function UserPage() {
  const app = App.useApp();

  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [form] = Form.useForm<BanUserForm>();

  const { mutateAsync: mutationExportUsers } = useExportUsers();
  const { mutateAsync: banUsers } = useBanUsers();
  const { mutateAsync: unBanUser } = useUnBanUser();

  const handleExportUser = async () => {
    mutationExportUsers().then((res: any) => {
      const csvData = res.data;

      const blob = new Blob([csvData], { type: 'text/csv' });

      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link); // Required for Firefox
      link.click();

      URL.revokeObjectURL(url);
    });
  };

  const handleBanUser = async (user: User) => {
    form.resetFields();
    app.modal.confirm({
      title: (
        <span>
          Are you sure you want to BAN <i>{user.username}</i>?
        </span>
      ),
      content: (
        <Form form={form}>
          <ProForm.Group>
            <ProFormTextArea
              name="reason"
              placeholder="Please enter ban reason"
            />
          </ProForm.Group>
        </Form>
      ),
      onOk: async () => {
        const formValue = form.getFieldsValue();
        await banUsers({
          users: [user],
          reason: formValue.reason,
        });
        app.message.success('Ban successfully');
      },
    });
  };

  const handleUnBanUser = async (user: User) => {
    app.modal.confirm({
      title: (
        <span>
          Are you sure you want to REMOVE ban from <i>{user.username}</i>?
        </span>
      ),
      onOk: async () => {
        await unBanUser(user);
        app.message.success('Remove ban successfully');
      },
    });
  };

  const renderAction = (_: any, record: User) => (
    <Space>
      {record.isBanned ? (
        <Button danger onClick={() => handleUnBanUser(record)}>
          Remove ban
        </Button>
      ) : (
        <Button danger onClick={() => handleBanUser(record)}>
          Ban
        </Button>
      )}
      <Button onClick={() => setSelectedUser(record)}>View</Button>
    </Space>
  );

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Button onClick={handleExportUser}>Export Users</Button>
      <Col span={24}>
        <UserTable actionRenderer={renderAction} />
      </Col>
      {selectedUser != null && (
        <Drawer open size="large" onClose={() => setSelectedUser(undefined)}>
          <UserDetail user={selectedUser!} />
        </Drawer>
      )}
    </Row>
  );
}
