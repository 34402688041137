import { ReactElement, useEffect } from 'react';
import { Form, Input, Button, UploadFile } from 'antd';
import { Collection, CollectionPutArg } from 'src/hooks/api/typing';
import { createRequester } from 'src/hooks/useApi';
import { useAppDispatch } from 'src/store/dispatch';
import UploadImage from 'src/components/UploadImage';
import styles from './index.module.scss';

export interface CollectionFormProps {
  onFinish(collection: Collection | CollectionPutArg): Promise<void>;
  collectionData: Collection | null;
  isEdit: boolean;
}

export interface CollectionFormData {
  name: string;
  totalSupply: number;
  defaultNftImg: string;
  imageFolderURL: string;
  collectionTagImage?: string;
  collectionNotificationImage?: string;
  collectionDescription: string;
  nftDescription: string;
}

export const normFile = (e: any): UploadFile[] => {
  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};

function CollectionForm(props: CollectionFormProps): ReactElement {
  const { onFinish, collectionData, isEdit } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onChangeImage = (value: string, field: string) => {
    form.setFieldValue(field, value);
  };

  const onSubmit = (collectionFormData: CollectionFormData): void => {
    const {
      name,
      totalSupply,
      defaultNftImg,
      imageFolderURL,
      collectionTagImage,
      collectionNotificationImage,
      collectionDescription,
      nftDescription,
    } = collectionFormData;

    let collection: Collection | CollectionPutArg;

    if (isEdit) {
      const requester = createRequester({ dispatch });
      collection = {
        id: collectionData?.id as number,
        name,
        totalSupply: totalSupply as number,
        imageFolderURL,
        collectionTagImage,
        collectionNotificationImage,
        defaultNftImg,
        collectionDescription,
        nftDescription,
        requester,
      };
    } else {
      collection = {
        name,
        totalSupply,
        defaultNftImg,
        imageFolderURL,
        collectionTagImage,
        collectionNotificationImage,
        collectionDescription,
        nftDescription,
      };
    }

    onFinish(collection);
    form.resetFields();
  };

  useEffect(() => {
    if (collectionData) {
      form.setFieldsValue({
        name: collectionData.name,
        totalSupply: collectionData.totalSupply,
        defaultNftImg: collectionData.defaultNftImg,
        collectionNotificationImage: collectionData.collectionNotificationImage,
        collectionTagImage: collectionData.collectionTagImage,
        collectionDescription: collectionData.collectionDescription,
        nftDescription: collectionData.nftDescription,
      });
    }
  }, [collectionData, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      className={styles.addCollectionContainer}
      onFinish={onSubmit}
    >
      <Form.Item
        label="Collection Name"
        name="name"
        rules={[
          {
            required: !collectionData,
            message: 'Please enter the name of this NFT collection.',
          },
        ]}
      >
        <Input
          placeholder={collectionData ? collectionData.name : 'Collection Name'}
        />
      </Form.Item>
      <Form.Item
        label="Total Number of NFTs in this Collection"
        name="totalSupply"
        rules={[
          {
            required: !collectionData,
            message: 'Please enter the number of NFTs in this collection',
          },
        ]}
      >
        <Input
          placeholder={
            collectionData
              ? collectionData.totalSupply.toString()
              : 'Total Number of NFTs in this Collection'
          }
        />
      </Form.Item>
      <Form.Item
        label="Image folder URL"
        name="imageFolderURL"
        rules={[
          {
            required: !collectionData,
            message: 'Please enter the image folder URL.',
          },
        ]}
      >
        <Input
          placeholder={
            collectionData ? collectionData?.imageFolderURL : 'Image folder URL'
          }
        />
      </Form.Item>
      <Form.Item
        label="Collection Description"
        name="collectionDescription"
        rules={[
          {
            required: !collectionData,
            message: 'Please enter the collection description.',
          },
        ]}
      >
        <Input
          placeholder={
            collectionData
              ? collectionData?.collectionDescription
              : 'Collection Description'
          }
        />
      </Form.Item>
      <Form.Item
        label="NFT Description"
        name="nftDescription"
        rules={[
          {
            required: !collectionData,
            message: 'Please enter the nft description.',
          },
        ]}
      >
        <Input
          placeholder={
            collectionData ? collectionData?.nftDescription : 'NFT Description'
          }
        />
      </Form.Item>
      <Form.Item
        name={['defaultNftImg']}
        label="Default NFT Image"
        help=" Please upload image with width of 741.6px and height of 1091.2px"
      >
        <UploadImage
          value={form.getFieldValue('defaultNftImg')}
          onChange={(value: string) => onChangeImage(value, 'defaultNftImg')}
        />
      </Form.Item>
      <Form.Item
        name={['collectionTagImage']}
        label="Collection Tag Image"
        help=" Please upload image with width of 741.6px and height of 1091.2px"
      >
        <UploadImage
          value={form.getFieldValue('collectionTagImage')}
          onChange={(value: string) =>
            onChangeImage(value, 'collectionTagImage')
          }
        />
      </Form.Item>
      <Form.Item
        name={['collectionNotificationImage']}
        label="Collection Notification Image"
        help=" Please upload image with width of 741.6px and height of 1091.2px"
      >
        <UploadImage
          value={form.getFieldValue('collectionNotificationImage')}
          onChange={(value: string) =>
            onChangeImage(value, 'collectionNotificationImage')
          }
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CollectionForm;
