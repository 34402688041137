import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo } from 'react';
import formatDate from 'src/helper/formatDate';
import {
  UseFetchActivitySubmissionsParams,
  useFetchActivitySubmissions,
} from 'src/hooks/api/activity-submission';
import { Page } from 'src/hooks/api/typing';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { ActivitySubmission, User } from 'src/types';

export type UserDetailProps = {
  user: User;
};

export default function UserActivitySubmission(props: UserDetailProps) {
  // Hooks
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<ActivitySubmission>();
  const { mapTableParams } = useMapTableParam();
  const { user } = props;
  // Server states
  const params: UseFetchActivitySubmissionsParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
      userId: user?.id.toString(),
    };
  }, [mapTableParams, pagination, sorters, user]);
  const dataSource = useFetchActivitySubmissions({ ...params });

  useEffect(() => {
    syncDataPagination(dataSource.data as Page<ActivitySubmission> | undefined);
  }, [syncDataPagination, dataSource.data]);

  const renderColumns = (): ColumnsType<ActivitySubmission> => [
    {
      title: 'Activity',
      dataIndex: ['activity', 'title'],
    },
    {
      title: 'XP tokens',
      dataIndex: ['activity', 'xpAward'],
      width: 100,
      responsive: ['xl'],
    },
    {
      title: 'Submitted date',
      dataIndex: 'submittedDate',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 180,
      render: (value) => (value ? formatDate(value) : 'N/A'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => formatDate(value),
    },
  ];

  return (
    <Table
      style={{ width: '100%' }}
      columns={renderColumns()}
      dataSource={dataSource.data?.items as never}
      loading={dataSource.isLoading}
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}
