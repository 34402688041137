import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import NoAccess from 'src/pages/NoAccess';
import { selectHasPermission, selectIsSignedIn } from 'src/store/selectors';

export type ProtectedRouteProps = {
  children: JSX.Element;
  permission?: string;
};

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { children, permission } = props;

  const hasPermissions = useSelector(selectHasPermission(permission));

  const isSignedIn = useSelector(selectIsSignedIn);

  if (!isSignedIn) {
    return <Navigate to="/sign-in" replace />;
  }

  if (permission && !hasPermissions) return <NoAccess />;

  return children;
}

ProtectedRoute.defaultProps = {
  permission: '',
};
