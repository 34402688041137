import { Navigate, createBrowserRouter } from 'react-router-dom';

import EmailMessagePage from 'src/pages/EmailMessage';
import NFTMintersPage from 'src/pages/NFTMinters';
import NotificationPage from 'src/pages/Notification';
import PaymentIntentPage from 'src/pages/PaymentIntent';
import Tag from 'src/pages/Tag';
import TotamPage from 'src/pages/Totam';
import PrivateSaleManager from 'src/pages/Tools/PrivateSaleManager';
import ProtectedRoute from '../components/ProtectedRoute';
import { SystemPermissions } from '../constants/roles-permissions';

// Pages
import ActivityPage from '../pages/Activity';
import ActivitySubmissionsPage from '../pages/ActivitySubmission';
import AdminPage from '../pages/Admin';
import AirdropPage from '../pages/Airdrop';
import AllegiancePage from '../pages/Allegiance';
import AllegianceSubmissionsPage from '../pages/AllegianceSubmission';
import App from '../pages/App';
import AssetsPage from '../pages/Assest';
import CollaboratorPage from '../pages/Collaborator';
import CollectionPage from '../pages/Collection';
import Dashboard from '../pages/Dashboard';
import DiscountPage from '../pages/Discount';
import DropPage from '../pages/Drop';
import DropDetailsPage from '../pages/DropDetails';
import FaqPage from '../pages/Faq';
import GiveAwayPage from '../pages/GiveAway';
import GiveAwayDetailPage from '../pages/GiveAwayDetail';
import NotFound from '../pages/NotFound';
import ReferralLedgerPage from '../pages/ReferralLedger';
import ReferralBoardPage from '../pages/RefferralBoard';
import RolePage from '../pages/Role';
import SignIn from '../pages/SignIn';
import DeployCollectionNFT from '../pages/Tools/DeployCollectionNFT';
import CollectionManager from '../pages/Tools/CollectionManager';
import UserPage from '../pages/User';
import WhitelistUsers from '../pages/WhitelistUsers';
import XPGrantPage from '../pages/XPGrant';
import XPLedgerPage from '../pages/XPLedger';

const AdminRouter = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    errorElement: <Navigate to="/404" replace />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: '/dashboard',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_DASHBOARD.key}
          >
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: '/users',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_USER.key}>
            <UserPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admins',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_ADMIN.key}>
            <AdminPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/roles',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_ROLE.key}>
            <RolePage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/collections',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_COLLECTION.key}
          >
            <CollectionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/drops',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_DROP.key}>
            <DropPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/drops/:id',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_DROP.key}>
            <DropDetailsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/whitelist-users',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_WHITELIST_USER.key}
          >
            <WhitelistUsers />
          </ProtectedRoute>
        ),
      },
      {
        path: '/deploy-collection-nft',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_DEPLOY_COLLECTION_NFT.key}
          >
            <DeployCollectionNFT />
          </ProtectedRoute>
        ),
      },
      {
        path: '/collection-manager',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_COLLECTION_MANAGER.key}
          >
            <CollectionManager />
          </ProtectedRoute>
        ),
      },
      {
        path: '/private-sale-manager',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_PRIVATE_SALE_MANAGER.key}
          >
            <PrivateSaleManager />
          </ProtectedRoute>
        ),
      },
      {
        path: '/airdrop',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_AIRDROP.key}>
            <AirdropPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/activities',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_ACTIVITY.key}>
            <ActivityPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/activity-submissions',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_ACTIVITY_SUBMISSION.key}
          >
            <ActivitySubmissionsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/discount',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_DISCOUNT.key}>
            <DiscountPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/faq',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_DISCOUNT.key}>
            <FaqPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/referral-ledger',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_REFERRAL_LEDGER.key}
          >
            <ReferralLedgerPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/referral-board',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_REFERRAL_BOARD.key}
          >
            <ReferralBoardPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/assets-repository',
        element: (
          <ProtectedRoute>
            <AssetsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/allegiances',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_ALLEGIANCE.key}
          >
            <AllegiancePage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/allegiance-submissions',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_ALLEGIANCE_SUBMISSION.key}
          >
            <AllegianceSubmissionsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/xp-ledgers',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_LEDGER.key}>
            <XPLedgerPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/xp-grants',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_GRANT_XP.key}>
            <XPGrantPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/collaborators',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_COLLABORATOR.key}
          >
            <CollaboratorPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/notifications',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_ALL_NOTIFICATION.key}
          >
            <NotificationPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/tags',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_TAG.key}>
            <Tag />
          </ProtectedRoute>
        ),
      },

      {
        path: '/email-messages',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_EMAIL_MESSAGE.key}
          >
            <EmailMessagePage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/give-aways',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_GIVE_AWAY.key}
          >
            <GiveAwayPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/give-aways/:id',
        element: (
          <ProtectedRoute
            permission={SystemPermissions.VIEW_PAGE_GIVE_AWAY.key}
          >
            <GiveAwayDetailPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/totams',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_TOTAM.key}>
            <TotamPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/transactions',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_TOTAM.key}>
            <PaymentIntentPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/nfts-minters',
        element: (
          <ProtectedRoute permission={SystemPermissions.VIEW_PAGE_DROP.key}>
            <NFTMintersPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/404',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '/sign-in',
    element: <SignIn />,
  },
]);

export default AdminRouter;
