import { useMemo } from 'react';
import { TabItem } from 'src/types';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Tabs from 'src/pages/CollaboratorDashboard/_shared/components/Tabs';
import Input from 'src/pages/CollaboratorDashboard/_shared/components/Input';
import styles from './styles.module.scss';

export default function MinSpotsFaq() {
  const navigate = useNavigate();

  const tabs: TabItem[] = useMemo(
    () => [
      {
        key: 'op1',
        label: 'Option 1',
        children: (
          <div className="color-white mt-24">
            <div className="mb-24">
              <div className="subtitle1 mb-8">
                How to award whitelist mint spots?
              </div>
              <div className="body2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet doloremagna
                aliquam erat volutpat. Ut wisi
              </div>
            </div>
            <div className="mb-24">
              <div className="subtitle1 mb-8">
                How to award whitelist mint spots?
              </div>
              <div className="body2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet doloremagna
                aliquam erat volutpat. Ut wisi
              </div>
            </div>
            <div className="mb-24">
              <div className="subtitle1 mb-8">
                How to award whitelist mint spots?
              </div>
              <div className="body2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet doloremagna
                aliquam erat volutpat. Ut wisi
              </div>
            </div>
            <div className="mb-24">
              <div className="subtitle1 mb-8">
                How to award whitelist mint spots?
              </div>
              <div className="body2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet doloremagna
                aliquam erat volutpat. Ut wisi
              </div>
            </div>
            <div>
              <div className="subtitle1 mb-8">
                How to award whitelist mint spots?
              </div>
              <div className="body2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet doloremagna
                aliquam erat volutpat. Ut wisi
              </div>
            </div>
          </div>
        ),
      },
      {
        key: 'op2',
        label: 'Option 2',
        children: (
          <div className="color-white  mt-24">
            <div>
              <div className="subtitle1 mb-16">Assign a wallet</div>
            </div>
            <Input placeholder="Enter wallet address" className="w-half" />
            <div className="mb-24 mt-24">
              <div className="subtitle1 mb-8">
                How to award whitelist mint spots?
              </div>
              <div className="body2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet doloremagna
                aliquam erat volutpat. Ut wisi
              </div>
            </div>
            <div className="mb-24">
              <div className="subtitle1 mb-8">
                How to award whitelist mint spots?
              </div>
              <div className="body2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet doloremagna
                aliquam erat volutpat. Ut wisi
              </div>
            </div>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header, 'flex items-center')}>
        <div className="flex items-center w-full">
          <div
            className={classNames(styles.backButton, 'flex w-max-content')}
            onClick={() => navigate('../airdrop')}
          >
            <div className={styles.backIcon}>{'<<'}</div>
            <div className="body1 ml-4">Back</div>
          </div>
          <div className={classNames(styles.title, 'subtitle1')}>
            How to award whitelist mint spots?
          </div>
        </div>
      </div>
      <div className="px-80 mt-8">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
}
