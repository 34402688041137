import { TablePaginationConfig } from 'antd/es/table';
import { SorterResult, SortOrder, FilterValue } from 'antd/es/table/interface';
import { useCallback } from 'react';
import { SortDirection, SortParams } from './api/typing';

const SORT_MAP: Record<NonNullable<SortOrder>, SortDirection> = {
  ascend: 'ASC',
  descend: 'DESC',
};

export type MapTableParamOption<E> = {
  pagination: TablePaginationConfig;
  sorters?: SorterResult<E>[];
  filters?: Record<string, FilterValue | null>;
};

export default function useMapTableParam() {
  const mapTableParams = useCallback(<E>(options: MapTableParamOption<E>) => {
    const { pagination, sorters, filters } = options;

    const sortDirection = sorters?.[0].order != null ? SORT_MAP[sorters[0].order] : undefined;

    const sortParams: SortParams | null = sortDirection != null
      ? {
        sortDirection,
        sortBy: sorters?.[0].field as string,
      }
      : null;

    return {
      ...sortParams,
      page: pagination.current?.toString(),
      limit: pagination.pageSize?.toString(),
      ...filters,
    };
  }, []);

  return { mapTableParams };
}
