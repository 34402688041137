import { useMutation } from '@tanstack/react-query';
import useApi from '../useApi';

const toEmail = process.env.REACT_APP_ENVIRONMENT === 'production'
  ? 'collab@planettota.com'
  : 'kaundi.k@planettota.com';

export interface ContactFormValues {
  to: string;
  subject: string;
  name?: string;
  email?: string;
  companyName: string;
  companyUrl: string;
  phoneNumber: string;
  message?: string;
}

export const useSendEmail = () => {
  const { requester } = useApi();
  return useMutation(
    async (emailData: {
      subject: string;
      name?: string;
      email?: string;
      message?: string;
    }) => {
      const payload: ContactFormValues = {
        ...emailData,
        to: toEmail,
        companyName: '',
        companyUrl: '',
        phoneNumber: '',
      };
      requester.post('/emails', payload);
    },
  );
};

export default useSendEmail;
