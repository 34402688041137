import { useCallback } from 'react';
import { GiveAway, GiveAwayType } from 'src/types/GiveAway';

export default function useRenderItemName() {
  const renderItemName = useCallback((giveAway: GiveAway) => {
    if (giveAway.type === GiveAwayType.DropWhitelist) {
      return 'Whitelist spot';
    }

    if (giveAway.type === GiveAwayType.DropAirdrop) {
      return 'Airdrop spot';
    }

    return giveAway.itemName;
  }, []);

  return { renderItemName };
}
