import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  Address,
} from 'wagmi';
import { getExplorerUrl } from 'src/helper/web3helper';
import { Button } from 'antd';
import privateSaleABI from '../../../abi/private-sale.json';

export default function Withdraw(props: { privateSaleAddress: Address }) {
  const privateSaleAddress = props.privateSaleAddress;
  const { config } = usePrepareContractWrite({
    abi: privateSaleABI,
    address: privateSaleAddress,
    functionName: 'withdraw',
  });

  const { data, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  return (
    <div>
      <Button
        type="primary"
        disabled={!write || isLoading}
        onClick={() => write?.()}
      >
        {isLoading ? 'Withdraw...' : 'Withdraw'}
      </Button>
      {isSuccess ? (
        <div>
          Successfully Withdraw!
          <div>
            <a href={`${getExplorerUrl()}/tx/${data?.hash}`}>Etherscan</a>
          </div>
        </div>
      ) : null}
    </div>
  );
}
