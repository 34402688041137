import
{
  Button, Card,
  Col,
  Drawer,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Space,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';

export type GrantXPProps = {
  open: boolean;
  onClose: () => void;
  onSubmit?: (data: GrantXPFormData) => void;
};

export type GrantXPFormData = {
  amount: number;
  description: string;
};

function GrantXP(props: GrantXPProps) {
  const {
    open, onClose = () => {}, onSubmit = () => {},
  } = props;

  const [form] = Form.useForm<GrantXPFormData>();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (formInstance: FormInstance<GrantXPFormData>) => {
    await formInstance.validateFields();
    const formValues = form.getFieldsValue();

    onSubmit({
      ...formValues,
    });
  };

  return (
    <Drawer
      title="Grant XP's"
      width={720}
      onClose={handleClose}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      extra={(
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => handleSubmit(form)}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      )}
    >
      <Space
        direction="vertical"
        size="small"
        style={{
          display: 'flex',
          marginBottom: 20,
        }}
      >
        <Card title="Grant">
          <Form
            name="grant-xp"
            form={form}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[
                    {
                      required: true,
                      message: 'Please input xps',
                    },
                  ]}
                >
                  <InputNumber placeholder="Number of XP's" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                >
                  <TextArea
                    placeholder="description"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Space>

    </Drawer>
  );
}

export default GrantXP;
