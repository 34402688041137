import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  SelectProps,
  Space,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import { useEffect, useMemo, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import dayjs, { Dayjs } from 'dayjs';
import { Tag, TagCollection } from 'src/types/Tag';
import { useFetchCollections } from 'src/hooks/api';
import UploadImage from '../UploadImage';

const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const { RangePicker } = DatePicker;

type TagForm = Omit<Tag, 'emailConfig' | 'collection'> & {
  emailConfig: {
    banner: string;
    content: string;
  };
  collection?: Omit<TagCollection, 'syncWindow'> & {
    syncWindow: [Dayjs, Dayjs];
  };
};

function CollectionSelect(props: Omit<SelectProps, 'options' | 'loading'>) {
  // Server state
  const { data: collection, isLoading } = useFetchCollections();
  // Deprived state
  const options = useMemo<SelectProps['options']>(() => {
    if (collection == null) {
      return undefined;
    }

    return collection.map((item) => ({
      label: item.name,
      value: item.id?.toString(),
    }));
  }, [collection]);

  return (
    <Select
      {...props}
      placeholder="Select collection"
      options={options}
      loading={isLoading}
    />
  );
}

export type CreateTagProps = {
  open: boolean;
  tag?: Tag;
  onClose?: () => void;
  onSubmit?: (values: PartialBy<Tag, 'id'>) => void;
};

export default function CreateTag(props: CreateTagProps) {
  const [syncWindowDate, setSyncWindowDate] = useState<
    [Dayjs | null, Dayjs | null]
  >([null, null]);

  const { open, tag, onSubmit = () => {}, onClose = () => {} } = props;

  const [form] = Form.useForm<TagForm>();
  const formData: TagForm | undefined = useMemo(() => {
    if (tag == null) {
      return undefined;
    }

    return {
      ...tag,
      emailConfig: {
        banner: tag.emailConfig.banner,
        content: tag.emailConfig.content.join('\n'),
      },
      collection:
        tag.collection != null
          ? {
              ...tag.collection,
              syncWindow: [
                dayjs(tag.collection.syncWindow.startTime, TIME_FORMAT),
                dayjs(tag.collection.syncWindow.endTime, TIME_FORMAT),
              ],
            }
          : undefined,
    };
  }, [tag]);

  useEffect(() => {
    if (formData != null) {
      form.setFieldsValue(formData);
    } else {
      form.resetFields();
    }
  }, [form, formData]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (formInstance: FormInstance<TagForm>) => {
    await formInstance.validateFields();
    const formValues = form.getFieldsValue();
    onSubmit({
      ...formValues,
      id: tag?.id,
      emailConfig: {
        ...formValues.emailConfig,
        content: formValues.emailConfig.content.split('\n'),
      },
      collection:
        formValues.collection != null &&
        formValues.collection.collectionId != null
          ? {
              ...formValues.collection,
              syncWindow: {
                startTime:
                  formValues.collection.syncWindow[0].format(TIME_FORMAT),
                endTime:
                  formValues.collection.syncWindow[1].format(TIME_FORMAT),
              },
            }
          : undefined,
    });
  };

  return (
    <Drawer
      open={open}
      title={tag != null ? 'Update tag ' : 'Add tag'}
      size="large"
      extra={
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit(form);
            }}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      }
      onClose={handleClose}
    >
      <Form id="create-tag" layout="vertical" form={form}>
        <Space direction="vertical">
          <Card title="Tag details">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input name',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="amountUser"
                  label="Amount per user"
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount user  ',
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="icon" label="Icon">
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="notificationIcon"
                  label="Notification icon"
                  help=" Please upload icon with SVG format"
                >
                  <UploadImage />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Collection detail">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name={['collection', 'collectionId']}
                  label="Collection"
                >
                  <CollectionSelect allowClear />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['collection', 'isRemoveOnTransfer']}
                  label="Remove tag on transfer"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['collection', 'syncWindow']}
                  label="Sync window"
                >
                  <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    value={syncWindowDate}
                    onChange={(value) => {
                      if (value) {
                        setSyncWindowDate([
                          value[0] as Dayjs,
                          value[1] as Dayjs,
                        ]);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title=" Tag email">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name={['emailConfig', 'banner']}
                  label="Email banner"
                >
                  <UploadImage prefix="tags/emails/banners" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['emailConfig', 'content']}
                  label="Email content"
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount user  ',
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Space>
      </Form>
    </Drawer>
  );
}
