import { ReactNode, useState } from 'react';
import {
  Button,
  Form,
  Popover,
  Space,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';

export type FilterProps<T> = {
  onReset?: () => void;
  onSubmit?: (data: T) => void;
  children?: ReactNode;
};

export default function BaseFilter<T extends Record<string, unknown>>(props: FilterProps<T>) {
  const {
    onSubmit = () => {},
    onReset = () => {},
    children,
  } = props;

  const [open, setOpen] = useState(false);

  const [form] = Form.useForm<T>();

  const handleSubmit = (data: T) => {
    onSubmit(data);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleReset = () => {
    form.resetFields();
    onReset();
  };

  const hasFilter = () => {
    const values = form.getFieldsValue();
    return Object
      .values(values)
      .some((value) => value !== undefined && value !== '');
  };

  const renderContent = () => (
    <Form form={form} onFinish={handleSubmit}>
      {children}
      <Form.Item style={{ textAlign: 'right' }}>
        <Space size="small">
          <Button type="dashed" onClick={handleReset}>Reset</Button>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );

  return (
    <Popover
      open={open}
      title="Filter"
      trigger="click"
      content={renderContent}
      onOpenChange={handleOpen}
    >
      <Button type={hasFilter() ? 'primary' : 'default'}>
        <FilterOutlined />
      </Button>
    </Popover>
  );
}
