import { useMutation } from '@tanstack/react-query';
import { User } from 'src/types';
import useApi from '../useApi';

export type GrantXpRequest = {
  users: User[];
  amount: number;
  description: string;
};

export const useGrantXP = () => {
  const { requester } = useApi();

  return useMutation(
    async (data: GrantXpRequest) => {
      const payload = {
        amount: data.amount,
        description: data.description,
        userIds: data.users.map((user) => user.id),
      };
      requester.post<void>(
        '/admin/users/xps',
        payload,
      );
    },
  );
};
