import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useMemo, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { User } from 'src/types';
import { Notification, NotificationType } from 'src/types/Notification';
import UserTable, { TypeUserTable } from '../UserTable';

export type NotificationForm = Omit<Notification, 'metadata'> & {
  selectedAllUser?: boolean;
  filterUser?: any;
};

export type CreateNotificationProps = {
  open: boolean;
  notification?: Notification;
  onClose?: () => void;
  onSubmit?: (values: NotificationForm) => void;
};

const typeOptions = [
  {
    label: 'Global',
    value: 0,
  },
  {
    label: 'User',
    value: 1,
  },
];

export default function CreateNotification(props: CreateNotificationProps) {
  const { open, notification, onSubmit = () => {}, onClose = () => {} } = props;
  const [selectedAllUser, setSelectedAllUser] = useState(false);
  const [filterUser, setFilterUser] = useState(null);

  const [selectedType, setSelectedType] = useState<NotificationType>(
    NotificationType.Global,
  );
  const [selectedUserIds, setSelectedUserIds] = useState<string[] | undefined>(
    undefined,
  );

  const [form] = Form.useForm<NotificationForm>();
  const formData: NotificationForm | undefined = useMemo(() => {
    if (notification == null) {
      return undefined;
    }

    return {
      ...notification,
    };
  }, [notification]);

  useEffect(() => {
    if (formData != null) {
      form.setFieldsValue(formData);
      setSelectedType(formData.type);

      if (formData.type === NotificationType.User) {
        setSelectedUserIds(formData?.userIds);
      }
    } else {
      form.resetFields();
    }
  }, [form, formData]);

  const handleClose = () => {
    onClose();
  };

  const handleChangeType = (value: NotificationType) => {
    setSelectedType(value);
  };

  const handleSelectedAllUser = (value: boolean) => {
    setSelectedAllUser(value);
  };

  const handleFilterUser = (value: any) => {
    setFilterUser(value);
  };

  const handleSubmit = async (formInstance: FormInstance<NotificationForm>) => {
    await formInstance.validateFields();
    const formValues = form.getFieldsValue();

    onSubmit({
      id: notification?.id,
      ...formValues,
      userIds: selectedUserIds,
      type: selectedType,
      selectedAllUser,
      filterUser,
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedUserIds?.map((id) => +id),
    onChange: (selectedRowKeys: React.Key[], selectedRows: User[]) => {
      setSelectedUserIds(selectedRows?.map((item) => item?.id?.toString()));
    },
  };

  return (
    <Drawer
      open={open}
      title={notification != null ? 'Update notification ' : 'Add notification'}
      size="large"
      extra={
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit(form);
            }}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      }
      onClose={handleClose}
    >
      <Form id="create-notification" layout="vertical" form={form}>
        <Space direction="vertical">
          <Card title=" Notification details">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: false,
                      message: 'Please select title',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="content"
                  label="Content"
                  rules={[
                    {
                      required: false,
                      message: 'Please select content',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="type"
                  label="Notification Type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={NotificationType.Global}
                >
                  <Select
                    style={{ width: 200 }}
                    placeholder="Select type"
                    options={typeOptions}
                    onChange={handleChangeType}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['metadata']}
                  label="Notification metadata"
                  help={`Example:   {
                          "image": "https://asset.planettota.com/image/connect.png",
                          "subtitle": "Create a handle, use your email ID, and create a password. That’s it!"
                          }`}
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: ' Notification metadata cannot be empty',
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Please enter metadata" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          {selectedType === NotificationType.User && (
            <UserTable
              type={TypeUserTable.DRAWER}
              isSelect
              rowSelection={rowSelection}
              onSelectedAll={(value) => handleSelectedAllUser(value)}
              selectedAllUser={selectedAllUser}
              onFilter={handleFilterUser}
            />
          )}
        </Space>
      </Form>
    </Drawer>
  );
}
