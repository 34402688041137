import { Switch, SwitchProps } from 'antd';
import classNames from 'classnames';
import styles from './style.module.scss';

export default function SwitchStyled(props: SwitchProps) {
  return (
    <div className={classNames(styles.container, 'flex items-center')}>
      <div className={classNames(styles.leftText, 'body2 color-black')}>
        {props.checked ? '' : 'NO'}
      </div>
      <Switch
        size="small"
        {...props}
        className={classNames(styles.customSwitch, props.className)}
      />
      <div className={classNames(styles.rightText, 'body2 color-primary')}>
        {props.checked ? 'YES' : ''}
      </div>
    </div>
  );
}
