import { Modal, ModalProps } from 'antd';
import { TagUserDetail } from 'src/types';
import TagUserTable from './TagUserTable';

export type AssignTagValue = {
  tagId: number;
  userIds: string[];
};

export type TagAssignModalProps = Omit<ModalProps, 'onOk'> & {
  tagUserDetail: TagUserDetail;
};

export default function TagDetailUserModal(props: TagAssignModalProps) {
  const { tagUserDetail, onCancel = () => {}, ...otherProps } = props;

  const handleCancel: ModalProps['onCancel'] = (e) => {
    onCancel(e);
  };

  return (
    <Modal
      {...otherProps}
      title={`${tagUserDetail.username}-${tagUserDetail.tag.name} `}
      centered
      width={1024}
      footer={false}
      onCancel={handleCancel}
    >
      <TagUserTable tagUserDetail={tagUserDetail} />
    </Modal>
  );
}
