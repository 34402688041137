import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Collaborator } from 'src/types/Collaborator';
import useApi from '../useApi';
import {
  Page,
  PaginationParams,
  SearchParams,
  SortParams,
  User,
} from './typing';
import useFetch from './useFetch';

const KEY_MANAGE_COLLABORATOR_QUERIES = ['collaborators'];

export type UseFetchCollaboratorParams = PaginationParams &
  SortParams &
  SearchParams;
export type CollaboratorForm = Omit<Collaborator, 'createdAt' | 'updatedAt'> & {
  password: string;
};

export type UseFetchCollaboratorUserParams = PaginationParams &
  SortParams &
  SearchParams;

type CreateCollaboratorRequest = {
  email: string;
  password: string;
  referralXP: number;
  name: string;
  amountUserWhitelist: number;
  amountUserAirdrop: number;
  userList?: Record<string, any>[];
};

type UpdateCollaboratorRequest = {
  name: string;
  email: string;
  password: string;
  referralXP: number;
  amountUserWhitelist: number;
  amountUserAirdrop: number;
  allegianceId: string | null;
  activityIds: string | [];
  userList: Record<string, string | number | boolean>[];
  userToRemove?: string[];
};

export const useFetchCollaborators = (params?: UseFetchCollaboratorParams) =>
  useFetch<Page<Collaborator>, Page<Collaborator>>({
    key: [...KEY_MANAGE_COLLABORATOR_QUERIES, params],
    url: '/collaborator',
    searchParams: params,
    parseFn: (data) => {
      const items = data.items.map(
        (res): Collaborator => ({
          id: res.id,
          name: res.name,
          email: res.email,
          referralXP: res.referralXP,
          amountUserWhitelist: res.amountUserWhitelist,
          amountUserAirdrop: res.amountUserAirdrop,
          refCode: res.refCode,
          allegianceId: res.allegianceId,
          avatar: res.avatar,
          activities: res.activities,
          createdAt: res.createdAt,
          updatedAt: res.updatedAt,
        }),
      );

      return {
        ...data,
        items,
      };
    },
  });

export const useCreateCollaborator = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: CollaboratorForm) => {
      const payload: CreateCollaboratorRequest = {
        ...data,
      };

      payload.userList = data.users;

      return requester.post<void, CreateCollaboratorRequest>(
        '/collaborator',
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_MANAGE_COLLABORATOR_QUERIES,
        });
      },
    },
  );
};

export const useUpdateCollaborator = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async ({
      collaboratorID,
      data,
    }: {
      collaboratorID: number;
      data: UpdateCollaboratorRequest;
    }) => {
      const payload: UpdateCollaboratorRequest = {
        ...data,
        allegianceId: data.allegianceId ? data.allegianceId : null,
      };

      return requester.patch<void, UpdateCollaboratorRequest>(
        `/collaborator/${collaboratorID}`,
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_MANAGE_COLLABORATOR_QUERIES,
        });
      },
    },
  );
};

export const useFetchCollaboratorDetail = (id?: number | null) =>
  useFetch<Collaborator>({
    key: [...KEY_MANAGE_COLLABORATOR_QUERIES, `collaborator-${id}`],
    url: `/collaborator/${id || -1}`,
  });

export const useDeleteCollaborator = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Collaborator) => {
      await requester.delete<void>(`/collaborator/${data.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_MANAGE_COLLABORATOR_QUERIES,
        });
      },
    },
  );
};

export const useFetchCollaboratorUsers = (
  id: number,
  params: UseFetchCollaboratorUserParams,
) =>
  useFetch<Page<User>>({
    key: ['collaborator-users', params],
    url: `/collaborator/${id}/users`,
    searchParams: params,
  });
