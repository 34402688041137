import { useState } from 'react';
import {
  Button, Form, Input, notification,
} from 'antd';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { selectCollaboratorProfile } from 'src/store/selectors';
import useSendEmail from 'src/hooks/api/email';
import { ErrorDataResponse } from 'src/typing';

import styles from './style.module.scss';

function ContactUs() {
  const [form] = Form.useForm();
  const { mutateAsync: sendEmailMutate } = useSendEmail();
  const profile = useSelector(selectCollaboratorProfile);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const onFinish = async (values: { message: string }) => {
    const formData = {
      subject: 'New message from collaborator',
      name: profile?.name,
      email: profile?.email,
      message: values.message,
    };

    try {
      await sendEmailMutate(formData);
      form.resetFields();
      setIsFormSuccess(true);

      setTimeout(() => {
        setIsFormSuccess(false);
      }, 3000);
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Message submission failed: ${errorMessage}`,
      });
    }
  };

  return (
    <div className={styles.contactFormWrapper}>
      <div className="pageHeader">
        <h5>DROP A NOTE</h5>
      </div>
      {isFormSuccess ? (
        <div className={styles.successMessage}>
          <p>
            <span>Thank you for your note</span>
            We will get back to you at the earliest
          </p>
        </div>
      ) : (
        <Form form={form} onFinish={onFinish} className={styles.formWrapper}>
          <Form.Item
            name="message"
            rules={[
              {
                required: true,
                message: 'Please enter your message!',
              },
              {
                type: 'string',
                min: 20,
              },
            ]}
          >
            <Input.TextArea
              autoSize={{
                minRows: 15,
                maxRows: 15,
              }}
              placeholder="Enter your message"
              className={styles.textarea}
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              className={styles.submitBtn}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}

    </div>
  );
}

export default ContactUs;
