import { Asset } from 'src/types/Asset';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import
{
  Page, PaginationParams, SearchParams, SortParams,
} from './typing';
import useFetch from './useFetch';
import useApi from '../useApi';

const KEY_ASSETS_QUERIES = ['assets'];

export type UseFetchAssetsParams = PaginationParams & SortParams & SearchParams;

export const useFetchAssets = (params?: UseFetchAssetsParams) => useFetch<Page<Asset>>({
  key: [...KEY_ASSETS_QUERIES, params],
  url: '/assets',
  searchParams: params,
});

export const useCreateAsset = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Asset) => {
      const payload: Asset = {
        ...data,
      };

      return requester.post<void>(
        '/assets',
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_ASSETS_QUERIES });
      },
    },
  );
};

export const useUpdateAsset = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Asset) => {
      const payload = {
        ...data,
        collaborators: data.collaborators,
      };

      await requester.patch<void>(
        `/assets/${data.id}`,
        payload,

      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_ASSETS_QUERIES });
      },
    },
  );
};
