import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Activity,
  ActivityStatus,
  ActivityType,
  ActivityWinner,
} from 'src/types/Activity';
import { ProofType, SubmissionProcessType } from 'src/types/Submission';
import useApi from '../useApi';
import { Page, PaginationParams, SearchParams, SortParams } from './typing';
import useFetch from './useFetch';

const KEY_MANAGE_ACTIVITY_QUERIES = ['manageActivities'];

export type CreateSubmissionRequest = {
  title: string;
  disclaimer: string;
  text: string;
  proofType: ProofType;
  proofLogo: string;
  proofImage: string;
  isAutoDeleteSubmission: boolean;
  isRecurring: boolean;
  submissionProcess?: SubmissionProcessType;
  maxAllowed?: number;
  maxEntries?: number;
  requiredEntries?: number;
};

type CreateActivityRequest = {
  type: ActivityType;
  title: string;
  description: string;
  image: string;
  notificationImage: string;
  xpAward: number;
  startDate: Date;
  endDate: Date;
  status: ActivityStatus;
  allegianceId?: string;
  allegianceUrl?: string;
  submissionType: CreateSubmissionRequest;
};

type UpdateActivityRequest = CreateActivityRequest;

export type SubmissionTypeResponse = {
  id: number;
  proofType: ProofType;
  proofLogo: string;
  proofImage: string;
  title: string;
  text: string;
  disclaimer: string;
  isAutoDeleteSubmission: boolean;
  isRecurring: boolean;
  submissionProcess?: SubmissionProcessType;
  maxAllowed?: number;
  maxEntries?: number;
  requiredEntries?: number;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
};

export type ActivityResponse = {
  id?: number;
  type: ActivityType;
  slug: string;
  title: string;
  description: string;
  image: string;
  notificationImage: string;
  xpAward: number;
  startDate: string;
  endDate: string;
  status: ActivityStatus;
  submissionType: SubmissionTypeResponse;
  allegianceId: string;
  allegianceUrl: string;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
};

export type UseFetchActivityParams = PaginationParams &
  SortParams &
  SearchParams;

export type UseFetchActivityWinnerParams = PaginationParams &
  SortParams &
  SearchParams;

export const useFetchActivities = (params?: UseFetchActivityParams) =>
  useFetch<Page<ActivityResponse>, Page<Activity>>({
    key: [...KEY_MANAGE_ACTIVITY_QUERIES, params],
    url: 'admin/activities',
    searchParams: params,
    parseFn: (data) => {
      const items = data.items.map(
        (res): Activity => ({
          id: res.id,
          type: res.type,
          slug: res.slug,
          title: res.title,
          description: res.description,
          image: res.image,
          notificationImage: res.notificationImage || '',
          startDate: new Date(res.startDate),
          endDate: new Date(res.endDate),
          xpAward: res.xpAward,
          status: res.status,
          allegianceId: res.allegianceId,
          allegianceUrl: res.allegianceUrl,
          submissionType: {
            id: res.submissionType.id,
            proofLogo: res.submissionType.proofLogo,
            proofImage: res.submissionType.proofImage,
            proofType: res.submissionType.proofType,
            title: res.submissionType.title,
            text: res.submissionType.text,
            disclaimer: res.submissionType.disclaimer,
            isAutoDeleteSubmission: res.submissionType.isAutoDeleteSubmission,
            isRecurring: res.submissionType.isRecurring,
            submissionProcess: res.submissionType.submissionProcess,
            maxAllowed: res.submissionType.maxAllowed,
            maxEntries: res.submissionType.maxEntries,
            requiredEntries: res.submissionType.requiredEntries,
            createdAt: new Date(res.submissionType.createdAt),
            updatedAt:
              res.submissionType.updatedAt != null
                ? new Date(res.submissionType.updatedAt)
                : undefined,
            deletedAt:
              res.deletedAt != null ? new Date(res.deletedAt) : undefined,
          },
          createdAt: new Date(res.submissionType.createdAt),
          updatedAt:
            res.submissionType.updatedAt != null
              ? new Date(res.submissionType.updatedAt)
              : undefined,
          deletedAt:
            res.deletedAt != null ? new Date(res.deletedAt) : undefined,
        }),
      );

      return {
        ...data,
        items,
      };
    },
  });

export const useCreateActivity = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Activity) => {
      const payload: CreateActivityRequest = {
        ...data,
        title: data.title,
        xpAward: data.xpAward,
        startDate: data.startDate,
        endDate: data.endDate,
        submissionType: {
          proofType: data.submissionType.proofType,
          proofLogo: data.submissionType.proofLogo || '',
          proofImage: data.submissionType.proofImage || '',
          title: data.submissionType.title,
          text: data.submissionType.text,
          disclaimer: data.submissionType.disclaimer,
          isAutoDeleteSubmission: data.submissionType.isAutoDeleteSubmission,
          isRecurring: data.submissionType.isRecurring,
          submissionProcess: data.submissionType.submissionProcess,
          maxAllowed: data.submissionType.maxAllowed,
          maxEntries: data.submissionType.maxEntries,
          requiredEntries: data.submissionType.requiredEntries,
        },
      };

      return requester.post<void, CreateActivityRequest>(
        '/admin/activities',
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_MANAGE_ACTIVITY_QUERIES,
        });
      },
    },
  );
};

export const useUpdateActivity = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Activity) => {
      const payload: UpdateActivityRequest = {
        ...data,
        title: data.title,
        xpAward: data.xpAward,
        startDate: data.startDate,
        endDate: data.endDate,
        submissionType: {
          proofType: data.submissionType.proofType,
          proofLogo: data.submissionType.proofLogo || '',
          proofImage: data.submissionType.proofImage || '',
          title: data.submissionType.title,
          text: data.submissionType.text,
          disclaimer: data.submissionType.disclaimer,
          isAutoDeleteSubmission: data.submissionType.isAutoDeleteSubmission,
          isRecurring: data.submissionType.isRecurring,
          submissionProcess: data.submissionType.submissionProcess,
          maxAllowed: data.submissionType.maxAllowed,
          maxEntries: data.submissionType.maxEntries,
          requiredEntries: data.submissionType.requiredEntries,
        },
      };

      await requester.patch<void, UpdateActivityRequest>(
        `/admin/activities/${data.id}`,
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_MANAGE_ACTIVITY_QUERIES,
        });
      },
    },
  );
};

export const useDeleteActivity = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Activity) => {
      await requester.delete<void, UpdateActivityRequest>(
        `/admin/activities/${data.id}`,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_MANAGE_ACTIVITY_QUERIES,
        });
      },
    },
  );
};

export const useAssignActivityWinner = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: any) => {
      const payload = {
        userIds: data.userIds,
      };
      await requester.post(`/admin/activities/${data.id}/winners`, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_MANAGE_ACTIVITY_QUERIES);
        queryClient.invalidateQueries({
          queryKey: ['dashboard-user'],
        });
      },
    },
  );
};

export const useFetchActivityWinners = (activityId: number) =>
  useFetch<Page<ActivityWinner>>({
    key: ['activity-winners'],
    url: `/admin/activities/${activityId}/winners`,
  });

export const useRemoveActivityWinner = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: any) => {
      await requester.delete(
        `/admin/activities/${data.id}/winners/${data.userId}`,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['activity-winners']);
        queryClient.invalidateQueries({
          queryKey: ['dashboard-user'],
        });
      },
    },
  );
};
