import { Card, Descriptions, Modal, Typography } from 'antd';
import { ActivitySubmission } from 'src/types';
import parse from 'html-react-parser';
import ViewImage from '../ViewImage';

export type SubmissionDetailProps = {
  submission?: ActivitySubmission | null;
  open: boolean;
  onClose: () => void;
};

export default function DetailPopup(props: SubmissionDetailProps) {
  const { submission, open, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      title="Activity details"
      open={open}
      footer={null}
      centered
      width={800}
      onCancel={handleClose}
    >
      <Card title="Activity details">
        <Descriptions column={2}>
          <Descriptions.Item label="Title" span={2}>
            {submission?.activity.title}
          </Descriptions.Item>
          <Descriptions.Item label="Activity type">
            {submission?.activity.type || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Activity reward XP tokens">
            {submission?.activity.xpAward}
          </Descriptions.Item>
          <Descriptions.Item label="Activity description" span={2}>
            {submission?.activity.description || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Activity poster">
            <ViewImage width={200} src={submission?.activity.image} />
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card title="Activity submission details">
        <Descriptions column={2}>
          <Descriptions.Item label="Submission title" span={2}>
            {submission?.type.title || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Submission type" span={2}>
            {submission?.type.proofType || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Submission text" span={2}>
            {submission?.type.text || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Disclaimer" span={2}>
            <Typography.Text>
              {submission?.type?.disclaimer
                ? parse(submission?.type.disclaimer)
                : 'N/A'}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Submission pop-up image" span={2}>
            <ViewImage width={200} src={submission?.type.proofImage} />
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </Modal>
  );
}
