import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmissionProof, SubmissionStatus } from 'src/types/Submission';
import { Admin, User } from 'src/types';
import { ActivityStatus, ActivitySubmission } from '../../types/Activity';
import { Page, PaginationParams, SearchParams, SortParams } from './typing';
import useFetch from './useFetch';
import useApi from '../useApi';
import { ActivityResponse, SubmissionTypeResponse } from './activity';

const KEY_ACTIVITY_SUBMISSION_QUERIES = ['activitySubmissions'];

export type FilterActivitySubmission = {
  proofType?: string[];
  startCreatedAt?: Date | null;
  endCreatedAt?: Date | null;
  isFavorite?: boolean;
};

export type UseFetchActivitySubmissionsParams = PaginationParams &
  SortParams &
  SearchParams & {
    startSubmittedDate?: Date;
    endSubmittedDate?: Date;
    activityId?: string;
    userId?: string;
    status?: SubmissionStatus;
    statues?: SubmissionStatus[];
    isFavorite?: boolean;
  };

export type UpdateActivitySubmissionRequest = {
  id: number;
  isFavorite?: boolean;
};

export type UpdateActivitySubmissionStatusRequest = {
  id: number;
  status: string;
  note?: string;
};

export type ActivitySubmissionResponse = {
  id: number;
  activity: ActivityResponse;
  user: User;
  admin: Admin;
  type: SubmissionTypeResponse;
  proof: SubmissionProof;
  submitCount: number;
  submittedDate: string;
  status: SubmissionStatus;
  verificationNote?: string;
  isFavorite: boolean;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
};

export const useFetchActivitySubmissions = (
  params?: UseFetchActivitySubmissionsParams,
) => {
  const searchParams = {
    ...params,
    startSubmittedDate: params?.startSubmittedDate?.toISOString(),
    endSubmittedDate: params?.endSubmittedDate?.toISOString(),
    statues: [
      SubmissionStatus.Pending.toString(),
      SubmissionStatus.Accepted.toString(),
      SubmissionStatus.Rejected.toString(),
    ].join(','),
  } as never;

  return useFetch<Page<ActivitySubmissionResponse>, Page<ActivitySubmission>>({
    searchParams,
    key: [...KEY_ACTIVITY_SUBMISSION_QUERIES, params],
    url: '/admin/activity-submissions',
    parseFn: (data) => {
      const activitySubmissions: ActivitySubmissionResponse[] =
        data.items || [];

      const items: ActivitySubmission[] = activitySubmissions.map(
        (activitySubmission) => ({
          id: activitySubmission.id,
          title: activitySubmission.type.title,
          status: activitySubmission.status,
          submittedDate: activitySubmission.submittedDate
            ? new Date(activitySubmission.submittedDate)
            : null,
          verificationNote: activitySubmission.verificationNote,
          type: {
            id: activitySubmission.type.id,
            proofLogo: activitySubmission.type.proofLogo,
            proofImage: activitySubmission.type.proofImage,
            proofType: activitySubmission.type.proofType,
            title: activitySubmission.type.title,
            text: activitySubmission.type.text,
            disclaimer: activitySubmission.type.disclaimer,
            isAutoDeleteSubmission:
              activitySubmission.type.isAutoDeleteSubmission,
            isRecurring: activitySubmission.type.isRecurring,
            maxAllowed: activitySubmission.type.maxAllowed,
            maxEntries: activitySubmission.type.maxEntries,
            createdAt: new Date(activitySubmission.type.createdAt),
            updatedAt:
              activitySubmission.type.updatedAt != null
                ? new Date(activitySubmission.type.updatedAt)
                : undefined,
            deletedAt:
              activitySubmission.deletedAt != null
                ? new Date(activitySubmission.deletedAt)
                : undefined,
          },
          user: {
            ...activitySubmission.user,
            walletAddress: activitySubmission.user.custodialWalletAddress,
          },
          proof: activitySubmission.proof,
          activity: {
            id: activitySubmission.activity.id,
            type: activitySubmission.activity.type,
            slug: activitySubmission.activity.slug,
            title: activitySubmission.activity.title,
            description: activitySubmission.activity.description,
            image: activitySubmission.activity.image,
            notificationImage: activitySubmission.activity.notificationImage,
            startDate: new Date(activitySubmission.activity.startDate),
            endDate: new Date(activitySubmission.activity.endDate),
            xpAward: activitySubmission.activity.xpAward,
            status: ActivityStatus.Publish,
            createdAt: new Date(activitySubmission.activity.createdAt),
            updatedAt:
              activitySubmission.activity.updatedAt != null
                ? new Date(activitySubmission.activity.updatedAt)
                : undefined,
            deletedAt:
              activitySubmission.activity.deletedAt != null
                ? new Date(activitySubmission.activity.deletedAt)
                : undefined,
          },
          updatedAt: activitySubmission.updatedAt,
          isFavorite: activitySubmission.isFavorite,
          admin: activitySubmission.admin,
        }),
      );

      return {
        ...data,
        items,
      };
    },
  });
};

export const useUpdateActivitySubmission = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async ({ id, isFavorite }: UpdateActivitySubmissionRequest) => {
      await requester.put(`/admin/activity-submissions/${id}`, { isFavorite });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_ACTIVITY_SUBMISSION_QUERIES,
        });
      },
    },
  );
};

export const useUpdateActivitySubmissionStatus = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async ({ id, status, note }: UpdateActivitySubmissionStatusRequest) => {
      await requester.put(`/admin/activity-submissions/${id}/status`, {
        value: status,
        note,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_ACTIVITY_SUBMISSION_QUERIES,
        });
      },
    },
  );
};

export const useExportActivitySubmissions = () => {
  const { requester } = useApi();

  return useMutation(async () =>
    requester.get('/admin/export/activity-submissions'),
  );
};
