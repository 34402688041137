/* eslint-disable import/prefer-default-export */
import createAppAsyncThunk from 'src/store/createAppAsyncThunk';

import { Collaborator } from 'src/types/Collaborator';
import { createRequester } from 'src/hooks/useApi';

export const getCollaboratorProfile = createAppAsyncThunk(
  'collaborator/getCollaboratorProfile',
  async (_, { dispatch }) => {
    const requester = createRequester({ dispatch });
    const response = await requester.get<Collaborator>('collaborator/profiles');
    return response.data;
  },
);
