import { Table, TableProps } from 'antd';
import classNames from 'classnames';
import { XpRecordColumnsTypes } from 'src/pages/CollaboratorDashboard/Dashboard';
import styles from './style.module.scss';

interface CustomTableProps
  extends TableProps<XpRecordColumnsTypes> {
  className?: string;
  loading?: boolean;
}

function CustomTable(props: CustomTableProps) {
  const {
    columns, dataSource, className, pagination, scroll, loading,
  } = props;
  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={dataSource || []}
      className={classNames(styles.table, className)}
      pagination={pagination}
      scroll={scroll}
      loading={loading}
    />
  );
}

export default CustomTable;
