import { UserOutlined } from '@ant-design/icons';
import {
  MenuProps, Row, Avatar, Dropdown, Space,
} from 'antd';
import { useAppDispatch } from 'src/store/dispatch';
import { signOut } from 'src/store/slices/auth-slice';
import { selectAdminInfo } from 'src/store/selectors';
import { useSelector } from 'react-redux';
import styles from './HeaderAvatar.module.scss';

const menuItems: MenuProps['items'] = [

  {
    key: 'signOut',
    label: 'Sign out',
    danger: true,
  },
];

export default function HeaderAvatar() {
  const info = useSelector(selectAdminInfo);
  const dispatch = useAppDispatch();

  const handleMenuClick: MenuProps['onClick'] = (menuInfo) => {
    if (menuInfo.key === 'signOut') {
      dispatch(signOut());
    }
  };

  return (
    <Row>
      <Space>
        {`${info?.username} | ${info?.roles} `}
        <Dropdown
          menu={{
            items: menuItems,
            onClick: handleMenuClick,
          }}
          trigger={['click']}
        >
          <Avatar
            className={styles.avatar}
            size={32}
            icon={<UserOutlined />}
          />
        </Dropdown>
      </Space>
    </Row>
  );
}
