import {
  Row,
  Col,
  InputNumber,
  Button,
  notification,
  Form,
  Card,
} from 'antd';
import { useEffect, useState } from 'react';
import { useFetchDiscountSettings, useUpdateDiscountSettings } from 'src/hooks/api/discount';
import styles from './Discount.module.scss';

const DISCOUNT_KEY = {
  XP_DISCOUNT: 'discount.user.xp',
  ALPHA_DISCOUNT: 'discount.user.alpha',
  MINTED_NFT_DISCOUNT: 'discount.user.minted_nft',
  ALLEGIANCES_DISCOUNT: 'discount.user.allegiances',
};

export default function DiscountPage() {
  const [form] = Form.useForm();

  const { data: discountSettings, isLoading } = useFetchDiscountSettings();
  const { mutateAsync: mutationBatchUpdateDiscountSetting } = useUpdateDiscountSettings();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!discountSettings) return;

    const data: Record<string, number> = {};

    discountSettings.forEach((discountItem) => {
      if (discountItem.key === DISCOUNT_KEY.XP_DISCOUNT) {
        data.xpDiscountPercent = discountItem.discountPercent;
        data.xpDiscountByEach = discountItem.discountByEach;
      } else if (discountItem.key === DISCOUNT_KEY.ALPHA_DISCOUNT) {
        data.alphaDiscountPercent = discountItem.discountPercent;
        data.alphaDiscountByEach = discountItem.discountByEach;
      } else if (discountItem.key === DISCOUNT_KEY.MINTED_NFT_DISCOUNT) {
        data.mintedNFTDiscountPercent = discountItem.discountPercent;
        data.mintedNFTDiscountByEach = discountItem.discountByEach;
      } else if (discountItem.key === DISCOUNT_KEY.ALLEGIANCES_DISCOUNT) {
        data.allegiancesDiscountPercent = discountItem.discountPercent;
        data.allegiancesDiscountByEach = discountItem.discountByEach;
      }
    });
    form.setFieldsValue(data);
  }, [discountSettings, form]);

  const handlingUpdateSetting = async (values: Record<string, number>) => {
    try {
      setSubmitting(true);
      await mutationBatchUpdateDiscountSetting([
        {
          key: DISCOUNT_KEY.XP_DISCOUNT,
          discountPercent: values.xpDiscountPercent,
          discountByEach: values.xpDiscountByEach,
        },
        {
          key: DISCOUNT_KEY.ALPHA_DISCOUNT,
          discountPercent: values.alphaDiscountPercent,
          discountByEach: values.alphaDiscountByEach,
        },
        {
          key: DISCOUNT_KEY.MINTED_NFT_DISCOUNT,
          discountPercent: values.mintedNFTDiscountPercent,
          discountByEach: values.mintedNFTDiscountByEach,
        },
        {
          key: DISCOUNT_KEY.ALLEGIANCES_DISCOUNT,
          discountPercent: values.allegiancesDiscountPercent,
          discountByEach: values.allegiancesDiscountByEach,
        },
      ]);
      setSubmitting(false);
      notification.success({
        message: 'Success',
        description: 'Discount setting updated',
      });
    } catch (error) {
      notification.error({
        message: 'Update fail',
        description: (error as Error).message || 'Server error',
      });
      setSubmitting(false);
    }
  };

  return (
    <Card title="Discount setting" loading={isLoading}>
      <Form
        form={form}
        name="discount_setting_form"
        onFinish={handlingUpdateSetting}
        autoComplete="off"
      >
        <Row gutter={16} align="middle" className={styles.discountItemRow}>
          <Col xs={12} lg={6}>
            <Form.Item
              name="xpDiscountPercent"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
                {
                  type: 'number',
                  message: 'Invalid value',
                },
                {
                  validator: async (rule, value) => {
                    if (value > 100 || value < 0) {
                      throw new Error('Value must be from 0 to 100');
                    }
                  },
                },
              ]}
              label="Discount"
            >
              <InputNumber
                addonAfter="%"
                onChange={(val) => form.setFieldValue('xpDiscountPercent', val)}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item
              name="xpDiscountByEach"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
                {
                  type: 'number',
                  message: 'Invalid value',
                },
              ]}
              label="for each"
            >
              <InputNumber
                addonAfter="XP"
                name="xpDiscountByEach"
                className={styles.discountItemInput}
                onChange={(val) => form.setFieldValue('xpDiscountByEach', val)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="middle" className={styles.discountItemRow}>
          <Col xs={12} lg={6}>
            <Form.Item
              name="alphaDiscountPercent"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
                {
                  type: 'number',
                  message: 'Invalid value',
                },
                {
                  validator: async (rule, value) => {
                    if (value > 100 || value < 0) {
                      throw new Error('Value must be from 0 to 100');
                    }
                  },
                },
              ]}
              label="Discount"
            >
              <InputNumber
                addonAfter="%"
                name="alphaDiscountPercent"
                onChange={(val) => form.setFieldValue('alphaDiscountPercent', val)}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item
              name="alphaDiscountByEach"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
                {
                  type: 'number',
                  message: 'Invalid value',
                },
              ]}
              label="for each"
            >
              <InputNumber
                addonAfter="Alpha token"
                className={styles.discountItemInput}
                onChange={(val) => form.setFieldValue('alphaDiscountByEach', val)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="middle" className={styles.discountItemRow}>
          <Col xs={12} lg={6}>
            <Form.Item
              name="mintedNFTDiscountPercent"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
                {
                  type: 'number',
                  message: 'Invalid value',
                },
                {
                  validator: async (rule, value) => {
                    if (value > 100 || value < 0) {
                      throw new Error('Value must be from 0 to 100');
                    }
                  },
                },
              ]}
              label="Discount"
            >
              <InputNumber
                addonAfter="%"
                onChange={(val) => form.setFieldValue('mintedNFTDiscountPercent', val)}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item
              name="mintedNFTDiscountByEach"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
                {
                  type: 'number',
                  message: 'Invalid value',
                },
              ]}
              label="for each"
            >
              <InputNumber
                addonAfter="Minted NFT"
                className={styles.discountItemInput}
                onChange={(val) => form.setFieldValue('mintedNFTDiscountByEach', val)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="middle" className={styles.discountItemRow}>
          <Col xs={12} lg={6}>
            <Form.Item
              name="allegiancesDiscountPercent"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
                {
                  type: 'number',
                  message: 'Invalid value',
                },
                {
                  validator: async (rule, value) => {
                    if (value > 100 || value < 0) {
                      throw new Error('Value must be from 0 to 100');
                    }
                  },
                },
              ]}
              label="Discount"
            >
              <InputNumber
                addonAfter="%"
                onChange={(val) => form.setFieldValue('allegiancesDiscountPercent', val)}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item
              name="allegiancesDiscountByEach"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
                {
                  type: 'number',
                  message: 'Invalid value',
                },
              ]}
              label="for each"
            >
              <InputNumber
                addonAfter="allegiances"
                className={styles.discountItemInput}
                onChange={(val) => form.setFieldValue('allegiancesDiscountByEach', val)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" size="large" loading={submitting} htmlType="submit">
          Save
        </Button>
      </Form>
    </Card>
  );
}
