import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import {
  RcFile, UploadChangeParam, UploadFile, UploadProps,
} from 'antd/es/upload';
import { useMemo, useState } from 'react';
import { UploadImageV2Response, useUploadImageV2 } from 'src/hooks/api/useUploadImage';

type UploadImageProps = {
  value?: string;
  prefix?: string;
  acceptFiles?: string[];
  onChange?: (newValue: string) => void;
};

function UploadImage(props: UploadImageProps) {
  const {
    value,
    prefix,
    onChange = () => {},
    acceptFiles,
  } = props;

  const [loading, setLoading] = useState(false);

  const imageUrl = useMemo(() => {
    if (value == null) {
      return null;
    }

    const url = new URL(`v2.0/files/${value}`, process.env.REACT_APP_API_URL);
    return url.toString();
  }, [value]);

  const { mutateAsync: uploadMutate } = useUploadImageV2();

  const beforeUpload = (file: RcFile) => {
    if (acceptFiles && !acceptFiles?.includes(file.type)) {
      message.error(`You can only upload ${acceptFiles} file!`);
      return false;
    }

    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml' || file.type === 'image/webp';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG/PNG/SVG file!');
    }

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG/PNG file!');
    }

    const isLt5M = file.size / 1024 / 1024 / 1024 / 1024 / 1024 < 5;

    if (!isLt5M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt5M;
  };

  const handleChangeImage: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile<UploadImageV2Response>>,
  ) => {
    if (info?.file?.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info?.file?.status === 'done') {
      onChange(info.file.response?.key || '');
      setLoading(false);
    }

    if (info?.file?.status === 'error') {
      setLoading(false);
      message.error(`${info?.file?.name} file upload failed.`);
    }
  };

  const handleUploadImage = async (file: string | RcFile | Blob) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      if (prefix != null) {
        formData.append('prefix', prefix);
      }

      const response = await uploadMutate(formData);
      return response.data;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleUploadRequest: UploadProps['customRequest'] = ({ file, onSuccess, onError }) => {
    handleUploadImage(file)
      .then((data) => {
        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
      });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload
      name="file"
      customRequest={handleUploadRequest}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChangeImage}
    >
      {imageUrl ? (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <img
            src={imageUrl}
            alt="file"
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      ) : (
        uploadButton
      )}
    </Upload>
  );
}

export default UploadImage;
