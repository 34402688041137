import {
  Button,
  Card,
  Col,
  Input,
  message,
  Row,
  Space,
  Spin,
  Switch,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import ABI from 'src/abi/tota-nft.json';
import { ReactElement, useMemo, useState } from 'react';

import {
  airdrop,
  FilterAdvancedUser,
  useFetchAdvancedFilterUsers,
  useFetchActiveCollection,
  useFetchActiveDrop,
  UseFetchUserParams,
} from 'src/hooks/api';
import { User } from 'src/types';
import { createRequester } from 'src/hooks/useApi';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { useAppDispatch } from 'src/store/dispatch';
import { useFetchAllegiances } from 'src/hooks/api/allegiance';
import { useFetchTags } from 'src/hooks/api/tag';
import BasicModal from '../Modal';
import NumberToAirdrop from './NumberToAirdrop';
import UserFilter, { FormDataFilter } from '../UserFilter';
import Web3Panel from '../Web3Panel';

function UserClaimedAirdrop(): ReactElement {
  const [search, setSearch] = useState<string | undefined>();
  const [selectUsers, setSelectUsers] = useState<User[]>([]);
  const [selectedUserKeys, setSelectedUserKeys] = useState<React.Key[]>([]); // look like dupplicate states
  const airdropUsers = selectUsers.map(
    (user) => user.walletAddress ?? user.custodialWalletAddress,
  );
  const [openModalSendAirDrop, setOpenModalSendAirDrop] =
    useState<boolean>(false);
  const [airdropQuantity, setAirdropQuantity] = useState<number>(1);
  const dispatch = useAppDispatch();
  const requester = createRequester({ dispatch });
  const [isMinting, setIsMinting] = useState(false);
  const { pagination, sorters, handleParamsChange } = useTableHelper<User>();
  const { mapTableParams } = useMapTableParam();

  const [flag, setFlag] = useState(() => {
    const storedBool = localStorage.getItem('bool');
    return storedBool ? JSON.parse(storedBool) : false;
  });

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Sent airdrop success',
      duration: 1,
    });
  };

  const isError = () => {
    messageApi.open({
      type: 'error',
      content: 'error',
      duration: 1,
    });
  };

  const balanceError = () => {
    messageApi.open({
      type: 'warning',
      content: 'Minting More Token Than Availabe',
      duration: 3,
    });
  };

  const cancelContractError = () => {
    messageApi.open({
      type: 'error',
      content: 'Transaction Rejected By User',
      duration: 3,
    });
  };

  const [filter, setFilter] = useState<FilterAdvancedUser | null>(null);
  const [openFilter, setOpenFilter] = useState(false);

  // Server states
  const params: UseFetchUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      ...filter,
      search,
      isAirdropped: true,
    };
  }, [mapTableParams, pagination, sorters, search, filter]);
  const {
    data: usersData,
    isLoading,
    refetch,
  } = useFetchAdvancedFilterUsers({ ...params });
  const dataAllegianceSource = useFetchAllegiances();
  const dataTagSource = useFetchTags();

  const { data: activeCollection } = useFetchActiveCollection();
  const { data: activeDrop } = useFetchActiveDrop();
  const airdropAmounts = selectUsers.map((user: User) => {
    let amount = 0;

    const maxAidropPerPerson = activeDrop?.maxAirdropNFTPerPerson ?? 0;
    const totalNftFromAirdrop = user.totalNftFromAirdrop ?? 0;
    const remaining = maxAidropPerPerson - totalNftFromAirdrop;

    if (remaining > airdropQuantity) {
      amount = airdropQuantity;
    } else {
      amount = remaining;
    }

    return amount;
  });

  const currentContractAddress = activeCollection?.contractAddress || '';
  const { config, error: prepareError } = usePrepareContractWrite({
    address: currentContractAddress as `0x${string}`,
    abi: ABI,
    functionName: 'airdrop',
    args: [airdropUsers, airdropAmounts],
    // enabled: Boolean(currentContractAddress) && airdropQuantity > 0,
  });

  let ErrorFlag = false;

  if (prepareError && !ErrorFlag) {
    balanceError();
    ErrorFlag = true;
  }

  console.log('prepareError', prepareError);
  const { data, write, error: writeError } = useContractWrite(config);

  if (writeError) {
    cancelContractError();
  }

  console.log('writeError', writeError);

  const storedAirdropUsers = localStorage.getItem('airdropUsers');
  const airdropUsersAfterReload = storedAirdropUsers
    ? JSON.parse(storedAirdropUsers)
    : [];

  const { isLoading: isAirdropLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  if (isAirdropLoading) {
    console.log('is loading...');
  }

  if (isSuccess && flag) {
    console.log(
      'tx success, hash',
      `https://sepolia.etherscan.io/tx/${data?.hash}`,
      data,
    );

    const fetchTokenData = async (): Promise<void | undefined> => {
      try {
        const response = await data?.wait();
        const logsArray = response?.logs ?? [];

        const tokenIdArray = logsArray
          .filter((log) => log.topics.length === 4)
          .map((log) => parseInt(log.topics[3], 16));

        const airdropNFTInfo = {
          tokenId: tokenIdArray,
          walletAddress: airdropUsersAfterReload as string[],
          txHash: response?.transactionHash as string,
        };

        await airdrop({
          requester,
          airdropNFTInfo,
        });

        success();
        setIsMinting(false);
        setFlag(false);
        localStorage.removeItem('bool');
        localStorage.removeItem('airdropUsers');
      } catch (error) {
        localStorage.removeItem('bool');
        localStorage.removeItem('airdropUsers');
        setIsMinting(false);
        isError();
      }
    };

    fetchTokenData();
  }

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
  };

  const handleSubmitFilter = (dataFilter: FormDataFilter) => {
    setFilter({
      isAirdropped: dataFilter.options?.includes('isAirdropped'),
      isOg: dataFilter.options?.includes('isOg'),
      isWhitelisted: dataFilter.options?.includes('isWhitelisted'),
      startCreatedAt: dataFilter.createdAt ? dataFilter.createdAt[0] : null,
      endCreatedAt: dataFilter.createdAt ? dataFilter.createdAt[1] : null,
      allegiance: dataFilter.allegiance,
    });
  };

  const handleResetFilter = () => {
    setFilter(null);
  };

  const renderSearchAndUserFilter = () => (
    <Row>
      <Space size="small">
        <Col>
          <Input.Search
            allowClear
            placeholder="Search"
            onSearch={handleSearch}
          />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <UserFilter
            onOpen={handleOpenFilter}
            open={openFilter}
            onCancel={handleCancelFilter}
            onSubmit={(dataFilter: FormDataFilter) =>
              handleSubmitFilter(dataFilter)
            }
            allegiances={dataAllegianceSource?.data?.items || []}
            onReset={handleResetFilter}
            tags={dataTagSource?.data?.items || []}
          />
        </Col>
      </Space>
    </Row>
  );

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedUserKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: User[]) => {
      setSelectedUserKeys(selectedRowKeys);
      setSelectUsers(selectedRows);
    },
    getCheckboxProps: (record: User) => ({
      name: record.username || 'Untitled',
      disabled:
        record.totalNftFromAirdrop === activeDrop?.maxAirdropNFTPerPerson,
    }),
  };

  const onSortCreatedAt = (userA: User, userB: User) => {
    const createdAtA = Math.floor(new Date(userA.createdAt).getTime() / 1000);
    const createdAtB = Math.floor(new Date(userB.createdAt).getTime() / 1000);

    return createdAtA - createdAtB;
  };

  const renderColumsn = (): ColumnsType<User> => [
    {
      title: 'Wallet address',
      dataIndex: 'walletAddress',
      render: (_, record) =>
        record?.walletAddress || record?.custodialWalletAddress,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Discord',
      dataIndex: 'discordUsername',
    },
    {
      title: 'XP',
      dataIndex: 'xp',
      sorter: (userA, userB) => userA.xp! - userB.xp!,
    },
    {
      title: 'OG',
      dataIndex: 'isOg',
      render: (value) => <Switch checked={value} />,
    },
    {
      title: 'NFT airdrop',
      dataIndex: 'totalNft',
      sorter: (userA, userB) => userA.totalNft! - userB.totalNft!,
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      render: (value) => new Date(value).toLocaleString(),
      sorter: (userA, userB) => onSortCreatedAt(userA, userB),
    },
  ];

  const onSubmitEligiblesUsers = async () => {
    localStorage.setItem('airdropUsers', JSON.stringify(airdropUsers));
    setFlag(true);
    setIsMinting(true);
    write?.();
    refetch();
    setSelectUsers([]);
    setSelectedUserKeys([]);
    setOpenModalSendAirDrop(false);
  };

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ display: 'flex' }}
      className="dropContainer"
    >
      {isMinting ? (
        <div
          style={{
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            zIndex: 99,
            fontWeight: 600,
          }}
        >
          <Spin tip="Loading" size="large" />
          Minting
        </div>
      ) : null}
      {contextHolder}
      <Web3Panel />
      <Button
        type="primary"
        onClick={() => setOpenModalSendAirDrop(true)}
        disabled={!selectUsers.length || ErrorFlag}
      >
        Airdrop NFT
      </Button>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Card title="Users" extra={renderSearchAndUserFilter()}>
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={renderColumsn()}
              dataSource={usersData?.items?.filter(
                (user: User) => user.isAirdropWhitelistClaimed,
              )}
              loading={isLoading}
              rowKey="id"
              pagination={pagination}
              onChange={handleParamsChange}
            />
          </Card>
        </Col>
      </Row>
      <BasicModal
        title="Send airdrop"
        open={openModalSendAirDrop}
        onClose={(): void => setOpenModalSendAirDrop(false)}
      >
        <NumberToAirdrop
          setAirdropQuantity={setAirdropQuantity}
          onFinish={onSubmitEligiblesUsers}
        />
      </BasicModal>
    </Space>
  );
}

export default UserClaimedAirdrop;
