import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo } from 'react';
import formatDate from 'src/helper/formatDate';
import { UseFetchUserParams } from 'src/hooks/api';
import { useFetchXPLedgers } from 'src/hooks/api/xp-ledger';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { renderDescription } from 'src/pages/XPLedger';
import { User, XPLedger } from 'src/types';

export type UserDetailProps = {
  user: User;
};

export default function UserXpLedger(props: UserDetailProps) {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<XPLedger>();
  const { mapTableParams } = useMapTableParam();
  const { user } = props;
  // Server states
  const params: UseFetchUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
      userId: user?.id,
    };
  }, [mapTableParams, pagination, sorters, user]);
  const dataSource = useFetchXPLedgers({ ...params });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const renderColumns = (): ColumnsType<XPLedger> => [
    {
      title: 'From',
      dataIndex: 'from',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'To',
      dataIndex: 'to',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (value) => renderDescription(value),
    },
    {
      title: 'XPs',
      dataIndex: 'amount',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => formatDate(value),
    },
  ];

  return (
    <Table
      style={{ width: '100%' }}
      columns={renderColumns()}
      dataSource={dataSource.data?.items}
      loading={dataSource.isLoading}
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}
