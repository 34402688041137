import { XpDescription } from './XPLedger';

export enum NotificationType {
  Global,
  User,
}

export type Notification = {
  id?: number;
  type: NotificationType;
  title: string;
  content: string;
  metadata?: Record<string, unknown>;
  userIds?: string[];
  createdAt?: Date;
};

export enum NotificationTitle {
  Welcome = 'noti.welcome',
  EarnXp = 'noti.earn_xp',
  XpAward = 'noti.xp_award',
  ActivityAdded = 'noti.event_added',
  AllegianceAdded = 'noti.allegiance_added',
  AdminGrant = 'noti.admin_grant',
}

export enum NotificationContent {
  Welcome = 'noti_content.welcome',
  EarnXp = 'noti_content.earn_xp',
  ActivityAdded = 'noti_content.event_added',
  AllegianceAdded = 'noti_content.allegiance_added',
}

export enum NotificationMetaType {
  XP = 1,
  AlphaXP,
  NFT,
  Allegiance,
  AdminXPGrant,
  OGTag,
  WhitelistSpot,
  AirdropEligibility,
  MintingLive,
  AdminCustom,
  NewMetaverseUpdate,
  NewCharacterMetaverseUpdate,
  NewProfileUpdate,
  NewAllegiance,
}

export const NOTIFICATION_XP_MAP = {
  [XpDescription.AdminGrant]: NotificationTitle.AdminGrant,
} as const;
