import
{
  Card, Col, Input,
  Row, Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import formatDate from 'src/helper/formatDate';
import { UseFetchFaqParams, useFetchFaqs } from 'src/hooks/api/faq';

import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { Faq } from 'src/types/Faq';

export default function FaqPage() {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<Faq>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();

  // Server states
  const params: UseFetchFaqParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);

  const dataSource = useFetchFaqs({ ...params });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<Faq> => [
    {
      title: 'Username',
      dataIndex: ['user', 'username'],
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
    },
    {
      title: 'Website Page',
      dataIndex: 'page',
    },
    {
      title: 'Question',
      dataIndex: 'question',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 150,
      render: (value) => formatDate(value.toString()),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col span={24}>
        <Card title="Faqs" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
    </Row>
  );
}
