import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Tag, TagUserDetail, UserTag } from 'src/types/Tag';
import { Page, PaginationParams, SearchParams, SortParams } from './typing';
import useFetch from './useFetch';
import useApi from '../useApi';

const KEY_TAGS_QUERIES = ['tags'];
const KEY_TAG_USERS_QUERIES = ['tags/users'];
const KEY_USER_TAGS_QUERY = (userId: string) => [...KEY_TAGS_QUERIES, userId];

export type UseFetchTagParams = PaginationParams & SortParams & SearchParams;
export type UseFetchUserTagParams = PaginationParams & {
  userId: string;
};
export type UseFetchTagUserParams = PaginationParams &
  SortParams &
  SearchParams & {
    isGroup?: boolean;
  };

export type AddTagUserRequets = {
  userIds: string[];
};

export const useFetchTags = (params?: UseFetchTagParams) =>
  useFetch<Page<Tag>>({
    key: [...KEY_TAGS_QUERIES, params],
    url: '/admin/tags',
    searchParams: params,
  });

export const useFetchUserTags = (params?: UseFetchUserTagParams) => {
  const { userId, ...otherParams } = params || {};

  return useFetch<Page<UserTag>>({
    key: [...KEY_USER_TAGS_QUERY(userId!), otherParams],
    url: '/admin/user-tags',
    searchParams: params,
    queryOptions: {
      enabled: userId != null,
    },
  });
};

export const useFetchTagUsers = (params?: UseFetchTagUserParams) =>
  useFetch<Page<TagUserDetail>>({
    key: [...KEY_TAG_USERS_QUERIES, params],
    url: '/admin/tags/users',
    searchParams: params,
  });

export const useCreateTag = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Omit<Tag, 'id'>) => {
      const payload: Omit<Tag, 'id'> = {
        name: data.name,
        icon: data.icon || '',
        notificationIcon: data.notificationIcon || '',
        amountUser: data.amountUser,
        emailConfig: data.emailConfig,
        collection: data.collection,
      };

      return requester.post<void>('/admin/tags', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_TAGS_QUERIES });
      },
    },
  );
};

export const useUpdateTag = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Tag) => {
      const payload: Omit<Tag, 'id'> = {
        name: data.name,
        icon: data.icon,
        notificationIcon: data.notificationIcon,
        amountUser: data.amountUser,
        emailConfig: data.emailConfig,
        collection: data.collection,
        status: data.status,
      };

      await requester.patch<void>(`/admin/tags/${data?.id}`, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_TAGS_QUERIES);
        queryClient.invalidateQueries({
          queryKey: ['dashboard-user'],
        });
      },
    },
  );
};

export type AddTagUserData = {
  id: number;
  userIds: string[];
};

export const useAddTagUser = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: AddTagUserData) => {
      const payload: AddTagUserRequets = {
        userIds: data.userIds,
      };
      await requester.post(`/admin/tags/${data.id}/users`, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_TAGS_QUERIES);
        queryClient.invalidateQueries({
          queryKey: ['dashboard-user'],
        });
      },
    },
  );
};

export type RemoveTagUserData = {
  tagId: number;
  tagUserId: number;
};

export const useRemoveTagUser = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: RemoveTagUserData) => {
      await requester.delete(
        `/admin/tags/${data.tagId}/users/${data.tagUserId}`,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_TAG_USERS_QUERIES);
      },
    },
  );
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: Tag) => {
      await requester.delete<void>(`/admin/tags/${data.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: KEY_TAGS_QUERIES,
        });
      },
    },
  );
};

export type SyncUserTagData = {
  tagId: string;
  userId: string;
};

export const useSyncTag = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async (data: SyncUserTagData) => {
      await requester.post<void>(
        `/admin/tags/${data.tagId}/users/${data.userId}/syncs`,
      );
    },
    {
      onSuccess: (_, data) => {
        queryClient.invalidateQueries({
          queryKey: KEY_USER_TAGS_QUERY(data.userId),
        });
      },
    },
  );
};
