import { ProCard, ProDescriptions } from '@ant-design/pro-components';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import { useEffect, useState } from 'react';
import formatDateTime from 'src/helper/formatDateTime';
import { useFetchNFTMintedForDrop } from 'src/hooks/api';
import { Drop, DropStatistics } from 'src/hooks/api/typing';

type PublicSaleStatsProps = {
  dropId: string;
  drop: Drop | undefined;
  dropStatistics: DropStatistics | undefined;
};

interface PublicSaleLine {
  walletAddress: string;
  mintQty: number;
  mintDate: string;
  totalAmountPaid: number;
}

function PublicSaleStats(param: PublicSaleStatsProps) {
  const { dropId, drop, dropStatistics } = param;
  const [remainingNFTs, setRemainingNFTs] = useState(0);

  useEffect(() => {
    if (drop && dropStatistics) {
      setRemainingNFTs(
        drop.qty -
          dropStatistics.totalNFTAirDropped -
          dropStatistics.totalNFTMinted,
      );
    }
  }, [drop, dropStatistics]);

  const { data: nfts, isLoading } = useFetchNFTMintedForDrop(dropId);
  const [publicSaleList, setPublicSaleList] = useState<PublicSaleLine[]>([]);

  useEffect(() => {
    if (nfts && drop) {
      const publicSaleHistories: PublicSaleLine[] = [];
      nfts.forEach((nft) => {
        const dropPublicSaleStart = Math.floor(
          new Date(drop.publicSaleStart).getTime() / 1000,
        );
        const dropPublicSaleEnd = Math.floor(
          new Date(drop.publicSaleEnd).getTime() / 1000,
        );

        if (
          +nft.generatedAt >= dropPublicSaleStart &&
          +nft.generatedAt <= dropPublicSaleEnd
        ) {
          const existedLine = publicSaleHistories.find(
            (item) => item.walletAddress === nft.recipientWalletAddress,
          );

          if (existedLine) {
            existedLine.mintQty += 1;
            existedLine.totalAmountPaid += nft.paidAmount;
          } else {
            publicSaleHistories.push({
              walletAddress: nft.recipientWalletAddress || '',
              mintQty: 1,
              mintDate: formatDateTime(
                new Date(Number(nft.generatedAt) * 1000),
              ),
              totalAmountPaid: nft.paidAmount,
            });
          }
        }
      });
      setPublicSaleList(publicSaleHistories);
    }
  }, [nfts, drop]);

  const publicSaleListColumns: ColumnsType<PublicSaleLine> = [
    {
      title: 'Wallet Address',
      dataIndex: ['walletAddress'],
      key: 'walletAddress',
    },
    {
      title: 'MINT quantity',
      dataIndex: ['mintQty'],
      key: 'mintQty',
    },

    {
      title: 'MINT date',
      dataIndex: 'mintDate',
      key: 'mintDate',
    },
    {
      title: 'Total amount paid',
      dataIndex: 'totalAmountPaid',
      key: 'totalAmountPaid',
    },
    {
      title: 'Action',
      render: () => (
        <Button type="default" onClick={() => {}}>
          View
        </Button>
      ),
    },
  ];

  return (
    <ProCard title="PUBLIC SALE STATS" split="horizontal" bordered>
      <ProCard>
        <ProDescriptions
          column={1}
          emptyText="-"
          dataSource={param}
          columns={[
            {
              title: 'Public start',
              dataIndex: ['drop', 'publicSaleStart'],
              valueType: 'dateTime',
            },
            {
              title: 'Public end',
              dataIndex: ['drop', 'publicSaleEnd'],
              valueType: 'dateTime',
            },
            {
              title: 'Number of NFTs minted in Public sale',
              dataIndex: ['dropStatistics', 'totalPublicSaleNFT'],
            },
            {
              title: 'Number of NFTs remaining',
              render: () => remainingNFTs,
            },
          ]}
        />
      </ProCard>
      <Table
        dataSource={publicSaleList}
        columns={publicSaleListColumns}
        rowKey="id"
        loading={isLoading}
      />
    </ProCard>
  );
}

export default PublicSaleStats;
