import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import { FormInstance, RuleObject } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Allegiance } from 'src/types/Allegiance';
import UploadImage from '../UploadImage';

const RadioGroup = Radio.Group;

type AllegianceForm = Allegiance;

const deleteOptions = [{
  label: 'Yes',
  value: true,
}, {
  label: 'No',
  value: false,
}];

const proofType = [
  {
    value: 'photo',
    label: 'Photo (jpg, png, pdf)',
  },
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'discord',
    label: 'Discord',
  },
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'audio',
    label: 'Audio',
  },
];

export type CreateAllegianceProps = {
  open: boolean;
  allegiance?: Allegiance;
  onClose?: () => void;
  onSubmit?: (values: Allegiance) => void;
};

export default function CreateAllegiance(props: CreateAllegianceProps) {
  const {
    open,
    allegiance,
    onSubmit = () => {},
    onClose = () => {},
  } = props;

  const [form] = Form.useForm<AllegianceForm>();
  const formData: AllegianceForm | undefined = useMemo(() => {
    if (allegiance == null) {
      return undefined;
    }

    return ({
      ...allegiance,
    });
  }, [allegiance]);

  useEffect(() => {
    if (formData != null) {
      form.setFieldsValue(formData);
    } else {
      form.resetFields();
    }
  }, [form, formData]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (formInstance: FormInstance<AllegianceForm>) => {
    await formInstance.validateFields();
    const formValues = form.getFieldsValue();

    onSubmit({
      ...formValues,
      id: formData?.id,
    });
  };

  const validateDisclaimer = (_: RuleObject, value: any, callback: (error?: string) => void) => {
    const regex = /<p>(\s*|&nbsp;|<br\s*\/?>)<\/p>/g;

    if (regex.test(value) || (value && value.trim().length === 0)) {
      callback('Please input allegiance disclaimer');
    } else {
      callback();
    }
  };

  return (
    <Drawer
      open={open}
      title={allegiance ? 'Update allegiance' : 'Add allegiance'}
      size="large"
      extra={(
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit(form);
            }}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      )}
      onClose={handleClose}
    >
      <Form
        id="create-activity"
        layout="vertical"
        form={form}
      >
        <Space direction="vertical" size="large">
          <Card title="Allegiance details">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={['title']}
                  label="Allegiance title"
                  rules={[{
                    required: true,
                    type: 'string',
                    message: 'Allegiance title cannot be empty',
                    whitespace: true,
                  }]}
                >
                  <Input placeholder="Please enter allegiance title" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['xpAward']}
                  label="XPS"
                  rules={[{
                    required: true,
                    type: 'number',
                    message: 'Allegiance XPs cannot be empty',
                    whitespace: true,
                  }]}
                >
                  <InputNumber placeholder="100" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['image']}
                  label="Allegiance logo"
                  help=" Please upload image with width of 741.6px and height of
                  1091.2px"
                >
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['notificationImage']}
                  label="Notification Image"
                >
                  <UploadImage />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Allegiance submission details">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'title']}
                  label="Submission title"
                  rules={[{
                    required: true,
                    type: 'string',
                    message: ' Please input allegiance submission title',
                    whitespace: true,
                  }]}
                >
                  <Input placeholder="Please enter submission title" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'proofType']}
                  label="Submission type"
                  rules={[{
                    required: true,
                    type: 'string',
                    message: 'Please select allegiance submission type',
                  }]}
                >
                  <Select
                    style={{ width: 200 }}
                    placeholder="Select type"
                    options={proofType}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'isAutoDeleteSubmission']}
                  label="Auto delete"
                  initialValue={false}
                  rules={[{
                    required: true,
                    type: 'boolean',
                    message: 'Type not empty',
                  }]}
                >
                  <RadioGroup options={deleteOptions} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['submissionType', 'proofImage']}
                  label="Submission pop up image"
                  help=" Please upload image with width of 741.6px and height of
                  1091.2px"
                >
                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['submissionType', 'proofLogo']}
                  label="Submission pop-up logo"
                  help=" Please upload image with width of 741.6px and height of
                  1091.2px"
                >

                  <UploadImage />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'text']}
                  label=" Submission text"
                  rules={[{
                    required: true,
                    type: 'string',
                    message: ' Allegiance submission text cannot be empty',
                    whitespace: true,
                  }]}
                >
                  <TextArea rows={4} placeholder="Please enter submission text" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['submissionType', 'disclaimer']}
                  label="Submission disclaimer"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please input allegiance disclaimer',
                      whitespace: true,
                    },
                    {
                      required: true,
                      validator: validateDisclaimer,
                    },
                  ]}
                >
                  <ReactQuill />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Space>
      </Form>
    </Drawer>
  );
}
