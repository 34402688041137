import { PlusOutlined } from '@ant-design/icons';
import
{
  Button,
  Col,
  Row,
  notification,
} from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import GrantXP, { GrantXPFormData } from 'src/components/GrantXP';
import UserTable from 'src/components/UserTable';
import { useGrantXP } from 'src/hooks/api/xp-grant';
import { User } from 'src/types';
import { ErrorDataResponse } from 'src/typing';

export default function XPGrantPage() {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const { mutateAsync: mutationGrantXP } = useGrantXP();

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleSubmitGrantXP = async (formValues: GrantXPFormData) => {
    try {
      await mutationGrantXP({
        users: selectedUsers,
        amount: formValues.amount,
        description: formValues.description,
      });
      setOpenDrawer(false);
      notification.success({ message: 'Grant XP successfully' });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response?.data.message;
      notification.error({ message: `Grant XP failed: ${errorMessage}` });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: User[]) => {
      setSelectedUsers(selectedRows);
    },
  };

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col flex="0 0 0">
        <Button disabled={selectedUsers.length === 0} type="primary" icon={<PlusOutlined />} onClick={handleOpenDrawer}>
          {'Grant XP\'s'}
        </Button>
      </Col>
      <Col span={24}>
        <UserTable isSelect rowSelection={rowSelection} />
      </Col>
      <GrantXP
        open={openDrawer}
        onClose={handleCloseDrawer}
        onSubmit={(data: GrantXPFormData) => handleSubmitGrantXP(data)}
      />

    </Row>
  );
}
