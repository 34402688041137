import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';

export default function MinSpotsFaq() {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={classNames(styles.header, 'flex items-center')}>
        <div className="flex items-center w-full">
          <div
            className={classNames(styles.backButton, 'flex w-max-content')}
            onClick={() => navigate('../whitelist')}
          >
            <div className={styles.backIcon}>{'<<'}</div>
            <div className="body1 ml-4">Back</div>
          </div>
          <div className={classNames(styles.title, 'subtitle1')}>
            How to award whitelist mint spots?
          </div>
        </div>
      </div>
      <div className="py-40 px-80 color-white">
        <div className="mb-24">
          <div className="subtitle1 mb-8">
            How to award whitelist mint spots?
          </div>
          <div className="body2">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet doloremagna aliquam erat
            volutpat. Ut wisi
          </div>
        </div>
        <div className="mb-24">
          <div className="subtitle1 mb-8">
            How to award whitelist mint spots?
          </div>
          <div className="body2">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet doloremagna aliquam erat
            volutpat. Ut wisi
          </div>
        </div>
        <div className="mb-24">
          <div className="subtitle1 mb-8">
            How to award whitelist mint spots?
          </div>
          <div className="body2">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet doloremagna aliquam erat
            volutpat. Ut wisi
          </div>
        </div>
        <div className="mb-24">
          <div className="subtitle1 mb-8">
            How to award whitelist mint spots?
          </div>
          <div className="body2">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet doloremagna aliquam erat
            volutpat. Ut wisi
          </div>
        </div>
        <div>
          <div className="subtitle1 mb-8">
            How to award whitelist mint spots?
          </div>
          <div className="body2">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet doloremagna aliquam erat
            volutpat. Ut wisi
          </div>
        </div>
      </div>
    </div>
  );
}
