import {
  Card, Col, Input, Row, Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import formatDate from 'src/helper/formatDate';
import { UseFetchUserParams } from 'src/hooks/api';

import { useFetchXPLedgers } from 'src/hooks/api/xp-ledger';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { XPLedger, XpDescription } from 'src/types';

export const renderDescription = (value: string) => {
  const descriptionMap: Record<any, string> = {
    [XpDescription.ReferalReward]: 'Referral bonus',
    [XpDescription.RefereeReward]: 'Referee bonus',
    [XpDescription.CollaboratorRefereeReward]: 'Collaborator referral bonus',
    [XpDescription.ClaimWhitelist]: 'Claim whitelist',
    [XpDescription.AdminGrant]: 'Admin grant XP',
    [XpDescription.ActivityReward]: 'Activity completion reward',
    [XpDescription.AllegianceReward]: 'Allegiance declaration reward',
    [XpDescription.SignInBonus]: 'Welcome bonus',
    [XpDescription.Revert]: 'XP deducted',
  };

  return descriptionMap[value as keyof typeof descriptionMap] || 'N/A';
};

export default function XPLedgerPage() {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<XPLedger>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  // Server states
  const params: UseFetchUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);
  const dataSource = useFetchXPLedgers({ ...params });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<XPLedger> => [
    {
      title: 'From',
      dataIndex: 'from',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'To',
      dataIndex: 'to',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (value) => renderDescription(value),
    },
    {
      title: 'XPs',
      dataIndex: 'amount',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => formatDate(value),
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col span={24}>
        <Card title="Xp Ledgers" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
    </Row>
  );
}
