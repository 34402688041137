import { Button, Space } from 'antd';
import { ReactElement } from 'react';
import { Collection } from 'src/hooks/api/typing';
import styles from './index.module.scss';

export interface CollectionActionProps {
  collection: Collection;
  onView(collection: Collection): void;
  onEdit(collection: Collection): void;
}

function CollectionAction(props: CollectionActionProps): ReactElement {
  const {
    collection, onView: onViewCollection, onEdit: onEditCollection,
  } = props;

  const onView = () => {
    onViewCollection(collection);
  };

  const onEdit = () => {
    onEditCollection(collection);
  };

  return (
    <Space
      className={styles.collectionActionContainer}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Button type="default" onClick={onView}>View</Button>
      <Button type="primary" onClick={onEdit}>Edit</Button>
    </Space>
  );
}

export default CollectionAction;
