import { PaymentIntent } from 'src/types/PaymentIntent';
import { Page, PaginationParams, SearchParams, SortParams } from './typing';
import useFetch from './useFetch';

const KEY_FAQ_QUERIES = ['paymentIntent'];

export type UseFetchPaymentIntentParams = PaginationParams &
  SortParams &
  SearchParams;

export const useFetchPaymentIntents = (params?: UseFetchPaymentIntentParams) =>
  useFetch<Page<PaymentIntent>>({
    key: [...KEY_FAQ_QUERIES, params],
    url: '/admin/totams/transactions',
    searchParams: params,
  });
