import CollaboratorRouter from '../router/collaborator';
import AdminRouter from '../router/admin';

interface App {
  subDomain: string;
  router: typeof AdminRouter;
  main: boolean;
}

export const APPS: App[] = [
  {
    subDomain: 'dashboard',
    router: AdminRouter,
    main: true,
  },
  {
    subDomain: 'collab',
    router: CollaboratorRouter,
    main: false,
  },
];

const getSubdomain = (location: string) => {
  const locationParts = location.split('.');

  let sliceLength = -2;

  // For localhost
  const isLocalhost = locationParts.slice(-1)[0] === 'localhost';

  if (isLocalhost) sliceLength = -1;

  return locationParts.slice(0, sliceLength).join('');
};

export const getAppRouter = () => {
  const subDomain = getSubdomain(window.location.hostname);

  const mainApp = APPS.find((app) => app.main);

  if (!mainApp) throw new Error('Main app not defined');

  if (subDomain === '') return mainApp.router;

  const app = APPS.find((subApp) => subDomain.includes(subApp.subDomain));

  if (!app) return mainApp.router;

  return app.router;
};
