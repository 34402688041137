import { SVGProps } from 'react';

function HeartIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.39511 3.05032C8.81383 2.59965 9.17393 2.13164 9.61776 1.75897C10.899 0.684289 12.5404 0.554288 13.8887 1.36897C15.3458 2.24431 16.1581 3.95167 15.9739 5.58969C15.8064 7.08905 15.1448 8.34573 14.165 9.40308C12.934 10.7378 11.6276 12.0118 10.3463 13.3031C9.72663 13.9271 9.08181 14.5252 8.42861 15.1578C8.17738 14.9152 7.91778 14.6812 7.67492 14.4298C6.14243 12.9131 4.59318 11.4051 3.07743 9.86242C2.39074 9.16041 1.79616 8.36307 1.39419 7.44439C0.891735 6.28304 0.632132 5.06969 1.05085 3.83033C1.55331 2.34831 2.52473 1.29096 4.0321 0.95296C5.67347 0.588955 6.98823 1.26496 8.03502 2.57365C8.10201 2.65165 8.16063 2.73832 8.21925 2.82499C8.27787 2.91165 8.33649 2.98966 8.37837 3.05899L8.39511 3.05032Z"
        stroke="currentColor"
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default HeartIcon;
