import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import { ProofType, SubmissionProof } from 'src/types/Submission';
import { Image, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import styles from './ActivitySubmissionDetail.module.scss';

type SubmissionFileProps = {
  type?: ProofType;
  proof?: SubmissionProof;
  open?: boolean;
};

export default function SubmissionFile(props: SubmissionFileProps) {
  const { type = ProofType.Text, proof, open } = props;
  const audioPlayerRef = useRef<ReactAudioPlayer>(null);

  const pauseAudio = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.audioEl.current?.pause();
    }
  };

  useEffect(() => {
    if (!open) {
      pauseAudio();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      pauseAudio();
    }
  }, [open]);

  return (
    <div className={styles.submissionFileItem}>
      {[ProofType.Text, ProofType.TextAndPhoto].includes(type) && (
        <Typography.Text>{proof?.text}</Typography.Text>
      )}
      {type === ProofType.Discord && (
        <Typography.Text>{proof?.file}</Typography.Text>
      )}
      {[ProofType.Photo, ProofType.TextAndPhoto].includes(type) && (
        <div>
          <Image
            className={styles.videoContainer}
            src={`${process.env.REACT_APP_API_URL}v2.0/files/${proof?.file}`}
          />
        </div>
      )}
      {type === ProofType.Video && (
        <div className={styles.videoContainer}>
          <ReactPlayer
            width="100%"
            height="100%"
            url={`${process.env.REACT_APP_API_URL}v2.0/files/${proof?.file}`}
            controls
            className={styles.reactPlayer}
            playing={open}
          />
        </div>
      )}
      {type === ProofType.Audio && (
        <ReactAudioPlayer
          ref={audioPlayerRef}
          src={`${process.env.REACT_APP_API_URL}v2.0/files/${proof?.file}`}
          autoPlay={false}
          controls
        />
      )}
    </div>
  );
}

SubmissionFile.defaultProps = {
  proof: {
    file: '',
    text: '',
  },
};
