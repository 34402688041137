import {
  Card,
  Col,
  Input,
  Row,
  Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import { useFetchReferralBoard, UseFetchReferralLedgerParams } from 'src/hooks/api/referral-ledger';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { ReferralBoard } from 'src/types';

export default function ReferralBoardPage() {
  // Hooks
  const {
    pagination, sorters, syncDataPagination, handleParamsChange,
  } = useTableHelper<ReferralBoard>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();

  // Server states
  const params: UseFetchReferralLedgerParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);

  const dataSource = useFetchReferralBoard({ ...params });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<ReferralBoard> => [
    {
      title: 'Referrer user name',
      dataIndex: ['username'],
    },
    {
      title: 'Referrer email',
      dataIndex: ['email'],
    },
    {
      title: 'Total number of referrals',
      dataIndex: ['totalReferral'],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Total Referral XP earned',
      dataIndex: ['totalXPearned'],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_, record) => (record?.totalReferral ? record.totalReferral * 50 : 0),
    },
    {
      title: 'Referral code',
      dataIndex: ['refCode'],
    },

  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col span={24}>
        <Card title="Referral leaderboard" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
    </Row>
  );
}
