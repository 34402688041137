import { Tabs } from 'antd';
import { TabItem } from 'src/types';
import ManageTagTab from './ManageTagTab';
import TagUserTab from './TagUserTab';

function Tag() {
  const tabs: TabItem[] = [
    {
      label: 'Manage Tags',
      key: 'manage',
      children: <ManageTagTab />,
    },
    {
      label: 'Tag Holder',
      key: 'user',
      children: <TagUserTab />,
    },
  ];

  return <Tabs type="card" items={tabs.map((tab) => tab)} />;
}

export default Tag;
