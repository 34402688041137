import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './slices/app-slice';
import authReducer from './slices/auth-slice';
import collaboratorReducer from './slices/collaborator-slice';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  collaborator: collaboratorReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
