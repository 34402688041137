import {
  useContractEvent,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import ABI from 'src/abi/nft-factory.json';

type UseNFTFactoryContractType = {
  name: string;
  symbol: string;
  maxSupply: number;
  baseURI: string;
  onReceiveNFTAddress: (address: string) => Promise<void>;
};

export default function useNFTFactoryContract(type: UseNFTFactoryContractType) {
  const {
    name,
    symbol,
    maxSupply = Number(0),
    baseURI,
    onReceiveNFTAddress,
  } = type;

  /**
   * `process.env.REACT_APP_NFT_FACTORY`
   * This is the contract address of the TOTA NFT Factory smart contract.
   * This is deployed only once. This factory smart contract is used to
   * deploy other Tota collection smart contracts
   */

  const { config: configNewCollection } = usePrepareContractWrite({
    address: process.env.REACT_APP_NFT_FACTORY as `0x${string}`,
    abi: ABI,
    functionName: 'newCollection',
    args: [name, symbol, maxSupply, baseURI],
  });
  const { write: newCollection, data: dataNewCollection } =
    useContractWrite(configNewCollection);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: dataNewCollection?.hash,
  });

  useContractEvent({
    address: process.env.REACT_APP_NFT_FACTORY as `0x${string}`,
    abi: ABI,
    eventName: 'NFTDeployed',
    listener(...args: any[]) {
      console.log('listen args', args);
      const txhashFromArgs = args[5].transactionHash;
      if (txhashFromArgs !== dataNewCollection?.hash) return;

      onReceiveNFTAddress(args[0]);
    },
  });

  return {
    newCollection,
    isLoading,
    isSuccess,
    dataNewCollection,
  };
}
