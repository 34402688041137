import { useCallback, useState } from 'react';
import type { TableProps, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Page } from './api/typing';

export default function useTableHelper<E>() {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 25,
    showSizeChanger: true,
    pageSizeOptions: [25, 50, 100],
  });
  const [sorters, setSorters] = useState<SorterResult<E>[]>();
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>();

  const syncDataPagination = useCallback((data: Page<E> | undefined) => {
    if (data != null) {
      setPagination((currentPagination) => ({
        ...currentPagination,
        total: data.meta.totalItems,
      }));
    }
  }, []);

  const handleParamsChange: TableProps<E>['onChange'] = (
    newPagination,
    newFilter,
    newSorters,
  ) => {
    setPagination(newPagination);
    setSorters(Array.isArray(newSorters) ? newSorters : [newSorters]);
    setFilters(newFilter);
  };

  return {
    pagination,
    sorters,
    filters,
    setFilters,
    syncDataPagination,
    handleParamsChange,
  };
}
