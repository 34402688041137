import { Card, Col, Input, Row, Statistic, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import {
  UseFetchPaymentIntentParams,
  useFetchPaymentIntents,
} from 'src/hooks/api/payment-intent';

import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { PaymentIntent } from 'src/types/PaymentIntent';

export default function PaymentIntentPage() {
  // Hooks
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<PaymentIntent>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();

  // Server states
  const params: UseFetchPaymentIntentParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search]);

  const dataSource = useFetchPaymentIntents({ ...params });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<PaymentIntent> => [
    {
      title: 'ID',
      dataIndex: ['paymentIntentId'],
    },
    {
      title: 'Username',
      dataIndex: ['user', 'username'],
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value) => <Statistic value={value} />,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col span={24}>
        <Card title="PaymentIntents" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
          />
        </Card>
      </Col>
    </Row>
  );
}
