import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';

export default function NotFound() {
  return (
    <Space>
      <ExclamationCircleOutlined />
      Not found
    </Space>
  );
}
