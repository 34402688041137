import { ProCard, ProDescriptions } from '@ant-design/pro-components';
import { useEffect, useState } from 'react';
import { useFetchWhitelistUsersForDrop } from 'src/hooks/api';
import { Drop, DropStatistics } from 'src/hooks/api/typing';
import EligibleUserList from './EligibleUserList';

type MintStatusProps = {
  drop: Drop | undefined;
  dropStatistics: DropStatistics | undefined;
};

function MintStatus(param: MintStatusProps) {
  const { drop, dropStatistics } = param;

  const { data: eligibleUserOfDrop = [], isLoading } =
    useFetchWhitelistUsersForDrop(drop?.id);

  const [remainingNFTs, setRemainingNFTs] = useState(0);
  useEffect(() => {
    if (drop && dropStatistics) {
      setRemainingNFTs(
        drop.qty -
          dropStatistics.totalNFTAirDropped -
          dropStatistics.totalNFTMinted,
      );
    }
  }, [drop, dropStatistics]);

  return (
    <ProCard title="MINT STATUS" split="horizontal" bordered>
      <ProCard>
        <ProDescriptions
          column={1}
          emptyText="-"
          dataSource={param}
          columns={[
            {
              title: 'Total NFTs minted in Pre sale',
              dataIndex: ['dropStatistics', 'totalPreSaleNFT'],
            },
            {
              title: 'Total NFTs minted in Public sale',
              dataIndex: ['dropStatistics', 'totalPublicSaleNFT'],
            },
            {
              title: 'Total NFTs remaining',
              renderText: () => remainingNFTs,
            },
          ]}
        />
      </ProCard>
      <ProCard title="WHITELIST USERS">
        <EligibleUserList
          eligibleUsers={eligibleUserOfDrop}
          loading={isLoading}
        />
      </ProCard>
    </ProCard>
  );
}

export default MintStatus;
