import { PlusOutlined } from '@ant-design/icons';

import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  notification as notificationAntd,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import CreateNotification, {
  NotificationForm,
} from 'src/components/NotificationCreate';
import formatDate from 'src/helper/formatDate';
import {
  UseFetchNotificationParams,
  useCreateNotification,
  useDeleteNotification,
  useFetchNotifications,
  useUpdateNotification,
  useUpdateSendAllUserNotification,
} from 'src/hooks/api/notification';

import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import {
  Notification,
  NotificationContent,
  NotificationTitle,
  NotificationType,
} from 'src/types/Notification';
import { ErrorDataResponse } from 'src/typing';

function getKeyByValueNotificationContent(value: string): string | undefined {
  const keys = Object.keys(
    NotificationContent,
  ) as (keyof typeof NotificationContent)[];
  const foundKey = keys.find((key) => NotificationContent[key] === value);
  return foundKey || undefined;
}

function getKeyByValueNotificationTitle(value: string): string | undefined {
  const keys = Object.keys(
    NotificationTitle,
  ) as (keyof typeof NotificationTitle)[];
  const foundKey = keys.find((key) => NotificationTitle[key] === value);
  return foundKey || undefined;
}

export default function NotificationPage() {
  // Hooks
  const {
    pagination,
    sorters,
    filters,
    syncDataPagination,
    handleParamsChange,
  } = useTableHelper<Notification>();
  const { mapTableParams } = useMapTableParam();
  // Local states
  const [search, setSearch] = useState<string | undefined>();
  const [notification, setNotification] = useState<Notification | undefined>(
    undefined,
  );
  const [edit, setEdit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  // Server states
  const params: UseFetchNotificationParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
      filters,
    });

    return {
      ...baseParams,
      search,
    };
  }, [mapTableParams, pagination, sorters, search, filters]);

  const dataSource = useFetchNotifications({ ...params });
  const { mutateAsync: mutationCreateNotification } = useCreateNotification();
  const { mutateAsync: mutationUpdateNotification } = useUpdateNotification();
  const { mutateAsync: mutationDeleteNotification } = useDeleteNotification();
  const { mutateAsync: mutationUpdateSendAllUserNotification } =
    useUpdateSendAllUserNotification();

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleAddNotification = () => {
    setNotification(undefined);
    setEdit(false);
    setOpenDrawer(true);
  };

  const handleEdit = (record: Notification) => {
    setEdit(true);
    setNotification(record);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setNotification(undefined);
    setEdit(false);
  };

  const handleSubmit = async (formValues: NotificationForm) => {
    if (!edit) {
      try {
        await mutationCreateNotification(formValues);
        setOpenDrawer(false);
        notificationAntd.success({
          message: 'Notification created successfully',
        });
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notificationAntd.error({
          message: `Notification create failed: ${errorMessage}`,
        });
      }
    }

    if (edit) {
      try {
        await mutationUpdateNotification(formValues);
        notificationAntd.success({ message: 'Notification updated success' });
        setOpenDrawer(false);

        if (formValues.selectedAllUser) {
          await mutationUpdateSendAllUserNotification(formValues);
        }
      } catch (error) {
        const errorMessage = (error as AxiosError<ErrorDataResponse>).response
          ?.data.message;
        notificationAntd.error({
          message: `Notification update failed: ${errorMessage}`,
        });
      }
    }
  };

  const handleDeleteNoti = async (deleteNoti: Notification) => {
    try {
      await mutationDeleteNotification(deleteNoti);
      notificationAntd.success({ message: 'Noti deleted successfully' });
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notificationAntd.error({
        message: `Noti deleted failed: ${errorMessage}`,
      });
    }
  };

  const renderSearch = () => (
    <Input.Search allowClear placeholder="Search" onSearch={handleSearch} />
  );

  const renderColumns = (): ColumnsType<Notification> => [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (value) => getKeyByValueNotificationTitle(value) || value,
    },
    {
      title: 'Content',
      dataIndex: 'content',
      render: (value) => getKeyByValueNotificationContent(value) || value,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: 'Global',
          value: 0,
        },
        {
          text: 'User',
          value: 1,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.type === value,
      render: (value) =>
        value === NotificationType.Global ? 'Global' : 'User',
    },
    {
      title: 'Metadata',
      dataIndex: 'metadata',
      responsive: ['sm'],
    },

    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: true,
      render: (value) => formatDate(value),
    },
    {
      title: 'Action',
      key: 'actions',
      width: '15%',
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete the notification"
            description="Are you sure to delete this notification?"
            onConfirm={async () => handleDeleteNoti(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col flex="0 0 0">
        <Button
          type="primary"
          onClick={handleAddNotification}
          icon={<PlusOutlined />}
        >
          Add notification
        </Button>
      </Col>
      <Col span={24}>
        <Card title="Notifications" extra={renderSearch()}>
          <Table
            columns={renderColumns()}
            dataSource={dataSource.data?.items}
            loading={dataSource.isLoading}
            pagination={pagination}
            onChange={handleParamsChange}
            scroll={{ x: true }}
          />
        </Card>
      </Col>
      <CreateNotification
        notification={notification}
        onClose={handleClose}
        open={openDrawer}
        onSubmit={(assetData) => handleSubmit(assetData)}
      />
    </Row>
  );
}
