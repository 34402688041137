import { User } from './User';

export enum ProofType {
  Photo = 'photo',
  Video = 'video',
  Discord = 'discord',
  Text = 'text',
  Audio = 'audio',
  TextAndPhoto = 'textAndPhoto',
}

export enum SubmissionProcessType {
  UploadOnly = 'upload_only',
  ClickCaptureRecord = 'click_capture_record',
  All = 'all',
}

export type SubmissionType = {
  id: number;
  proofLogo: string;
  proofImage: string;
  proofType: ProofType;
  title: string;
  text: string;
  disclaimer: string;
  isAutoDeleteSubmission: boolean;
  isRecurring: boolean;
  submissionProcess?: SubmissionProcessType;
  maxAllowed?: number;
  requiredEntries?: number;
  maxEntries?: number;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
};

export enum SubmissionStatus {
  Partaken = 'partaken',
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export type SubmissionProof = {
  file?: string;
  text?: string;
};

export type Submission = {
  id: number;
  user: User;
  type: SubmissionType;
  title?: string;
  disclaimer?: string;
  submittedDate?: Date | null;
  proof: SubmissionProof;
  status: SubmissionStatus;
  verificationNote?: string;
  isFavorite: boolean;
};
