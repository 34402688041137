import { ProColumns, ProTable } from '@ant-design/pro-components';
import { App, Button, Modal, ModalProps, Popconfirm, Space, Tabs } from 'antd';
import { useState } from 'react';
import UserTable, { UserTableProps } from 'src/components/UserTable';
import {
  useFetchActivityWinners,
  useRemoveActivityWinner,
} from 'src/hooks/api/activity';
import useTableHelper from 'src/hooks/useTableHelpers';
import { Activity } from 'src/types';

export type AssignWinnerValue = {
  activityId?: number;
  userIds: string[];
};

export type TagAssignModalProps = Omit<ModalProps, 'onOk'> & {
  activity?: Activity;
  onOk?: (value: AssignWinnerValue) => void | Promise<void>;
};

function ListWinner(props: TagAssignModalProps) {
  const { pagination, handleParamsChange } = useTableHelper<any>();

  const app = App.useApp();
  const { activity } = props;
  const dataSource = useFetchActivityWinners(activity?.id || 0);
  const { mutateAsync: removeWinner } = useRemoveActivityWinner();

  const handleDeleteWinner = async (data: any) => {
    try {
      await removeWinner({
        id: activity?.id,
        userId: data?.userId,
      });
      app.message.success('Remove user successfully!');
    } catch {
      app.message.error('Remove user successfully!');
    }
  };

  const renderColumns = (): ProColumns<any>[] => [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },

    {
      title: 'Actions',
      key: 'actions',
      valueType: 'option',
      width: '1%',
      render: (_, user) => (
        <Space>
          <Popconfirm
            title="Remove winner"
            description="Are you sure you want to remove?"
            onConfirm={async () => handleDeleteWinner(user)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Remove</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <ProTable
      ghost
      search={false}
      options={false}
      columnEmptyText="-"
      columns={renderColumns()}
      dataSource={dataSource.data?.items || []}
      loading={dataSource.isLoading}
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}

export default function WinnerAssignModal(props: TagAssignModalProps) {
  const {
    activity,
    onOk = () => {},
    onCancel = () => {},
    ...otherProps
  } = props;

  // Local states
  const [selectedUserIds, setSelectedUserIds] = useState<React.Key[]>([]);

  const resetState = () => {
    setSelectedUserIds([]);
  };

  const userTableSelection: UserTableProps['rowSelection'] = {
    selectedRowKeys: selectedUserIds,
    onChange: (newKeys: React.Key[]) => setSelectedUserIds(newKeys),
  };

  const handleOk: ModalProps['onOk'] = async () => {
    await onOk({
      activityId: activity?.id,
      userIds: selectedUserIds.map((key) => key.toString()),
    });
    resetState();
  };

  const handleCancel: ModalProps['onCancel'] = (e) => {
    onCancel(e);
    resetState();
  };

  const renderTitle = () => (
    <Space>
      <Space style={{ marginRight: '30px' }}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleOk} type="primary">
          {' '}
          Assign
        </Button>
      </Space>
    </Space>
  );

  return (
    <Modal
      {...otherProps}
      centered
      width={1024}
      footer={false}
      onCancel={handleCancel}
    >
      {activity?.title}
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: 'Assign',
            key: '1',
            children: (
              <>
                {renderTitle()}
                <UserTable
                  isSelect
                  rowSelection={userTableSelection}
                  optionFilter={{ activityIds: [activity?.id] } as any}
                />
              </>
            ),
          },
          {
            label: 'List winner',
            key: '2',
            children: <ListWinner {...props} />,
          },
        ]}
      />
    </Modal>
  );
}
