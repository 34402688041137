import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ReactElement } from 'react';
import { Drop } from 'src/hooks/api/typing';
import useTableHelper from 'src/hooks/useTableHelpers';
import DropAction from './DropAction';

export interface DropListColumnsTypes {
  title: string;
  dataIndex: string;
  key: string;
}

export interface DropListDataType {
  key: string;
  name: string;
  presaleStart: string;
  presaleEnd: string;
  publicSaleStart: string;
  publicSaleEnd: string;
  collectionId: number;
}

export interface DropListProps {
  drops: Drop[];
  onView(drop: Drop): void;
  onEdit(drop: Drop): void;
}

function DropList(props: DropListProps): ReactElement {
  const { drops = [], onView, onEdit } = props;
  const { pagination, handleParamsChange } = useTableHelper<Drop>();

  const columns: ColumnsType<Drop> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Pre Sale Start',
      dataIndex: 'presaleStart',
      key: 'presaleStart',
      render: (presaleStart) => (
        <Tag color="green">
          {`${new Date(presaleStart).toLocaleDateString()} - ${new Date(
            presaleStart,
          ).toLocaleTimeString()}`}
        </Tag>
      ),
    },
    {
      title: 'Pre Sale End',
      dataIndex: 'presaleEnd',
      key: 'presaleEnd',
      render: (presaleEnd) => (
        <Tag color="red">
          {`${new Date(presaleEnd).toLocaleDateString()} - ${new Date(
            presaleEnd,
          ).toLocaleTimeString()}`}
        </Tag>
      ),
    },
    {
      title: 'Public Sale Start',
      dataIndex: 'publicSaleStart',
      key: 'publicSaleStart',
      render: (publicSaleStart) => (
        <Tag color="green">
          {`${new Date(publicSaleStart).toLocaleDateString()} - ${new Date(
            publicSaleStart,
          ).toLocaleTimeString()}`}
        </Tag>
      ),
    },
    {
      title: 'Public Sale End',
      dataIndex: 'publicSaleEnd',
      key: 'publicSaleEnd',
      render: (publicSaleEnd) => (
        <Tag color="red">
          {`${new Date(publicSaleEnd).toLocaleDateString()} - ${new Date(
            publicSaleEnd,
          ).toLocaleTimeString()}`}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (x: Drop) => (
        <DropAction drop={x} onView={onView} onEdit={onEdit} />
      ),
    },
  ];

  return (
    <Table
      dataSource={drops || []}
      columns={columns}
      rowKey="id"
      pagination={pagination}
      onChange={handleParamsChange}
    />
  );
}

export default DropList;
