import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AllegianceSubmission, SubmissionStatus } from 'src/types';
import useApi from '../useApi';
import
{
  Page,
  PaginationParams,
  SearchParams,
  SortParams,
} from './typing';
import useFetch from './useFetch';

const KEY_ALLEGIANCE_SUBMISSION_QUERIES = ['allegianceSubmissions'];

export type FilterAllegianceSubmission = {
  proofType?: string[];
  startCreatedAt?: Date | null;
  endCreatedAt?: Date | null;
};

export type UseFetchAllegianceSubmissionsParams = PaginationParams & SortParams & SearchParams & {
  startSubmittedDate?: Date;
  endSubmittedDate?: Date;
  status?: SubmissionStatus;
};

export type UpdateAllegianceSubmissionStatusRequest = {
  id: number;
  status: string;
  note?: string;
};

export const useFetchAllegianceSubmissions = (
  params?: UseFetchAllegianceSubmissionsParams,
) => {
  const searchParams = {
    ...params,
    startSubmittedDate: params?.startSubmittedDate?.toISOString(),
    endSubmittedDate: params?.endSubmittedDate?.toISOString(),
    statues: [
      SubmissionStatus.Pending.toString(),
      SubmissionStatus.Accepted.toString(),
      SubmissionStatus.Rejected.toString(),
    ].join(','),
  };

  return useFetch<Page<AllegianceSubmission>, Page<AllegianceSubmission>>({
    key: [...KEY_ALLEGIANCE_SUBMISSION_QUERIES, params],
    url: '/admin/allegiance-submissions',
    searchParams,
    parseFn: (data) => {
      const allegianceSubmissions: AllegianceSubmission[] = data.items || [];

      const items: AllegianceSubmission[] = allegianceSubmissions.map((allegianceSubmission) => ({
        ...allegianceSubmission,
        user: {
          ...allegianceSubmission.user,
          walletAddress: allegianceSubmission.user.custodialWalletAddress,
        },
      }));

      return {
        ...data,
        items,
      };
    },
  });
};

export const useUpdateAllegianceSubmissionStatus = () => {
  const queryClient = useQueryClient();
  const { requester } = useApi();

  return useMutation(
    async ({ id, status, note }: UpdateAllegianceSubmissionStatusRequest) => {
      await requester.put(`/admin/allegiance-submissions/${id}/status`, {
        value: status,
        note,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: KEY_ALLEGIANCE_SUBMISSION_QUERIES });
      },
    },
  );
};
