import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
} from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

export default function useWeb3() {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();

  return {
    address,
    isConnected,
    connect,
    disconnect,
    chain,
  };
}
