import { ReferralBoard, ReferralLedger } from 'src/types';
import {
  Page, PaginationParams, SearchParams, SortParams,
} from './typing';
import useFetch from './useFetch';

const KEY_REFERRAL_LEDGER_QUERIES = ['referral-ledger'];

export type UseFetchReferralLedgerParams = PaginationParams &
SortParams &
SearchParams;

export const useFetchReferralLedger = (params?: UseFetchReferralLedgerParams) => useFetch<Page<ReferralLedger>>({
  key: [...KEY_REFERRAL_LEDGER_QUERIES, params],
  url: 'admin/users/referral-ledgers',
  searchParams: params,
});

export const useFetchReferralBoard = (params?: UseFetchReferralLedgerParams) => useFetch<Page<ReferralBoard>>({
  key: [...KEY_REFERRAL_LEDGER_QUERIES, params],
  url: 'admin/users/referral-leaderboard',
  searchParams: params,
});
