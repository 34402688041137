import useFetch from './useFetch';

type DashboardData = {
  totalRegisteredUser: number;
  totalActivity: number;
  totalCollaborator: number;
  totalXPsAwarded: number;
  totalActivitySubmission: number;
  totalUserExternalWallet: number;
  totalUserRegisteredViaReferral: number;
};

const useFetchDashboardData = () => useFetch<DashboardData>({
  key: ['dashboard'],
  url: '/admin/statistics/dashboard',
});

export default useFetchDashboardData;
