import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { configResponsive } from 'ahooks';
import { App, ConfigProvider, ThemeConfig, notification } from 'antd';
import enUS from 'antd/locale/en_US';
import 'antd/dist/reset.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './global.scss';
import reportWebVitals from './reportWebVitals';
import { getAppRouter } from './helper/appRouter';
import store, { persistor } from './store';

const appRouter = getAppRouter();

// TODO: move this to a separated files
const customTheme: ThemeConfig = {
  token: {
    colorPrimary: '#00b96b',
  },
};

notification.config({
  placement: 'bottomRight',
});
configResponsive({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <ConfigProvider theme={customTheme} locale={enUS}>
            <App>
              <RouterProvider router={appRouter} />
            </App>
          </ConfigProvider>
        </QueryClientProvider>
      </PersistGate>
    </StoreProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
