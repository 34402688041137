import { FilterOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Popover,
  Select,
  Space,
} from 'antd';
import { ReactNode } from 'react';
import { Allegiance, Tag } from 'src/types';

const CheckboxGroup = Checkbox.Group;

const options = [
  {
    label: 'Whitelisted',
    value: 'isWhitelisted',
  },
  {
    label: 'Airdropped',
    value: 'isAirdropped',
  },
  {
    label: 'Banned',
    value: 'isBanned',
  },
];

const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';

export type FormDataFilter = {
  options: string[];
  createdAt: [Date | null, Date | null];
  allegiance: string;
  tagIds: string[];
};

export type FilterProps = {
  open: boolean;
  onOpen: () => void;
  onCancel: () => void;
  onReset: () => void;
  onSubmit: (data: FormDataFilter) => void;
  allegiances?: Allegiance[];
  children?: ReactNode;
  isOptions?: boolean;
  isAllegiance?: boolean;
  tags?: Tag[];
};

const { Option } = Select;

export default function UserFilter(props: FilterProps) {
  const {
    onCancel,
    onSubmit,
    allegiances,
    open,
    onOpen,
    onReset,
    children,
    isOptions,
    isAllegiance,
    tags,
  } = props;

  const [form] = Form.useForm();

  const handleSubmit = (data: any) => {
    onSubmit(data);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleReset = () => {
    form.resetFields();
    onReset();
  };

  const getCreatedAt = (value: Date[]) => [
    new Date(value[0]),
    new Date(value[1]),
  ];

  const hasData = () => {
    const values = form.getFieldsValue();
    return Object.values(values).some(
      (value) => value !== undefined && value !== '',
    );
  };

  const renderContent = () => (
    <Form form={form} onFinish={handleSubmit}>
      <Form.Item
        name="createdAt"
        label="Created at"
        valuePropName="createdAt"
        getValueFromEvent={getCreatedAt}
      >
        <RangePicker format={dateFormat} allowClear />
      </Form.Item>
      {isOptions ? (
        <Form.Item label="Options" name="options">
          <CheckboxGroup options={options} />
        </Form.Item>
      ) : null}
      {isAllegiance ? (
        <Form.Item label="Allegiance" name="allegiance">
          <Select>
            {allegiances?.map((allegiance: Allegiance) => (
              <Option key={allegiance.id} value={allegiance.id}>
                {allegiance?.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ) : null}
      <Form.Item label="Tag" name="tagIds">
        <Select mode="multiple">
          {tags?.map((tag: Tag) => (
            <Option key={tag.id} value={tag.id}>
              {tag?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {children || null}
      <Form.Item style={{ textAlign: 'right' }}>
        <Space size="small">
          <Button type="dashed" onClick={handleReset}>
            Reset
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );

  return (
    <Popover
      content={renderContent}
      title="Filter"
      trigger="click"
      open={open}
      onOpenChange={onOpen}
    >
      <Button type={hasData() ? 'primary' : 'default'}>
        <FilterOutlined />
      </Button>
    </Popover>
  );
}

UserFilter.defaultProps = {
  isOptions: true,
  isAllegiance: true,
};
