import {
  useBalance,
  useContractEvent,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import ABI from 'src/abi/tota-nft.json';
import { message } from 'antd';

export default function useWithdrawTotaNFTContract(
  contractAddress: string | undefined,
) {
  const [messageApi, contextHolder] = message.useMessage();

  const withdrawSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Withdraw succeed',
      duration: 1,
    });
  };

  const { data: contractBalance } = useBalance({
    address: contractAddress as `0x${string}`,
    enabled: contractAddress !== undefined,
  });

  const { config: configWithdraw, error: prepareError } =
    usePrepareContractWrite({
      address: contractAddress as `0x${string}`,
      abi: ABI,
      functionName: 'withdraw',
      enabled: Number(contractBalance?.formatted) > 0,
    });

  console.log('configWithdraw', configWithdraw);
  console.log('prepareError', prepareError);

  const { write: withdraw, data: dataWithdraw } =
    useContractWrite(configWithdraw);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: dataWithdraw?.hash,
  });

  useContractEvent({
    address: contractAddress as `0x${string}`,
    abi: ABI,
    eventName: 'Withdraw',
    listener(...args: any[]) {
      console.log('args: ', args);
      withdrawSuccess();
    },
  });

  return {
    withdraw,
    isSuccess,
    isLoading,
    dataWithdraw,
    contextHolder,
    withdrawSuccess,
    contractBalance,
  };
}
