import { FilterOutlined } from '@ant-design/icons';
import { Col, Row, Space, Button, Table, App } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo } from 'react';
import formatDate from 'src/helper/formatDate';
import {
  UseFetchTagUserParams,
  useFetchTagUsers,
  useRemoveTagUser,
} from 'src/hooks/api/tag';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { TagUserDetail } from 'src/types';

export type TagUserTableProps = {
  tagUserDetail: TagUserDetail;
};

export default function TagUserTable(props: TagUserTableProps) {
  const { tagUserDetail } = props;

  // Hooks
  const app = App.useApp();
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<TagUserDetail>();
  const { mapTableParams } = useMapTableParam();
  // Server states
  const params: UseFetchTagUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });

    if (tagUserDetail) {
      return {
        ...baseParams,
        userIds: [tagUserDetail.userId],
        tagIds: [tagUserDetail.tag.id],
        isGroup: false,
      };
    }

    return {
      ...baseParams,
    };
  }, [mapTableParams, pagination, sorters, tagUserDetail]);

  const tagUsers = useFetchTagUsers({ ...params });
  const { mutateAsync: removeTagUser } = useRemoveTagUser();

  useEffect(() => {
    syncDataPagination(tagUsers.data);
  }, [syncDataPagination, tagUsers.data]);

  const handleUnAssignButtonClick = async (tagUser: TagUserDetail) => {
    try {
      await removeTagUser({
        tagId: tagUser.tag.id,
        tagUserId: tagUser.id,
      });
      app.message.success('Success');
    } catch (err: any) {
      app.message.error(err.message);
    }
  };

  const renderTitle = () => (
    <Row>
      <Col flex={1} />
      <Col flex={0}>
        <Space direction="vertical">
          <Button icon={<FilterOutlined />} />
        </Space>
      </Col>
    </Row>
  );

  const renderColumns = (): ColumnsType<TagUserDetail> => [
    {
      title: 'Username',
      dataIndex: ['username'],
    },
    {
      title: 'Tag name',
      dataIndex: ['tag', 'name'],
    },
    {
      title: 'Created at',
      dataIndex: ['createdAt'],
      render: (value) => formatDate(value),
    },
    {
      title: '',
      key: 'actions',
      width: '1%',
      render: (_, record) => (
        <Button danger onClick={() => handleUnAssignButtonClick(record)}>
          Remove tag
        </Button>
      ),
    },
  ];

  return (
    <Row justify="end" gutter={[0, 16]}>
      <Col span={24}>
        <Table
          title={renderTitle}
          columns={renderColumns()}
          dataSource={tagUsers.data?.items}
          loading={tagUsers.isLoading}
          pagination={pagination}
          onChange={handleParamsChange}
        />
      </Col>
    </Row>
  );
}
