import { Form, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Input from 'src/pages/CollaboratorDashboard/_shared/components/Input';
import Button from 'src/pages/CollaboratorDashboard/_shared/components/Button';

import useSendEmail from 'src/hooks/api/email';

import { AxiosError } from 'axios';
import { ErrorDataResponse } from 'src/typing';
import styles from './style.module.scss';

export default function MasterCardForgotPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { mutateAsync: sendEmailMutate } = useSendEmail();

  const onFinish = async (values: { email: string }) => {
    const formData = {
      subject: 'Password reset request from collaborator',
      email: values.email,
      message: `Collaborator has requested to reset his password for email ${values.email}`,
    };

    try {
      await sendEmailMutate(formData);
      notification.success({ message: 'Password update has been submitted' });
      navigate('/sign-in');
      form.resetFields();
    } catch (error) {
      const errorMessage = (error as AxiosError<ErrorDataResponse>).response
        ?.data.message;
      notification.error({
        message: `Password update submission failed: ${errorMessage}`,
      });
    }
  };

  return (
    <main className={classNames(styles.main)}>
      <div className={classNames(styles.container, 'flex-col m-auto')}>
        <img className={styles.logo} src="logo.svg" alt="Planet Tota" />
        <h3 className={classNames(styles.title, 'mb-48')}>
          HELLO AND WELCOME TO
          <br />
          THE COLLABORATOR’S CORNER
        </h3>
        <h3
          className={classNames(
            classNames(styles.title, styles.titleMobile),
            'mb-48',
          )}
        >
          HELLO AND
          <br />
          WELCOME TO THE
          <br />
          COLLABORATOR’S CORNER
        </h3>
        <Form
          layout="vertical"
          className={classNames(styles.forgotPasswordForm, 'mb-120 w-full')}
          onFinish={onFinish}
        >
          <div className="subtitle1 color-white text-center mb-32">
            Please enter your email address. Our team will get back to you at
            the earliest
          </div>
          <Form.Item
            className="mb-24"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email address.',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address!',
              },
            ]}
          >
            <Input placeholder="xyz@gmail.com" className="mb-16" />
          </Form.Item>
          <div
            className={classNames(
              styles.link,
              'mt-12 mb-16 color-white text-center',
            )}
          >
            <Link to="/sign-in" className="body2">
              Already have an account?
            </Link>
          </div>
          <Button className={styles.loginButton} htmlType="submit">
            Submit
          </Button>
        </Form>
      </div>
    </main>
  );
}
