import { Button, Space } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import { UseFetchUserParams } from 'src/hooks/api';
import useMapTableParam from 'src/hooks/useMapTableParams';
import useTableHelper from 'src/hooks/useTableHelpers';
import { ActivitySubmission, Submission, SubmissionStatus } from 'src/types';
import { useFetchActivitySubmissions } from 'src/hooks/api/activity-submission';
import formatDate from 'src/helper/formatDate';
import SubmissionFile from './SubmissionFile';
import DetailPopup from './DetailPopup';
import SubmissionNoteDeny from './SubmissionNoteDeny';

export type SubmissionDetailProps = {
  submission?: ActivitySubmission | null;
  onApprove: (submission: Submission) => void;
  onReject: (submission: Submission, note?: string) => void;
  onOpenNote: () => void;
  onCloseNote: () => void;
  openNote: boolean;
};

export default function SubmissionMultipleEntries(
  props: SubmissionDetailProps,
) {
  // Hooks
  const { pagination, sorters, syncDataPagination, handleParamsChange } =
    useTableHelper<Submission>();
  const { mapTableParams } = useMapTableParam();
  // Server states
  const params: UseFetchUserParams = useMemo(() => {
    const baseParams = mapTableParams({
      pagination,
      sorters,
    });
    return {
      ...baseParams,
    };
  }, [mapTableParams, pagination, sorters]);

  const { submission, onApprove, onReject, onCloseNote, openNote, onOpenNote } =
    props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReject, setSelectedReject] = useState<Submission | null>(null);

  const dataSource = useFetchActivitySubmissions({
    ...params,
    activityId: submission?.activity?.id?.toString(),
    userId: submission?.user?.id?.toString(),
  });

  useEffect(() => {
    syncDataPagination(dataSource.data);
  }, [syncDataPagination, dataSource.data]);

  const handleApprove = (data: Submission) => {
    onApprove(data);
  };

  const handleReject = (data: Submission, note?: string) => {
    onReject(data, note);
  };

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  const renderColumns = (): ColumnsType<Submission> => [
    {
      title: 'Activity',
      dataIndex: ['activity', 'title'],
      sortDirections: ['ascend', 'descend'],
      render: (value) => (
        <Button type="link" onClick={() => handleShowModal()}>
          {value}
        </Button>
      ),
    },
    {
      title: 'Submission',
      dataIndex: ['type'],
      width: '200px',
      render: (_, record) => (
        <div>
          <SubmissionFile type={record?.type.proofType} proof={record?.proof} />
        </div>
      ),
    },
    {
      title: 'Submitted date',
      dataIndex: 'submittedDate',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? formatDate(value) : 'N/A'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '100px',
    },
    {
      title: '',
      key: 'actions',

      render: (_, record) =>
        record.status === SubmissionStatus.Pending && (
          <Space>
            <Button onClick={() => handleApprove(record)}>Approve</Button>
            <SubmissionNoteDeny
              onClose={onCloseNote}
              open={selectedReject?.id === record.id ? openNote : false}
              onSubmit={(note) => handleReject(record, note)}
            >
              <Button
                type="primary"
                danger
                onClick={() => {
                  onOpenNote();
                  setSelectedReject(record);
                }}
              >
                Reject
              </Button>
            </SubmissionNoteDeny>
          </Space>
        ),
    },
  ];

  return (
    <>
      <Table
        style={{ width: '100%' }}
        columns={renderColumns()}
        dataSource={dataSource.data?.items}
        loading={dataSource.isLoading}
        pagination={pagination}
        onChange={handleParamsChange}
      />
      <DetailPopup
        submission={submission}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
