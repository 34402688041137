import { NFTPerTransaction } from 'src/types/NFT';
import { Page, PaginationParams, SortParams } from './typing';
import useFetch from './useFetch';

const KEY_MANAGE_NFT_TRANSACTION_QUERIES = ['nft-transactions'];

export type UseFetchNFTMintersParams = PaginationParams &
  SortParams &
  FilterAdvancedNFTMinter;

export type FilterAdvancedNFTMinter = {
  collectionId?: number;
  dropId?: number;
};

export const useFetchNFTMinters = (params?: UseFetchNFTMintersParams) =>
  useFetch<Page<NFTPerTransaction>>({
    key: [...KEY_MANAGE_NFT_TRANSACTION_QUERIES, params],
    url: '/nft-transactions',
    searchParams: params,
  });
