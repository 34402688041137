import { useCallback } from 'react';

export default function useGetWebUrl() {
  const getWebUrl = useCallback(
    (url: string): string =>
      new URL(url, process.env.REACT_APP_WEB_URL).toString(),
    [],
  );

  return getWebUrl;
}
