import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCollaboratorProfile } from 'src/store/selectors';
import { useFetchCollaboratorAllegianceSubmissions } from 'src/hooks/api/collaborator-dashboard';
import { AllegianceSubmission } from 'src/types';

import styles from './style.module.scss';

export default function CollaboratorAllegiance() {
  const [search] = useState<string | undefined>();
  const profile = useSelector(selectCollaboratorProfile);

  const params = useMemo(
    () => ({
      statues: 'accepted',
      allegianceId: profile?.allegianceId,
      search,
    }),
    [search, profile?.allegianceId],
  );

  const dataSource = useFetchCollaboratorAllegianceSubmissions(params);

  const renderColumns = (): ColumnsType<AllegianceSubmission> => [
    {
      title: 'User Handle',
      dataIndex: ['user', 'username'],
      width: '30%',
    },
    {
      title:
        profile?.name.toUpperCase() === 'MASTERCARD'
          ? 'Card Issuing Bank'
          : 'Mobile Number',
      dataIndex: ['proof', 'text'],
    },
  ];

  return (
    <div className={styles.container}>
      <div className="pageHeader">
        <h5>
          Allegiance Declared
          <strong>{dataSource.data?.meta.totalItems}</strong>
        </h5>
      </div>
      <div
        className={classNames(styles.bodyColumnWrapper, ' awesome-scrollbar')}
      >
        <Table
          className={styles.table}
          columns={renderColumns()}
          dataSource={dataSource.data?.items}
          loading={dataSource.isLoading}
          pagination={false}
        />
      </div>
    </div>
  );
}
