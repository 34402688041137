import { InputProps, Tabs } from 'antd';
import { TabItem } from 'src/types';
import classNames from 'classnames';
import styles from './style.module.scss';

type Props = InputProps & {
  tabs: TabItem[];
};

export default function InputStyled(props: Props) {
  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <Tabs
      defaultActiveKey="1"
      items={props.tabs}
      onChange={onChange}
      className={classNames(styles.tabs, props.className)}
    />
  );
}
